import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import TodoListTableRowQuickActions, {
	DeleteMailQuickAction
} from 'App/pages/TodoList/TodoListTableRow/TodoListTableRowQuickActions';
import ActivityAttributes from 'App/babel/attributes/ActivityAttributes';
import activityFilters from 'App/babel/filterConfigs/Activity';
import { useTranslation } from 'Components/Helpers/translate';
import { TableColumn, TableRow } from '@upsales/components';
import ActivityListResource from 'App/resources/ActivityList';
import type Activity from 'App/resources/Model/Activity';
import Todo from 'App/resources/Model/Todo';
import ListView from 'App/components/ListView';
import React, { useCallback, useMemo, useState } from 'react';
import { closedActivitiesFilter, openActivitiesFilter } from './ActivityListHelpers';
import TodoListTableRowDescriptionCol from 'App/pages/TodoList/TodoListTableRow/TodoListTableRowDescriptionCol';
import BemClass from '@upsales/components/Utils/bemClass';
import { openDrawer } from 'Services/Drawer';
import Mail from 'App/resources/Model/Mail';
import { isMail } from './ActivityListHelpers';

type Props = {
	columns?: string[];
	clientIds: number[];
	hideHeader?: boolean;
	filterValue: 'open' | 'closed';
	getDataFilter: (rb: RequestBuilder, clientIds: number[]) => void;
};

const ActivityList = ({
	clientIds,
	hideHeader,
	filterValue,
	getDataFilter,
	columns = ['activity', 'client', 'contact', 'description', 'opportunity', 'users', '']
}: Props) => {
	const { t } = useTranslation();
	const ActivityFilters = useMemo(() => activityFilters(), []);
	const classes = new BemClass('ActivityList');
	const [initialSorting, setInitialSorting] = useState([{ attribute: 'date', ascending: false }]);

	const customActivityAttributes = useMemo(() => {
		const attributesCopy = { ...ActivityAttributes };
		attributesCopy.contact = {
			...attributesCopy.contact,
			title: 'todoListTable.withWhom'
		};
		return attributesCopy;
	}, []);

	const renderTableRow = (activity: Activity | Mail, { columns, attributes }: ListViewTableProvided<Activity>) => {
		const goTo = (activity: Activity | Mail) => {
			if (isMail(activity)) {
				openDrawer('SentMail', { mail: { id: activity.id } });
				return;
			}

			Tools.$upModal.open(activity.isAppointment ? 'editAppointment' : 'editActivity', {
				id: activity.id
			});
		};

		const columnElements = columns.map(column => {
			switch (column) {
				case 'relations': {
					//mail relations doesnt work in activity list atm, will fix in a separate ticket
					if (isMail(activity)) {
						return <TableColumn key={column + activity.id}></TableColumn>;
					}

					return <TodoListTableRowDescriptionCol key={column + activity.id} todo={activity} />;
				}
				case '': {
					if (isMail(activity)) {
						activity.type = 'mail';
						return (
							<TableColumn key={column + activity.id} align="right">
								<DeleteMailQuickAction mail={activity} />
							</TableColumn>
						);
					}

					const mapToTodo = (activity: Activity): Omit<Todo, 'hot' | 'weblinkUrl' | 'location'> => ({
						...activity,
						type: activity.isAppointment ? 'appointment' : 'legacy'
					});

					const todo = mapToTodo(activity);

					return (
						<TableColumn key={'actionColumn' + activity.id} align="right">
							<TodoListTableRowQuickActions todo={todo} />
						</TableColumn>
					);
				}

				default: {
					return (
						<ListViewDefaultColumn<Activity | Mail>
							key={column}
							item={activity}
							attributes={attributes}
							column={column}
						/>
					);
				}
			}
		});

		return (
			<TableRow key={activity.id} onClick={() => goTo(activity)}>
				{columnElements}
			</TableRow>
		);
	};

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			if (clientIds.length === 0) {
				return {
					data: [],
					metadata: { total: 0, limit: 50, offset: 0 }
				};
			}

			getDataFilter(rb, clientIds);
			rb.addFilter({ field: 'projectPlan.id' }, ComparisonTypes.Equals, null);

			if (filterValue === 'open') {
				openActivitiesFilter(rb);
			}
			if (filterValue === 'closed') {
				closedActivitiesFilter(rb);
			}

			return ActivityListResource.find(rb.build());
		},
		[clientIds, filterValue]
	);

	return (
		<ListView<Activity>
			key={initialSorting.map(sort => `${sort.ascending}`).join('-')}
			filterConfigs={ActivityFilters}
			hideFilters
			className={classes.b()}
			broadcastType={['activity', 'mail']}
			getData={getData}
			attributes={customActivityAttributes}
			renderTableRow={renderTableRow}
			columns={columns}
			skipSortById
			renderHeader={hideHeader ? () => null : undefined}
			quickSearchFilter={'ListSearch'}
			quickSearchPlaceholder={t('account.searchActivitesPlaceHolder')}
			renderToolsColumn={false}
			canSortCustomFields={false}
			initialSorting={initialSorting}
			onSortChange={({ field }) => {
				if (field === 'client.operationalAccount.name') {
					setInitialSorting(prev =>
						prev.map(sort => ({
							...sort,
							ascending: !sort.ascending,
							missing: sort.ascending ? 'first' : 'last'
						}))
					);
				}
			}}
		/>
	);
};

export default ActivityList;
