import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Checkbox, Flex, Text } from '@upsales/components';
import './UserProspectingSettings.scss';
import { useTranslation } from 'Components/Helpers/translate';
import { CountryFlag } from 'App/components/CountryNameWithFlag/CountryNameWithFlag';
import { SlideFade } from '@upsales/components/animations';
import { getAvailableCountries } from 'App/services/prospectingService';

interface UserProspectingSettingsProps {
	/**
	 * The countries that are prohibited for the user.
	 * Will be a comma-separated string of country codes.
	 */
	countries: 'all' | string;
	multiSoliditetAccess: boolean;
	onChange: (
		updateParam: 'allowedProspectingCountries' | 'multiSoliditetAccess',
		value: string | null | boolean
	) => void;
}

interface TitledCheckboxProps {
	title: string;
	propagateClick: (checked: boolean) => void;
	checked: boolean;
	size?: 'xs' | 'sm' | 'md' | 'lg';
	children?: React.ReactNode;
	subtitle?: string;
	// If the countries are hidden in the fade slide, prevent the click event
	// on countries as they should not be triggered but can still be triggered
	// by tabbing to them or a similar action.
	preventClick?: boolean;
	isDisabled?: boolean;
	articleId?: number;
}

const TitledCheckbox = ({
	propagateClick,
	title,
	children,
	size,
	subtitle,
	checked = false,
	preventClick = false,
	isDisabled = false,
	articleId
}: TitledCheckboxProps) => {
	const classes = new BemClass('TitledCheckbox');
	const isChecked = !preventClick ? checked : false;

	const handleClick = () => {
		if (preventClick) {
			return;
		}
		propagateClick(!isChecked);
	};

	const handleKeyClick = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (preventClick) {
			return;
		}
		if (event.key === 'Enter' || event.key === ' ') {
			event.preventDefault();
			propagateClick(!isChecked);
		}
	};

	return (
		<div className={classes.mod({ isDisabled }).b()}>
			<label htmlFor={`checkbox-${title}`}>
				<Flex alignItems="center" onClick={handleClick}>
					<Checkbox
						id={`checkbox-${title}`}
						size={size ?? 'xs'}
						checked={isChecked}
						onClick={handleClick}
						onKeyDown={handleKeyClick}
						tabIndex={preventClick ? -1 : 0}
						aria-checked={isChecked}
					/>
					<Flex direction="column" gap="u1">
						<Flex alignItems="center" gap="u1">
							{children ? children : <Text>{title}</Text>}
							{articleId ? <ReactTemplates.elevio articleId={articleId} /> : null}
						</Flex>
						{subtitle ? <Text size="xs">{subtitle}</Text> : null}
					</Flex>
				</Flex>
			</label>
		</div>
	);
};

export const UserProspectingSettings = ({
	countries: stringCountries,
	multiSoliditetAccess,
	onChange
}: UserProspectingSettingsProps) => {
	const classes = new BemClass('UserProspectingSettings');
	const { t } = useTranslation();
	const hasProspectingCountriesSettings = Tools.FeatureHelper.hasSoftDeployAccess(
		'USER_PROSPECTING_COUNTRIES_SETTINGS'
	);
	const hasProspectingMultiCompaniesSettings = Tools.FeatureHelper.hasSoftDeployAccess(
		'USER_PROSPECTING_MULTI_COMPANIES_SETTINGS'
	);
	const [isProhibitingCountries, setIsProhibitingCountries] = React.useState(
		!!stringCountries && stringCountries !== 'all'
	);
	const [showError, setShowError] = React.useState(false);
	const ProspectingCountries = getAvailableCountries();
	const checkedCountries = stringCountries === 'all' ? ProspectingCountries : stringCountries?.split(',') || [];
	const isAllowingMultiCompanies = multiSoliditetAccess;

	const handleProhibitClick = (checked: boolean) => {
		setShowError(false);
		setIsProhibitingCountries(checked);
		if (!checked) {
			onChange('allowedProspectingCountries', 'all');
		}
	};

	const handleAllowMultiCompanies = (checked: boolean) => {
		onChange('multiSoliditetAccess', checked);
	};

	const handleCountryClick = (country: string) => {
		setShowError(false);
		let countries = checkedCountries;
		if (checkedCountries?.includes(country)) {
			countries = checkedCountries.filter(c => c !== country);
		} else {
			countries = [...(checkedCountries ?? []), country];
		}
		const allowedProspectingCountries =
			countries && countries.length > 0
				? countries.length === ProspectingCountries.length
					? 'all'
					: countries.join(',')
				: null;
		if (!allowedProspectingCountries) {
			setShowError(true);
			return;
		}
		onChange('allowedProspectingCountries', allowedProspectingCountries);
	};

	return (
		<>
			{hasProspectingCountriesSettings || hasProspectingMultiCompaniesSettings ? (
				<Flex className={classes.b()} direction="column">
					<h2>{t('admin.prospecting')}</h2>
					{hasProspectingMultiCompaniesSettings ? (
						<div>
							<div className={classes.elem('checkbox-container').b()}>
								<TitledCheckbox
									propagateClick={handleAllowMultiCompanies}
									checked={isAllowingMultiCompanies}
									title={t('admin.multiSoliditetAccess')}
									subtitle={t('admin.accountSettings.prospect.multipleCompanies.subtitle')}
									size="sm"
									articleId={1234}
								/>
							</div>
						</div>
					) : null}
					{hasProspectingCountriesSettings ? (
						<div>
							<div>
								<div className={classes.elem('checkbox-container').b()}>
									<TitledCheckbox
										propagateClick={handleProhibitClick}
										checked={isProhibitingCountries}
										title={t('admin.accountSettings.prospect.prohibitCountries')}
										size="sm"
									/>
								</div>
								<SlideFade visible={isProhibitingCountries} direction="top">
									<Flex
										direction="column"
										gap="u3"
										className={classes
											.elem('country-list')
											.mod({ hidden: !isProhibitingCountries })
											.b()}
									>
										{ProspectingCountries.map(country => (
											<TitledCheckbox
												key={country}
												propagateClick={() => handleCountryClick(country)}
												checked={checkedCountries?.includes(country) ?? false}
												title={country}
												isDisabled={
													checkedCountries.length === 1 && checkedCountries.includes(country)
												}
												preventClick={!isProhibitingCountries && !stringCountries}
											>
												<CountryFlag countryCode={country} />
												<span className="sr-only">{`${t(
													'admin.accountSettings.prospect.country.checkbox'
												)} ${checkedCountries?.includes(country)}`}</span>
											</TitledCheckbox>
										))}
									</Flex>
								</SlideFade>
							</div>
							{isProhibitingCountries && (
								<Text
									className={classes.elem('error').b()}
									role="alert"
									id="country-error-message"
									color="red"
									aria-live="polite"
								>
									{showError ? t('admin.accountSettings.prospect.country.errors') : ''}
								</Text>
							)}
						</div>
					) : null}
				</Flex>
			) : null}
		</>
	);
};
