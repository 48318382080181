'use strict';

const { formatWithSubAccounts } = require('App/helpers/accountsHelper');

var accountSelectDir = 'upAccounts';
angular
	.module('upDirectives')
	.directive(accountSelectDir, function (Account, selectHelper, LatestAccountsService, AppService, $translate) {
		const hasSubaccountsV1 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		const hasSubaccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
		const hasSubaccountsFeature =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
		const hasSubaccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;
		return selectHelper.getDirectiveObject(accountSelectDir, {
			resource: Account,
			ajax: true,
			asIds: false,
			idAttr: Account.attr.id,
			limit: 50,
			titleAttr: Account.attr.name,
			sorting: { field: Account.attr.name, ascending: true },
			emptyMsg: $translate.instant('default.noName'),
			addEvent: 'account.added',
			fields: ['addresses', 'operationalAccount'],
			filterType: 'account',
			formatData: function (data) {
				if (!hasSubaccounts) {
					return data;
				}

				return formatWithSubAccounts(data, $translate.instant);
			},
			formatResult: function (account, container, query, escape) {
				var title = '';
				var addresses = account.$addresses || account.addresses;
				if (addresses) {
					var city = '';

					if (addresses.Visit) {
						city = addresses.Visit.city;
					} else if (addresses.Mail) {
						city = addresses.Mail.city;
					} else if (addresses.Invoice) {
						city = addresses.Invoice.city;
					} else if (addresses.Delivery) {
						city = addresses.Delivery.city;
					} else if (addresses.Other) {
						city = addresses.Other.city;
					}

					title +=
						'<span class="subtitle grey" style="display:block;text-transform:uppercase;">' +
						escape(city || '') +
						'</span>';
				}
				if (hasSubaccounts && account.operationalAccount) {
					title +=
						'<div class="subtitle grey">' +
						$translate.instant('account.subaccount.tooltip', { parent: account.operationalAccount.name }) +
						'</div>';
				}
				return (
					'<div style="position:relative;">' +
					(account.name ? escape(account.name) : $translate.instant('default.noName')) +
					title +
					'</div>'
				);
			},
			goTo: function (obj, $state) {
				return $state.go('account.dashboard', { id: obj.id }, { reload: true });
			},
			data: function (attrs) {
				var onlyActive =
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES) &&
					attrs.hideInactive === 'true';
				let latestAccounts = LatestAccountsService.get(AppService.getCustomerId(), AppService.getSelf().id, {
					onlyActive: onlyActive
				});
				if (attrs.filterSelf && attrs.accountId && !isNaN(Number(attrs.accountId))) {
					latestAccounts = latestAccounts.filter(i => i.id !== Number(attrs.accountId));
				}
				if (!hasSubaccounts) {
					return [
						{
							name: $translate.instant('default.latestAccounts'),
							children: latestAccounts
						}
					];
				}

				return formatWithSubAccounts(latestAccounts, $translate.instant, true);
			},
			filters: function (rb, attrs) {
				var onlyActive =
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES) &&
					attrs.hideInactive === 'true';

				if (onlyActive) {
					rb.addFilter(Tools.Account.attr.active, rb.comparisonTypes.Equals, true);
				}

				if (attrs.filterSelf && attrs.accountId && !isNaN(Number(attrs.accountId))) {
					rb.addFilter(Tools.Account.attr.id, rb.comparisonTypes.NotEquals, attrs.accountId);
				}
			},
			cachedTotal: function () {
				return AppService.getTotals('accounts');
			}
		});
	})
	.directive(
		accountSelectDir + 'Ids',
		function (Account, selectHelper, LatestAccountsService, AppService, $translate) {
			return selectHelper.getDirectiveObjectIds(accountSelectDir + 'Ids', {
				resource: Account,
				ajax: true,
				asIds: true,
				idAttr: Account.attr.id,
				titleAttr: Account.attr.name,
				sorting: { field: Account.attr.name, ascending: true },
				emptyMsg: $translate.instant('default.noName'),
				addEvent: 'account.added',
				filterType: 'account',
				goTo: function (obj, $state) {
					return $state.go('account.dashboard', { id: obj.id }, { reload: true });
				},
				data: function () {
					return [
						{
							name: $translate.instant('default.latestAccounts'),
							children: LatestAccountsService.get(AppService.getCustomerId(), AppService.getSelf().id)
						}
					];
				},
				filters: function (rb, attrs) {
					if (
						Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES) &&
						attrs.hideInactive === 'true'
					) {
						rb.addFilter(Tools.Account.attr.active, rb.comparisonTypes.Equals, true);
					}
				},
				cachedTotal: function () {
					return AppService.getTotals('accounts');
				}
			});
		}
	);
