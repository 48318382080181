import React, { useRef } from 'react';
import { Button, SplitButtonWithActions, Text } from '@upsales/components';

import NotificationService from '../../NotificationService';
import { saveView, showSaveView } from 'Store/reducers/SharedViewsReducer';
import store from 'Store/index';
import T from '../Helpers/translate';
import './FilterCopy.scss';
import { AnyAction } from 'redux';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useSelector } from 'App/components/hooks';
import { ListView } from 'App/resources/AllIWant';
import ProjectBoard from 'App/resources/Model/ProjectBoard';

type FilterCopyProps = {
	isVisible: boolean;
	isDisabled: boolean;
	selectedView?: ListView | ProjectBoard | null;
	onViewChange?: (view: ListView | ProjectBoard, opts?: { fromSave: boolean }) => void;
};

type Action = {
	id: string;
	onClick: () => void;
	title: string;
};

const FilterCopy = ({ isVisible, isDisabled, selectedView, onViewChange }: FilterCopyProps) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const copyLink = () => {
		inputRef.current?.select();

		try {
			document.execCommand('copy');
			NotificationService.add({
				icon: 'check',
				style: 'success',
				title: T('default.linkCopiedToClipboard'),
				body: T('sharedViews.shareLinkWithCollegues')
			});
		} catch (err) {
			console.error('Copy Error', err);
		}
	};

	const { selectedView: selectedViewFromStore } = useSelector(state => state.SharedViews);

	if (!selectedView && selectedViewFromStore) {
		selectedView = selectedViewFromStore;
	}

	const createActions: Record<string, Action> = {
		save: {
			id: 'save',
			title: 'filters.saveViewAsNew',
			onClick: () => {
				store.dispatch(showSaveView({ editView: selectedView, createNew: true }) as unknown as AnyAction);
			}
		},
		update: {
			id: 'update',
			title: 'sharedViews.updateView',
			onClick: () => {
				const isResetView = false;
				store.dispatch(saveView({}, selectedView, isResetView, true) as unknown as AnyAction);

				if (selectedView && onViewChange) {
					onViewChange(selectedView, { fromSave: true });
				}
			}
		}
	};

	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS) && isVisible && !isDisabled) {
		return (
			<div className="FilterCopy">
				<input readOnly type="text" ref={inputRef} value={window.location.href} />
				{selectedView && 'regDate' in selectedView && selectedView.regDate && !selectedView.standard ? (
					<SplitButtonWithActions
						space="mrs"
						trigger="click"
						mainAction={'update'}
						actions={Object.values(createActions).map(i => ({
							...i,
							title: T(i.title),
							onClick: () => {
								i.onClick();
							}
						}))}
						dropdownDirection="top"
					/>
				) : (
					<PrimaryButton
						onClick={() => {
							store.dispatch(
								showSaveView({
									editView: selectedView ?? null,
									createNew: true
								}) as unknown as AnyAction
							);
						}}
						block
					>
						{T('sharedViews.saveAndShare')}
					</PrimaryButton>
				)}
				<Button onClick={copyLink} type="link" color="bright-blue" block>
					<Text color="inherit">{`${T('default.copy')} ${T('default.link').toLowerCase()}`}</Text>
					<ReactTemplates.elevio articleId={898} sidebar={true} />
				</Button>
			</div>
		);
	}

	return null;
};

export default FilterCopy;
