import { Button, Checkbox, Help, Icon, Paginator, Table, Tooltip } from '@upsales/components';
import ProductAttributes from 'App/babel/attributes/ProductAttributes';
import logError from 'App/babel/helpers/logError';
import { MAX_CACHED_PRODUCTS, getProductPriceStr, getUpdatedProductBundles } from 'App/babel/helpers/product';
import ComparisonTypes, { Equals, NotEquals } from 'App/babel/resources/ComparisonTypes';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import ListViewActions from 'App/components/ListViewActions';
import openModal from 'App/services/Modal';
import LocalStorage from 'Components/Helpers/LocalStorage';
import T from 'Components/Helpers/translate';
import { openEditProductModal } from 'Components/Modals/EditProduct/EditProduct';
import { openDrawer } from 'Services/Drawer';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import AdminHeader from './AdminHeader';
import ProductListFilters from './ProductListFilters';

const fetchLimit = 100;

const countChildren = item => {
	let count = 0;

	_.each(item.children, child => {
		if (child.type === 'productCategory') {
			count += countChildren(child);
		} else {
			count++;
		}
	});
	item.total = count;

	return count;
};

class AdminProducts extends React.Component {
	constructor(props) {
		super(props);

		this.useCachedProducts = Tools.AppService.getTotals('products') <= MAX_CACHED_PRODUCTS;
		this.updatedProduct = null;
		this.listeners = [
			Tools.$rootScope.$on('product.added', (_e, product) => {
				if (this.useCachedProducts) return;

				if (product) {
					const categoryId = product.category?.id ?? 0;
					const updatedProducts = this.state.productsByCategory[categoryId].products.concat(product);

					this.setState(
						{
							productsByCategory: {
								...this.state.productsByCategory,
								[categoryId]: {
									...this.state.productsByCategory[categoryId],
									products: updatedProducts
								}
							},
							activeProductsCount: this.state.activeProductsCount + 1
						},
						() => this.updateRows()
					);
				}
			}),
			Tools.$rootScope.$on('product.updated', async (_e, product) => {
				const productsByCategory = { ...this.state.productsByCategory };

				// Keep reference if a single product was updated, to update affected product bundles
				if (product && !Array.isArray(product)) {
					this.updatedProduct = product;
				}

				if (this.useCachedProducts) return;

				const updatedProducts = Array.isArray(product) ? product : [product];

				// find all categories affected by the updated products
				const updatedCategories = new Set(updatedProducts.map(p => p.category?.id ?? 0));
				Object.values(productsByCategory).forEach(({ products }) => {
					products.forEach(p => {
						if (updatedProducts.some(up => up.id === p.id)) {
							updatedCategories.add(p.category?.id ?? 0);
						}
					});
				});

				// refetch products for each affected category
				for (const categoryId of updatedCategories) {
					const offset = productsByCategory[categoryId].offset ?? 0;
					const { products, total } = await this.fetchProducts(categoryId, offset);
					productsByCategory[categoryId] = { products, total, offset };
				}

				this.setState({ productsByCategory }, () => this.updateRows());
			}),
			Tools.$rootScope.$on('product.deleted', (_e, product) => {
				if (this.useCachedProducts) return;

				if (product) {
					const categoryId = product.category?.id ?? 0;
					const updatedProducts = this.state.productsByCategory[categoryId].products.filter(
						p => p.id !== product.id
					);

					this.setState(
						{
							productsByCategory: {
								...this.state.productsByCategory,
								[categoryId]: {
									...this.state.productsByCategory[categoryId],
									products: updatedProducts
								}
							},
							activeProductsCount: this.state.activeProductsCount - 1
						},
						() => this.updateRows()
					);
				}
			}),
			Tools.$rootScope.$on('productCategory.added', (_e, productCategory) => {
				if (productCategory) {
					const { productsByCategory } = this.state;

					productsByCategory[productCategory.id] = { total: undefined, offset: 0, products: [] };

					this.setState(
						{
							productsByCategory
						},
						() => this.updateRows()
					);
				}
			})
		];

		const appsWDisableProducts = Tools.AppService.getMetadata().integrations.active.filter(app => {
			return app.inits.includes('disable_products');
		});

		const disabledProductApps = appsWDisableProducts.map(app => app.name).join(', ');

		this.canExport = Tools.AppService.getSelf().export;

		this.state = {
			openCategories: [0],
			searchStr: '',
			onlyActive: true,
			currency: _.find(Tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true }),
			rows: null,
			productsByCategory: this.getEmptyProductsState(),
			containerHeight: 1000,
			selectedProducts: {},
			disabledProductApps,
			disableProducts: appsWDisableProducts.length > 0,
			subscriptionProducts: true,
			oneOffProducts: true,
			productBundles: true,
			activeProductsCount: Tools.AppService.getTotals('products'),
			checked: Tools.AppService.getMetadata().params.OrderProductsImprovedSearch
		};

		this.currencyFormat = Tools.$filter('currencyFormat');
		const t = Tools.$translate;

		this.lang = {
			admin: t('default.admin'),
			title: t('default.products'),
			desc: t('admin.products.description'),
			product: t('default.product'),
			from: t('default.from'),
			category: t('default.category'),
			noCategory: t('default.noCategory'),
			add: t('default.add'),
			new: t('default.new'),
			new2: t('default.new2'),
			table: {
				title: t('admin.products.tableTitle')
			},
			warning: t('admin.products.confirmRemoval.body2'),
			confirmRemoval: t('admin.products.confirmRemoval.body'),
			activeProducts: t('admin.activeProducts'),
			activateContributionMargin: t('admin.activateContributionMargin'),
			inactiveProducts: t('admin.inactiveProducts'),
			allProducts: t('admin.allProducts'),
			includeInactive: t('ads.includeInactive'),
			cannotDeleteCategoryWithProducts: t('admin.cannotDeleteCategoryWithProducts'),
			productCategory: t('default.productCategory').toLowerCase(),
			loading: t('default.loading'),
			newProductDisabledByApps: t('product.newProductDisabledByApps', { apps: disabledProductApps }),
			deleteProduct: t('soliditet.matcher.actionDelete') + ' ' + t('default.product').toLowerCase(),
			confirm: t('default.confirm'),
			bundle: t('default.bundle'),
			toggleLabelImprovedSearch: t('admin.products.header.toggleLabelImprovedSearch')
		};

		this.onResize = this.onResize.bind(this);
		this.toggleFilter = this.toggleFilter.bind(this);
		this.moveDown = this.moveDown.bind(this);
		this.moveUp = this.moveUp.bind(this);
		this.onHashChange = this.onHashChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.doSearch = _.debounce(this.doSearch, 300).bind(this);
		this.scrollHelper = new window.ScrollHelper(this);
		this.version = props.rootData.pageData.version;
		this.resizeTimeout = null;
		this.mounted = false;
		this.hasTiers = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_TIERS);
		this.hasProductBundles =
			Tools.FeatureHelper.hasSoftDeployAccess('PRODUCT_BUNDLE') &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_BUNDLES);
		this.defaultPriceListId = Tools.AppService.getPriceLists().find(priceList => priceList.isDefault)?.id;
	}

	buildTree(products, categories) {
		const productCategoriesById = _.reduce(
			categories,
			(res, category) => {
				res[category.id] = Object.assign(
					{
						type: 'productCategory',
						children: []
					},
					category
				);

				return res;
			},
			{}
		);

		let topLvlProducts = [];

		_.each(products, item => {
			var product = Object.assign(item, { type: 'product' });
			if (item.category && productCategoriesById[item.category.id]) {
				productCategoriesById[item.category.id].children.push(product);
			} else {
				topLvlProducts.push(product);
			}
		});

		_.each(productCategoriesById, category => {
			if (category.parentId) {
				productCategoriesById[category.parentId].children.push(category);
			}
		});

		_.each(productCategoriesById, category => {
			const maxProductSortId = _.max(category.children, child => {
				return child.type === 'productCategory' ? 0 : child.sortId;
			});

			category.children = _.sortBy(category.children, child => {
				if (child.type === 'productCategory') {
					return maxProductSortId + child.sortId;
				}
				return child.sortId;
			});
		});

		let topLvlCategories = _.filter(productCategoriesById, category => {
			countChildren(category);
			return category.parentId === 0;
		});

		topLvlCategories = _.sortBy(topLvlCategories, 'name');
		topLvlCategories = _.sortBy(topLvlCategories, 'sortId');
		topLvlProducts = _.sortBy(topLvlProducts, 'sortId');

		this.version = this.props.rootData.pageData.version;

		return [
			{
				id: 0,
				name: this.lang.noCategory,
				type: 'productCategory',
				children: topLvlProducts
			}
		].concat(topLvlCategories);
	}

	filterItems(items) {
		const searchStr = this.state.searchStr || '';
		const isImport = searchStr.indexOf('import:') === 0;

		return _.filter(items, item => {
			const name = item.name || '';
			const match = name.toLowerCase().indexOf(searchStr.toLowerCase()) > -1;

			if (item.type === 'productCategory') {
				item.children = this.filterItems(item.children);

				if (
					(match &&
						(this.state.searchStr.length ||
							Tools.AppService.getTotals('products') < MAX_CACHED_PRODUCTS)) ||
					item.children.length
				) {
					return true;
				}
			} else if (match) {
				return true;
			} else if (isImport && item.importId && item.importId === parseInt(searchStr.substr(7))) {
				return true;
			}
			return false;
		});
	}

	getEmptyProductsState() {
		const productsByCategory = Tools.AppService.getProductCategories().reduce((acc, category) => {
			acc[category.id] = { total: undefined, offset: 0, products: [] };
			return acc;
		}, {});
		productsByCategory[0] = { total: undefined, offset: 0, products: [] };

		return productsByCategory;
	}

	async resetProducts() {
		const totalCount = await this.fetchTotalCount();

		this.setState(
			{
				productsByCategory: this.getEmptyProductsState(),
				openCategories: [0],
				activeProductsCount: totalCount
			},
			() => (this.useCachedProducts ? this.updateRows() : this.fetchPage(0))
		);
	}

	async fetchProducts(categoryId, offset = 0, limit = fetchLimit) {
		if (!this.useCachedProducts) {
			const { productBundles, oneOffProducts, subscriptionProducts } = this.state;

			if (!productBundles && !oneOffProducts && !subscriptionProducts) {
				return { products: [], total: 0 };
			}
			var searchableProducts = _.filter(Tools.AppService.getCustomFields('product'), { searchable: 1 }).map(
				function (cf) {
					return cf.id;
				}
			);

			var rb = new Tools.RequestBuilder();
			rb.limit = limit;
			rb.offset = offset;

			rb.addSort(Tools.Product.attr.name, true);
			rb.addExtraParam('ignoreCache', true);
			rb.addExtraParam('usePriceLists', true);

			if (categoryId != null) {
				rb.addFilter(
					ProductAttributes.categoryId,
					ComparisonTypes.Equals,
					categoryId === 0 ? null : categoryId
				);
			}

			if (this.state.onlyActive) {
				rb.addFilter(Tools.Product.attr.active, ComparisonTypes.Equals, 1);
			}

			if (!productBundles) {
				rb.addFilter({ field: 'bundlePriceAdjustment' }, Equals, null);

				if (!oneOffProducts) {
					rb.addFilter({ field: 'isRecurring' }, Equals, 1);
				}
				if (!subscriptionProducts) {
					rb.addFilter({ field: 'isRecurring' }, Equals, 0);
				}
			} else {
				const ob = rb.orBuilder();
				ob.next();
				ob.addFilter({ field: 'bundlePriceAdjustment' }, NotEquals, null);

				if (oneOffProducts) {
					ob.next();
					ob.addFilter({ field: 'isRecurring' }, Equals, 0);
				}
				if (subscriptionProducts) {
					ob.next();
					ob.addFilter({ field: 'isRecurring' }, Equals, 1);
				}
				ob.done();
			}

			if (this.state.searchStr.startsWith('import:')) {
				rb.addFilter({ field: 'importId' }, Equals, this.state.searchStr.split(':')[1]);
			} else {
				//TODO: lägg till filtren på produkttyp här också
				if (searchableProducts.length) {
					var orBuilder = rb.orBuilder();
					orBuilder.next();
					orBuilder.addFilter(Tools.Product.attr.name, rb.comparisonTypes.Search, this.state.searchStr);
					orBuilder.next();
					var groupFilter = orBuilder.groupBuilder();
					groupFilter.addFilter({ field: 'custom.fieldId' }, rb.comparisonTypes.Equals, searchableProducts);
					groupFilter.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Search, this.state.searchStr);
					groupFilter.done();
					orBuilder.done();
				} else {
					rb.addFilter(Tools.Product.attr.name, rb.comparisonTypes.Search, this.state.searchStr);
				}
			}

			const res = await Tools.Product.customer(Tools.AppService.getCustomerId()).find(rb.build());
			return { products: _.filter(res.data, product => product.$hasAccess), total: res.metadata.total };
		} else {
			const products = Tools.AppService.getProducts(this.state.onlyActive, null, true).filter(product => {
				const isBundle = product.bundlePriceAdjustment !== null;
				return (
					(this.state.subscriptionProducts && !!product.isRecurring && !isBundle) ||
					(this.state.oneOffProducts && !product.isRecurring && !isBundle) ||
					(this.state.productBundles && isBundle)
				);
			});
			return { products, total: products.length };
		}
	}

	async fetchPage(categoryId) {
		const { offset, total } = this.state.productsByCategory[categoryId];

		if (!total || offset < total) {
			const { products, total } = await this.fetchProducts(categoryId, offset);

			this.setState(
				{
					productsByCategory: {
						...this.state.productsByCategory,
						[categoryId]: {
							total,
							offset,
							products
						}
					}
				},
				() => this.updateRows()
			);
		}
	}

	async fetchTotalCount() {
		const { total } = await this.fetchProducts(undefined, 0, 0);

		return total;
	}

	async updateRows() {
		let { searchStr, activeProductsCount } = this.state;
		let allProducts;

		if (this.useCachedProducts) {
			const { products, total } = await this.fetchProducts();
			allProducts = products;
			activeProductsCount = total;
		} else {
			const { productsByCategory } = this.state;
			allProducts = Object.values(productsByCategory).reduce((acc, { products }) => {
				return acc.concat(products);
			}, []);
		}

		if (this.updatedProduct) {
			allProducts = await getUpdatedProductBundles(allProducts, this.updatedProduct);
			this.updatedProduct = null;
		}

		const categories = Tools.AppService.getProductCategories();
		const tree = this.buildTree(allProducts, categories);
		const productTree = searchStr ? this.filterItems(tree) : tree;
		if (this.mounted) {
			this.setState({
				rows: this.getRows(productTree, 0),
				activeProductsCount: searchStr
					? allProducts.filter(({ name }) => name.toLowerCase().indexOf(searchStr.toLowerCase()) > -1).length
					: activeProductsCount
			});
		}
	}

	componentDidMount() {
		this.mounted = true;
		window.addEventListener('resize', this.onResize);
		window.addEventListener('hashchange', this.onHashChange, false);
		this.onResize();

		const search = Tools.$location.search();
		if (search?.import !== undefined) {
			this.setState({ searchStr: `import:${search.import}`, onlyActive: false }, () =>
				setTimeout(() => this.doSearch(), 0)
			);
		} else {
			setTimeout(() => this.resetProducts(), 0);
		}
	}

	onResize() {
		if (!this.resizeTimeout && this._scrollContainer) {
			this.resizeTimeout = setTimeout(() => {
				this.resizeTimeout = null;
				this.setState({ containerHeight: this._scrollContainer.offsetHeight });
			}, 66);
		}
	}

	componentWillUnmount() {
		this.mounted = false;
		if (this.resizeTimeout) {
			clearTimeout(this.resizeTimeout);
		}
		this.listeners.forEach(listener => listener());
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('hashchange', this.onHashChange, false);
	}

	componentDidUpdate() {
		if (this.props.rootData.pageData.version > this.version) {
			this.updateRows();
		}
	}

	onHashChange() {
		const search = Tools.$location.search();
		if (search?.import !== undefined) {
			this.setState({ searchStr: `import:${search.import}`, onlyActive: false }, () => this.doSearch());
		}
	}

	async doSearch() {
		const { productsByCategory, searchStr } = this.state;

		if (searchStr) {
			const { products } = await this.fetchProducts();
			const groupedProducts = _.groupBy(products, 'category.id');

			Object.keys(productsByCategory).forEach(categoryId => {
				const categoryProducts = groupedProducts[categoryId === '0' ? null : categoryId] || [];
				productsByCategory[categoryId] = {
					total: categoryProducts.length,
					offset: 0,
					products: categoryProducts
				};
			});

			this.setState(
				{
					productsByCategory,
					openCategories: Tools.AppService.getProductCategories()
						.map(category => category.id)
						.concat([0])
				},
				() => this.updateRows()
			);
		} else {
			this.resetProducts();
		}
	}

	onSearch(e) {
		const searchStr = e.target.value;
		this.setState({ searchStr }, () => this.doSearch());
	}

	toggleFilter(type) {
		if (type === 'allProducts') {
			this.setState(
				{
					oneOffProducts: !this.state.oneOffProducts,
					subscriptionProducts: !this.state.subscriptionProducts
				},
				() => this.resetProducts()
			);
		} else {
			this.setState({ [type]: !this.state[type] }, () => this.resetProducts());
		}
	}

	setRef(name, ref) {
		this[name] = ref;
	}

	toggleCategory(id) {
		const ids = this.state.openCategories;

		if (ids.length) {
			for (let i = 0; i < ids.length; i++) {
				if (!ids.includes(id)) {
					ids.push(id);
					break;
				} else if (id === parseInt(ids[i])) {
					ids.splice(i, 1);
					break;
				}
			}
		} else {
			ids.push(id);
		}

		this.setState({ openCategories: ids }, () => {
			if (ids.includes(id) && this.state.productsByCategory[id].total === undefined && !this.useCachedProducts) {
				this.fetchPage(id);
			} else {
				this.updateRows();
			}
		});
	}

	onNewProduct() {
		openEditProductModal({ type: 'AddProduct', product: null });
	}

	onNewProductCategory() {
		if (Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS')) {
			openModal('EditProductCategory');
		} else {
			Tools.$upModal.open('AddProductCategoryModal', { type: 'AddProductCategory', productCategory: null });
		}
	}

	onEditProduct(item) {
		this.prevProduct = item;
		if (item.bundlePriceAdjustment != null) {
			openDrawer('ProductBundleDrawer', { bundle: item });
		} else {
			openEditProductModal({ type: 'AddProduct', product: item });
		}
	}

	onEditProductCategory(item, e) {
		e.stopPropagation();
		if (Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS')) {
			openModal('EditProductCategory', { productCategory: item });
		} else {
			Tools.$upModal.open('AddProductCategoryModal', { type: 'AddProductCategory', productCategory: item });
		}
	}

	doExport() {
		const actions = Tools.MultiRunnerActions.get(Tools.MultiRunnerActions.type.PRODUCT);
		const idArray = Object.keys(this.state.selectedProducts).map(parseInt);
		actions.export.run(
			idArray,
			this.state.onlyActive,
			undefined,
			this.state.subscriptionProducts,
			this.state.oneOffProducts,
			this.state.productBundles
		);
	}

	renderHeader(item, level, closed) {
		const onRowClick = e => {
			e.stopPropagation();
			this.toggleCategory(item.id);
		};

		if (item.id === null) {
			return (
				<div className="admin-table-row is-header" key={'header-cell-' + item.name.replace(' ', '-') + '-0'}>
					<div className="admin-table-cell" style={{ paddingLeft: 20 * level + 20 }}>
						<i className={closed ? 'fa fa-folder fa-tw' : 'fa fa-folder-open fa-tw'} />
					</div>
					<div className="admin-table-cell header-title" style={{ left: 20 * level + 20 }}>
						<div className="name">
							<div className="name-inner">{item.name}</div>
							<span className="count">{item.total}</span>
						</div>
						<div className="actions">
							<Tooltip
								title={
									!this.canExport
										? Tools.$translate('missingAccess.export')
										: Tools.$translate('default.export') || ''
								}
								position="left"
							>
								<b
									data-test-id="file-download"
									className={`fa fa-file-excel-o export-button ${!this.canExport ? 'disabled' : ''}`}
									onClick={() => {
										if (!this.canExport) {
											return;
										}
										this.doExport();
									}}
								/>
							</Tooltip>
						</div>
					</div>
				</div>
			);
		} else {
			let trash = null,
				edit = <Icon name="edit" onClick={this.onEditProductCategory.bind(this, item)} />;

			const btn = (
				<Button
					type="link"
					color="grey"
					hoverColor="red"
					disabled={!!item.children.length}
					className={!item.children.length ? 'delete-btn' : undefined}
					onClick={this.removeCategory.bind(this, item)}
				>
					<Icon name="trash" />
				</Button>
			);

			if (item.id === 0) {
				trash = null;
				edit = null;
			} else if (item.children.length) {
				trash = (
					<Tooltip
						title={this.lang.cannotDeleteCategoryWithProducts}
						position={'left'}
						onClick={ReactTemplates.TOOLS.stopProp}
						className={'delete-btn'}
					>
						{btn}
					</Tooltip>
				);
			} else {
				trash = btn;
			}

			return (
				<div
					className="admin-table-row is-header clickable"
					key={'header-cell-' + item.name.replace(' ', '-') + '-' + item.id}
					onClick={onRowClick}
				>
					<div className="admin-table-cell" style={{ paddingLeft: 20 * level + 20 }}>
						<i className={closed ? 'fa fa-folder fa-tw' : 'fa fa-folder-open fa-tw'} />
					</div>
					<div className="admin-table-cell header-title" style={{ left: 20 * level + 20 }}>
						<div className="name">
							<div className="name-inner">{item.name}</div>
						</div>
						{edit}
						{trash}
					</div>
				</div>
			);
		}
	}

	removeCategory(category, event) {
		event.stopPropagation();
		this.props.rootData.pageData.removeCategory(category);
	}

	moveDown(type, item, visibleNext, visiblePrev, e) {
		e.stopPropagation();
		if (!visibleNext || visibleNext.type === 'productCategory') {
			return;
		}
		this.props.rootData.pageData.onMoveProducts(item, visibleNext, true);
	}

	moveUp(type, item, visibleNext, visiblePrev, e) {
		e.stopPropagation();
		if (!visiblePrev || visiblePrev.type === 'productCategory') {
			return;
		}
		this.props.rootData.pageData.onMoveProducts(item, visiblePrev, false);
	}

	onDelete(item, event) {
		const self = this;
		event.stopPropagation();
		const promise = self.props.rootData.pageData.checkProduct(item);

		promise
			.then(function (count) {
				const canBeRemoved = count > 0 ? false : true;
				if (canBeRemoved) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('RemoveAlert', {
							body: self.lang.confirmRemoval,
							title: 'default.product',
							onClose: confirmed => {
								if (confirmed) {
									self.props.rootData.pageData.onDeleteProduct(item);
								}
							}
						});
						return;
					}

					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('warningConfirm', {
							body: self.lang.confirmRemoval,
							title: self.lang.deleteProduct,
							resolveTrue: self.lang.confirm
						})
						.then(function () {
							self.props.rootData.pageData.onDeleteProduct(item);
						});
				} else {
					Tools.$upModal.open('errorAlert', {
						body: self.lang.warning,
						title: self.lang.deleteProduct
					});
				}
			})
			.catch(e => logError(e, 'Failed to check product'));
	}

	renderRow(product, level, visibleNext, visiblePrev) {
		let classNames = 'admin-table-row clickable';

		if (!product.active) {
			classNames += ' inactive-row';
		}

		const checked = this.state.selectedProducts[product.id] ? true : false;
		const isTieredProduct = !!(product.tiers && product.tiers.length);
		const isProductBundle = product.bundlePriceAdjustment != null;

		return (
			<div className={classNames} key={'product-' + product.id} onClick={() => this.onEditProduct(product)}>
				<div
					className="admin-table-cell checkbox-col"
					onClick={ReactTemplates.TOOLS.stopProp}
					style={{ paddingLeft: 20 * level + 2 }}
				>
					<div>
						<Checkbox checked={checked} size="sm" onChange={() => this.toggleCheckbox(product.id)} />
					</div>
				</div>
				<div className="admin-table-cell name-col" style={{ paddingLeft: 20 }}>
					<div className="name-inner">
						{product.name}
						{this.hasProductBundles && isProductBundle ? <Icon name="bundle" color="black" /> : null}
					</div>
				</div>
				{this.hasTiers ? (
					<div className="admin-table-cell tier-col">
						<div className="tier-inner">
							{isTieredProduct ? <Icon name="tiers" color="bright-blue" /> : null}
						</div>
					</div>
				) : null}
				<div className="admin-table-cell price-column">
					<span className="price">
						{getProductPriceStr(product, this.state.currency.iso, this.defaultPriceListId)}
					</span>
					<Button
						type="link"
						color="grey"
						onClick={this.moveUp.bind(null, 'product', product, visibleNext, visiblePrev)}
					>
						<Icon name="chevron-up" />
					</Button>
					<Button
						type="link"
						color="grey"
						onClick={this.moveDown.bind(null, 'product', product, visibleNext, visiblePrev)}
					>
						<Icon name="chevron-down" />
					</Button>
					<Button type="link" color="grey" onClick={this.onDelete.bind(null, product)}>
						<Icon name="trash" />
					</Button>
				</div>
			</div>
		);
	}

	renderPaginator(categoryId) {
		const { productsByCategory, searchStr } = this.state;
		const { offset, total } = productsByCategory[categoryId];

		if (this.useCachedProducts || searchStr || total <= fetchLimit) {
			return null;
		}

		const setPage = offset => {
			const productsByCategory = { ...this.state.productsByCategory };
			productsByCategory[categoryId].offset = offset;
			this.setState({ productsByCategory }, () => this.fetchPage(categoryId));
		};

		return (
			<div key={`paginator-${categoryId}`} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
				<Paginator
					limit={fetchLimit}
					offset={offset}
					total={total}
					paddButtons={4}
					align="center"
					onChange={setPage.bind(this)}
				/>
			</div>
		);
	}

	getRows(array, level) {
		return array.reduce((rows, item, i) => {
			if (item.type === 'productCategory') {
				const closed = !this.state.openCategories.includes(item.id);

				rows.push(this.renderHeader(item, level + 1, closed, false, i + 1));

				if (!closed) {
					const childValues = this.getRows(item.children, level + 1);
					rows = rows.concat(childValues);
				}

				return rows;
			} else {
				const categoryId = item.category?.id ?? 0;
				const closed = !this.state.openCategories.includes(categoryId);

				if (!closed) {
					const visibleNext = array[i + 1];
					const visiblePrev = array[i - 1];
					rows.push(this.renderRow(item, level + 1, visibleNext, visiblePrev));

					// If this is the last item of type product, add paginator
					if (i === array.length - 1 || array[i + 1].type === 'productCategory') {
						rows.push(this.renderPaginator(categoryId));
					}
				}
				return rows;
			}
		}, []);
	}

	getElementHeights(element) {
		return _.get(element, 'props.className', '').includes('is-header') ? 34 : 40;
	}

	getListViewActionProps() {
		const selectedProducts = this.state.selectedProducts;
		const actions = Tools.MultiRunnerActions.get(Tools.MultiRunnerActions.type.PRODUCT);

		return {
			actions,
			runAction: action => {
				const idArray = Object.keys(this.state.selectedProducts);
				return action
					.run(
						idArray,
						this.state.onlyActive,
						undefined,
						this.state.subscriptionProducts,
						this.state.oneOffProducts,
						this.state.productBundles
					)
					.then(() => this.clearSelectedProducts());
			},
			selectNone: this.clearSelectedProducts,
			allSelected: false,
			selected: Object.keys(selectedProducts).length
		};
	}

	clearSelectedProducts = () => {
		this.setState({ selectedProducts: {} }, () => this.updateRows());
	};

	toggleCheckbox(id) {
		const selectedProducts = { ...this.state.selectedProducts };

		if (selectedProducts[id]) {
			delete selectedProducts[id];
		} else {
			selectedProducts[id] = true;
		}

		this.setState({ selectedProducts }, () => this.updateRows());
	}

	handleToggleChange = async checked => {
		Tools.ClientParam.save(261, checked)
			.then(() => {
				const metadata = Tools.AppService.getMetadata();
				Tools.AppService.setMetadata({
					...metadata,
					params: {
						...metadata.params,
						OrderProductsImprovedSearch: checked
					}
				});
				this.setState({ checked });
			})
			.catch(e => {
				logError(e);
			});
	};

	render() {
		const { disableProducts, checked } = this.state;
		const lang = this.lang;
		const scrollHelperOptions = {
			top: 305,
			containerHeight: this.state.containerHeight,
			elementHeights: this.getElementHeights
		};
		const rows = this.state.rows ? this.scrollHelper.filter(this.state.rows, scrollHelperOptions) : null;

		return (
			<div
				id="admin-page-products"
				ref={this.setRef.bind(this, '_scrollContainer')}
				onScroll={this.scrollHelper.onScroll}
			>
				<AdminHeader
					title={this.lang.title}
					description={
						<div>
							{this.lang.desc} <Help sidebar articleId={1442} />
						</div>
					}
					image="products.svg"
					toggle={Tools.FeatureHelper.hasSoftDeployAccess('ORDER_PRODUCTS_IMPROVED_SEARCH')}
					toggleChecked={checked}
					toggleDisabled={!rows}
					onToggleChange={this.handleToggleChange}
					toggleLabel={this.lang.toggleLabelImprovedSearch}
				></AdminHeader>

				<div id="admin-content">
					<ProductListFilters
						subscriptionChecked={this.state.subscriptionProducts}
						oneOffChecked={this.state.oneOffProducts}
						bundleChecked={this.state.productBundles}
						inactiveChecked={!this.state.onlyActive}
						onChange={this.toggleFilter}
					/>
					<div className="admin-table">
						<div className="admin-table-top table-top-flex">
							<span className="admin-table-title table-title-flex">{lang.table.title}</span>
							<div>
								<div className="table-search" style={{ display: 'inline-block', marginRight: '10px' }}>
									<input
										type="text"
										onChange={this.onSearch}
										value={this.state.searchStr}
										placeholder={T('default.search')}
									/>
									<b className="fa fa-search" />
								</div>
								{disableProducts ? (
									<Tooltip
										title={lang.newProductDisabledByApps}
										position="left"
										className="disable-products-tooltip"
									>
										<Button
											onClick={this.onNewProduct}
											className="new-product-button-disabled"
											size="sm"
											style={{ marginRight: '10px' }}
											disabled={true}
										>
											<Icon name="plus" style={{ marginRight: '8px' }} />
											{lang.product}
										</Button>
									</Tooltip>
								) : (
									<Button onClick={this.onNewProduct} size="sm" style={{ marginRight: '10px' }}>
										<Icon name="plus" style={{ marginRight: '8px' }} />
										{lang.product}
									</Button>
								)}
								{this.hasProductBundles ? (
									<Button
										onClick={() => openDrawer('ProductBundleDrawer')}
										size="sm"
										style={{ marginRight: '10px' }}
									>
										<Icon name="plus" style={{ marginRight: '8px' }} />
										{lang.bundle}
									</Button>
								) : null}
								<Button onClick={this.onNewProductCategory} size="sm">
									<Icon name="plus" style={{ marginRight: '8px' }} />
									{lang.category}
								</Button>
								<Tooltip title="Switch to list view" position="top">
									<Icon
										onClick={() => {
											const localStorage = new LocalStorage();
											localStorage.setValue('admin-products-view', 'list');
											Tools.$state.reload();
										}}
										style={{ cursor: 'pointer', marginLeft: 8 }}
										name="list"
										color="blue"
									/>
								</Tooltip>
							</div>
						</div>
						<div className="admin-table-body">
							{this.renderHeader(
								{
									id: null,
									name: this.state.onlyActive ? lang.activeProducts : lang.allProducts,
									total: this.state.activeProductsCount
								},
								0,
								false,
								false,
								0
							)}
							{rows ? (
								rows
							) : (
								<div className="admin-table-row" key="loading-row">
									<div style={{ width: '100%', textAlign: 'center' }} className="admin-table-cell">
										{lang.loading}
									</div>
								</div>
							)}
							<Table>
								{!this.state.rows?.length ? (
									<DefaultNoData formatNoData={() => T('default.noResults')} />
								) : null}
							</Table>
						</div>
					</div>
				</div>
				<ListViewActions {...this.getListViewActionProps()} />
			</div>
		);
	}
}

AdminProducts.propTypes = {
	rootData: PropTypes.object
};

export default AdminProducts;
