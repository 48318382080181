import { SalesModelOptionType, SalesModelType } from 'App/resources/AllIWant';
import ReportResource, { ReportEntity } from 'App/resources/Report';
import t from 'Components/Helpers/translate';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import ContactResource from 'App/resources/Contact';
import Prospecting from 'App/babel/resources/Prospecting';
import ClientResource from 'App/resources/Client';
import logError from 'Helpers/logError';

import { getFilterFn } from 'App/babel/components/ListTab/ListTabHelper';

const filterFn = getFilterFn(true);

const getValuesBasedOnSalesModel = (
	salesModel: SalesModelType,
	salesModelOption?: SalesModelOptionType,
	trend?: boolean
) => {
	let attribute = 'growth.';

	if (salesModel === 'rr') {
		if (salesModelOption === 'mrr') {
			attribute += trend ? 'mrrLast12Months' : 'mrr';
		} else {
			attribute += trend ? 'arrLast12Months' : 'arr';
		}
	} else if (salesModel === 'cm') {
		attribute += trend ? 'cmTrend12Months' : 'cmLast12Months';
	} else {
		attribute += trend ? 'salesTrend12Months' : 'salesLast12Months';
	}

	return attribute;
};

type ReportData = {
	// eslint-disable-next-line camelcase
	data: { sum_valueInMasterCurrency: { value: number; doc_count: number }; result: { value: number } };
};

export const getSalesReport = (
	clientIds: number[],
	salesModel: SalesModelType,
	salesModelOption?: SalesModelOptionType,
	trend?: boolean
) => {
	const attribute = getValuesBasedOnSalesModel(salesModel, salesModelOption, trend);
	const rb = new RequestBuilder();
	const filterFnSales = getFilterFn(true, false, false, '');
	filterFnSales(rb, clientIds);
	rb.limit = 0;

	const salesChange12MonthsAgg = rb.aggregationBuilder();
	salesChange12MonthsAgg.addAggregation('sum', attribute);
	salesChange12MonthsAgg.aggregationName('result');
	salesChange12MonthsAgg.done();

	return makeCancelable(ReportResource.find(ReportEntity.CLIENT, rb.build()) as Promise<ReportData>);
};

export const getSalesTabSubtitle = (salesModel?: SalesModelType, salesModelOption?: SalesModelOptionType) => {
	if (salesModel === 'rr') {
		if (salesModelOption === 'mrr') {
			return `(${t('default.monthlyValue')})`;
		}
		return `(${t('default.annualValue')})`;
	}
	if (salesModel === 'cm' && salesModelOption === 'cm') {
		return `(${t('default.contributionMarginShort')})`;
	}
	return '';
};

export const fetchContactNumbers = (clientIds: number[]) => {
	const contactRb = new RequestBuilder();
	contactRb.addFilter({ field: 'active' }, ComparisonTypes.Equals, true);
	filterFn(contactRb, clientIds);
	contactRb.limit = 0;

	return ContactResource.find(contactRb.build());
};

export const fetchPipelineNumbers = (clientIds: number[]) => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'probability' }, ComparisonTypes.NotEquals, [0, 100]);
	filterFn(rb, clientIds);

	const aggregration = rb.aggregationBuilder();
	aggregration.addAggregation('sum', 'valueInMasterCurrency');
	aggregration.done();

	return ReportResource.find(ReportEntity.ORDER, rb.build()) as Promise<ReportData>;
};

export const fetchPipelineExistingNumbers = (clientIds: number[]) => {
	const existingRb = new RequestBuilder();
	existingRb.addFilter({ field: 'probability' }, ComparisonTypes.NotEquals, [0, 100]);
	existingRb.addFilter({ field: 'client.journeyStep' }, ComparisonTypes.Equals, 'customer');
	filterFn(existingRb, clientIds);

	const pipelineExistingAgg = existingRb.aggregationBuilder();
	pipelineExistingAgg.addAggregation('sum', 'valueInMasterCurrency');
	pipelineExistingAgg.done();

	return ReportResource.find(ReportEntity.ORDER, existingRb.build()) as Promise<ReportData>;
};

export const getProspectingGroup = (prospectingId: string) => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'prospectingId' }, 'eq', prospectingId);
	rb.extraParams.push({
		key: 'country',
		value: Prospecting.getCountryFromProspectingId(prospectingId)
	});

	return Prospecting.getCompaniesFromGroup(rb.build());
};

export const fetchCompanies = (clientIds: number[]) => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'id' }, comparisonTypes.Equals, clientIds);
	return makeCancelable(ClientResource.find(rb.build()).then(res => res.data));
};

export const handleRequest = <T, S>(
	param: T,
	fetcher: (param: T) => CancelablePromise<S>,
	setter: (params: S) => void,
	errorType: string
) => {
	const request = fetcher(param);

	request.promise
		.then(data => setter(data))
		.catch(err => {
			logError(err, 'CompanyGroupStartPage - Error when fetching ' + errorType);
		});

	return request;
};

export const fetchTree = (prospectingId: string) => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'prospectingId' }, comparisonTypes.Equals, prospectingId);
	rb.extraParams.push({
		key: 'country',
		value: Prospecting.getCountryFromProspectingId(prospectingId)
	});

	return Prospecting.findGroupstructure(rb.build()).then(res => res.data[0]);
};
