import React from 'react';
import { TableColumn, Link, Text } from '@upsales/components';
import ClientTooltip from 'Components/ClientTooltip';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import type Ticket from 'App/resources/Model/Ticket';
import type ProjectPlanModel from 'App/resources/Model/ProjectPlan';
import { useSoftDeployAccess } from 'App/components/hooks';

export const ClientColumn = ({ item }: { item: Ticket | ProjectPlanModel }) => {
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2');
	const hasSubaccounts = useSoftDeployAccess('SUB_ACCOUNTS');

	if (!hasSubaccountsV2 || !hasSubaccounts || !item.client) return null;

	return (
		<TableColumn>
			<Text size="sm" ellipsis>
				<ClientTooltip id={item.client.id} position="bottom">
					<Link
						onClick={e => e.stopPropagation()}
						href={Tools.$state.href('account.dashboard', {
							customerId: Tools.AppService.getCustomerId(),
							id: item.client.id
						})}
					>
						{item.client.name}
					</Link>
				</ClientTooltip>
				<SubAccountLabel isCompanyColumn operationalAccount={item.client.operationalAccount ?? null} />
			</Text>
		</TableColumn>
	);
};
