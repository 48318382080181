import { useSelector, useSoftDeployAccess } from 'App/components/hooks';
import { Block, ClickableItem, DropDownMenu, SplitButtonWithActions } from '@upsales/components';
import Client from 'App/resources/Model/Client';
import { createActions, createMenuAvailable } from './actions';
import { createActionsWithoutClient, createMenuAvailableWithoutClient } from './actionsWithoutClient';
import { RootState } from 'Store/index';
import React from 'react';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import type { CreateMenuAvailable } from './actions';
import type { CreateMenuAvailableWithoutClient } from './actionsWithoutClient';

import './ClientCardSplitButton.scss';

type Props = {
	currentTab: string;
	client?: Client;
	score?: number;
	contactsTotal?: number;
};

const ClientCardSplitButton = (props: Props) => {
	let { currentTab, client, score, contactsTotal } = props;
	const { t } = useTranslation();
	const accountSelf = useSelector(({ App }) => App.accountSelf);
	const { customerId } = useSelector(({ App }: RootState) => App);
	const hasActivities = !useSoftDeployAccess('REMOVE_ACTIVITIES');
	const classes = new BemClass('ClientCardSplitButton');

	if (!accountSelf) {
		return null;
	}

	if (!hasActivities && currentTab === 'activities') {
		currentTab = 'phonecalls';
	}

	const tabActions: (keyof CreateMenuAvailable)[] = [
		'contacts',
		'activities',
		'phonecalls',
		'opportunities',
		'orders',
		'relations',
		'market',
		'files',
		'support',
		'projectPlan'
	];
	let actions;
	let filteredActions: ReturnType<typeof createActions> | [];
	let hasCreateMenuAvailable;

	if (client) {
		const canCreateEntity: CreateMenuAvailable = createMenuAvailable(accountSelf, client);
		actions = createActions(accountSelf, client, customerId, score, contactsTotal);
		filteredActions = actions.filter(action => action.visible?.(accountSelf, client as Client) ?? true);
		hasCreateMenuAvailable = canCreateEntity[currentTab as keyof CreateMenuAvailable];
	} else {
		const canCreateEntity: CreateMenuAvailableWithoutClient = createMenuAvailableWithoutClient(accountSelf);
		actions = createActionsWithoutClient(accountSelf);
		filteredActions = actions.filter(action => action.visible?.(accountSelf) ?? true);
		hasCreateMenuAvailable = canCreateEntity[currentTab as keyof CreateMenuAvailableWithoutClient];
	}

	return tabActions.includes(currentTab as keyof CreateMenuAvailable) && hasCreateMenuAvailable ? (
		<SplitButtonWithActions
			className={classes.b()}
			actions={filteredActions.map(i => ({
				...i,
				title: i.title,
				onClick: () => {
					i.onClick();
				}
			}))}
			space="mtm"
			trigger="click"
			dropdownDirection="bottom"
			mainAction={currentTab}
			icon="plus"
		/>
	) : (
		<DropDownMenu
			useAnimation
			align="right"
			className={classes.b()}
			noMinWidth
			renderTrigger={(_, setExpanded) => (
				<Block space="mtm">
					<PrimaryButton onClick={setExpanded} icon="plus">
						{t('default.create')}
					</PrimaryButton>
				</Block>
			)}
		>
			{close =>
				filteredActions.map((entity, i) => (
					<ClickableItem
						block
						key={i}
						icon={entity.icon}
						color="black"
						title={t(entity.title)}
						onClick={e => {
							e.stopPropagation();
							entity.onClick();
							close();
						}}
					/>
				))
			}
		</DropDownMenu>
	);
};

export default ClientCardSplitButton;
