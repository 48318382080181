import { Headline, Text, Row, Column, Icon, Input, Block } from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';
import Bem from '@upsales/components/Utils/bemClass';
import { passwordHelper } from '@upsales/common';
import logError from 'Helpers/logError';
import './RequestChangePassword.scss';
import T from '../Helpers/translate';
import PropTypes from 'prop-types';
import React from 'react';

const CSSClass = new Bem('RequestChangePassword');

const RuleFulfilledIndicator = ({ fullfilled }: { fullfilled: boolean }) => (
	<div className={CSSClass.elem('rule-fulfilled-indicator').mod({ fullfilled }).b()}>
		<Icon name="check" />
	</div>
);

type PasswordShape = {
	description: string;
	test: (value: string) => boolean;
};

type State = {
	newPassword: string;
	newPasswordRepeat: string;
	displayPassword: boolean;
	displayPasswordRepeat: boolean;
	loading: boolean;
};

type Props = {
	changePassword: (newPassword: string) => void;
	oldPassword: string;
};

class RequestChangePassword extends React.Component<Props, State> {
	PASSWORD_RULES: Array<PasswordShape>;
	static propTypes = {
		changePassword: PropTypes.func.isRequired,
		oldPassword: PropTypes.string.isRequired
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			newPassword: '',
			newPasswordRepeat: '',
			displayPassword: false,
			displayPasswordRepeat: false,
			loading: false
		};

		this.PASSWORD_RULES = passwordHelper.getRules();
		this.PASSWORD_RULES.push({
			test: value => !!(value && value !== this.props.oldPassword),
			description: 'resetPassword.rule.differentThanOld'
		});
		this.PASSWORD_RULES.forEach(rule => {
			rule.description = T(rule.description);
		});
	}

	onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newPassword: event.target.value });
	};
	onPasswordRepeatChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		this.setState({ newPasswordRepeat: event.target.value });
	onDisplayPasswordChange = () => this.setState({ displayPassword: !this.state.displayPassword });
	onDisplayPasswordRepeatChange = () => this.setState({ displayPasswordRepeat: !this.state.displayPasswordRepeat });
	// Make this a functional component and let LoginForceResetPassword handle the state when useReactInit is live for every environment
	// Second thought: this component is only used in LoginForceResetPassword then, they can be merged into one component (LoginForceResetPassword)
	changePassword = () =>
		this.setState({ loading: true }, async () => {
			try {
				await this.props.changePassword(this.state.newPassword);
				this.setState({
					displayPassword: false,
					displayPasswordRepeat: false,
					newPassword: '',
					newPasswordRepeat: '',
					loading: false
				});
			} catch (error) {
				logError(error, 'Failed to change password');
				this.setState({ loading: false });
			}
		});

	render() {
		const { newPassword, displayPassword, newPasswordRepeat, displayPasswordRepeat, loading } = this.state;

		const ruleCheckers = this.PASSWORD_RULES.map((rule, index) => (
			<Column key={index}>
				<Row>
					<RuleFulfilledIndicator fullfilled={rule.test(newPassword)} />
					<Text color="super-light-green">{T(rule.description)}</Text>
				</Row>
			</Column>
		));

		const disableSubmit =
			!passwordHelper.validate(newPassword).valid ||
			newPasswordRepeat !== newPassword ||
			newPassword === this.props.oldPassword;

		return (
			<div className={CSSClass.b()}>
				<Block space="mbl">
					<Headline size="md" color="super-light-green">
						{T('resetPassword.forceReset.title')}
					</Headline>
				</Block>
				<Block space="mbl">
					<Text color="super-light-green">{T('resetPassword.forceReset.description')}</Text>
				</Block>
				<Block space="mbl">
					<Text color="super-light-green" bold>
						{T('resetPassword.forceReset.rulesTitle')}
					</Text>
				</Block>
				<Row direction="column" className={CSSClass.elem('rule-checker-wrap').b()}>
					{ruleCheckers}
				</Row>
				<Block space="mtxl mbxl">
					<Text color="super-light-green" bold>
						{T('default.enterNewPassword')}
					</Text>
					<Block className={CSSClass.elem('input-wrap').b()} space="mbxl mtm">
						<Input
							type={displayPassword ? 'text' : 'password'}
							size="lg"
							value={newPassword}
							onChange={this.onPasswordChange}
							autoComplete="new-password"
						/>
						<Icon name={displayPassword ? 'eye-slash' : 'eye'} onClick={this.onDisplayPasswordChange} />
					</Block>
					<Text color="super-light-green" bold>
						{T('default.repeatPassword')}
					</Text>
					<Block className={CSSClass.elem('input-wrap').b()} space="mbxl mtm">
						<Input
							type={displayPasswordRepeat ? 'text' : 'password'}
							size="lg"
							value={newPasswordRepeat}
							onChange={this.onPasswordRepeatChange}
							state={newPasswordRepeat && newPasswordRepeat !== newPassword ? 'error' : null}
							autoComplete="new-password"
						/>
						<Icon
							name={displayPasswordRepeat ? 'eye-slash' : 'eye'}
							onClick={this.onDisplayPasswordRepeatChange}
						/>
					</Block>
					<DefaultButton
						size="xl"
						disabled={disableSubmit}
						onClick={this.changePassword}
						block
						loading={loading}
					>
						{T('default.startUsingUpsales')}
					</DefaultButton>
				</Block>
			</div>
		);
	}
}

export default RequestChangePassword;
