'use strict';
import ProjectBoard from 'App/resources/ProjectBoard';

angular.module('services.listViewService', []).service('ListViewService', [
	'$q',
	'localStorageService',
	'$rootScope',
	'ListAttributes',
	'ListView',
	'FilterHelper',
	function ($q, localStorageService, $rootScope, ListAttributes, ListView, FilterHelper) {
		var searchViews = {
			account: {
				title: 'filters.searchResults',
				columns: ['name', 'phone', 'Address_Visit_city', 'users', 'status'],
				sorting: [{ attribute: 'name', ascending: true }],
				limit: 50,
				type: 'account'
			},
			contact: {
				title: 'filters.searchResults',
				columns: ['name', 'account', 'phone', 'email'],
				sorting: [{ attribute: 'name', ascending: true }],
				limit: 50,
				type: 'contact'
			},
			activity: {
				title: 'filters.searchResults',
				columns: ['date', 'account', 'description', 'activityType', 'contacts', 'users'],
				sorting: [{ attribute: 'date', ascending: true }],
				limit: 50,
				type: 'activity'
			},
			appointment: {
				title: 'filters.searchResults',
				columns: ['date', 'account', 'description', 'activityType', 'contacts', 'users'],
				sorting: [{ attribute: 'date', ascending: false }],
				limit: 50,
				type: 'appointment'
			},
			agreement: {
				title: 'filters.searchResults',
				columns: ['date', 'account', 'description', 'value', 'stage', 'user'],
				sorting: [{ attribute: 'date', ascending: true }],
				limit: 50,
				type: 'agreement'
			},
			order: {
				title: 'filters.searchResults',
				columns: ['date', 'account', 'description', 'value', 'stage', 'user'],
				sorting: [{ attribute: 'date', ascending: true }],
				limit: 50,
				type: 'order'
			},
			opportunity: {
				title: 'filters.searchResults',
				columns: ['date', 'account', 'description', 'value', 'stage', 'user'],
				sorting: [{ attribute: 'date', ascending: true }],
				limit: 50,
				type: 'opportunity'
			}
		};

		var getType = function (type) {
			if (type === 'assignedLeads' || type === 'unassignedLeads') {
				type = 'account';
			} else if (type.indexOf('userDefinedObject') !== -1) {
				type = 'userDefinedObject';
			}
			return type;
		};

		return {
			get: function (type, customerId, callback) {
				if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS)) {
					type = type === 'account' ? 'client' : type;
				}
				if (type === 'salesboard') {
					// I wrap it in q.when becouse Axios does not use angular promises and will not run a digest to update the view
					return $q.when(
						window.Tools.Salesboard.find().then(function (res) {
							if (callback) {
								if (console) {
									console.warn('Use .then() method instead of callback');
								}
								callback(res.data);
							}
							return res.data;
						})
					);
				}

				return $q
					.all({
						getPublicViews: ListView.customer(customerId)
							.setVisibilityType('public')
							.setEntityType(type)
							.find({}),
						getOwnViews: ListView.customer(customerId).setVisibilityType('own').setEntityType(type).find({})
					})
					.then(function (res) {
						var result = res.getPublicViews.data.concat(res.getOwnViews.data);

						if (callback) {
							if (console) {
								console.warn('Use .then() method instead of callback');
							}
							callback(result);
						}

						return result;
					});
			},

			getAllShared: function (customerId) {
				return ListView.customer(customerId).setVisibilityType('public').setEntityType('all').find({});
			},

			getAll: function (customerId) {
				return $q.all({
					account: this.get('account', customerId),
					contact: this.get('contact', customerId),
					activity: this.get('activity', customerId),
					agreement: this.get('agreement', customerId),
					order: this.get('order', customerId),
					opportunity: this.get('opportunity', customerId),
					mail: this.get('mail', customerId),
					mailTemplate: this.get('mailTemplate', customerId),
					lead: this.get('lead', customerId),
					leads2: this.get('leads2', customerId),
					assignedLeads: this.get('assignedLeads', customerId),
					unassignedLeads: this.get('unassignedLeads', customerId),
					visitor: this.get('visitor', customerId),
					campaign: this.get('campaign', customerId),
					mailCampaign: this.get('mailCampaign', customerId),
					salesboard: this.get('salesboard', customerId),
					formSubmit: this.get('formSubmit', customerId),
					form: this.get('form', customerId),
					page: this.get('page', customerId),
					appointment: this.get('appointment', customerId),
					userDefinedObject1: this.get('userdefobj', customerId),
					userDefinedObject2: this.get('userdefobj2', customerId),
					userDefinedObject3: this.get('userdefobj3', customerId),
					userDefinedObject4: this.get('userdefobj4', customerId),
					findProspects: this.get('findProspects', customerId)
				});
			},

			getAttrs: function (type) {
				type = getType(type);
				return ListAttributes.get(type).attr;
			},

			getKeys: function (type) {
				type = getType(type);
				return ListAttributes.get(type).keys;
			},

			getFilters: function (type) {
				type = getType(type);
				return ListAttributes.get(type).filters;
			},

			getStandardFilters: function (type) {
				type = getType(type);
				return ListAttributes.get(type).standardFilters;
			},

			getRelatedEntities: function (type) {
				type = getType(type);
				return ListAttributes.get(type).relatedEntities;
			},

			getField: function (type, field) {
				type = getType(type);
				return ListAttributes.get(type).attr[field];
			},

			getAttrByField: function (type, field) {
				type = getType(type);
				return _.find(ListAttributes.get(type).attr, { field: field });
			},

			getSearchView: function (type, filters) {
				type = getType(type);
				var view = angular.copy(searchViews[type]);
				angular.forEach(filters, function (filter) {
					var config = FilterHelper.getConfig(filter.filterName, type);
					if (config.type !== 'raw') {
						if (FilterHelper.isCustom(filter.filterName)) {
							view.columns.push(filter.filterName);
						} else if (config.selectableColumn) {
							view.columns.push(config.columnPath || filter.field);
						}
					}
				});
				view.columns = _.unique(view.columns);
				return view;
			},

			save: function (type, view, customerId, publicType, options) {
				if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS)) {
					type = type === 'account' ? 'client' : type;
				}
				if (type === 'salesboard') {
					// I wrap it in q.when becouse Axios does not use angular promises and will not run a digest to update the view
					return $q.when(
						window.Tools.Salesboard.save(view).then(function (res) {
							return res.data;
						})
					);
				}

				if (type === 'projectBoard') {
					view.standard = false;
					return ProjectBoard.save(view).then(function (res) {
						return res.data;
					});
				}

				publicType = publicType ? 'public' : 'own';
				return ListView.customer(customerId)
					.setEntityType(type)
					.setVisibilityType(publicType)
					.save(view, options)
					.then(function (res) {
						return res.data;
					});
			},

			delete: function (type, view, customerId) {
				var defered = $q.defer();
				if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SHARED_VIEWS)) {
					type = type === 'account' ? 'client' : type;
				}
				if (type === 'salesboard') {
					window.Tools.Salesboard.delete(view.id);
				} else if (type === 'projectBoard') {
					ProjectBoard.delete(view.id);
				} else {
					ListView.customer(customerId).setEntityType(type).delete(view);
				}

				setTimeout(function () {
					defered.resolve('deleted');
				}, 0);

				return defered.promise;
			}
		};
	}
]);
