'use strict';

const { formatWithSubAccounts } = require('App/helpers/accountsHelper');

angular.module('domain.contact').controller('MoveContact', [
	'$scope',
	'$modalParams',
	'$q',
	'RequestBuilder',
	'Account',
	'AppService',
	'Contact',
	'ContactRelation',
	'$translate',
	function ($scope, $modalParams, $q, RequestBuilder, Account, AppService, Contact, ContactRelation, $translate) {
		// var customerId = $modalParams.customerId;
		var MoveContact = this;
		var customerId;
		MoveContact.relationDescription = $translate.instant('contact.relationDefaultValue');

		const hasSubaccountsV1 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		const hasSubaccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
		const hasSubaccountsFeature =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
		const hasSubaccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;

		MoveContact.move = function () {
			MoveContact.moving = true;

			var promises = {};
			var contact = { id: MoveContact.contact.id, client: MoveContact.selectedAccount };

			promises.contact = Contact.customer(customerId).move(contact);

			if (MoveContact.relationDescription) {
				var rel = {
					contactId: MoveContact.contact.id,
					relatedToClientId: MoveContact.contact.client.id,
					description: MoveContact.relationDescription
				};
				promises.relation = ContactRelation.customer(customerId).save(rel);
			}

			// eslint-disable-next-line promise/catch-or-return
			$q.all(promises)
				.then(function (res) {
					$scope.resolve(res.contact.data);
				})
				.finally(function () {
					MoveContact.moving = false;
				});
		};

		var init = function () {
			customerId = AppService.getCustomerId();
			MoveContact.contact = $modalParams.contact;

			MoveContact.accountSelect = {
				minimumInputLength: 3,
				quietMillis: 100,
				allowClear: 1,
				ajax: {
					data: function (term) {
						return term;
					},
					transport: function (query) {
						if (query.data) {
							var filter = new RequestBuilder();

							filter.addFilter(Account.attr.name, filter.comparisonTypes.WildcardEnd, query.data);
							filter.addFilter(
								Account.attr.id,
								filter.comparisonTypes.NotEquals,
								MoveContact.contact.client.id
							);
							filter.addSort(Account.attr.name, true);
							filter.limit = 15;
							filter.fields = ['id', 'name', 'operationalAccount'];

							return Account.customer(customerId).find(filter.build()).then(query.success);
						}
						return $q.when({ data: [] });
					},
					results: function (res) {
						if (hasSubaccounts) {
							return { results: formatWithSubAccounts(res.data, $translate.instant) };
						}
						return { results: res.data };
					}
				},
				initSelection: _.noop,
				formatResult: function (obj, el, x, encode) {
					let res = encode(obj.name);
					if (hasSubaccounts && obj.operationalAccount) {
						res +=
							'<div class="subtitle grey">' +
							$translate.instant('account.subaccount.tooltip', {
								parent: obj.operationalAccount.name
							}) +
							'</div>';
					}
					return res;
				},
				formatSelection: function (obj, x, encode) {
					return encode(obj.name);
				}
			};

			MoveContact.selectedAccount = null;
		};

		init();
	}
]);
