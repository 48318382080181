import { OutsideClick } from '@upsales/components';
import { CountrySelect } from 'App/components/Inputs/Selects/CountrySelect';
import T from 'Components/Helpers/translate';

ReactTemplates.upFilters.filterTypes.select = window.ReactCreateClass({
	handleClickOutside: function () {
		this.setState({
			open: false
		});
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	getRef: function () {
		return this.ref;
	},
	getDefaultProps: function () {
		return {
			open: false,
			onChange: function () {},
			text: function () {}
		};
	},
	getInitialState: function () {
		return {
			open: this.props.open
		};
	},
	toggleExpanded: function () {
		this.setState({
			open: !this.props.open
		});
	},
	onChange: function (value) {
		this.props.onChange(value);
	},
	render: function () {
		var self = this;

		var options = _.map(self.props.options, function (option, index) {
			var text = option.text ? option.text : option.value;

			return (
				<li key={index} onClick={self.onChange.bind(null, option.value)}>
					<a ng-click="filterObj.value.AddressType.value = null; change();">{text}</a>
				</li>
			);
		});

		var className = self.state.open ? 'dropdown open' : 'dropdown';

		return (
			<div className="input-group-btn" ref={this.setRef.bind(this, 'ref')}>
				<OutsideClick
					targetRef={this.getRef}
					outsideClick={this.handleClickOutside}
					className={className}
					style={{ display: 'table-cell' }}
				>
					<button
						type="button"
						onClick={self.toggleExpanded}
						className="btn up-btn dropdown-toggle"
						style={{ height: '34px', lineHeight: '34px' }}
					>
						{self.props.text()}
						<b className="fa fa-caret-down"></b>
					</button>

					<ul className="dropdown-menu up-dropdown-white">{options}</ul>
				</OutsideClick>
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.valueToggle = window.ReactCreateClass({
	getDefaultProps: function () {
		return {
			options: [
				{
					value: true,
					text: 'true'
				},
				{
					value: false,
					text: 'false'
				}
			],
			value: true,
			onChange: function () {}
		};
	},
	onChange: function () {
		var self = this;
		var length = self.props.options.length;

		var index = _.findIndex(self.props.options, function (option) {
			return option.value === self.props.value;
		});

		if (index === -1) {
			index = 0;
		}

		index = (index + 1) % length;

		self.props.onChange(self.props.options[index].value);
	},
	render: function () {
		var self = this;
		var translate = Tools.$translate;

		var option = _.find(self.props.options, function (option) {
			return option.value === self.props.value;
		});

		var text = option ? option.text : self.props.options[0].text;

		return (
			<div className="up-comparison-type-toggle" onClick={self.onChange}>
				<span>{translate(text)}</span>
			</div>
		);
	}
});

ReactTemplates.upFilters.filterTypes.address = window.ReactCreateClass({
	getDefaultProps: function () {
		return {};
	},
	getInitialState: function () {
		const filter = this.props.filter.value;

		this.search = _.debounce(this.search, 300);
		this.lang = {
			address: T('address.address'),
			all: T('default.all'),
			'address.Delivery': T('address.delivery'),
			'address.Invoice': T('address.invoice'),
			'address.Mail': T('address.mail'),
			'address.Municipality': T('address.municipality'),
			'address.Other': T('address.other'),
			'address.Visit': T('address.visit'),
			city: T('address.city'),
			enter: T('default.enter'),
			searchAddress: T('default.searchAddress'),
			state: T('address.state'),
			municipality: T('address.municipality'),
			from: T('default.from'),
			to: T('default.to'),
			filter: T('filters.filter'),
			filters: T('filters.filters'),
			zip: T('address.zip'),
			country: T('address.country')
		};

		return {
			expanded: this.props.expanded || false,
			address: this.getValue(filter, 'Address.value', ''),
			city: this.getValue(filter, 'City.value', ''),
			state: this.getValue(filter, 'State.value', ''),
			municipality: this.getValue(filter, 'Municipality.value', ''),
			zipStart: this.getValue(filter, 'Zipcode.value.start', ''),
			zipEnd: this.getValue(filter, 'Zipcode.value.end', '')
		};
	},
	getValue: function (filter, path, undefinedValue) {
		var keys = path.split('.');

		var current = filter,
			key;

		for (var itr = 0, length = keys.length; itr < length; ++itr) {
			if (typeof current !== 'object') {
				return undefinedValue || undefined;
			}

			key = keys[itr];

			if (itr === length - 1) {
				return current[key];
			} else {
				current = current[key];
			}
		}
	},
	toggleExpanded: function () {
		this.setState({
			expanded: !this.state.expanded
		});
	},
	//writeTimeout: null,
	//writeLock: false,
	search: function (filter) {
		this.props.onChange(
			{
				filterName: this.props.filterName,
				value: filter
			},
			{
				action: 'add'
			}
		);
	},
	onChange: function (action, value) {
		var filter = this.props.filter.value ? this.props.filter.value : this.props.filter.generate().value;

		switch (action) {
			case 'AddressType':
				filter.AddressType.value = value;
				break;
			case 'AddressComparisionToggle':
				filter.Address.comparisonType = value;
				break;
			case 'Address':
				filter.Address.value = value.target.value;
				this.setState({ address: value.target.value });
				break;
			case 'ZipCodeFrom':
				filter.Zipcode.value.start = value.target.value;
				this.setState({ zipStart: value.target.value });
				break;
			case 'ZipCodeTo':
				filter.Zipcode.value.end = value.target.value;
				this.setState({ zipEnd: value.target.value });
				break;
			case 'CityComparisionToggle':
				filter.City.comparisonType = value;
				break;
			case 'MunicipalityComparisionToggle':
				filter.Municipality.comparisonType = value;
				break;
			case 'StateComparisionToggle':
				filter.State.comparisonType = value;
				break;
			case 'CountryComparisionToggle':
				filter.Country.comparisonType = value;
				break;
			case 'State':
				if (filter.State.comparisonType === 'Equals') {
					filter.State.comparisonType = 'Search';
				}
				filter.State.value = value.target.value;
				this.setState({ state: value.target.value });
				break;
			case 'City':
				if (filter.City.comparisonType === 'Equals') {
					filter.City.comparisonType = 'Search';
				}
				filter.City.value = value.target.value;
				this.setState({ city: value.target.value });
				break;
			case 'Municipality':
				if (filter.Municipality.comparisonType === 'Equals') {
					filter.Municipality.comparisonType = 'Search';
				}
				filter.Municipality.value = value.target.value;
				this.setState({ municipality: value.target.value });
				break;
			case 'Country':
				filter.Country.value = value ? value : '';
				break;
			case 'remove':
				filter = this.props.filter.generate().value;
				break;
		}

		this.search(filter);
	},
	onRemoveFilter: function (event) {
		this.setState({
			expanded: false
		});

		this.onChange('remove');

		ReactTemplates.TOOLS.stopProp(event);
	},
	formatValueString: function () {
		var number = this.props.filter.value ? this.props.filter.getText(this.props.filter.value) : 0;

		switch (number) {
			case 0:
				return '';
			case 1:
				return number + ' ' + this.lang.filter;
			default:
				return number + ' ' + this.lang.filters;
		}
	},
	render: function () {
		const self = this;
		const filter = self.props.filter;
		const active = this.props.filter.value && this.props.filter.getText(this.props.filter.value) > 0;

		const hasAddressMunicipality = Tools.FeatureHelper.hasSoftDeployAccess('ADDRESS_MUNICIPALITY');

		const title = filter.title ? Tools.$translate(filter.title) : self.props.filterName;

		const availableAddresses = _.map(self.props.filter.availableAddresses, function (address) {
			const text = self.lang['address.' + address];

			return {
				value: address,
				text: text
			};
		});

		availableAddresses.unshift({
			value: null,
			text: self.lang.all
		});

		const addressSelect = React.createElement(ReactTemplates.upFilters.filterTypes.select, {
			options: availableAddresses,
			text: function () {
				if (
					!self.props.filter.value ||
					!self.props.filter.value.AddressType ||
					!self.props.filter.value.AddressType.value
				) {
					return self.lang.all;
				} else {
					return self.lang['address.' + self.props.filter.value.AddressType.value];
				}
			},
			onChange: function (value) {
				self.onChange('AddressType', value);
			}
		});

		const addressValue = self.state.address;
		const addressToggleValue =
			self.props.filter.value && self.props.filter.value.Address
				? self.props.filter.value.Address.comparisonType
				: null;
		const addressToggle = React.createElement(ReactTemplates.upFilters.filterTypes.valueToggle, {
			options: [
				{ value: 'Search', text: 'comparison.Equals' },
				{ value: 'NotEquals', text: 'comparison.NotEquals' }
			],
			onChange: function (value) {
				self.onChange('AddressComparisionToggle', value);
			},
			value: addressToggleValue
		});

		const cityValue = self.state.city;
		const cityFilterActive =
			self.props.filter.value &&
			self.props.filter.value.City &&
			self.props.filter.value.City.value &&
			self.props.filter.value.City.value.length;
		const cityToggleValue =
			self.props.filter.value && self.props.filter.value.City
				? self.props.filter.value.City.comparisonType
				: null;
		const cityToggle = React.createElement(ReactTemplates.upFilters.filterTypes.valueToggle, {
			options: [
				{ value: 'Search', text: 'comparison.Equals' },
				{ value: 'NotEquals', text: 'comparison.NotEquals' }
			],
			onChange: function (value) {
				self.onChange('CityComparisionToggle', value);
			},
			value: cityToggleValue
		});

		const municipalityValue = self.state.municipality;
		const municipalityFilterActive = !!self.props.filter.value?.Municipality?.value?.length;
		const municipalityToggleValue = self.props.filter.value?.Municipality?.comparisonType ?? null;
		const municipalityToggle = React.createElement(ReactTemplates.upFilters.filterTypes.valueToggle, {
			options: [
				{ value: 'Search', text: 'comparison.Equals' },
				{ value: 'NotEquals', text: 'comparison.NotEquals' }
			],
			onChange: function (value) {
				self.onChange('MunicipalityComparisionToggle', value);
			},
			value: municipalityToggleValue
		});

		const stateValue = self.state.state;
		const stateToggleValue =
			self.props.filter.value && self.props.filter.value.State
				? self.props.filter.value.State.comparisonType
				: null;
		const stateToggle = React.createElement(ReactTemplates.upFilters.filterTypes.valueToggle, {
			options: [
				{ value: 'Search', text: 'comparison.Equals' },
				{ value: 'NotEquals', text: 'comparison.NotEquals' }
			],
			onChange: function (value) {
				self.onChange('StateComparisionToggle', value);
			},
			value: stateToggleValue
		});

		const countryToggleValue =
			self.props.filter.value && self.props.filter.value.Country
				? self.props.filter.value.Country.comparisonType
				: null;
		const countryToggle = React.createElement(ReactTemplates.upFilters.filterTypes.valueToggle, {
			options: [
				{ value: 'Search', text: 'comparison.Equals' },
				{ value: 'NotEquals', text: 'comparison.NotEquals' }
			],
			onChange: function (value) {
				self.onChange('CountryComparisionToggle', value);
			},
			value: countryToggleValue
		});

		const zipcodeFilterActive =
			self.props.filter.value &&
			self.props.filter.value.Zipcode &&
			(self.props.filter.value.Zipcode.start || self.props.filter.value.Zipcode.end);
		const zipcodeStart = self.state.zipStart;
		const zipcodeEnd = self.state.zipEnd;

		return (
			<div className="list-filters-row">
				<div onClick={this.toggleExpanded} className={active ? 'header active' : 'header'}>
					<span className={active ? 'title active' : 'title'}>{title}</span>
					{active && (
						<span className="delete-button pull-right" onClick={this.onRemoveFilter}>
							<i className="fa fa-times-circle" aria-hidden="true"></i>
						</span>
					)}
					<span className={active ? 'selected-values pull-right' : 'selected-values pull-right default'}>
						{this.formatValueString()}
					</span>
				</div>

				{this.state.expanded && (
					<div className="drilldown address-drilldown">
						<div className="input-wrap">
							<label>{this.lang.address}</label>
							{addressToggle}

							<div className="input-group">
								{addressSelect}
								<input
									onChange={self.onChange.bind(self, 'Address')}
									type="text"
									value={addressValue}
									className="form-control"
									placeholder={self.lang.searchAddress}
								/>
							</div>
						</div>

						<div className="input-wrap">
							<label>{this.lang.zip}</label>
							<div
								className={zipcodeFilterActive ? 'active input-group' : 'input-group'}
								style={{ width: '100%' }}
							>
								<input
									type="number"
									min="0"
									value={zipcodeStart}
									className="form-control combined-fields"
									placeholder={this.lang.from}
									onChange={self.onChange.bind(self, 'ZipCodeFrom')}
								/>
								<input
									type="number"
									min="0"
									value={zipcodeEnd}
									className="form-control combined-fields"
									placeholder={this.lang.to}
									onChange={self.onChange.bind(self, 'ZipCodeTo')}
								/>
							</div>
						</div>

						<div style={{ marginBottom: '10px' }}>
							<div className={cityFilterActive ? 'active input-wrap' : 'input-wrap'}>
								<label>{self.lang.city}</label>
								{cityToggle}
								<input
									type="text"
									className="form-control"
									value={cityValue}
									onChange={self.onChange.bind(self, 'City')}
									placeholder={this.lang.enter + ' ' + this.lang.city}
								/>
							</div>
							{hasAddressMunicipality ? (
								<div className={municipalityFilterActive ? 'active input-wrap' : 'input-wrap'}>
									<label>{self.lang.municipality}</label>
									{municipalityToggle}
									<input
										type="text"
										className="form-control"
										value={municipalityValue}
										onChange={self.onChange.bind(self, 'Municipality')}
										placeholder={self.lang.enter + ' ' + self.lang.municipality}
									/>
								</div>
							) : null}
							<div className="input-wrap">
								<label>{self.lang.state}</label>
								{stateToggle}
								<input
									type="text"
									value={stateValue}
									className="form-control"
									placeholder={self.lang.enter + ' ' + self.lang.state}
									onChange={self.onChange.bind(self, 'State')}
								/>
							</div>
						</div>

						<div style={{ marginBottom: '10px' }}>
							<div className="input-wrap">
								<label>{self.lang.country}</label>
								{countryToggle}
								<CountrySelect
									anchor={document.getElementsByClassName('list-filter-page')[0]}
									value={self.props.filter.value?.Country?.value || null}
									onChange={country => {
										this.onChange('Country', country ? country.id : null);
									}}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
});
