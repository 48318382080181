'use strict';
import { phoneNumberHelper } from '@upsales/common';

import { Text, Icon, Tooltip, Flex } from '@upsales/components';
import LinkButtonGroup from 'Components/Buttons/LinkButtonGroup';
import { createGoogleSearchURL, determineCompanyDescription } from 'Components/Prospecting/utils';
import GroupTreeColumn from 'Components/Prospecting/ProspectingGroupTree';
import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import { SubAccountLabelRaw } from 'Components/Misc/SubAccountLabel';

ReactTemplates.ROWSTATICS.accountRow = function () {
	var statics = {};
	statics.addUserTool = Tools.$translate('create_contact');
	statics.editTool = Tools.$translate('default.edit');
	statics.deleteTool = Tools.$translate('default.delete');
	statics.noAccountManager = Tools.$translate('default.noAccountManager');
	statics.noCampaigns = Tools.$translate('default.noCampaigns');
	statics.noCategories = Tools.$translate('default.noCategories');
	statics.noName = Tools.$translate('default.noName');
	statics.addExternal = Tools.$translate('default.addExternal');
	statics.assign = Tools.$translate('assign.assign');
	statics.createAccount = Tools.$translate('scoreboard.CreateAccount');

	return statics;
};

ReactTemplates.ROW.accountRow = window.ReactCreateClass({
	displayName: 'accountRow',
	click: function () {
		this.props.tools.opts.clickedEntry(this.props.item);
	},
	edit: function () {
		this.props.tools.opts.editEntry(this.props.item);
	},
	addContact: function (e) {
		const tools = this.props.tools;
		const customerId = tools.AppService.getCustomerId();
		const params = { customerId, account: this.props.item };
		tools.$upModal.open('editContact', params, e);
	},
	saveExternal: function (item) {
		this.props.tools.opts.saveExternal(item || this.props.item);
	},
	refreshSoliditet: function (e) {
		if (e) {
			ReactTemplates.TOOLS.stopProp(e);
		}
		this.props.tools.opts.refreshSoliditet(this.props.item);
	},
	remove: function () {
		this.props.tools.opts.removeEntry(this.props.item);
	},
	render: function () {
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;
		var refreshSoliditet = this.refreshSoliditet;
		var columnTools = this.props.tools.opts.columnTools || {};
		const lang = {
			website: tools.$translate('website')
		};

		const socialButtons = [
			item.webpage
				? { service: lang.website, href: item.webpage, icon: 'globe' }
				: { service: 'Google', href: createGoogleSearchURL(item), icon: 'globe', search: true },
			{ service: 'LinkedIn', href: item.linkedin, icon: 'linkedin' }
		];

		const description = determineCompanyDescription(item);

		const formatCurrency = tools.$filter('currencyFormat');
		const masterCurrency = _.find(tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true });
		const currency = masterCurrency ? masterCurrency.iso : '';

		const hasSubaccountsV1 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		const hasSubaccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
		const hasSubaccountsFeature =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
		const hasSubaccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;

		var getColumn = function (column, item) {
			var tooltip, icon, txt, address, style;

			var key = 'column-' + column;
			switch (column) {
				case 'Account_Extra_pipeline':
					return (
						<td key={key}>
							<Text size="sm" loading={item.accountExtra?.pipelineValue === undefined}>
								{formatCurrency(item.accountExtra?.pipelineValue, currency)}
							</Text>
						</td>
					);
				case 'Account_Extra_order': {
					let iconName = 'minus';
					let iconColor = 'grey-8';
					let tooltipTitle = tools.$translate('account.accountExtraOrderTooltipNoData');
					if (
						typeof item.accountExtra?.orderValue === 'number' &&
						typeof item.accountExtra?.orderValueLastYear === 'number' &&
						item.accountExtra.orderValueLastYear
					) {
						const increase = item.accountExtra.orderValue - item.accountExtra.orderValueLastYear;
						const differencePercentage = Math.round(
							(increase / item.accountExtra.orderValueLastYear) * 100
						);

						if (differencePercentage > 0) {
							iconName = 'chevron-up';
							iconColor = 'medium-green';
							tooltipTitle = `+${tools.$translate('account.accountExtraOrderTooltip', {
								sum: formatCurrency(increase, currency),
								per: `+${differencePercentage}`
							})}`;
						} else if (differencePercentage < 0) {
							iconName = 'chevron-down';
							iconColor = 'red';
							tooltipTitle = tools.$translate('account.accountExtraOrderTooltip', {
								sum: formatCurrency(increase, currency),
								per: `${differencePercentage}`
							});
						}
					}
					return (
						<td key={key}>
							<Tooltip title={tooltipTitle}>
								<Text size="sm" loading={item.accountExtra?.orderValue === undefined}>
									{item.accountExtra?.orderValue !== undefined && (
										<Icon name={iconName} color={iconColor} />
									)}{' '}
									{formatCurrency(item.accountExtra?.orderValue, currency)}
								</Text>
							</Tooltip>
						</td>
					);
				}
				case 'phone':
					var val = item.phone;
					var tel = ReactTemplates.TOOLS.upVoice(item, 'client', item.phone);
					return (
						<td key={key}>
							{tel ? (
								<a target={tel.target} href={tel.href} onClick={tel.onClick}>
									{phoneNumberHelper.formatNumber(val)}
								</a>
							) : null}
						</td>
					);
				case 'links':
					return (
						<td key={key} onClick={event => event.stopPropagation()}>
							<LinkButtonGroup description={description} buttons={socialButtons} />
						</td>
					);

				//case 'addresses':
				case 'Address_Visit_city':
				case 'Address_Other_city':
				case 'Address_Invoice_city':
				case 'Address_Delivery_city':
				case 'Address_Mail_city':
					if (!item.addresses || !item.addresses.length) {
						return ReactTemplates.TOOLS.emptyTD();
					}

					address = undefined;

					if (column === 'Address_Visit_city') {
						address = _.find(item.addresses, { type: 'Visit' });
					} else if (column === 'Address_Other_city') {
						address = _.find(item.addresses, { type: 'Other' });
					} else if (column === 'Address_Invoice_city') {
						address = _.find(item.addresses, { type: 'Invoice' });
					} else if (column === 'Address_Delivery_city') {
						address = _.find(item.addresses, { type: 'Delivery' });
					} else if (column === 'Address_Mail_city') {
						address = _.find(item.addresses, { type: 'Mail' });
					}

					if (!address || !address.city) {
						return ReactTemplates.TOOLS.emptyTD();
					}

					style = { width: '30px', textAlign: 'center' };
					icon = <b className="icon fa fa-map-marker" style={style} />;

					if (address.country) {
						icon = (
							<b className={'icon flag-icon flag-icon-' + address.country.toLowerCase()} style={style} />
						);
					}

					return (
						<td key={key}>
							{icon}
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.id}
								text={address.city}
								sub="addresses"
								tools={tools}
							/>
						</td>
					);

				case 'users':
					if (!item.users || !item.users.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noAccountManager}</i>
							</td>
						);
					} else {
						if (item.users.length === 1) {
							return (
								<td key={key}>
									<ReactTemplates.TOOLS.avatar
										user={item.users[0]}
										size={15}
										avatarService={tools.avatarService}
										style={{ marginTop: '0px' }}
									/>{' '}
									{item.users[0].name}
								</td>
							);
						} else {
							return (
								<td key={key}>
									{_.map(item.users, function (user, i) {
										return (
											<ReactTemplates.TOOLS.avatar
												key={'u-' + i}
												user={user}
												size={15}
												avatarService={tools.avatarService}
												style={{ marginTop: '0px', marginRight: '3px' }}
											/>
										);
									})}
								</td>
							);
						}
					}

				case 'campaigns':
					if (!item.projects || !item.projects.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCampaigns}</i>
							</td>
						);
					} else {
						return <td key={key}>{ReactTemplates.TOOLS.campaignsText(item.projects, tools)}</td>;
					}

				case 'categories':
					if (!item.$standardCategories || !item.$standardCategories.length) {
						return (
							<td key={key}>
								<i className="grey">{statics.noCategories}</i>
							</td>
						);
					} else {
						return (
							<td key={key}>{ReactTemplates.TOOLS.categoriesText(item.$standardCategories, tools)}</td>
						);
					}

				case 'assigned':
					return (
						<ReactTemplates.processedByColumn
							theKey={key}
							client={item}
							item={item}
							tools={tools}
							options={options}
						/>
					);

				case 'parent':
					if (!item.parent) {
						return ReactTemplates.TOOLS.emptyTD();
					}
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.parent.id}
								text={item.parent.name}
								tools={tools}
							/>
						</td>
					);

				case 'eventTypes':
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.eventTypes theKey={item.id} account={item} tools={tools} />
						</td>
					);

				case 'name':
					var text = _.trim(item.name);
					if (!text.length || text === ' ') {
						return (
							<td key={key}>
								<i>
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.id}
										text={statics.noName}
										tools={tools}
									/>
								</i>
							</td>
						);
					} else if (item.isExternal) {
						return (
							<td key={key}>
								<span className="grey">
									{item.name}
									&nbsp;{'('}
									<a onClick={options.saveExternal.bind(this, item)}>{statics.createAccount}</a>
									{')'}
								</span>
							</td>
						);
					} else {
						return (
							<td key={key}>
								<Flex alignItems="center">
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.id}
										text={item.name}
										tools={tools}
									/>
									{hasSubaccounts ? (
										<SubAccountLabelRaw
											operationalAccount={item.operationalAccount}
											translator={Tools.$translate}
										/>
									) : null}
								</Flex>
							</td>
						);
					}

				case 'status':
					return (
						<td key={key}>
							<ReactTemplates.TOOLS.leadStatus
								theKey={item.id}
								tools={tools}
								activity={item.hasActivity}
								activityOld={item.hadActivity}
								appointment={item.hasAppointment}
								appointmentOld={item.hadAppointment}
								opportunity={item.hasOpportunity}
								opportunityOld={item.hadOpportunity}
								order={item.hasOrder}
								orderOld={item.hadOrder}
							/>
						</td>
					);

				case 'journeyStep':
					return (
						<td key={key}>
							<JourneyStepIcon id={item.journeyStep} />
						</td>
					);

				case 'groupStructure':
					return (
						<td key={key}>
							<GroupTreeColumn
								prospectingId={item.prospectingId}
								country={item.country}
								existingClient={item}
							></GroupTreeColumn>
						</td>
					);

				default:
					if (tools.FilterHelper.isCustomCategory(column)) {
						var id = column.split('_')[1];
						if (!item.$extraCategories[id] || !item.$extraCategories[id].length) {
							return (
								<td key={key}>
									<i className="grey">{statics.noCategories}</i>
								</td>
							);
						} else {
							return (
								<td key={key}>
									{ReactTemplates.TOOLS.categoriesText(item.$extraCategories[id], tools)}
								</td>
							);
						}
					} else if (tools.FilterHelper.isAdCampaign(column)) {
						var parsedNested = tools.FilterHelper.parseNested(column);
						txt = item.$adCampaign[parsedNested.parentId]
							? item.$adCampaign[parsedNested.parentId][parsedNested.child]
							: null;

						if (!item.$adCampaign[parsedNested.parentId]) {
							if (parsedNested.child === 'hasIp') {
								if (
									(!item.dunsNo || item.dunsNo === '0' || item.dunsNo === '-1') &&
									!item.prospectingId
								) {
									icon = (
										<div onClick={refreshSoliditet} className="ad-status-label no-duns">
											<div className="ad-status-inner">
												<i className="fa fa-exclamation-triangle" />
											</div>
										</div>
									);
									tooltip = Tools.$translate('ads.accountHasNoDunsTooltip');
								} else {
									icon = (
										<div
											onClick={ReactTemplates.TOOLS.stopProp}
											className="ad-status-label not-active-yet"
										>
											<div className="ad-status-inner">
												<i className="fa fa-question" />
											</div>
										</div>
									);
									tooltip = Tools.$translate('ads.accountIsNotActiveYetTooltip');
								}
								return (
									<td key={key}>
										{ReactTemplates.TOOLS.withTooltip(icon, tooltip, { placement: 'top' })}
									</td>
								);
							}
							if (parsedNested.child === 'grade') {
								return <td key={key}>{''}</td>;
							}
							return <td key={key}>{'0'}</td>;
						}

						if (parsedNested.child === 'hasIp') {
							icon = (
								<div onClick={ReactTemplates.TOOLS.stopProp} className="ad-status-label no-ip">
									<div className="ad-status-inner">
										<i className="fa fa-hourglass-start" />
									</div>
								</div>
							);
							tooltip = Tools.$translate('ads.accountHasNoIpTooltip');
							if (txt === 1 || txt === true) {
								icon = (
									<div onClick={ReactTemplates.TOOLS.stopProp} className="ad-status-label has-ip">
										<div className="ad-status-inner">
											<i className="fa fa-check" />
										</div>
									</div>
								);
								tooltip = Tools.$translate('ads.accountHasIpTooltip');
							} else if (
								(!item.dunsNo || item.dunsNo === '0' || item.dunsNo === '-1') &&
								!item.prospectingId
							) {
								icon = (
									<div onClick={refreshSoliditet} className="ad-status-label no-duns">
										<div className="ad-status-inner">
											<i className="fa fa-exclamation-triangle" />
										</div>
									</div>
								);
								tooltip = Tools.$translate('ads.accountHasNoDunsTooltip');
							}
							return (
								<td key={key}>
									{ReactTemplates.TOOLS.withTooltip(icon, tooltip, { placement: 'top' })}
								</td>
							);
						}

						if (parsedNested.child === 'grade') {
							if (!txt) {
								return <td key={key}>{''}</td>;
							}
							if (txt > 150) {
								tooltip = Tools.$translate('ads.accountGrade4Tooltip');
								icon = (
									<div>
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-on" />
									</div>
								);
							} else if (txt > 50) {
								tooltip = Tools.$translate('ads.accountGrade3Tooltip');
								icon = (
									<div>
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-off" />
									</div>
								);
							} else if (txt > 10) {
								tooltip = Tools.$translate('ads.accountGrade2Tooltip');
								icon = (
									<div>
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-off" />
										<i className="fa fa-building ip-grade-off" />
									</div>
								);
							} else if (txt > 0) {
								tooltip = Tools.$translate('ads.accountGrade1Tooltip');
								icon = (
									<div>
										<i className="fa fa-building ip-grade-on" />
										<i className="fa fa-building ip-grade-off" />
										<i className="fa fa-building ip-grade-off" />
										<i className="fa fa-building ip-grade-off" />
									</div>
								);
							}
							return (
								<td key={key}>
									{ReactTemplates.TOOLS.withTooltip(icon, tooltip, { placement: 'top' })}
								</td>
							);
						}

						if (parsedNested.child === 'active') {
							if (txt) {
								return <td key={key}>{Tools.$translate('default.yes')}</td>;
							}
							return <td key={key}>{Tools.$translate('default.no')}</td>;
						}

						if (parsedNested.child === 'lastTimestamp') {
							if (!txt) {
								return ReactTemplates.TOOLS.emptyTD();
							}
							return <td key={key}>{moment(txt).format('D MMM YYYY')}</td>;
						}
						return <td key={key}>{item.$adCampaign[parsedNested.parentId][parsedNested.child]}</td>;
					} else if (tools.FilterHelper.isAddress(column)) {
						var spl = column.split('_');
						var type = spl[1];
						txt = '';
						address = item.$addresses?.[type];
						if (address) {
							if (spl.length === 3) {
								txt = address[spl[2]];
								return (
									<td key={key}>
										<ReactTemplates.TOOLS.accountHref
											customerId={customerId}
											id={item.id}
											text={txt}
											sub="addresses"
											tools={tools}
										/>
									</td>
								);
							}
							style = { width: '30px', textAlign: 'center' };
							icon = <b className="icon fa fa-map-marker" style={style} />;
							if (address.address && address.address.length && address.city && address.city.length) {
								txt = address.address + ', ' + address.city;
							} else if (address.address && address.address.length) {
								txt = address.address;
							} else if (address.city && address.city.length) {
								txt = address.city;
							}

							if (address.country) {
								icon = (
									<b
										className={'icon flag-icon flag-icon-' + address.country.toLowerCase()}
										style={style}
									/>
								);
							}

							return (
								<td key={key}>
									{icon}
									<ReactTemplates.TOOLS.accountHref
										customerId={customerId}
										id={item.id}
										text={txt}
										sub="addresses"
										tools={tools}
									/>
								</td>
							);
						} else {
							return ReactTemplates.TOOLS.emptyTD();
						}
					}

					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		if (!columnTools.extraColumn1) {
			columnTools.extraColumn1 = {
				show: function () {
					return false;
				}
			};
		}

		var classes =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES) && !item.active
				? 'inactive'
				: '';

		return (
			<tr className={classes} key={'item-' + item.id} onClick={this.click} ref="row">
				<ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />
				{tds}
				<td className="column-tools account-tools">
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={columnTools.extraColumn1.show(item)}
						icon={columnTools.extraColumn1.icon}
						tooltip={Tools.$translate(columnTools.extraColumn1.tooltip)}
						onClick={columnTools.extraColumn1.onClick}
					/>
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.isExternal && item.userEditable && columnTools.edit !== false}
						icon="plus"
						tooltip={statics.addExternal}
						onClick={this.saveExternal}
					/>
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={item.createRights?.Contact}
						icon="user-plus"
						tooltip={statics.addUserTool}
						onClick={this.addContact}
					/>
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={!item.isExternal && item.userEditable && columnTools.edit !== false}
						icon="edit"
						tooltip={statics.editTool}
						onClick={this.edit}
					/>
					<ReactTemplates.TOOLS.rowTool
						item={item}
						show={!item.isExternal && item.userRemovable && columnTools.remove !== false}
						icon="trash-o"
						tooltip={statics.deleteTool}
						onClick={this.remove}
					/>
				</td>
			</tr>
		);
	}
});
