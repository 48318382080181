import { insertAfter } from 'App/babel/store/helpers/array';
import getCategoryConfigs from 'App/babel/filters/CategoryFilters';
import { generateUserFilter } from 'App/upsales/common/attributes/filter/userFilter';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';
import AddressFilter from 'App/upsales/common/attributes/filter/addressFilter';
import OrderFilter from 'App/babel/angular/attributes/filter/orderFilter';
import TaskFilter from 'App/babel/angular/attributes/filter/taskFilter';
import OpportunityFilter from 'App/babel/angular/attributes/filter/opportunityFilter';
import ActivityFilter from 'App/babel/angular/attributes/filter/activityFilter';
import FormSubmitFilter from 'App/babel/angular/attributes/filter/formSubmitFilter';
import MailCampaignFilter from 'App/babel/angular/attributes/filter/mailCampaignFilter';
import VisitorFilter from 'App/babel/angular/attributes/filter/visitorFilter';
import AccountManagerFilter from 'App/upsales/common/attributes/filter/accountManagerFilter';

angular.module('upAttributes').service('AccountAttributes', [
	'FilterType',
	'DisplayType',
	'FeatureHelper',
	'AppService',
	'StaticValueLookUpFilter',
	'$q',
	function (FilterType, DisplayType, FeatureHelper, AppService, StaticValueLookUpFilter, $q) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'id'
				},
				name: {
					title: 'default.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'name',
					selectableColumn: true
					// inputType: 'text'
				},
				phone: {
					title: 'default.phone',
					field: 'phone',
					type: FilterType.String,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'phone'
				},
				fax: {
					title: 'default.fax',
					field: 'fax',
					type: FilterType.String,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: false,
					sortable: 'fax'
				},
				webpage: {
					field: 'webpage',
					title: 'website',
					selectableColumn: true,
					sortable: 'webpage',
					type: FilterType.String
				},
				assigned: {
					type: FilterType.Object,
					DisplayType: DisplayType.String,
					field: 'assigned',
					elevioId: 242,
					title: 'assign.assignedTo',
					selectableColumn: true
				},
				eventTypes: {
					type: FilterType.Number,
					DisplayType: DisplayType.Number,
					field: 'eventTypes',
					title: 'scoreboard.eventTypes',
					selectableColumn: false
				},
				score: {
					field: 'score',
					title: 'default.score',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: true,
					filterable: true,
					sortable: 'score'
				},
				scoreUpdateDate: {
					title: 'default.scoreUpdate',
					field: 'scoreUpdateDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: true,
					filterable: true,
					sortable: 'scoreUpdateDate'
				},
				leadRegDate: {
					title: 'column.date',
					field: 'leadRegDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: false,
					filterable: false
				},
				operationalAccount: {
					title: 'default.accountManager',
					field: 'operationalAccount.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					filterName: 'operationalAccountId',
					displayAttr: 'name',
					parent: 'operationalAccount',
					attr: {
						id: {
							field: 'operationalAccount.id',
							type: FilterType.Number
						},
						name: {
							field: 'operationalAccount.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				users: {
					title: 'default.accountManager',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					filterName: 'userId',
					// inputType: 'select',
					displayAttr: 'name',
					parent: 'users',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				lead: {
					title: 'default.lead',
					field: 'lead.id',
					type: FilterType.Number,
					parent: 'lead',
					attr: {
						id: {
							field: 'lead.id',
							type: FilterType.Number
						}
					}
				},
				campaigns: {
					title: 'default.campaigns',
					field: 'project.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'projects',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				categories: {
					title: 'default.categories',
					field: 'category.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'name',
					// inputType: 'select',
					parent: 'categories',
					attr: {
						id: {
							field: 'category.id',
							type: FilterType.Number
						},
						name: {
							field: 'category.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				parent: {
					title: 'default.parentCompany',
					field: 'parent.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'name',
					parent: 'parent',
					attr: {
						id: {
							field: 'parent.id',
							type: FilterType.Number
						},
						name: {
							field: 'parent.name',
							type: FilterType.String
						}
					},
					groupable: false,
					selectableColumn: true
				},
				notes: {
					field: 'notes',
					type: FilterType.String
				},
				isExternal: {
					field: 'isExternal'
				},
				addresses: {
					title: 'address.address',
					field: 'addresses',
					parent: 'addresses',
					selectableColumn: false,
					sortable: true,
					attr: {
						address: {
							field: 'address.address',
							type: FilterType.String
						},
						city: {
							field: 'address.city',
							type: FilterType.String
						},
						country: {
							field: 'address.country',
							type: FilterType.String
						},
						municipality: {
							field: 'address.municipality',
							type: FilterType.String
						},
						state: {
							field: 'address.state',
							type: FilterType.String
						},
						type: {
							field: 'address.type',
							type: FilterType.String
						},
						zipcode: {
							field: 'address.zipcode',
							type: FilterType.String
						}
					}
				},
				dunsNo: {
					title: 'default.duns',
					field: 'dunsNo',
					type: FilterType.Number
				},
				prospectingId: {
					title: '',
					field: 'prospectingId',
					type: FilterType.Number
				},
				adCampaign: {
					title: 'default.adCampaign',
					field: 'adCampaign.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					displayAttr: 'id',
					// inputType: 'select',
					parent: 'adCampaign',
					attr: {
						id: {
							field: 'adCampaign.id',
							type: FilterType.Number
						},
						active: {
							field: 'adCampaign.active',
							type: FilterType.Number
						},
						clicks: {
							field: 'adCampaign.clicks',
							type: FilterType.Number
						},
						impressions: {
							field: 'adCampaign.impressions',
							type: FilterType.Number
						},
						hasIp: {
							field: 'adCampaign.hasIp',
							type: FilterType.Number
						}
					},
					groupable: false,
					filterable: false,
					selectableColumn: false
				},
				adClicks: {
					title: 'default.adClicks',
					field: 'ads.clicks',
					selectableColumn: false,
					filterable: false,
					type: FilterType.Number,
					sortable: 'ads.clicks'
				},
				adImpressions: {
					title: 'default.adImpressions',
					field: 'ads.impressions',
					selectableColumn: false,
					filterable: false,
					type: FilterType.Number,
					sortable: 'ads.impressions'
				},
				adLastImpression: {
					title: 'default.adLastImpression',
					field: 'ads.lastImpression',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: false,
					filterable: false,
					sortable: 'ads.lastImpression'
				},
				status: {
					title: 'default.history',
					field: 'statusx',
					type: FilterType.String,
					selectableColumn: true
				},
				clientStatus: {
					title: 'default.status',
					field: 'status',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'status',
					unreleasedFeature: 'NEW_FIELDS'
				},
				orgNo: {
					title: 'default.orgNumber',
					field: 'orgNo',
					type: FilterType.String,
					selectableColumn: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				cfar: {
					title: 'default.cfar',
					field: 'cfar',
					type: FilterType.String,
					selectableColumn: false,
					unreleasedFeature: 'NEW_FIELDS'
				},
				registrationDate: {
					title: 'default.founded',
					field: 'registrationDate',
					type: FilterType.Date,
					selectableColumn: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				sniCode: {
					title: 'tag.account.sniCode',
					field: 'sniCode',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'sniCode',
					unreleasedFeature: 'NEW_FIELDS'
				},
				sicCode: {
					title: 'tag.account.sicCode',
					field: 'sicCode',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'sicCode',
					unreleasedFeature: 'NEW_FIELDS'
				},
				naicsCode: {
					title: 'tag.account.naicsCode',
					field: 'naicsCode',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'naicsCode',
					unreleasedFeature: 'NEW_FIELDS'
				},
				ukSicCode: {
					title: 'default.companyBranch.uksic',
					field: 'ukSicCode',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'ukSicCode',
					unreleasedFeature: 'NEW_FIELDS'
				},
				naceCode: {
					title: 'default.companyBranch.nace',
					field: 'naceCode',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'naceCode',
					unreleasedFeature: 'NEW_FIELDS'
				},
				companyForm: {
					title: 'soliditet.corporateForm',
					field: 'companyForm',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'companyForm',
					unreleasedFeature: 'NEW_FIELDS'
				},
				creditRating: {
					title: 'default.creditRating',
					field: 'creditRating',
					type: FilterType.String,
					selectableColumn: true,
					standardField: 'creditRating',
					unreleasedFeature: 'NEW_FIELDS'
				},
				turnover: {
					title: 'default.turnover',
					field: 'turnover',
					type: FilterType.Number,
					displayType: DisplayType.Currency,
					selectableColumn: true,
					sortable: 'turnover',
					unreleasedFeature: 'NEW_FIELDS'
				},
				profit: {
					title: 'default.profit',
					field: 'profit',
					type: FilterType.Number,
					displayType: DisplayType.Currency,
					selectableColumn: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				noEmployees: {
					title: 'default.numOfEmployees',
					field: 'noEmployees',
					type: FilterType.Number,
					selectableColumn: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				facebook: {
					title: 'default.facebook',
					field: 'facebook',
					type: FilterType.String,
					selectableColumn: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				twitter: {
					title: 'default.twitter',
					field: 'twitter',
					type: FilterType.String,
					selectableColumn: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				linkedin: {
					title: 'default.linkedin',
					field: 'linkedin',
					type: FilterType.String,
					selectableColumn: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				links: {
					title: 'default.links',
					field: 'links',
					type: FilterType.String,
					selectableColumn: true
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'journeyStep',
					displayType: DisplayType.None,
					sortable: 'journeySort',
					selectableColumn: false,
					groupable: false
				},
				active: {
					title: 'default.active',
					field: 'active',
					type: FilterType.Boolean
				},
				groupStructure: {
					title: 'account.relations.groupSize.groupSize',
					field: 'groupStructure',
					displayType: DisplayType.None,
					selectableColumn: true,
					groupable: false
				},
				custom: {},
				Account_Extra_order: {
					title: 'default.order',
					field: 'accountExtra.orderValue',
					type: FilterType.String
				},
				Account_Extra_pipeline: {
					title: 'pipeline',
					field: 'accountExtra.pipelineValue',
					type: FilterType.String
				}
			};

			if (AppService.loaded) {
				if (!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM)) {
					attributes.status.selectableColumn = false;
				}
			}

			if (AppService.loaded) {
				if (!FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA)) {
					attributes.scoreUpdateDate.selectableColumn = false;
					attributes.score.selectableColumn = false;
					attributes.assigned.selectableColumn = false;
					attributes.eventTypes.selectableColumn = false;
				}
			}

			var dateFilterPresets = [
				'whenever',
				'currentDay',
				'lastDay',
				'currentWeek',
				'lastWeek',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			const { Category, ...customCategoryFilters } = getCategoryConfigs('account');

			var standardFilters = {
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'name' }, rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				Name: {
					title: 'default.name',
					field: 'name',
					columnPath: 'name',
					inputType: 'text',
					type: 'text'
				},
				Phone: {
					title: 'filters.columns.phoneAccount',
					field: 'phone',
					columnPath: 'phone',
					inputType: 'text',
					type: 'text'
				},
				Parent: {
					field: 'parent.name',
					columnPath: 'parent',
					inputType: 'select',
					multiple: true,
					type: 'list',
					displayType: 'listLazy',
					dataPromise: function (customerId, Account, filterName, Lookup) {
						return Lookup.customer(customerId)
							.setType('account')
							.findEnd(Account.attr.parent.attr.name, '', 1000)
							.then(function (res) {
								var data = _.reduce(
									res.data,
									function (res, item) {
										if (item.value) {
											res.push({ id: item.value, name: item.value, count: item.count });
										}
										return res;
									},
									[]
								);

								return { data: data };
							});
					},
					searchFn: function (Account, AppService, Lookup) {
						var cId = AppService.getCustomerId();

						return function (term) {
							return Lookup.customer(cId)
								.setType('account')
								.findEnd(Account.attr.parent.attr.name, term.toLowerCase(), 1000)
								.then(function (res) {
									var data = _.reduce(
										res.data,
										function (res, item) {
											if (item.value) {
												res.push({ id: item.value, name: item.value, count: item.count });
											}
											return res;
										},
										[]
									);

									return { data: data };
								});
						};
					},
					searchField: 'name',
					displayText: 'name',
					search: true,
					title: 'default.parentCompany',
					multiComparitors: true
				},
				ParentId: {
					field: 'parent.id',
					columnPath: 'parent',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					hide: true,
					displayType: 'listLazy',
					includeFields: ['name'],
					searchFn: function (Account, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Account.attr.name, true);

							if (term) {
								filter.addFilter(Account.attr.name, filter.comparisonTypes.Search, term);
							}
							filter.addFilter({ field: 'connectedClients.id' }, filter.comparisonTypes.NotEquals, null);

							return Account.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: 'name',
					search: true,
					title: 'default.parentCompany',
					multiComparitors: true
				},
				ContactName: {
					title: 'filters.contactname',
					field: 'contact.name',
					inputType: 'text',
					type: 'text'
				},
				User: generateUserFilter(AppService.AccessType.ACCOUNT, {
					title: 'default.accountManagers',
					field: 'user.id',
					columnPath: 'users'
				}),
				Campaign: {
					field: 'project.id',
					inputType: 'select',
					resource: 'Campaign',
					multiple: true,
					search: true,
					includeFields: ['name'],
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}

							return Campaign.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					type: 'list',
					displayType: 'listLazy',
					multiComparitors: true,
					title: 'default.campaigns',
					columnPath: 'campaigns'
				},
				Category,
				StandardCategory: Category,
				...customCategoryFilters,
				JourneyStep: {
					type: 'list',
					filterName: 'JourneyStep',
					field: 'journeyStep',
					title: 'default.journeyStep',
					hide: true,
					dataPromise: function () {
						return $q.when({
							data: _.map(Tools.AppService.getJourneySteps(), function (j) {
								j.id = j.value;
								return j;
							})
						});
					},
					displayComponent: function (item) {
						return renderJourneyStepFilterRow(item);
					}
				},
				SignalsFilter: {
					type: 'raw',
					filterName: 'SignalsClientId',
					field: 'id',
					hide: true,
					build: function (filter, rb) {
						const journey = filter.value.journey || [];
						const include = filter.value.include || [];
						const exclude = filter.value.exclude || [];

						/*
						 * Add all these filters even though they might be empty.
						 * Otherwise the customer might end up with a filter that is too inclusive!
						 */

						if (journey.length || include.length) {
							const or = rb.orBuilder();
							or.next();
							or.addFilter({ field: 'journeyStep' }, rb.comparisonTypes.Equals, journey);
							or.next();
							or.addFilter({ field: 'id' }, rb.comparisonTypes.Equals, include);
							or.done();
						} else {
							rb.addFilter({ field: 'journeyStep' }, rb.comparisonTypes.Equals, journey);
						}

						rb.addFilter({ field: 'id' }, rb.comparisonTypes.NotEquals, exclude);
					}
				},
				Active: {
					field: 'active',
					listTitle: 'default.activeInactive',
					title: 'filters.companyStatus',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.activeContacts', value: true, comparisonType: 'Equals' },
						{ text: 'default.inactiveContacts', value: false, comparisonType: 'Equals' }
					],
					hide: true
				},
				SubAccounts: {
					field: 'operationalAccount.id',
					title: 'account.subaccounts',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.show', inactive: true, comparisonType: 'NotEquals' },
						{ text: 'default.hide', value: null, comparisonType: 'Equals' },
						{ text: 'default.only', value: null, comparisonType: 'NotEquals' }
					]
				},
				Score: {
					title: 'default.score',
					field: 'score',
					columnPath: 'score',
					type: 'range',
					inputType: 'range',
					parent: 'navbar.market'
				},
				ScoreUpdateDate: {
					title: 'default.scoreUpdate',
					field: 'scoreUpdateDate',
					columnPath: 'scoreUpdateDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'navbar.market'
				},
				RegistrationDate: {
					title: 'default.founded',
					unreleasedFeature: 'NEW_FIELDS',
					field: 'registrationDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'filters.columns.account'
				},
				RegDate: {
					title: 'default.created',
					field: 'regDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					parent: 'filters.columns.account'
				},
				SniCode: StaticValueLookUpFilter({
					field: 'sniCode'
				}),
				SniCodeRange: {
					title: 'tag.account.sniCodeRange',
					field: 'sniCode',
					type: 'range',
					inputType: 'range',
					parent: 'filters.columns.account'
				},
				SicCode: StaticValueLookUpFilter({
					field: 'sicCode'
				}),
				NaicsCode: StaticValueLookUpFilter({
					field: 'naicsCode'
				}),
				UKSicCode: StaticValueLookUpFilter({
					title: 'default.companyBranch.uksic',
					field: 'ukSicCode'
				}),
				NaceCode: StaticValueLookUpFilter({
					title: 'default.companyBranch.nace',
					field: 'naceCode'
				}),
				Status: {
					title: 'default.status',
					unreleasedFeature: 'NEW_FIELDS',
					field: 'status',
					type: 'list',
					inputType: 'select',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('status');
					},
					searchField: 'text',
					displayText: _.property('text'),
					parent: 'filters.columns.account',
					comparisonType: 'MatchExact'
				},
				CompanyForm: {
					title: 'soliditet.corporateForm',
					unreleasedFeature: 'NEW_FIELDS',
					field: 'companyForm',
					type: 'list',
					inputType: 'select',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('companyForm');
					},
					searchField: 'text',
					displayText: _.property('text'),
					parent: 'filters.columns.account',
					comparisonType: 'MatchExact'
				},
				CreditRating: {
					title: 'default.creditRating',
					unreleasedFeature: 'NEW_FIELDS',
					field: 'creditRating',
					type: 'list',
					inputType: 'select',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('creditRating');
					},
					searchField: 'text',
					displayText: _.property('text'),
					parent: 'filters.columns.account',
					comparisonType: 'MatchExact'
				},
				Turnover: {
					title: 'default.turnover',
					unreleasedFeature: 'NEW_FIELDS',
					field: 'turnover',
					type: 'range',
					inputType: 'range',
					parent: 'filters.columns.account'
				},
				Profit: {
					title: 'default.profit',
					unreleasedFeature: 'NEW_FIELDS',
					field: 'profit',
					type: 'range',
					inputType: 'range',
					parent: 'filters.columns.account'
				},
				NoEmployees: {
					title: 'default.numOfEmployees',
					unreleasedFeature: 'NEW_FIELDS',
					field: 'noEmployees',
					type: 'range',
					inputType: 'range',
					parent: 'filters.columns.account'
				},
				HasVisit: {
					title: 'default.siteVisits',
					parent: 'navbar.market',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.with', value: true },
						{ text: 'default.without', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val === null) {
							return;
						} else if (val === true) {
							rb.addFilter({ field: 'visit.id' }, rb.comparisonTypes.NotEquals, null);
						} else {
							rb.addFilter({ field: 'visit.id' }, rb.comparisonTypes.Equals, null);
						}
					}
				},
				HasMail: {
					title: 'default.mailing',
					parent: 'navbar.market',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.with', value: true },
						{ text: 'default.without', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val === null) {
							return;
						} else if (val === true) {
							var group = rb.groupBuilder();
							group.addFilter({ field: 'mail.mailCampaign.id' }, rb.comparisonTypes.GreatherThan, 0);
							group.done();
						} else {
							var notGroup = rb.groupBuilder();
							notGroup.addFilter({ field: 'mail.mailCampaign.id' }, rb.comparisonTypes.GreatherThan, 0);
							notGroup.isNotFilter();
							notGroup.done();
						}
					}
				},
				HasFormSubmit: {
					title: 'event.types.Form',
					parent: 'navbar.market',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.with', value: true },
						{ text: 'default.without', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val === null) {
							return;
						}
						var start;
						var end;
						var isExclude = false;

						if (typeof val === 'object') {
							if (val.start && moment(val.start).isValid()) {
								start = val.start;
							}
							if (val.end && moment(val.end).isValid()) {
								end = val.end;
							}
							if (val.exclude) {
								isExclude = true;
							}
						} else if (val === false) {
							isExclude = true;
						}

						if (val && !isExclude) {
							// has submit
							if (start) {
								rb.addFilter(
									{ field: 'formSubmit.regDate' },
									rb.comparisonTypes.GreaterThanEquals,
									val.start
								);
							}
							if (end) {
								rb.addFilter(
									{ field: 'formSubmit.regDate' },
									rb.comparisonTypes.LessThanEquals,
									val.end
								);
							}
							rb.addFilter({ field: 'formSubmit.id' }, rb.comparisonTypes.NotEquals, null);
						} else {
							// missing submit
							// Find out if we need a group filter
							if (start && end) {
								// need both to work
								var groupBuilder = rb.groupBuilder();
								groupBuilder.isNotFilter();
								groupBuilder.addFilter(
									{ field: 'formSubmit.regDate' },
									rb.comparisonTypes.GreaterThanEquals,
									val.start
								);
								groupBuilder.addFilter(
									{ field: 'formSubmit.regDate' },
									rb.comparisonTypes.LessThanEquals,
									val.end
								);
								groupBuilder.addFilter({ field: 'formSubmit.id' }, rb.comparisonTypes.NotEquals, null);
								groupBuilder.done();
							} else {
								rb.addFilter({ field: 'formSubmit.id' }, rb.comparisonTypes.Equals, null);
							}
						}
					}
				},
				HasTodo: {
					title: 'default.todo',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: TaskFilter.getText,
					generate: TaskFilter.generate,
					isInactive: TaskFilter.isInactive,
					toUrl: TaskFilter.toUrl,
					fromUrl: TaskFilter.fromUrl,
					build: TaskFilter.build({ phonecall: false }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				HasPhonecall: {
					title: 'default.phonecall',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: TaskFilter.getText,
					generate: TaskFilter.generate,
					isInactive: TaskFilter.isInactive,
					toUrl: TaskFilter.toUrl,
					fromUrl: TaskFilter.fromUrl,
					build: TaskFilter.build({ phonecall: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				HasActivity: {
					title: 'default.activity',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: ActivityFilter.getText,
					generate: ActivityFilter.generate,
					isInactive: ActivityFilter.isInactive,
					toUrl: ActivityFilter.toUrl,
					fromUrl: ActivityFilter.fromUrl,
					build: ActivityFilter.build(),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				HasAppointment: {
					title: 'default.appointment',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: ActivityFilter.getText,
					generate: ActivityFilter.generate,
					isInactive: ActivityFilter.isInactive,
					toUrl: ActivityFilter.toUrl,
					fromUrl: ActivityFilter.fromUrl,
					build: ActivityFilter.buildOnlyAppointment(),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				HasOpportunity: {
					title: 'default.opportunity',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: OpportunityFilter.getText,
					generate: OpportunityFilter.generate,
					isInactive: OpportunityFilter.isInactive,
					toUrl: OpportunityFilter.toUrl,
					fromUrl: OpportunityFilter.fromUrl,
					build: OpportunityFilter.build({ mixedProductsAndCategories: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month',
						'custom'
					]
				},
				HasOrder: {
					title: 'default.order',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: OrderFilter.getText,
					generate: OrderFilter.generate,
					isInactive: OrderFilter.isInactive,
					toUrl: OrderFilter.toUrl,
					fromUrl: OrderFilter.fromUrl,
					build: OrderFilter.build({ mixedProductsAndCategories: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month',
						'custom'
					]
				},
				ContactPhone: {
					title: 'filters.columns.phoneContact',
					field: 'contact.phone',
					inputType: 'text',
					type: 'text',
					parent: 'filters.columns.contactinformation'
				},
				Fax: {
					field: 'fax',
					inputType: 'text',
					type: 'text',
					title: 'default.fax',
					parent: 'filters.columns.contactinformation'
				},
				Address: {
					field: 'address.address',
					inputType: 'text',
					title: 'address.addresses',
					listTitle: 'address.address',
					type: 'text',
					hide: true,
					parent: 'filters.columns.contactinformation'
				},
				City: {
					field: 'address.city',
					inputType: 'text',
					listTitle: 'address.city',
					title: 'address.cities',
					type: 'text',
					columnPath: 'addresses',
					hide: true,
					parent: 'filters.columns.contactinformation'
				},
				Country: {
					field: 'address.country',
					inputType: 'select',
					title: 'address.countries',
					listTitle: 'address.country',
					multiComparitors: true,
					type: 'list',
					columnPath: 'addresses',
					hide: true,
					parent: 'filters.columns.contactinformation'
				},
				Zipcode: {
					field: 'address.zipcode',
					inputType: 'text',
					title: 'address.zip',
					type: 'text',
					hide: true,
					parent: 'filters.columns.contactinformation'
				},
				AddressFilters: {
					title: 'default.addresses',
					type: 'raw',
					inputType: 'address',
					displayType: 'address',
					controller: 'AddressFilter',
					availableAddresses: ['Visit', 'Mail', 'Delivery', 'Invoice', 'Other'],
					view: 'upsales/common/components/listFilters/address.tpl.html',
					getText: AddressFilter.getText,
					generate: AddressFilter.generate,
					isInactive: AddressFilter.isInactive,
					toUrl: AddressFilter.toUrl,
					fromUrl: AddressFilter.fromUrl,
					build: AddressFilter.build(),
					parent: 'filters.columns.contactinformation'
				},
				Webpage: {
					field: 'webpage',
					inputType: 'text',
					type: 'text',
					title: 'default.webpage',
					parent: 'filters.columns.contactinformation'
				},
				IsAssigned: {
					title: 'scoreboard.assigned',
					hide: true,
					field: 'assigned.user.id',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'scoreboard.assigned', value: null, comparisonType: 'NotEquals' },
						{ text: 'scoreboard.unassigned', value: null, comparisonType: 'Equals' }
					],
					parent: 'filters.columns.account'
				},
				Notes: {
					field: 'notes',
					inputType: 'text',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter(
								getField('comment.description'),
								rb.comparisonTypes.Search,
								filter.value
							);

							orBuilder.next();
							orBuilder.addFilter(getField('notes'), rb.comparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					},
					title: 'default.notes',
					search: true,
					displayType: 'text'
				},
				OrgNo: {
					field: 'orgNo',
					unreleasedFeature: 'NEW_FIELDS',
					inputType: 'text',
					type: 'text',
					title: 'default.orgNumber',
					parent: 'filters.columns.account'
				},
				OrgNoLookup: {
					field: 'orgNo',
					unreleasedFeature: 'NEW_FIELDS',
					columnPath: 'orgNo',
					type: 'list',
					displayType: 'lookUp',
					comparisonType: 'MatchExact',
					title: 'default.orgNumber',
					parent: 'filters.columns.account',
					hide: true
				},
				Id: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'number',
					type: 'text',
					parent: 'filters.columns.account'
				},
				ActivityFilters: {
					title: 'filters.advancedActivityFilter',
					type: 'raw',
					controller: '',
					view: '',
					generate: ActivityFilter.generate,
					isInactive: ActivityFilter.isInactive,
					toUrl: ActivityFilter.toUrl,
					fromUrl: ActivityFilter.fromUrl,
					build: ActivityFilter.build()
				},
				OrderFilters: {
					title: 'filters.advancedOrderFilter',
					type: 'raw',
					controller: '',
					view: '',
					generate: OrderFilter.generate,
					isInactive: OrderFilter.isInactive,
					toUrl: OrderFilter.toUrl,
					fromUrl: OrderFilter.fromUrl,
					build: OrderFilter.build()
				},
				OpportunityFilters: {
					title: 'filters.advancedOpportunityFilter',
					type: 'raw',
					controller: '',
					view: '',
					generate: OpportunityFilter.generate,
					isInactive: OpportunityFilter.isInactive,
					toUrl: OpportunityFilter.toUrl,
					fromUrl: OpportunityFilter.fromUrl,
					build: OpportunityFilter.build()
				},
				MailCampaignFilters: {
					title: 'filters.advancedMailCampaignFilters',
					type: 'raw',
					controller: '',
					view: '',
					generate: MailCampaignFilter.generate,
					isInactive: MailCampaignFilter.isInactive,
					toUrl: MailCampaignFilter.toUrl,
					fromUrl: MailCampaignFilter.fromUrl,
					build: MailCampaignFilter.build()
				},
				VisitorFilters: {
					title: 'filters.advancedVisitorFilters',
					type: 'raw',
					controller: '',
					view: '',
					generate: VisitorFilter.generate,
					isInactive: VisitorFilter.isInactive,
					toUrl: VisitorFilter.toUrl,
					fromUrl: VisitorFilter.fromUrl,
					build: VisitorFilter.build()
				},
				FormSubmitFilters: {
					title: 'filters.advancedFormSubmitFilters',
					type: 'raw',
					controller: '',
					view: '',
					generate: FormSubmitFilter.generate,
					isInactive: FormSubmitFilter.isInactive,
					toUrl: FormSubmitFilter.toUrl,
					fromUrl: FormSubmitFilter.fromUrl,
					build: FormSubmitFilter.build()
				},
				AccountManagerFilters: {
					title: 'filter.accountManagerFilter',
					type: 'raw',
					controller: '',
					view: '',
					generate: AccountManagerFilter.generate,
					isInactive: AccountManagerFilter.isInactive,
					toUrl: AccountManagerFilter.toUrl,
					fromUrl: AccountManagerFilter.fromUrl,
					build: AccountManagerFilter.build()
				},
				FromImport: {
					field: 'importId',
					multiple: true,
					inputType: 'select',
					type: 'list',
					dataPromise: function (Import) {
						return Import.find({ status: Import.status.FINISHED, limit: 500, f: ['id', 'file'] });
					},
					searchField: 'file.filename',
					displayText: function (imprt) {
						return imprt.file ? imprt.file.filename : '';
					},
					title: 'import.import',
					multiComparitors: true,
					parent: 'filters.columns.account'
				},
				MADNoSalesLast36: {
					title: 'default.MADNoSalesLast36',
					type: 'raw',
					inputType: 'boolean',
					displayType: 'boolean',
					hide: true,
					generate: function () {
						return {
							value: false
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val) {
							var limit = moment().subtract(36, 'months').startOf('day').format();
							var or = rb.orBuilder();
							or.next();
							or.addFilter({ field: 'order.date' }, rb.comparisonTypes.LessThanEquals, limit);
							or.next();
							or.addFilter({ field: 'order.id' }, rb.comparisonTypes.Equals, null);
							or.done();
						}
					}
				},
				MADNoActivityLast90day: {
					title: 'default.MADNoActivityLast90day',
					type: 'raw',
					inputType: 'boolean',
					displayType: 'boolean',
					hide: true,
					generate: function () {
						return {
							value: false
						};
					},
					build: function (filter, rb) {
						var val = filter.value;
						var limit = moment().subtract(90, 'days').startOf('day').format();

						if (val) {
							var or = rb.orBuilder();
							or.next();
							or.addFilter({ field: 'activity.date' }, rb.comparisonTypes.LessThanEquals, limit);
							or.next();
							or.addFilter({ field: 'activity.id' }, rb.comparisonTypes.Equals, null);
							or.done();
						} else {
							rb.addFilter({ field: 'activity.id' }, rb.comparisonTypes.NotEquals, null);
							rb.addFilter({ field: 'activity.closeDate' }, rb.comparisonTypes.Equals, null);
							rb.addFilter({ field: 'activity.date' }, rb.comparisonTypes.GreaterThanEquals, limit);
						}
					}
				},
				MADHasOpportunity: {
					title: 'default.MADHasOpportunity',
					type: 'raw',
					inputType: 'boolean',
					displayType: 'boolean',
					hide: true,
					generate: function () {
						return {
							value: false
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val) {
							var groupBuilder = rb.groupBuilder();
							groupBuilder.addFilter(
								{ field: 'order.probability' },
								rb.comparisonTypes.GreaterThanEquals,
								25
							);
							groupBuilder.addFilter(
								{ field: 'order.probability' },
								rb.comparisonTypes.LessThanEquals,
								99
							);
							groupBuilder.done();
							rb.addFilter({ field: 'order.probability' }, rb.comparisonTypes.NotEquals, 100);
						}
					}
				},
				MADHasSalesLatestYear: {
					title: 'default.MADHasSalesLatestYear',
					type: 'raw',
					inputType: 'boolean',
					displayType: 'boolean',
					hide: true,
					generate: function () {
						return {
							value: false
						};
					},
					build: function (filter, rb) {
						var val = filter.value;
						var limit = moment().subtract(12, 'months').startOf('day').format();

						if (val) {
							var groupBuilder = rb.groupBuilder();
							groupBuilder.addFilter(
								{ field: 'order.probability' },
								rb.comparisonTypes.GreaterThanEquals,
								100
							);
							groupBuilder.addFilter(
								{ field: 'order.date' },
								rb.comparisonTypes.GreaterThanEquals,
								limit
							);
							groupBuilder.done();
						} else {
							var or = rb.orBuilder();
							or.next();
							or.addFilter({ field: 'order.date' }, rb.comparisonTypes.LessThanEquals, limit);
							or.addFilter({ field: 'order.probability' }, rb.comparisonTypes.Equals, 100);
							or.next();
							or.addFilter({ field: 'order.id' }, rb.comparisonTypes.Equals, null);
							or.done();
						}
					}
				},
				MADHasSalesOrPipeLatestYear: {
					title: 'default.MADHasSalesOrPipeLatestYear',
					type: 'raw',
					inputType: 'boolean',
					displayType: 'boolean',
					hide: true,
					generate: function () {
						return {
							value: false
						};
					},
					build: function (filter, rb) {
						var val = filter.value;
						var limit = moment().subtract(12, 'months').startOf('day').format();

						if (val) {
							var groupBuilder = rb.groupBuilder();
							groupBuilder.addFilter(
								{ field: 'order.date' },
								rb.comparisonTypes.GreaterThanEquals,
								limit
							);
							groupBuilder.done();
						} else {
							var group = rb.groupBuilder();
							group.addFilter({ field: 'order.date' }, rb.comparisonTypes.GreaterThanEquals, limit);
							group.isNotFilter();
							group.done();
						}
					}
				},
				Segment: {
					field: 'segment.id',
					columnPath: 'segments',
					resource: 'Segment',
					inputType: 'select',
					type: 'list',
					search: true,
					searchFn: function (Segment, RequestBuilder) {
						return function (term) {
							var filter = new RequestBuilder();
							filter.addFilter(Segment.attr.name, filter.comparisonTypes.Search, term);
							return Segment.find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					title: 'segment.segments',
					multiComparitors: true,
					multiple: true,
					hide: true
				}
			};

			if (AppService.loaded) {
				if (!FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
					standardFilters.HasPhonecall.hide = true;
					standardFilters.HasTodo.hide = true;
				}

				if (FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
					standardFilters.HasActivity.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE)) {
					standardFilters.HasOpportunity.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS)) {
					standardFilters.HasOrder.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS)) {
					standardFilters.HasActivity.hide = true;
				}

				if (FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
					standardFilters.HasActivity.hide = true;
				}
				if (!FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
					standardFilters.HasAppointment.hide = true;
				}
				if (!FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS')) {
					standardFilters.SubAccounts.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.LEADS)) {
					standardFilters.ScoreUpdateDate.hide = true;
					standardFilters.Score.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO)) {
					attributes.groupStructure.hide = true;
				}

				if (FeatureHelper.isAvailableProduct('MA')) {
					attributes.eventTypes.selectableColumn = true;
				}

				if (!FeatureHelper.isAvailableProduct('MA')) {
					standardFilters.Score.hide = true;
					standardFilters.ScoreUpdateDate.hide = true;
					standardFilters.HasVisit.hide = true;
					standardFilters.HasMail.hide = true;
					standardFilters.HasFormSubmit.hide = true;
				}

				if (!FeatureHelper.isAvailableProduct('CRM')) {
					standardFilters.HasActivity.hide = true;
					standardFilters.HasOpportunity.hide = true;
					standardFilters.HasOrder.hide = true;
				}

				if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
					standardFilters.JourneyStep.hide = false;
					attributes.journeyStep.selectableColumn = true;
				}

				if (
					AppService.getMetadata().params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(dateFilterPresets, 'lastYear', 'lastFiscalYear');
				}

				if (FeatureHelper.isAvailable(FeatureHelper.Feature.INACTIVE_COMPANIES)) {
					standardFilters.Active.hide = false;
				}
			}

			var requiredFields = ['custom'];

			var extraSelectables = [
				// Visit
				{
					title: 'address.visit',
					key: 'Address_Visit'
				},
				{
					title: 'address.address.visit',
					key: 'Address_Visit_address'
				},
				{
					title: 'address.zipcode.visit',
					key: 'Address_Visit_zipcode'
				},
				{
					title: 'address.city.visit',
					key: 'Address_Visit_city'
				},
				{
					title: 'address.country.visit',
					key: 'Address_Visit_country'
				},
				{
					title: 'address.state.visit',
					key: 'Address_Visit_state'
				},
				// Mail
				{
					title: 'address.mail',
					key: 'Address_Mail'
				},
				{
					title: 'address.address.mail',
					key: 'Address_Mail_address'
				},
				{
					title: 'address.zipcode.mail',
					key: 'Address_Mail_zipcode'
				},
				{
					title: 'address.city.mail',
					key: 'Address_Mail_city'
				},
				{
					title: 'address.country.mail',
					key: 'Address_Mail_country'
				},
				{
					title: 'address.state.mail',
					key: 'Address_Mail_state'
				},
				// Invoice
				{
					title: 'address.invoice',
					key: 'Address_Invoice'
				},
				{
					title: 'address.address.invoice',
					key: 'Address_Invoice_address'
				},
				{
					title: 'address.zipcode.invoice',
					key: 'Address_Invoice_zipcode'
				},
				{
					title: 'address.city.invoice',
					key: 'Address_Invoice_city'
				},
				{
					title: 'address.country.invoice',
					key: 'Address_Invoice_country'
				},
				{
					title: 'address.state.invoice',
					key: 'Address_Invoice_state'
				},
				// Delivery
				{
					title: 'address.delivery',
					key: 'Address_Delivery'
				},
				{
					title: 'address.address.delivery',
					key: 'Address_Delivery_address'
				},
				{
					title: 'address.zipcode.delivery',
					key: 'Address_Delivery_zipcode'
				},
				{
					title: 'address.city.delivery',
					key: 'Address_Delivery_city'
				},
				{
					title: 'address.country.delivery',
					key: 'Address_Delivery_country'
				},
				{
					title: 'address.state.delivery',
					key: 'Address_Delivery_state'
				},
				// Other
				{
					title: 'address.other',
					key: 'Address_Other'
				},
				{
					title: 'address.address.other',
					key: 'Address_Other_address'
				},
				{
					title: 'address.zipcode.other',
					key: 'Address_Other_zipcode'
				},
				{
					title: 'address.city.other',
					key: 'Address_Other_city'
				},
				{
					title: 'address.country.other',
					key: 'Address_Other_country'
				},
				{
					title: 'address.state.other',
					key: 'Address_Other_state'
				}
			];

			if (FeatureHelper.hasSoftDeployAccess('CUSTOMER_LIST_2')) {
				// api/v2/accounts/extra
				extraSelectables = extraSelectables.concat([
					{
						title: 'default.order',
						key: 'Account_Extra_order'
					},
					{
						title: 'pipeline',
						key: 'Account_Extra_pipeline'
					}
				]);
			}

			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				extraSelectables: angular.copy(extraSelectables),
				keys: {
					ID: 'id',
					NAME: 'name',
					PHONE: 'phone',
					FAX: 'fax',
					USERS: 'users',
					CATEGORIES: 'categories',
					CUSTOM: 'custom',
					CAMPAIGNS: 'campaigns',
					ADDRESSES: 'addresses'
				}
			};
		};
	}
]);
