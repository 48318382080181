import RequestBuilder from 'Resources/RequestBuilder';
import { FetcherProps } from './SalesTab';
import OrderAttributes from 'App/babel/attributes/Order';
import ComparisonTypes from 'Resources/ComparisonTypes';
import OrderResource from 'App/resources/Order';
import { getFilterFn } from 'Components/ListTab/ListTabHelper';
import attributes from 'App/babel/attributes/Agreement';
import AgreementResource from 'Resources/Agreement';
import Order from 'App/resources/Model/Order';
import moment from 'moment';
import Agreement from 'App/resources/Model/Agreement';
import { Currency } from 'App/resources/AllIWant';
import ARRChangeResource from 'App/babel/resources/ARRChanges';
import { ARRChange } from 'App/resources/Model/ARRChange';
import ClientResource from 'App/resources/Client';
import { getStagesFromState } from 'Store/selectors/AppSelectors';
import store from 'Store/index';

export const fetcherOrders = async (
	rb: RequestBuilder,
	{ clientIds, isGroupBySubAccount, hasRecurring }: FetcherProps
) => {
	if (hasRecurring) {
		return { data: [], metadata: { total: 0, limit: 0, offset: 0 } };
	}
	const filterFn = getFilterFn(!isGroupBySubAccount, false, true);
	const excludedStages = getStagesFromState(store.getState().App).filter(s => s.exclude);

	rb.addFilter(OrderAttributes.probability, ComparisonTypes.Equals, 100);
	rb.addFilter(
		OrderAttributes.stage,
		ComparisonTypes.NotEquals,
		excludedStages.map(stage => stage.id)
	);

	filterFn(rb, clientIds);

	return OrderResource.find(rb.build());
};

export const fetcherAgreements = async (
	rb: RequestBuilder,
	{ clientIds, isGroupBySubAccount, hasRecurring }: FetcherProps
) => {
	if (!hasRecurring) {
		return { data: [], metadata: { total: 0, limit: 0, offset: 0 } };
	}
	const filterFn = getFilterFn(!isGroupBySubAccount, false, true);
	filterFn(rb, clientIds);

	const date = new Date();

	const orGroup = rb.orBuilder();
	orGroup.next();
	orGroup.addFilter(attributes.metadata.attr.agreementEnddate, ComparisonTypes.Equals, null);
	orGroup.next();
	orGroup.addFilter(attributes.metadata.attr.agreementEnddate, ComparisonTypes.GreaterThan, date);
	orGroup.done();
	rb.addFilter(attributes.metadata.attr.agreementStartdate, ComparisonTypes.LessThanEquals, date);

	return AgreementResource.find(rb.build());
};

export const fetcherARRChanges = async (
	rb: RequestBuilder,
	{ clientIds, isGroupBySubAccount, hasRecurring }: FetcherProps
) => {
	if (!hasRecurring) {
		return { data: [], metadata: { total: 0, limit: 0, offset: 0 } };
	}
	const filterFn = getFilterFn(!isGroupBySubAccount, false, true);
	filterFn(rb, clientIds);

	return ARRChangeResource.find(rb.build());
};

export const fetcherClients = async (rb: RequestBuilder, { clientIds, isGroupBySubAccount }: FetcherProps) => {
	const filterFn = getFilterFn(!isGroupBySubAccount, false, false, '');
	filterFn(rb, clientIds);
	rb.fields = ['id'];

	return ClientResource.find(rb.build());
};

export const getSummedOrders = (orders: Order[], defaultCurrency: Pick<Currency, 'iso' | 'rate'>) => {
	let sumOrder = 0;
	let sumCM = 0;
	const key = 'valueInMasterCurrency';
	const keyInOwnCurrency = 'value';

	orders?.forEach(order => {
		if (moment(order.date).add(1, 'year').isAfter()) {
			const isDefaultCurrency = (order.currency || '').toLowerCase() === defaultCurrency.iso.toLowerCase();
			const orderValue = isDefaultCurrency
				? order?.[keyInOwnCurrency] ?? 0
				: Math.round(order[key] * defaultCurrency.rate);
			const cmValue = isDefaultCurrency
				? order.contributionMarginLocalCurrency
				: Math.round(order.contributionMarginLocalCurrency * defaultCurrency.rate);

			sumOrder += orderValue;
			sumCM += cmValue;
		}
	});

	return { sumOrder, sumCM };
};

export const getSummedAgreements = (agreements: Agreement[], defaultCurrency: Pick<Currency, 'iso' | 'rate'>) => {
	let sumARR = 0;
	const key = 'yearlyValueInMasterCurrency';
	const keyInOwnCurrency = 'yearlyValue';

	agreements?.forEach(agreement => {
		if (moment(agreement.metadata.agreementStartdate).add(1, 'year').isAfter()) {
			const isDefaultCurrency = (agreement.currency || '').toLowerCase() === defaultCurrency.iso.toLowerCase();
			const agreementValue = isDefaultCurrency
				? agreement?.[keyInOwnCurrency] ?? 0
				: Math.round(agreement[key] * defaultCurrency.rate);

			sumARR += agreementValue;
		}
	});
	return sumARR;
};

export const getSummedChanges = (arrChanges: ARRChange[], defaultCurrency: Pick<Currency, 'iso' | 'rate'>) => {
	let sumChangesARR = 0;
	let sumChangesMRR = 0;

	arrChanges?.forEach(arrChange => {
		if (moment(arrChange.date).add(1, 'year').isAfter() && moment(arrChange.date).isBefore()) {
			sumChangesARR += arrChange.annualValueInMasterCurrency * defaultCurrency.rate;
			sumChangesMRR += arrChange.monthlyValueInMasterCurrency * defaultCurrency.rate;
		}
	});

	return { sumChangesARR, sumChangesMRR };
};
