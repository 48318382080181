import { Icon, Table, TableColumn, TableHeader, TableRow, Text, Title, Toggle, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { useSelector } from 'App/components/hooks';
import history from 'App/pages/routes/history';
import t from 'Components/Helpers/translate';
import {
	Feature,
	getFeatureAvailableFromState,
	getProductAvailableFromState,
	getSoftDeployAccessFromState,
	Product
} from 'App/components/hooks/featureHelper';
import React, { useEffect, useState } from 'react';
import './HideTabs.scss';

type Tab = { id: string; title: string; hidden: boolean };

const HideTabs = ({ hiddenTabs = [], onChange }: { hiddenTabs?: string[]; onChange: any }) => {
	const allKeys = {
		'sub-todos': { lang: t('default.tasksHeader') },
		'sub-projectBoard': { lang: t('default.projectPlans') },
		'sub-projects': { lang: t('default.projectPlans') },
		'sub-customerPortfolio': { lang: t('default.customerPortfolio') },
		'sub-activities': { lang: t('default.activities') },
		'sub-signals': { lang: t('default.signals') },
		'sub-accounts': { lang: t('default.clientRegister') },
		'sub-prospects': { lang: t('default.findProspects') },
		'sub-orders': { lang: t('default.orders') },
		'sub-salesboard': { lang: t('default.salesboard') },
		'sub-listMail': { lang: t('default.mails') },
		'sub-userDefinedObject': { lang: t('default.userdefinedobjects') },
		'sub-esign': { lang: t('default.eSign') },
		'sub-recurringOrder': { lang: t('default.recurringOrder') },
		'sub-campaigns': { lang: t('default.campaigns') },
		'sub-phoneCalls': { lang: t('voice.phoneCalls') },
		'sub-accountGrowth': { lang: t('accountGrowth.tab') },
		'sub-leads': { lang: t('default.assignedLeads') },
		'sub-visits': { lang: t('default.siteVisitors') },
		'sub-form': { lang: t('default.formSubmits') },
		'sub-page': { lang: t('navbar.formsAndLandingpages') },
		'sub-segments': { lang: t('segment.segments') },
		'sub-mailings': { lang: t('mail.mailings') },
		'react-root-mailtemplates': { lang: t('mail.templates') },
		'sub-mailTemplates': { lang: t('mail.templates') },
		'sub-listAds': { lang: t('default.advertising') },
		'sub-events': { lang: t('default.socialEvents') },
		'sub-reportcenter': { lang: t('default.reportcenter') },
		'sub-looker': { lang: t('default.looker') },
		'sub-risks': { lang: t('default.risks') },
		'sub-portfolio-signals': { lang: t('default.signals') },
		'sub-potential': { lang: t('default.potential') },
		'sub-portfolio-clients': { lang: t('default.clients') },
		'sub-portfolio-contacts': { lang: t('default.contacts') },
		'sub-overview': { lang: t('default.overview') }
	};

	const classes = new BemClass('HideTabs');
	const scrollTo = new URLSearchParams(history.location.search).get('scrollTo') as 'sale' | 'market' | 'portfolio';

	const getInitialCollapsedSections = () => {
		if (!scrollTo) {
			return { sale: true, market: true, portfolio: true };
		}
		const initial = { sale: false, market: false, portfolio: false };
		if (scrollTo) {
			initial[scrollTo] = true;
		}

		return initial;
	};
	const [collapsedSections, setCollapsSections] = useState(getInitialCollapsedSections());

	const element = scrollTo ? document.getElementById('admin-profile-starting-screen') : null;

	useEffect(() => {
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [element]);

	const { accessRights, accountSelf, self, metadata, phoneIntegration, esignIntegrations } = useSelector(
		({ App }) => App
	);
	const userDefinedObject = metadata?.params.UserDefinedObject;
	const features = accountSelf?.features || {};
	const products = accountSelf?.products || {};
	const hasMA = getProductAvailableFromState(accountSelf, Product.MA);
	const isAdmin = self?.administrator;
	const marketAdmin = self?.userParams.mailAdmin;
	const hasMultiSoliditet = isAdmin || self?.userParams.multiSoliditetAccess;
	const hasAcceptedTerms = metadata?.map.mail;
	let esignActive = metadata?.esign.active;
	const hasEsignFeature = getFeatureAvailableFromState(accountSelf, Feature.ESIGN);
	if (!esignActive && hasEsignFeature) {
		esignActive = !!esignIntegrations.length;
	}
	const hasEsign = hasEsignFeature && esignActive;
	const hasMAAccess = isAdmin || marketAdmin;
	const hasCampaigns = getFeatureAvailableFromState(accountSelf, Feature.PROJECTS);
	const hasSegment = getFeatureAvailableFromState(accountSelf, Feature.SEGMENT);
	const showCampaignsInSalesSection = products.crm && (hasSegment || !products.ma || !hasMAAccess);
	const hasVoice = !!phoneIntegration;
	const hasHideAdvertising = getSoftDeployAccessFromState(accountSelf, 'HIDE_ADVERTISING');
	const hasEventAccess = accessRights.SocialEvent && getFeatureAvailableFromState(accountSelf, Feature.SOCIAL_EVENTS);
	const hasEventsFeature = getFeatureAvailableFromState(accountSelf, Feature.SOCIAL_EVENTS);

	const hasTopCustomerPortfolio =
		getFeatureAvailableFromState(accountSelf, Feature.TOP_CUSTOMER_PORTFOLIO) &&
		getSoftDeployAccessFromState(accountSelf, 'TOP_CUSTOMER_PORTFOLIO');
	const hasClientsContactsInPortfolio =
		!getSoftDeployAccessFromState(accountSelf, 'CONTACTS_AND_CLIENTS_NOT_IN_PORTFOLIO') && hasTopCustomerPortfolio;

	// Only allow hiding tabs of sale and market
	const portfolioTabs: { [key: string]: Tab } = {};
	const salesTabs: { [key: string]: Tab } = {};
	const marketTabs: { [key: string]: Tab } = {};

	const addTab = (obj: { [key: string]: Tab }, id: keyof typeof allKeys) => {
		obj[id] = {
			id: id,
			title: allKeys[id as keyof typeof allKeys].lang,
			hidden: hiddenTabs.includes(id)
		};
	};

	// SALES
	//sub-todos
	if (
		features.activitiesAndAppointments &&
		(getSoftDeployAccessFromState(accountSelf, 'TODO_LIST') ||
			getSoftDeployAccessFromState(accountSelf, 'TASK_FILTERS'))
	) {
		addTab(salesTabs, 'sub-todos');
	}
	// sub-projectBoard || sub-projects
	if (getFeatureAvailableFromState(accountSelf, Feature.PROJECT_PLAN)) {
		const hasProjectBoard = getSoftDeployAccessFromState(accountSelf, 'PROJECT_BOARD');
		addTab(salesTabs, hasProjectBoard ? 'sub-projectBoard' : 'sub-projects');
	}
	// sub-customerPortfolio
	if (
		features.customerPortfolio &&
		getSoftDeployAccessFromState(accountSelf, 'CUSTOMER_PORTFOLIO') &&
		!hasTopCustomerPortfolio
	) {
		addTab(salesTabs, 'sub-customerPortfolio');
	}
	// sub-activities
	if (
		features.activitiesAndAppointments &&
		!(
			getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES') &&
			getSoftDeployAccessFromState(accountSelf, 'TODO_LIST')
		)
	) {
		addTab(salesTabs, 'sub-activities');
	}
	// sub-signals
	if (
		products.crm &&
		features.companiesAndContacts &&
		getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_PRO) &&
		getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_SIGNALS) &&
		!hasTopCustomerPortfolio
	) {
		addTab(salesTabs, 'sub-signals');
	}
	// sub-accounts
	if (products.crm && features.companiesAndContacts && !hasClientsContactsInPortfolio) {
		addTab(salesTabs, 'sub-accounts');
	}
	// sub-prospect
	if (products.crm && features.companiesAndContacts && hasMultiSoliditet && !hasTopCustomerPortfolio) {
		addTab(salesTabs, 'sub-prospects');
	}
	// sub-orders
	if (features.orders) {
		addTab(salesTabs, 'sub-orders');
	}
	// sub-salesboard
	if (features.pipeline) {
		addTab(salesTabs, 'sub-salesboard');
	}
	// sub-listMail
	if (
		products.crm &&
		features.email &&
		(hasAcceptedTerms || isAdmin || (metadata?.integrations.inits.mail && metadata?.integrations.inits.mail.length))
	) {
		addTab(salesTabs, 'sub-listMail');
	}
	// sub-userDefinedObject
	if (features.userDefinedObjects && userDefinedObject && userDefinedObject.length) {
		addTab(salesTabs, 'sub-userDefinedObject');
	}
	// sub-esign
	if (hasEsign) {
		addTab(salesTabs, 'sub-esign');
	}
	// sub-recurringOrder
	if (
		features.recurringOrder &&
		getFeatureAvailableFromState(accountSelf, Feature.RECURRING_ORDER) &&
		metadata?.params.AgreementEnabled &&
		accessRights.Agreement
	) {
		addTab(salesTabs, 'sub-recurringOrder');
	}
	// sub-campaigns
	if (hasCampaigns && showCampaignsInSalesSection) {
		addTab(salesTabs, 'sub-campaigns');
	}
	// sub-phoneCalls
	if (hasVoice) {
		addTab(salesTabs, 'sub-phoneCalls');
	}
	// sub-accountGrowth
	if (getSoftDeployAccessFromState(accountSelf, 'ACCOUNT_GROWTH')) {
		addTab(salesTabs, 'sub-accountGrowth');
	}

	// MARKET
	if (hasMAAccess) {
		// sub-leads
		if (features.leads) {
			addTab(marketTabs, 'sub-leads');
		}
		// sub-visits
		if (features.visits || features.visits2) {
			addTab(marketTabs, 'sub-visits');
		}
		// sub-form
		if (features.forms) {
			addTab(marketTabs, 'sub-form');
			addTab(marketTabs, 'sub-page');
		}
		if (hasSegment) {
			addTab(marketTabs, 'sub-segments');
		}
		if (hasCampaigns && !showCampaignsInSalesSection) {
			addTab(marketTabs, 'sub-campaigns');
		}
	}

	if (products.crm) {
		// sub-mailings
		if (features.groupMail && (marketAdmin || isAdmin)) {
			addTab(marketTabs, 'sub-mailings');
		}
		if (features.email && (marketAdmin || isAdmin)) {
			// react-root-mailtemplates
			if (getSoftDeployAccessFromState(accountSelf, 'MAIL_TEMPLATES_V2')) {
				addTab(marketTabs, 'react-root-mailtemplates');
				// sub-mailTemplates
			} else {
				addTab(marketTabs, 'sub-mailTemplates');
			}
		}
	}
	// sub-listAds
	if (features.ads && hasMAAccess && !hasHideAdvertising) {
		addTab(marketTabs, 'sub-listAds');
	}
	// sub-events
	if (hasEventsFeature && hasEventAccess) {
		addTab(marketTabs, 'sub-events');
	}

	//PORTFOLIO
	if (hasTopCustomerPortfolio) {
		addTab(portfolioTabs, 'sub-overview');
		addTab(portfolioTabs, 'sub-risks');
		addTab(portfolioTabs, 'sub-portfolio-signals');

		// sub-potential
		if (getSoftDeployAccessFromState(accountSelf, 'CUSTOMER_PORTFOLIO_POTENTIAL')) {
			addTab(portfolioTabs, 'sub-potential');
		}

		//sub-prospects
		if (
			getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_BASIC || self?.userParams.soliditetIsActive)
		) {
			addTab(portfolioTabs, 'sub-prospects');
		}

		if (hasClientsContactsInPortfolio) {
			addTab(portfolioTabs, 'sub-portfolio-clients');
			addTab(portfolioTabs, 'sub-portfolio-contacts');
		}
	}

	const renderRows = (section: 'sale' | 'market' | 'portfolio', list: Tab[] = []) => {
		const getSectionName = () => {
			if (section === 'sale') {
				return t('navbar.sale');
			}
			if (section === 'market') {
				return t('navbar.market');
			}
			if (section === 'portfolio') {
				return t('default.customerPortfolio');
			}
		};
		const toggleCollapse = () => {
			setCollapsSections(prev => ({ ...prev, [section]: !prev[section] }));
		};
		const rows = list.map(item => {
			const className = item.hidden ? classes.elem('DisabledRow').b() : '';
			const newTabs = { ...portfolioTabs, ...salesTabs, ...marketTabs };

			const toggleHidden = (value: boolean) => {
				newTabs[item.id].hidden = value;
				const hiddenTabs = Object.values(newTabs)
					.filter(tab => tab.hidden)
					.map(tab => tab.id);
				const paramValue = hiddenTabs.length ? JSON.stringify(hiddenTabs) : '';
				onChange(paramValue);
			};

			const renderToggle = () => {
				const isLastShown = list.filter(tab => !tab.hidden).length === 1;
				let toggleTooltip = t(item.hidden ? 'show' : 'hide');
				toggleTooltip = toggleTooltip.charAt(0).toUpperCase() + toggleTooltip.slice(1);
				if (isLastShown && !item.hidden) {
					return (
						<Tooltip title={t('admin.profile.hideLastTabTooltip')} position="left">
							<Toggle checked={item.hidden} onChange={toggleHidden} disabled />
						</Tooltip>
					);
				}
				return (
					<Tooltip title={toggleTooltip} position="left">
						<Toggle checked={item.hidden} onChange={toggleHidden} />
					</Tooltip>
				);
			};
			return (
				<TableRow key={item.id} className={className}>
					<TableColumn>
						<Text size="md" color={item.hidden ? 'grey-9' : 'black'} italic={item.hidden}>
							{item.title}
						</Text>
					</TableColumn>
					<TableColumn />
					<TableColumn align="right">{renderToggle()}</TableColumn>
				</TableRow>
			);
		});
		return (
			<>
				<TableRow onClick={toggleCollapse} role="button" className={classes.elem('section').b()}>
					<TableColumn size="sm">
						<Text size="sm" bold>
							{getSectionName()}
						</Text>
					</TableColumn>
					<TableColumn size="sm" />
					<TableColumn size="sm" align="right">
						<Icon name={collapsedSections[section] ? 'chevron-up' : 'chevron-down'} />
					</TableColumn>
				</TableRow>
				{collapsedSections[section] ? rows : null}
			</>
		);
	};

	return (
		<>
			<div className="admin-card-top">
				<Title className="admin-card-title">{t('admin.profile.hideTabs')}</Title>
				<div className="description">
					<Text color="grey-10">{t('admin.profile.hideTabs.description')}</Text>
				</div>
			</div>
			<Table>
				<TableHeader
					columns={[{ title: 'Tab' }, {}, { title: 'Hide tab' }]}
					className={classes.elem('tableHeader').b()}
				/>
				{hasTopCustomerPortfolio ? renderRows('portfolio', Object.values(portfolioTabs)) : null}
				{renderRows('sale', Object.values(salesTabs))}
				{hasMA ? renderRows('market', Object.values(marketTabs)) : null}
			</Table>
		</>
	);
};

export default HideTabs;
