import { Button, Icon, Loader, Tooltip } from '@upsales/components';
import React, { useEffect, useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import GroupTreeModal from 'App/pages/Prospecting/GroupTreeModal';
import logError from 'App/babel/helpers/logError';
import RefreshAccount from '../../Modals/RefreshAccount/RefreshAccount';
import Prospecting from 'Resources/Prospecting';
import Client from 'App/resources/Model/Client';
import Lead from 'App/resources/Model/Lead';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import CreditsafeClient from 'App/resources/Model/CreditsafeClient';
import { makeCancelable } from 'App/babel/helpers/promise';
import translate from 'Components/Helpers/translate';
import { GroupSizeBatchFetcher } from 'App/helpers/prospecting';
import openModal from 'App/services/Modal';
import { openGenericModal } from 'App/components/GenericModal/GenericModal';

import './GroupTreeColumn.scss';

const groupSizeFetcher = new GroupSizeBatchFetcher();

type GroupTreeColumnProps = {
	prospectingId?: string | null;
	groupSize?: number;
	existingClient?: (Client & { userEditable: boolean }) | (Lead & { id: number; name: string });
};

const GroupTreeColumn: React.FC<GroupTreeColumnProps> = ({ prospectingId, groupSize, existingClient }) => {
	const classes = new bemClass('GroupTreeColumn');
	const [size, setSize] = useState<number | undefined>(groupSize);
	const [id, setId] = useState(prospectingId);
	const [loading, setLoading] = useState(false);
	const [openingModal, setOpeningModal] = useState(false);

	const { FeatureHelper, $upModal, AppService } = Tools;

	useEffect(() => {
		if (id && size === undefined) {
			setLoading(true);
			const cancelable = makeCancelable(groupSizeFetcher.getGroupSize(id));
			// eslint-disable-next-line promise/catch-or-return
			cancelable.promise.then(groupSize => {
				setSize(groupSize);
				setLoading(false);
			});
			return () => cancelable.cancel();
		}
	}, [id]);

	const lang = {
		openModal: translate('account.showGroup'),
		loading: translate('default.loadingEllipsis'),
		notProMessage: translate('account.relations.groupSize.commercialDescription2'),
		connectProspecting: translate('prospecting.groupStructure.connect')
	};

	const open = async (event: any) => {
		ReactTemplates.TOOLS.stopProp(event);
		if (openingModal) {
			return;
		}
		setOpeningModal(true);
		try {
			const data = await Prospecting.getCompanyGroup(id, true);

			openGenericModal({
				Component: (props: { resolve: () => void }) => (
					<GroupTreeModal
						account={data?.client as CreditsafeClient}
						companyGroup={data}
						{...props}
					></GroupTreeModal>
				),
				fullscreen: true
			});
		} catch (error) {
			logError(error, 'GroupTreeColumn: Failed to open group tree');
		} finally {
			setOpeningModal(false);
		}
	};

	const onRefreshAccount = (account: Client) => {
		if (account.prospectingId) {
			setId(account.prospectingId);
		}
	};

	const hasProspectingPRO = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO);

	const find = async (event: any) => {
		ReactTemplates.TOOLS.stopProp(event);
		if (hasProspectingPRO && existingClient && !id) {
			const customerId = AppService.getCustomerId();

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RefreshAccount', {
					account: existingClient,
					name: existingClient.name,
					duns: existingClient.dunsNo,
					onlyProspecting: true,
					onClose: account => {
						if (account) {
							onRefreshAccount(account);
						}
					}
				});
			} else {
				try {
					const component = (props: any) => <ReduxRootComponent component={RefreshAccount} {...props} />;
					const account = await $upModal.open('alert', {
						className: 'form-md allow-overflow',
						dialog: component,
						account: existingClient,
						name: existingClient.name,
						customerId: customerId,
						accountId: existingClient.id,
						duns: existingClient.dunsNo,
						onlyProspecting: true
					});
					onRefreshAccount(account);
				} catch (err) {
					if (err) {
						console.error(err);
					}
					// Modal closed
				}
			}
		}
	};

	if (!id && existingClient && hasProspectingPRO) {
		return (
			<div className={classes.b()}>
				<Button
					color="super-light-green"
					disabled={!existingClient.userEditable}
					size="sm"
					className="connect-btn"
					onClick={find}
				>
					<Icon name="plus" />
					{lang.connectProspecting}
				</Button>
			</div>
		);
	} else if (id && groupSize === null) {
		return (
			<div className={classes.b()}>
				<span>{'-'}</span>
			</div>
		);
	}

	return (
		<div className={classes.b()}>
			{/* Loading spinner */}
			{loading ? (
				<span className="grey-text loader">
					<Loader size="xs" noU={true} />
					<span>{lang.loading}</span>
				</span>
			) : null}
			{/* Show group size non clickable (feature missing) */}
			{!hasProspectingPRO && size && size <= 1 ? (
				<span id="single-not-pro">
					<i className="Icon Icon-sitemap" /> {size}
				</span>
			) : null}
			{/* Show group size non clickable with tooltip (feature missing) */}
			{!hasProspectingPRO && size && size > 1 ? (
				<Tooltip title={lang.notProMessage} position="left" distance={20}>
					<span>
						<i className="Icon Icon-sitemap" /> {size}
					</span>
				</Tooltip>
			) : null}

			{/* Show group size (hide on hover) */}
			{hasProspectingPRO && !loading ? (
				<span className={size && size > 1 ? 'hide-on-row-hover' : ''}>
					<i className="Icon Icon-sitemap" /> {size}
				</span>
			) : null}
			{/* Show group size (show on hover) */}
			{hasProspectingPRO && !loading && size && size > 1 ? (
				<span className="show-on-row-hover">
					<i className="Icon Icon-sitemap" />{' '}
					<Button className="btn-inline" type="link" color="bright-blue" onClick={event => open(event)}>
						{lang.openModal}
					</Button>
				</span>
			) : null}
			{!hasProspectingPRO && !existingClient && !size ? <span></span> : null}
		</div>
	);
};

export default GroupTreeColumn;
