import React, { useMemo, useState, useEffect, useRef } from 'react';
import ProjectPlanResource from 'Resources/ProjectPlan';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import ProjectPlanModel from 'App/resources/Model/ProjectPlan';
import logError from 'App/babel/helpers/logError';
import Client from 'App/resources/Model/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import t from 'Components/Helpers/translate';
import {
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Block,
	Text,
	Paginator,
	Flex,
	ButtonSelect
} from '@upsales/components';
import SubaccountsColumn from 'Components/Account/AccountListContacts/Columns/SubaccountsColumn';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import { openDrawer } from 'Services/Drawer';
import { ClientColumn } from 'App/pages/CompanyGroupCard/Helpers/SubaccountV2Helpers';

import './ProjectPlan.scss';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import { useProjectPlanStages } from 'App/components/hooks/appHooks';
import Contact from 'App/resources/Model/Contact';
import { Feature } from 'Store/actions/FeatureHelperActions';

type Props = {
	client: Client;
	contact?: Contact;
	subAccountIds?: number[];
};

type TableProps = {
	client: Client;
	contact?: Contact;
	projectPlanStage: {
		ids: number[];
		name: string;
	};
	subAccountIds?: number[];
};

const LIMIT = 5;

const ProjectPlanTable = ({ projectPlanStage, client, subAccountIds, contact }: TableProps) => {
	const [projectPlans, setProjectPlans] = useState<ProjectPlanModel[]>([]);
	const [offset, setOffset] = useState(0);
	const [total, setTotal] = useState(0);
	const promises = React.useRef<CancelablePromise | null>(null);
	const hasSubaccountsV1 = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
	const hasSubaccountsFeature = useFeatureAvailable(Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
	const hasSubAccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;
	const isOperationalAccount = subAccountIds && subAccountIds.length > 0;

	const lang = useMemo(
		() => ({
			name: t('column.name'),
			startDate: t('default.startDate'),
			endDate: t('default.endDate'),
			assignee: t('defaultView.assignee'),
			noProjects: t('account.projectPlan.noProjects'),
			subaccounts: hasSubaccountsV2 ? t('account') : t('account.subaccount'),
			stage: t('default.stage'),
			noDate: t('default.noDate'),
			unassigned: t('default.unassigned')
		}),
		[]
	);

	const columns = [
		{ title: lang.name },
		{ title: lang.startDate },
		{ title: lang.endDate },
		{ title: lang.assignee },
		{ title: lang.stage }
	];

	if (hasSubAccounts && isOperationalAccount) {
		columns.splice(1, 0, { title: lang.subaccounts });
	}

	const fetchProjects = () => {
		const rb = new RequestBuilder();
		if (!contact) {
			rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, [client.id, ...(subAccountIds || [])]);
		} else {
			rb.addFilter({ field: 'contact.id' }, comparisonTypes.Equals, contact?.id);
		}
		rb.addFilter({ field: 'projectPlanStage.id' }, comparisonTypes.Equals, projectPlanStage.ids);
		rb.addSort('endDate', true);
		rb.offset = offset;
		rb.limit = LIMIT;
		const cancableFindProjectPlan = makeCancelable(ProjectPlanResource.find(rb.build()));

		cancableFindProjectPlan.promise
			.then(({ data, metadata }) => {
				setProjectPlans(data);
				setTotal(metadata.total);
			})
			.catch(error => {
				logError(error, 'Could not fetch project plan:');
			});

		return cancableFindProjectPlan;
	};

	useEffect(() => {
		promises.current?.cancel();
		promises.current = fetchProjects();
		return () => promises.current?.cancel();
	}, [offset]);

	useEffect(() => {
		const isAccountOrSubAccount = (clientEntity: Client | null) =>
			clientEntity &&
			(clientEntity.id === client.id ||
				(hasSubAccounts && subAccountIds && subAccountIds.includes(clientEntity.id)));

		const projectPlanUpdateListener = Tools.$rootScope.$on('projectPlan.updated', (event, data) => {
			const projectPlan = projectPlans.find(projectPlan => projectPlan.id === data.id);

			if (projectPlanStage.ids.includes(data.projectPlanStage.id) || projectPlan) {
				promises.current?.cancel();
				promises.current = fetchProjects();
			}
		});

		const comapnyMergeListener = Tools.$rootScope.$on('account.merged', function (event, data) {
			if (data.merged.id === client.id) {
				promises.current?.cancel();
				promises.current = fetchProjects();
			}
		});

		const addedProjectPlanListener = Tools.$rootScope.$on('projectPlan.added', (event, data) => {
			if (isAccountOrSubAccount(data.client)) {
				promises.current?.cancel();
				promises.current = fetchProjects();
			}
		});

		const deletedProjectPlanListener = Tools.$rootScope.$on('projectPlan.deleted', (event, data) => {
			setProjectPlans(projectPlans.filter(projectPlan => projectPlan.id !== data.id));
		});

		return () =>
			[
				projectPlanUpdateListener,
				comapnyMergeListener,
				addedProjectPlanListener,
				deletedProjectPlanListener
			].forEach(unsubscribe => unsubscribe());
	}, [projectPlans]);

	return (
		<Block>
			<Text space="mll ptxl" size="xl">
				{projectPlanStage.name}
			</Text>
			<Table>
				<TableHeader columns={columns}></TableHeader>
				{projectPlans.length > 0 ? (
					projectPlans.map(projectPlan => (
						<TableRow
							key={projectPlan.id}
							onClick={() =>
								openDrawer('EditProjectPlan', {
									projectPlanId: projectPlan.id
								})
							}
						>
							<TableColumn size="md">{projectPlan.name}</TableColumn>
							{hasSubaccountsV1 && isOperationalAccount && !hasSubaccountsFeature ? (
								<TableColumn>
									{projectPlan.client.id !== client.id ? (
										<SubaccountsColumn client={projectPlan.client} />
									) : null}
								</TableColumn>
							) : null}
							<ClientColumn item={projectPlan} />
							<TableColumn>
								{projectPlan.startDate ? (
									moment(projectPlan.startDate).format('L')
								) : (
									<Text size="sm" italic color="grey-10">
										{lang.noDate}
									</Text>
								)}
							</TableColumn>
							<TableColumn>
								{projectPlan.endDate ? (
									moment(projectPlan.endDate).format('L')
								) : (
									<Text size="sm" italic color="grey-10">
										{lang.noDate}
									</Text>
								)}
							</TableColumn>
							<TableColumn>
								{projectPlan.user?.name ? (
									projectPlan.user.name
								) : (
									<Text size="sm" italic color="grey-10">
										{lang.unassigned}
									</Text>
								)}
							</TableColumn>
							<TableColumn>{projectPlan.projectPlanStage.name}</TableColumn>
						</TableRow>
					))
				) : (
					<TableRow>
						<TableColumn colSpan={5} size="lg" align="center">
							<Text space="mtxl mbxl" size="lg" color="grey-10">
								{' '}
								{lang.noProjects}
							</Text>
						</TableColumn>
					</TableRow>
				)}
				{total > LIMIT ? (
					<TableRow>
						<TableColumn colSpan={4} size="lg" align="center">
							<Paginator
								onChange={val => {
									setOffset(val);
								}}
								total={total}
								limit={LIMIT}
								offset={offset}
								align="center"
							/>
						</TableColumn>
					</TableRow>
				) : null}
			</Table>
		</Block>
	);
};

const ProjectPlan = ({ client, subAccountIds, contact }: Props) => {
	const classes = new BemClass('ClientCardContent__ProjectPlan');
	const hasSubaccountsV1 = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
	const hasSubaccountsFeature = useFeatureAvailable(Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
	const fetchProjectCategoryReq = useRef<CancelablePromise | null>(null);
	const [view, setView] = useState('all');
	const [projectCounts, setProjectCounts] = useState({ open: 0, finished: 0 });

	const fetchProjectCategories = (open?: boolean) => {
		const rb = new RequestBuilder();
		if (!contact) {
			rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, [client.id, ...(subAccountIds || [])]);
		} else {
			rb.addFilter({ field: 'contact.id' }, comparisonTypes.Equals, contact?.id);
		}
		rb.addFilter(
			{ field: 'projectPlanStage.category' },
			open ? comparisonTypes.NotEquals : comparisonTypes.Equals,
			'DONE'
		);
		rb.addSort('endDate', true);
		rb.limit = LIMIT;

		return ProjectPlanResource.find(rb.build());
	};

	useEffect(() => {
		const fetchCounts = () => {
			fetchProjectCategoryReq.current?.cancel();

			fetchProjectCategoryReq.current = makeCancelable(
				Promise.all([fetchProjectCategories(true), fetchProjectCategories()])
			);

			fetchProjectCategoryReq.current.promise
				.then(([openData, finishedData]) => {
					setProjectCounts({
						open: openData.metadata.total,
						finished: finishedData.metadata.total
					});
				})
				.catch(error => {
					logError(error);
				});
		};

		fetchCounts();

		const listeners = [
			Tools.$rootScope.$on('projectPlan.added', () => setTimeout(() => fetchCounts(), 1000)),
			Tools.$rootScope.$on('projectPlan.updated', () => setTimeout(() => fetchCounts(), 1000)),
			Tools.$rootScope.$on('projectPlan.deleted', () => setTimeout(() => fetchCounts(), 1000))
		];

		return () => {
			fetchProjectCategoryReq.current?.cancel();
			listeners.forEach(unsub => unsub());
		};
	}, [client, subAccountIds, contact]);

	const projectStages = useProjectPlanStages();
	const projectStageOptions = {
		open: {
			ids: projectStages.filter(stage => stage.category !== 'DONE').map(({ id }) => id),
			name: t('default.open')
		},
		done: {
			ids: projectStages.filter(stage => stage.category === 'DONE').map(({ id }) => id),
			name: t('projectPlan.done')
		}
	};

	const PROJECT_VIEWS = [
		{
			value: 'open',
			key: 'projectPlan.open',
			projectPlanStage: projectStageOptions.open,
			count: projectCounts.open
		},
		{
			value: 'finished',
			key: 'projectPlan.done',
			projectPlanStage: projectStageOptions.done,
			count: projectCounts.finished
		}
	];

	return (
		<Block className={classes.b()}>
			{hasSubaccountsFeature ? (
				<Flex space="mtxl mbl mll" alignItems="center" gap="u3">
					<Text>{t('projectPlan.showProjects')}</Text>
					<ButtonSelect
						size="sm"
						value={view}
						options={[
							{ value: 'all', title: t('projectPlan.all') },
							...PROJECT_VIEWS.map(({ value, key, count }) => ({
								value,
								title: count + ' ' + t(key)
							}))
						]}
						onChange={setView}
					/>
				</Flex>
			) : null}

			{PROJECT_VIEWS.filter(({ value }) => view === 'all' || view === value).map(({ key, projectPlanStage }) => (
				<ProjectPlanTable
					key={key}
					projectPlanStage={projectPlanStage}
					client={client}
					contact={contact}
					subAccountIds={subAccountIds}
				/>
			))}
		</Block>
	);
};

export default ProjectPlan;
