import { ConnectedProps, Provider, connect } from 'react-redux';
import store, { RootState } from 'Store/index';
import QuotaCharts from 'App/babel/components/NavBar/QuotaCharts/QuotaCharts.js';
import React, { ComponentProps, Fragment } from 'react';
import NavbarBrandDropdown from 'App/navbar/NavbarBrandDropdown';
import OnboardingNotification from 'Components/Onboarding/OnboardingNotification';
import NavbarAddMenu from 'App/navbar/NavbarAddMenu';
import NavbarCustomLinks from 'App/navbar/NavbarCustomLinks';
import { Icon, BadgeLogo, Text, Button } from '@upsales/components';
import { goToStartPage } from 'App/upsales/common/helpers/startpageHelper';
import {
	listViewSplitter,
	projectBoardListViewSplitter,
	salesboardListViewSplitter
} from 'App/helpers/listViewSplitter';
import SubMenuItem from './legacySubMenuItem';
import NavbarSubMenu from './NavbarSubMenu';
import t from 'Components/Helpers/translate';
import BrowserService from 'Services/BrowserService';
import { ListView, SalesboardListView } from 'App/resources/AllIWant';
import { TrialAddon } from 'Components/Billing/Addon';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getSectionFromPath } from 'App/pages/routes';
import {
	Feature,
	Product,
	getFeatureAvailableFromState,
	getProductAvailableFromState,
	getSoftDeployAccessFromState
} from 'App/components/hooks/featureHelper';
import NotificationDropdown from 'App/upsales/common/components/react/navbar/notificationDropdown';
import { hasSoliditetMatcher } from 'App/helpers/matcherHelper';
import type ProjectBoard from 'App/resources/Model/ProjectBoard';

// returns current language (for when you change language)
const getLang = () => ({
	activities: t('default.activities'),
	landingpages: t('default.landingpages'),
	allFormSubmits: t('default.allFormSubmits'),
	forms: t('feature.forms'),
	appointments: t('default.appointments'),
	accounts: t('default.accounts'),
	clientRegister: t('default.clientRegister'),
	contacts: t('default.contacts'),
	orders: t('default.orders'),
	salesboard: t('default.salesboard'),
	projectPlans: t('default.projectPlans'),
	projectBoard: t('default.projectBoard'),
	opportunities: t('default.opportunities'),
	findProspects: t('default.findProspects'),
	findClients: t('default.findClients'),
	prospects: t('leads.prospects'),
	matchClients: t('default.matchClients'),
	mails: t('default.mails'),
	templates: t('mail.templates'),
	allClients: t('default.allClients'),
	allContacts: t('default.allContacts'),
	userdefinedobjects: t('default.userdefinedobjects'),
	recurringOrder: t('default.recurringOrder'),
	esign: t('default.eSign'),
	callLists: t('default.callLists'),
	companyRegister: t('solidtet.companyRegister'),
	duplicatedClients: t('default.duplicatedClients'),
	duplicatedContacts: t('default.duplicatedContacts'),
	cleanDuplicates: t('default.cleanDuplicates'),
	campaigns: t('default.campaigns'),
	campaignsCallLists: t('default.campaignsCallLists'),
	assignedLeads: t('default.assignedLeads'),
	siteVisitors: t('default.siteVisitors'),
	formsAndLandingpages: t('navbar.formsAndLandingpages'),
	formSubmits: t('default.formSubmits'),
	manageForms: t('form.manageForms'),
	mailings: t('mail.mailings'),
	mailAndTemplates: t('mail.mailAndTemplates'),
	engage: t('default.advertising'),
	order: t('default.order'),
	opportunity: t('default.opportunity'),
	activity: t('default.activity'),
	appointment: t('default.appointment'),
	reportcenter: t('default.reportcenter'),
	looker: t('default.looker'),
	userSettings: t('admin.userSettings'),
	segment: t('segment.segments'),
	flows: t('default.flows'),
	segmentAndFlows: t('navbar.segmentsAndFlows'),
	phoneCalls: t('voice.phoneCalls'),
	defaultSales: t('default.sales'),
	section_sale: t('navbar.sale'),
	section_market: t('navbar.market'),
	section_followup: t('navbar.followup'),
	section_portfolio: t('default.customerPortfolio'),
	marketingBoard: t('default.marketingBoard'),
	events: t('default.socialEvents'),
	browserOutdated: t('browser.outdated'),
	newVersionAvailable: t('default.newVersionAvailable'),
	todos: t('default.tasksHeader'),
	signals: t('default.signals'),
	accountGrowth: t('accountGrowth.tab'),
	byUpsales: t('sharedViews.standardViews'),
	sharedViews: t('sharedViews.sharedViews'),
	customerPorfolio: t('default.customerPortfolio'),
	myLists: t('sharedViews.myViews'),
	overview: t('default.overview'),
	risks: t('default.risks'),
	upsell: t('default.upsell'),
	section_support: t('default.support')
});

const mapStateToProps = ({ App }: RootState) => ({
	listViews: App.listViews,
	userDefinedObject: App.metadata?.params.UserDefinedObject,
	accountSelf: App.accountSelf,
	self: App.self,
	metadata: App.metadata,
	customerId: App.customerId,
	accessRights: App.accessRights,
	searchableFields: App.searchableFields,
	brands: App.brands,
	esignIntegrations: App.esignIntegrations,
	hasVoice: !!App.phoneIntegration,
	hasFullReportAccess: App.hasFullReportAccess
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

class Navbar extends React.Component<
	Props,
	{
		showBrowserWarning: boolean;
		showNewVersionWarning: boolean;
		currentAngularState: string | null;
	}
> {
	private lang: { [k: string]: string };
	private hardRefreshPushListener?: () => void;
	private deRegisterAngularListener?: () => void;

	constructor(props: Props) {
		super(props);
		this.state = {
			showBrowserWarning: !BrowserService.isSupported(),
			showNewVersionWarning: false,
			currentAngularState: null
		};

		this.isSection = this.isSection.bind(this);
		this.getSectionLi = this.getSectionLi.bind(this);
		this.getStateHref = this.getStateHref.bind(this);
		this.getDropdown = this.getDropdown.bind(this);
		this.getSubMenuItems = this.getSubMenuItems.bind(this);

		this.lang = getLang();
		this.deRegisterAngularListener = Tools.$rootScope.$on('$stateChangeSuccess', (event, stateParams) => {
			this.setState({ currentAngularState: stateParams?.name });
		});
	}
	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		this.hardRefreshPushListener = Tools.$rootScope.$on('hardRefreshPush', () => {
			this.setState({ showNewVersionWarning: true });
		});
	}
	// eslint-disable-next-line camelcase
	UNSAFE_componentWillUpdate() {
		this.lang = getLang();
	}
	componentWillUnmount() {
		this.hardRefreshPushListener?.();
		this.deRegisterAngularListener?.();
	}

	isSection(name: string, currentSection: string | null) {
		return !!((currentSection || 'sale') === name);
	}
	getSectionLi(
		name: string,
		state: string,
		icon: string,
		currentSection: string | null,
		stateParams?: object,
		sectionName?: string
	) {
		var classes = this.isSection(name, currentSection) ? 'current' : '';
		var iconClasses = 'icon ' + icon;
		return (
			<li className="menu-item" id={name + '-section'} key={name + '-section'}>
				<a href={this.getStateHref(state, stateParams)} className={classes}>
					<b className={iconClasses} />{' '}
					<span className="item-text">
						{sectionName ? this.lang[sectionName] : this.lang['section_' + name]}
					</span>
				</a>
			</li>
		);
	}
	getStateHref(stateName: string, params?: { customerId?: number; [k: string]: any }) {
		params = params || {};
		params.customerId = this.props.customerId;
		return Tools.$state.href(stateName, params);
	}
	getDropdown(
		splitListView:
			| ReturnType<typeof listViewSplitter>
			| ReturnType<typeof salesboardListViewSplitter>
			| ReturnType<typeof projectBoardListViewSplitter>,
		title: string,
		state: string
	) {
		const dropdown: {
			title: string;
			type?: string;
			items?:
				| ListView[]
				| SalesboardListView[]
				| ProjectBoard[]
				| { title: string; state: string; stateParams?: { [key: string]: any } }[];
			state?: string;
		}[] = [{ title: title, type: 'header' }];
		if (splitListView.userList.length > 0) {
			dropdown.push({
				title: this.lang.myLists,
				items: splitListView.userList,
				state
			});
		}
		if (splitListView.sharedList.length > 0) {
			dropdown.push({
				title: this.lang.sharedViews,
				items: splitListView.sharedList,
				state
			});
		}
		dropdown.push({
			title: this.lang.byUpsales,
			items: splitListView.defaultList,
			state
		});
		return dropdown;
	}
	getSubMenuItems(section: 'sale' | 'market' | 'followup' | 'admin' | 'support' | 'portfolio') {
		const { accessRights, accountSelf, listViews, userDefinedObject, self, metadata, esignIntegrations } =
			this.props;
		const features = accountSelf?.features || {};
		const products = accountSelf?.products || {};
		const isAdmin = self?.administrator;
		const marketAdmin = self?.userParams.mailAdmin;
		var items = [];
		const itemConfigs: ComponentProps<typeof SubMenuItem>['item'][] = [];
		let dropdown: {
			title: string;
			items?:
				| ListView[]
				| SalesboardListView[]
				| ProjectBoard[]
				| { title: string; state: string; stateParams?: { [key: string]: any } }[];
			state?: string;
		}[];
		let options;
		const hasCampaigns = getFeatureAvailableFromState(accountSelf, Feature.PROJECTS);
		const hasSegment = getFeatureAvailableFromState(accountSelf, Feature.SEGMENT);
		var mapMailActivated = metadata?.map.mailActivated;
		const hasAcceptedTerms = metadata?.map.mail;
		const hasMAAccess = isAdmin || marketAdmin;
		var showCampaignsInSalesSection = products.crm && (hasSegment || !products.ma || !hasMAAccess);
		let esignActive = metadata?.esign.active;
		const hasEsignFeature = getFeatureAvailableFromState(accountSelf, Feature.ESIGN);
		if (!esignActive && hasEsignFeature) {
			esignActive = !!esignIntegrations.length;
		}
		const hasEsign = hasEsignFeature && esignActive;
		const hasMultiSoliditet = isAdmin || self?.userParams.multiSoliditetAccess;
		const hasProjectPlanFeature =
			getFeatureAvailableFromState(accountSelf, Feature.PROJECT_PLAN) && (isAdmin || self?.crm || self?.service);
		const hasTaskList = getSoftDeployAccessFromState(accountSelf, 'TASK_FILTERS');
		const hasProjectBoard = getSoftDeployAccessFromState(accountSelf, 'PROJECT_BOARD');
		const hasServiceManagementStagesAndBoards = getSoftDeployAccessFromState(
			accountSelf,
			'SERVICE_MANAGEMENT_STAGES_AND_BOARDS'
		);
		const hasHideAdvertising = getSoftDeployAccessFromState(accountSelf, 'HIDE_ADVERTISING');
		const hasTopCustomerPortfolio =
			getFeatureAvailableFromState(accountSelf, Feature.TOP_CUSTOMER_PORTFOLIO) &&
			getSoftDeployAccessFromState(accountSelf, 'TOP_CUSTOMER_PORTFOLIO');
		const hasClientsContactsInPortfolio =
			!getSoftDeployAccessFromState(accountSelf, 'CONTACTS_AND_CLIENTS_NOT_IN_PORTFOLIO') &&
			hasTopCustomerPortfolio;

		switch (section) {
			case 'sale':
				if (
					features.activitiesAndAppointments &&
					(getSoftDeployAccessFromState(accountSelf, 'TODO_LIST') || hasTaskList)
				) {
					const todos = {
						title: this.lang.todos,
						key: 'sub-todos',
						id: 'sub-todos',
						state: hasTaskList ? 'react-root-task' : 'react-root-todo',
						dropdown: null,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, todos));
					itemConfigs.push(todos);
				}

				if (hasProjectPlanFeature) {
					const projectListDropdown = this.getDropdown(
						listViewSplitter(listViews.projectPlan),
						this.lang.projectPlans,
						'react-root-projects'
					);

					let dropdown = [...projectListDropdown];

					if (hasProjectBoard) {
						if (hasServiceManagementStagesAndBoards) {
							const projectBoardDropdown = this.getDropdown(
								projectBoardListViewSplitter(listViews.projectBoard),
								this.lang.projectBoard,
								'react-root-projectBoard'
							);
							dropdown = [...projectBoardDropdown, ...projectListDropdown];
						}
						const projectBoardConfig = {
							title: this.lang.projectPlans,
							key: 'sub-projectBoard',
							id: 'sub-projectBoard',
							state: 'react-root-projectBoard',
							dropdown: dropdown,
							getStateHref: this.getStateHref
						};
						items.push(React.createElement(SubMenuItem, projectBoardConfig));
						itemConfigs.push(projectBoardConfig);
					} else {
						const projects = {
							title: this.lang.projectPlans,
							key: 'sub-projects',
							id: 'sub-projects',
							state: 'react-root-projects',
							dropdown: dropdown,
							getStateHref: this.getStateHref
						};

						items.push(React.createElement(SubMenuItem, projects));
						itemConfigs.push(projects);
					}
				}

				if (
					features.customerPortfolio &&
					getSoftDeployAccessFromState(accountSelf, 'CUSTOMER_PORTFOLIO') &&
					!hasTopCustomerPortfolio
				) {
					const customerPortfolioConfig = {
						title: this.lang.customerPorfolio,
						key: 'sub-customerPortfolio',
						id: 'sub-customerPortfolio',
						state: 'react-root-customerPortfolio',
						dropdown: null,
						getStateHref: this.getStateHref,
						className: 'right'
					};

					items.push(React.createElement(SubMenuItem, customerPortfolioConfig));
					itemConfigs.push(customerPortfolioConfig);
				}

				if (
					features.activitiesAndAppointments &&
					!(
						getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES') &&
						getSoftDeployAccessFromState(accountSelf, 'TODO_LIST')
					) &&
					(self?.crm ?? true) // CRM required to show activities
				) {
					const dropdown = this.getDropdown(
						listViewSplitter(listViews.activity),
						this.lang.activities,
						'activities'
					);
					const activitiesConfig = {
						title: this.lang.activities,
						key: 'sub-activities',
						id: 'sub-activities',
						state: 'activities',
						dropdown: dropdown,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, activitiesConfig));
					itemConfigs.push(activitiesConfig);
				}

				if (
					products.crm &&
					features.companiesAndContacts &&
					getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_PRO) &&
					getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_SIGNALS) &&
					!hasTopCustomerPortfolio
				) {
					const signalsConfig = {
						title: this.lang.signals,
						key: 'sub-signals',
						id: 'sub-signals',
						state: 'react-root-signals',
						getStateHref: this.getStateHref,
						// TODO: This will not be needed when we add redux to navbar
						showTrialBanner: (ongoingTrialAddons: { [key: string]: TrialAddon }) => {
							return !!(
								ongoingTrialAddons.PROSPECTING_PRO_SIGNALS ||
								ongoingTrialAddons.PROSPECTING_PRO_ENTERPRISE
							);
						}
					};

					items.push(React.createElement(SubMenuItem, signalsConfig));
					itemConfigs.push(signalsConfig);
				}

				if (products.crm && features.companiesAndContacts && !hasClientsContactsInPortfolio) {
					const companyListState = getSoftDeployAccessFromState(accountSelf, 'LIST_COMPANIES_REACT')
						? 'react-root-companies'
						: 'accounts';
					const contactListState = getSoftDeployAccessFromState(accountSelf, 'LIST_CONTACTS_REACT')
						? 'react-root-contacts'
						: 'contacts';
					const accountDropDown = this.getDropdown(
						listViewSplitter(listViews.account),
						this.lang.accounts,
						getSoftDeployAccessFromState(accountSelf, 'LIST_COMPANIES_REACT')
							? 'react-root-companies'
							: 'accounts'
					);
					const contactDropDown = this.getDropdown(
						listViewSplitter(listViews.contact),
						this.lang.contacts,
						contactListState
					);

					if (isAdmin && getSoftDeployAccessFromState(accountSelf, 'NEW_DUPLICATE_VIEW')) {
						accountDropDown.push({
							title: this.lang.duplicatedClients,
							items: [
								{
									title: this.lang.cleanDuplicates,
									state: 'react-root-detected-duplicates-landing-page',
									stateParams: { entity: 'accounts' }
								}
							],
							state: 'react-root-companies'
						});
						contactDropDown.push({
							title: this.lang.duplicatedContacts,
							items: [
								{
									title: this.lang.cleanDuplicates,
									state: 'react-root-detected-duplicates-landing-page',
									stateParams: { entity: 'contacts' }
								}
							],
							state: 'react-root-companies'
						});
					}

					if (hasSoliditetMatcher(self, accountSelf)) {
						accountDropDown.push({
							title: this.lang.companyRegister,
							items: [{ title: this.lang.matchClients, state: 'matcher' }]
						});
					}

					dropdown = [...accountDropDown, ...contactDropDown];
					const accounts = {
						title: this.lang.clientRegister,
						key: 'sub-accounts',
						id: 'sub-accounts',
						secondActiveState: contactListState,
						state: companyListState,
						className: 'no-hide',
						dropdown: dropdown,
						getStateHref: this.getStateHref
					};

					items.push(React.createElement(SubMenuItem, accounts));
					itemConfigs.push(accounts);
				}

				if (products.crm && features.companiesAndContacts && hasMultiSoliditet && !hasTopCustomerPortfolio) {
					if (getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_BASIC)) {
						const prospectsConfig = {
							title: this.lang.findProspects,
							key: 'sub-prospects',
							id: 'sub-prospects',
							state: 'react-root-prospecting',
							getStateHref: this.getStateHref,
							showTrialBanner: (ongoingTrialAddons: { [key: string]: TrialAddon }) => {
								return !!(
									ongoingTrialAddons.PROSPECTING_PRO_SIGNALS ||
									ongoingTrialAddons.PROSPECTING_PRO_PROFESSIONAL
								);
							}
						};

						items.push(React.createElement(SubMenuItem, prospectsConfig));
						itemConfigs.push(prospectsConfig);
					} else if (self?.userParams.soliditetIsActive) {
						const prospectsConfig = {
							title: this.lang.findProspects,
							key: 'sub-prospects',
							id: 'sub-prospects',
							state: 'findProspects',
							dropdown: [
								{ title: this.lang.prospects, state: 'findProspects', items: listViews.soliditet }
							],
							getStateHref: this.getStateHref
						};

						items.push(React.createElement(SubMenuItem, prospectsConfig));
						itemConfigs.push(prospectsConfig);
					}
				}

				if (features.orders) {
					const splitListView = listViewSplitter(listViews.order);
					const state = 'react-root-orders';
					const dropdown = this.getDropdown(splitListView, this.lang.orders, state);

					const ordersConfig = {
						title: this.lang.orders,
						key: 'sub-orders',
						id: 'sub-orders',
						state,
						dropdown,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, ordersConfig));
					itemConfigs.push(ordersConfig);
				}
				if (features.pipeline) {
					const opportunityDropDown = this.getDropdown(
						listViewSplitter(listViews.opportunity),
						this.lang.opportunities,
						'react-root-opportunities'
					);
					const salesDropDown = this.getDropdown(
						salesboardListViewSplitter(listViews.salesboard),
						this.lang.salesboard,
						'react-root-salesboard'
					);

					dropdown = [...salesDropDown, ...opportunityDropDown];

					const salesboardConfig = {
						title: this.lang.salesboard,
						key: 'sub-salesboard',
						id: 'sub-salesboard',
						state: 'react-root-salesboard',
						dropdown: dropdown,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, salesboardConfig));
					itemConfigs.push(salesboardConfig);
				}
				if (
					products.crm &&
					features.email &&
					(hasAcceptedTerms ||
						isAdmin ||
						(metadata?.integrations.inits.mail && metadata?.integrations.inits.mail.length))
				) {
					const mailConfig = {
						title: this.lang.mails,
						key: 'sub-listMail',
						id: 'sub-listMail',
						state: 'react-root-mail',
						dropdown: this.getDropdown(
							listViewSplitter(listViews.mail),
							this.lang.mails,
							'react-root-mail'
						),
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, mailConfig));
					itemConfigs.push(mailConfig);
				}
				if (features.userDefinedObjects && userDefinedObject && userDefinedObject.length) {
					const userDefinedObjectState = 'react-root-userdefinedobjects';
					const userDefinedObjectConfig = {
						title: this.lang.userdefinedobjects,
						key: 'sub-userDefinedObject',
						id: 'sub-userDefinedObject',
						state: userDefinedObjectState,
						className: 'no-hide',
						stateParams: { typeId: userDefinedObject[0].id },
						dropdown: userDefinedObject.map(udo => {
							return {
								title: udo.name || t('default.noName'),
								items: listViews['userDefinedObject' + udo.id],
								state: userDefinedObjectState,
								stateParams: { typeId: udo.id }
							};
						}),
						getStateHref: this.getStateHref
					};

					items.push(React.createElement(SubMenuItem, userDefinedObjectConfig));
					itemConfigs.push(userDefinedObjectConfig);
				}

				if (hasEsign) {
					const esignConfig = {
						title: this.lang.esign,
						key: 'sub-esign',
						id: 'sub-esign',
						state: 'esign',
						dropdown: null,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, esignConfig));
					itemConfigs.push(esignConfig);
				}
				if (
					features.recurringOrder &&
					getFeatureAvailableFromState(accountSelf, Feature.RECURRING_ORDER) &&
					metadata?.params.AgreementEnabled &&
					accessRights.Agreement
				) {
					const agreementState = getSoftDeployAccessFromState(accountSelf, 'NEW_AGREEMENT_LIST')
						? 'react-root-agreements'
						: 'agreements';
					const splitListView = listViewSplitter(listViews.agreement);
					const dropdown = this.getDropdown(splitListView, this.lang.recurringOrder, agreementState);

					const recurringOrderConfig = {
						title: this.lang.recurringOrder,
						key: 'sub-recurringOrder',
						id: 'sub-recurringOrder',
						state: agreementState,
						dropdown,
						getStateHref: this.getStateHref,
						className: 'right'
					};
					items.push(React.createElement(SubMenuItem, recurringOrderConfig));
					itemConfigs.push(recurringOrderConfig);
				}
				if (hasCampaigns && showCampaignsInSalesSection) {
					const campaignDropDown = this.getDropdown(
						listViewSplitter(listViews.campaign),
						this.lang.campaigns,
						'react-root-campaigns'
					);

					const callListDropDown = getSoftDeployAccessFromState(accountSelf, 'CALL_LISTS')
						? this.getDropdown(
								listViewSplitter(listViews.callList),
								this.lang.callLists,
								'react-root-calllists'
						  )
						: [];

					dropdown = [...campaignDropDown, ...callListDropDown].filter(Boolean);

					const campaignsConfig = {
						title: getSoftDeployAccessFromState(accountSelf, 'CALL_LISTS')
							? this.lang.campaignsCallLists
							: this.lang.campaigns,
						key: 'sub-campaigns',
						id: 'sub-campaigns',
						state: 'react-root-campaigns',
						dropdown,
						getStateHref: this.getStateHref,
						className: 'right'
					};

					items.push(React.createElement(SubMenuItem, campaignsConfig));
					itemConfigs.push(campaignsConfig);
				}

				if (this.props.hasVoice) {
					const splitListView = listViewSplitter(listViews.phoneCall);
					const dropdown = this.getDropdown(splitListView, this.lang.phoneCalls, 'react-root-phonecalls');
					const phoneCallsConfig = {
						title: this.lang.phoneCalls,
						key: 'sub-phoneCalls',
						id: 'sub-phoneCalls',
						state: 'react-root-phonecalls',
						dropdown,
						getStateHref: this.getStateHref,
						className: 'right'
					};
					items.push(React.createElement(SubMenuItem, phoneCallsConfig));
					itemConfigs.push(phoneCallsConfig);
				}
				if (getSoftDeployAccessFromState(accountSelf, 'ACCOUNT_GROWTH')) {
					const accountGrowthConfig = {
						title: this.lang.accountGrowth,
						key: 'sub-accountGrowth',
						id: 'sub-accountGrowth',
						state: 'react-root-accountGrowth',
						getStateHref: this.getStateHref,
						className: 'right'
					};
					items.push(React.createElement(SubMenuItem, accountGrowthConfig));
					itemConfigs.push(accountGrowthConfig);
				}

				break;

			case 'market': {
				const mailCampaignState = 'react-root-mail-campaigns';
				if (!products.crm && features.companiesAndContacts) {
					dropdown = [
						{ title: this.lang.accounts, items: listViews.account, state: 'accounts' },
						{
							title: this.lang.contacts,
							items: listViews.contact,
							state: getSoftDeployAccessFromState(accountSelf, 'LIST_CONTACTS_REACT')
								? 'react-root-contacts'
								: 'contacts'
						}
					];

					if (hasMultiSoliditet || isAdmin) {
						options = [
							{ title: this.lang.findProspects, state: 'findProspects' },
							{ title: this.lang.matchClients, state: 'matcher' }
						];

						dropdown.push({
							title: 'Företagsregister',
							items: options
						});
					}
					const subMenuItemConfig = {
						title: this.lang.clientRegister,
						key: 'sub-accounts',
						id: 'sub-accounts',
						state: 'accounts',
						className: 'no-hide',
						dropdown: dropdown,
						getStateHref: this.getStateHref
					};

					items.push(React.createElement(SubMenuItem, subMenuItemConfig));
					itemConfigs.push(subMenuItemConfig);
				}
				if (hasMAAccess) {
					if (features.leads) {
						const leadState = 'react-root-leads';
						const dropdown = this.getDropdown(
							listViewSplitter(listViews.leads2),
							this.lang.assignedLeads,
							leadState
						);
						const leadSubMenuItemConfig = {
							title: this.lang.assignedLeads,
							key: 'sub-leads',
							id: 'sub-leads',
							state: leadState,
							dropdown: [
								...dropdown,
								{
									title: this.lang.marketingBoard,
									items: null,
									state: 'react-root-marketingboard'
								}
							],
							getStateHref: this.getStateHref
						};
						items.push(React.createElement(SubMenuItem, leadSubMenuItemConfig));
						itemConfigs.push(leadSubMenuItemConfig);
					}
					if (features.visits || features.visits2) {
						const visitorState = 'react-root-visitors';
						const splitListView = listViewSplitter(listViews.visitor);
						const dropdown = this.getDropdown(splitListView, this.lang.siteVisitors, visitorState);

						const visitorSubMenuItemConfig = {
							title: this.lang.siteVisitors,
							key: 'react-sub-visits',
							id: 'sub-visits',
							state: visitorState,
							dropdown,
							getStateHref: this.getStateHref
						};
						items.push(React.createElement(SubMenuItem, visitorSubMenuItemConfig));
						itemConfigs.push(visitorSubMenuItemConfig);
					}
					if (features.forms) {
						const splitListView = listViewSplitter(listViews.formSubmit);
						const formSubmitDropdown = this.getDropdown(
							splitListView,
							this.lang.formSubmits,
							'react-root-formSubmits'
						);

						const formSubmitConfig = {
							title: this.lang.formSubmits,
							key: 'sub-form',
							id: 'sub-form',
							state: 'react-root-formSubmits',
							getStateHref: this.getStateHref,
							dropdown: formSubmitDropdown
						};
						items.push(React.createElement(SubMenuItem, formSubmitConfig));
						itemConfigs.push(formSubmitConfig);

						const formState = 'react-root-forms';
						const landingPageState = 'react-root-landingpages';

						const formDropdown = this.getDropdown(
							listViewSplitter(listViews.form),
							this.lang.forms,
							formState
						);
						const landingPageDropdown = this.getDropdown(
							listViewSplitter(listViews.page),
							this.lang.landingpages,
							landingPageState
						);

						dropdown = [...formDropdown, ...landingPageDropdown];

						const formAndLandingPageConfig = {
							title: this.lang.formsAndLandingpages,
							key: 'sub-page',
							id: 'sub-page',
							state: formState,
							secondActiveState: landingPageState,
							dropdown,
							getStateHref: this.getStateHref
						};
						items.push(React.createElement(SubMenuItem, formAndLandingPageConfig));
						itemConfigs.push(formAndLandingPageConfig);
					}
					// Add segment or campaign
					const hasFlows = getFeatureAvailableFromState(accountSelf, Feature.FLOW);
					const segmentState = 'react-root-segments';
					const flowState = 'react-root-flows';
					if (hasSegment) {
						const segmentDropdown = this.getDropdown(
							listViewSplitter(listViews.segment),
							this.lang.segment,
							segmentState
						);
						const props = {
							title: hasFlows ? this.lang.segmentAndFlows : this.lang.segment,
							key: 'sub-segments',
							id: 'sub-segments',
							state: segmentState,
							secondActiveState: flowState,
							dropdown: segmentDropdown,
							getStateHref: this.getStateHref
						};
						if (hasFlows) {
							const flowDropdown = this.getDropdown(
								listViewSplitter(listViews.flow),
								this.lang.flows,
								flowState
							);
							props.dropdown = [...segmentDropdown, ...flowDropdown];
						}

						items.push(React.createElement(SubMenuItem, props));
						itemConfigs.push(props);
					}
					if (hasCampaigns && !showCampaignsInSalesSection) {
						const campaignsSubMenuItemConfig = {
							title: this.lang.campaigns,
							key: 'sub-campaigns',
							id: 'sub-campaigns',
							state: 'react-root-campaigns',
							dropdown: [
								{
									title: this.lang.campaigns,
									items: listViews.campaign,
									state: 'react-root-campaigns'
								}
							],
							getStateHref: this.getStateHref
						};
						items.push(React.createElement(SubMenuItem, campaignsSubMenuItemConfig));
						itemConfigs.push(campaignsSubMenuItemConfig);
					}
				}

				if (products.crm) {
					if (features.groupMail && (marketAdmin || isAdmin)) {
						const splitListView = listViewSplitter(listViews.mailCampaign);
						const state = hasAcceptedTerms ? mailCampaignState : 'administration.mailSettings';
						const dropdown = this.getDropdown(splitListView, this.lang.mailings, state);

						const mailingsSubMenuItemConfig = {
							title: this.lang.mailings,
							key: 'sub-mailings',
							id: 'sub-mailings',
							state,
							secondActiveState: 'mailCampaign',
							dropdown,
							getStateHref: this.getStateHref
						};
						items.push(React.createElement(SubMenuItem, mailingsSubMenuItemConfig));
						itemConfigs.push(mailingsSubMenuItemConfig);
					}
					if (features.email && (marketAdmin || isAdmin)) {
						const dropdown = this.getDropdown(
							listViewSplitter(listViews.mailTemplate),
							this.lang.templates,
							hasAcceptedTerms ? 'listMailTemplates' : 'administration.mailSettings'
						);
						if (getSoftDeployAccessFromState(accountSelf, 'MAIL_TEMPLATES_V2')) {
							const mailTemplatesConfig = {
								title: this.lang.templates,
								key: 'react-root-mailtemplates',
								id: 'react-root-mailtemplates',
								state: hasAcceptedTerms ? 'react-root-mailtemplates' : 'administration.mailSettings',
								dropdown,
								getStateHref: this.getStateHref,
								className: 'right'
							};

							items.push(React.createElement(SubMenuItem, mailTemplatesConfig));
							itemConfigs.push(mailTemplatesConfig);
						} else {
							const mailTemplatesConfig = {
								title: this.lang.templates,
								key: 'sub-mailTemplates',
								id: 'sub-mailTemplates',
								state: hasAcceptedTerms ? 'listMailTemplates' : 'administration.mailSettings',
								dropdown,
								getStateHref: this.getStateHref,
								className: 'right'
							};
							items.push(React.createElement(SubMenuItem, mailTemplatesConfig));
							itemConfigs.push(mailTemplatesConfig);
						}
					}
				}

				if (!products.crm && features.groupMail && ((mapMailActivated && marketAdmin) || isAdmin)) {
					var mailAndTemplatesSubItems = [
						{ title: this.lang.mailings, items: listViews.mailCampaign, state: mailCampaignState }
					];

					if (features.email) {
						mailAndTemplatesSubItems.push({
							title: this.lang.mails,
							items: listViews.mail,
							state: 'listMail'
						});
						mailAndTemplatesSubItems.push({
							title: this.lang.templates,
							items: listViews.mailTemplate,
							state: 'listMailTemplates'
						});
					}

					const mailAndTemplatesConfig = {
						title: this.lang.mailAndTemplates,
						key: 'sub-mailings',
						id: 'sub-mailings',
						state: mailCampaignState,
						secondActiveState: 'mailCampaign',
						dropdown: mailAndTemplatesSubItems,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, mailAndTemplatesConfig));
					itemConfigs.push(mailAndTemplatesConfig);
				}

				if (features.ads && hasMAAccess && !hasHideAdvertising) {
					const engageConfig = {
						title: this.lang.engage,
						key: 'sub-listAds',
						id: 'sub-listAds',
						state: 'listAds',
						dropdown: null,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, engageConfig));
					itemConfigs.push(engageConfig);
				}
				var hasEventsFeature = getFeatureAvailableFromState(accountSelf, Feature.SOCIAL_EVENTS);
				var hasEventAccess = accessRights.SocialEvent;
				if (hasEventsFeature && hasEventAccess) {
					const eventsConfig = {
						title: this.lang.events,
						key: 'sub-events',
						id: 'sub-events',
						state: 'react-root-events',
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, eventsConfig));
					itemConfigs.push(eventsConfig);
				}
				break;
			}
			case 'followup':
				if (features.reports && (!metadata?.params.HideReportCenter || !features.looker)) {
					const reportCenterConfig = {
						title: `${this.lang.reportcenter}`,
						key: 'sub-reportcenter',
						id: 'sub-reportcenter',
						state: 'react-root-reportcenter',
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, reportCenterConfig));
					itemConfigs.push(reportCenterConfig);
				}

				if (
					(getFeatureAvailableFromState(accountSelf, Feature.LOOKER) && features.looker) ||
					metadata?.params?.LookerDashboardExeptionIds
				) {
					const lookerConfig = {
						title: this.lang.looker,
						key: 'sub-looker',
						id: 'sub-looker',
						state: 'looker',
						dropdown: null,
						currentAngularState: this.state.currentAngularState,
						getStateHref: this.getStateHref
					};
					items.push(React.createElement(SubMenuItem, lookerConfig));
					itemConfigs.push(lookerConfig);
				}

				break;

			case 'portfolio': {
				const overviewConfig = {
					title: this.lang.overview,
					key: 'sub-overview',
					id: 'sub-overview',
					state: 'react-root-portfolio',
					getStateHref: this.getStateHref
				};
				items.push(React.createElement(SubMenuItem, overviewConfig));
				itemConfigs.push(overviewConfig);

				const risksConfig = {
					title: this.lang.risks,
					key: 'sub-risks',
					id: 'sub-risks',
					state: 'react-root-risks',
					getStateHref: this.getStateHref
				};

				items.push(React.createElement(SubMenuItem, risksConfig));
				itemConfigs.push(risksConfig);

				if (
					getSoftDeployAccessFromState(accountSelf, 'CUSTOMER_PORTFOLIO_POTENTIAL') &&
					this.props.hasFullReportAccess
				) {
					const potentialConfig = {
						title: this.lang.upsell,
						key: 'sub-potential',
						id: 'sub-potential',
						state: 'react-root-upsell-potential',
						getStateHref: this.getStateHref
					};

					items.push(React.createElement(SubMenuItem, potentialConfig));
					itemConfigs.push(potentialConfig);
				}

				if (getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_BASIC)) {
					const prospectsConfig = {
						title: this.lang.findProspects,
						key: 'sub-prospects',
						id: 'sub-prospects',
						state: 'react-root-prospecting',
						getStateHref: this.getStateHref,
						showTrialBanner: (ongoingTrialAddons: { [key: string]: TrialAddon }) => {
							return !!(
								ongoingTrialAddons.PROSPECTING_PRO_SIGNALS ||
								ongoingTrialAddons.PROSPECTING_PRO_PROFESSIONAL
							);
						}
					};

					items.push(React.createElement(SubMenuItem, prospectsConfig));
					itemConfigs.push(prospectsConfig);
				} else if (self?.userParams.soliditetIsActive) {
					const prospectsConfig = {
						title: this.lang.findProspects,
						key: 'sub-prospects',
						id: 'sub-prospects',
						state: 'findProspects',
						dropdown: [{ title: this.lang.prospects, state: 'findProspects', items: listViews.soliditet }],
						getStateHref: this.getStateHref
					};

					items.push(React.createElement(SubMenuItem, prospectsConfig));
					itemConfigs.push(prospectsConfig);
				}

				const signalsConfig = {
					title: this.lang.signals,
					key: 'sub-portfolio-signals',
					id: 'sub-portfolio-signals',
					state: 'react-root-signals',
					getStateHref: this.getStateHref
				};

				items.push(React.createElement(SubMenuItem, signalsConfig));
				itemConfigs.push(signalsConfig);

				if (hasClientsContactsInPortfolio) {
					const clientPortfolioState = 'react-root-companies';
					const accountDropDown = this.getDropdown(
						listViewSplitter(listViews.account),
						this.lang.accounts,
						clientPortfolioState
					);

					const clientsConfig = {
						title: this.lang.accounts,
						key: 'sub-portfolio-clients',
						id: 'sub-portfolio-clients',
						state: clientPortfolioState,
						getStateHref: this.getStateHref,
						dropdown: accountDropDown,
						className: 'no-hide'
					};

					items.push(React.createElement(SubMenuItem, clientsConfig));
					itemConfigs.push(clientsConfig);

					const contactPortfolioState = 'react-root-contacts';
					const contactDropDown = this.getDropdown(
						listViewSplitter(listViews.contact),
						this.lang.contacts,
						contactPortfolioState
					);

					const contactsConfig = {
						title: this.lang.contacts,
						key: 'sub-portfolio-contacts',
						id: 'sub-portfolio-contacts',
						state: contactPortfolioState,
						getStateHref: this.getStateHref,
						dropdown: contactDropDown
					};

					items.push(React.createElement(SubMenuItem, contactsConfig));
					itemConfigs.push(contactsConfig);

					if (isAdmin && getSoftDeployAccessFromState(accountSelf, 'NEW_DUPLICATE_VIEW')) {
						accountDropDown.push({
							title: this.lang.duplicatedClients,
							items: [
								{
									title: this.lang.cleanDuplicates,
									state: 'react-root-detected-duplicates-landing-page',
									stateParams: { entity: 'accounts' }
								}
							],
							state: contactPortfolioState
						});
						contactDropDown.push({
							title: this.lang.duplicatedContacts,
							items: [
								{
									title: this.lang.cleanDuplicates,
									state: 'react-root-detected-duplicates-landing-page',
									stateParams: { entity: 'contacts' }
								}
							],
							state: contactPortfolioState
						});
					}

					if (hasSoliditetMatcher(self, accountSelf)) {
						accountDropDown.push({
							title: this.lang.companyRegister,
							items: [{ title: this.lang.matchClients, state: 'matcher' }]
						});
					}
				}
				break;
			}
		}

		// Return only itemConfigs when NEW_NAVBAR is released
		return { old: items, new: itemConfigs };
	}
	render() {
		const { self, accountSelf, location, metadata, accessRights, searchableFields } = this.props;
		const hasNewNavBar = getSoftDeployAccessFromState(accountSelf, 'NEW_NAVBAR');
		const hasTopCustomerPortfolio =
			getFeatureAvailableFromState(accountSelf, 'TOP_CUSTOMER_PORTFOLIO') &&
			getSoftDeployAccessFromState(accountSelf, 'TOP_CUSTOMER_PORTFOLIO');

		var showBrands = getFeatureAvailableFromState(accountSelf, Feature.BRANDS);
		var supersearch = React.createElement(ReactTemplates.navbar.supersearch, {
			searchableFields,
			size: showBrands ? 'md' : 'lg'
		});

		const products = accountSelf?.products || {};
		const features = accountSelf?.features || {};
		const isAdmin = self?.administrator;
		const marketAdmin = self?.userParams.mailAdmin;

		var rootClass = '';
		var sections = [];
		var submenu = [];
		const currentSection = accountSelf && self ? getSectionFromPath(location.pathname, accountSelf, self) : null;

		const hasSupportFeature = getFeatureAvailableFromState(accountSelf, Feature.CUSTOMER_SUPPORT);
		const hasSupportAccess = (isAdmin || self?.support) && hasSupportFeature;
		const isSupportOnly = self?.support && !self?.crm;

		let sectionClass = 'sale';
		if (currentSection && ['portfolio', 'sale', 'market', 'followup', 'support'].indexOf(currentSection) !== -1) {
			sectionClass = currentSection;
		}
		rootClass = `future-nav future-nav--${sectionClass}`;

		if (getSoftDeployAccessFromState(accountSelf, 'NEW_BRANDING')) {
			rootClass += ' new-branding';
		} else {
			rootClass += ' old-branding';
		}
		if (hasTopCustomerPortfolio) {
			const portfolioDefaultState = 'react-root-portfolio';
			sections.push(
				this.getSectionLi('portfolio', portfolioDefaultState, 'Icon Icon-sales-open', currentSection)
			);
			submenu.push(
				hasNewNavBar ? (
					<NavbarSubMenu
						key="potfolio-nav"
						visible={this.isSection('portfolio', currentSection)}
						items={this.getSubMenuItems('portfolio').new}
						currentSection={currentSection}
					/>
				) : (
					<nav
						key="portfolio-nav"
						className={this.isSection('portfolio', currentSection) ? 'visible-force' : ''}
					>
						{this.getSubMenuItems('portfolio').old}
					</nav>
				)
			);
		}

		if (products.crm || isAdmin) {
			let state;

			if (isAdmin) {
				state = 'react-root-salesboard';
			} else if (isSupportOnly && hasSupportAccess) {
				state = getSoftDeployAccessFromState(accountSelf, 'LIST_COMPANIES_REACT')
					? 'react-root-companies'
					: 'accounts';
			} else {
				state =
					(getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES') || !self?.crm) &&
					getSoftDeployAccessFromState(accountSelf, 'TODO_LIST')
						? 'react-root-todo'
						: 'activities';
			}

			if (!products.crm) {
				state = 'campaigns';
			}
			sections.push(
				this.getSectionLi(
					'sale',
					state,
					`Icon Icon-${hasTopCustomerPortfolio ? 'opportunity' : 'sales'}`,
					currentSection,
					undefined,
					hasTopCustomerPortfolio ? 'defaultSales' : undefined
				)
			);
			submenu.push(
				hasNewNavBar ? (
					<NavbarSubMenu
						key="sale-nav"
						visible={this.isSection('sale', currentSection)}
						items={this.getSubMenuItems('sale').new}
						currentSection={currentSection}
					/>
				) : (
					<nav key="sale-nav" className={this.isSection('sale', currentSection) ? 'visible-force' : ''}>
						{this.getSubMenuItems('sale').old}
					</nav>
				)
			);
		}

		const hasEventAccess =
			accessRights.SocialEvent && getFeatureAvailableFromState(accountSelf, Feature.SOCIAL_EVENTS);
		const hasMA = getProductAvailableFromState(accountSelf, Product.MA);

		if (hasMA && (isAdmin || marketAdmin || hasEventAccess)) {
			let maDefaultState = 'react-root-leads';

			if (getFeatureAvailableFromState(accountSelf, Feature.MA_DASHBOARD) && (marketAdmin || isAdmin)) {
				maDefaultState = 'react-root-marketingboard';
			} else if (getFeatureAvailableFromState(accountSelf, Feature.LEADS) && (marketAdmin || isAdmin)) {
				maDefaultState = 'react-root-leads';
			} else if (getFeatureAvailableFromState(accountSelf, Feature.VISITS) && (marketAdmin || isAdmin)) {
				maDefaultState = 'visitors';
			} else if (hasEventAccess) {
				maDefaultState = 'react-root-events';
			} else if (getFeatureAvailableFromState(accountSelf, Feature.GROUP_MAIL) && (marketAdmin || isAdmin)) {
				maDefaultState = 'react-root-mail-campaigns';
			} else if (
				getFeatureAvailableFromState(accountSelf, Feature.ADS) &&
				(marketAdmin || isAdmin) &&
				!getSoftDeployAccessFromState(accountSelf, 'HIDE_ADVERTISING')
			) {
				maDefaultState = 'listAds';
			}

			sections.push(
				this.getSectionLi(
					'market',
					maDefaultState,
					hasTopCustomerPortfolio ? 'Icon Icon-bullseye' : 'fa fa-bullseye',
					currentSection
				)
			);

			submenu.push(
				hasNewNavBar ? (
					<NavbarSubMenu
						key="market-nav"
						visible={this.isSection('market', currentSection)}
						items={this.getSubMenuItems('market').new}
						currentSection={currentSection}
					/>
				) : (
					<nav key="market-nav" className={this.isSection('market', currentSection) ? 'visible-force' : ''}>
						{this.getSubMenuItems('market').old}
					</nav>
				)
			);
		}

		if (features.reports || features.looker) {
			var followupState =
				!features.reports || (metadata?.params.HideReportCenter && features.looker)
					? 'looker'
					: 'react-root-reportcenter';
			const subMenuItems = this.getSubMenuItems('followup').old;
			if (subMenuItems.length < 2) {
				if (subMenuItems[0].key === 'sub-reportcenter') {
					sections.push(
						this.getSectionLi(
							'followup',
							followupState,
							'fa fa-line-chart',
							currentSection,
							{ reportEntity: 'order' },
							'reportcenter'
						)
					);
				} else if (subMenuItems[0].key === 'sub-looker') {
					sections.push(
						this.getSectionLi(
							'followup',
							followupState,
							'fa fa-line-chart',
							currentSection,
							{ reportEntity: 'order' },
							'looker'
						)
					);
				} else {
					sections.push(
						this.getSectionLi('followup', followupState, 'fa fa-line-chart', currentSection, {
							reportEntity: 'order'
						})
					);
				}
			} else {
				sections.push(
					this.getSectionLi('followup', followupState, 'fa fa-line-chart', currentSection, {
						reportEntity: 'order'
					})
				);
				submenu.push(
					hasNewNavBar ? (
						<NavbarSubMenu
							key="followup-nav"
							visible={this.isSection('followup', currentSection)}
							items={this.getSubMenuItems('followup').new}
							currentSection={currentSection}
						/>
					) : (
						<nav
							key="followup-nav"
							className={this.isSection('followup', currentSection) ? 'visible-force' : ''}
						>
							{subMenuItems}
						</nav>
					)
				);
			}
		}
		if (hasSupportAccess) {
			sections.push(
				this.getSectionLi('support', 'react-root-support', 'Icon Icon-customer-support', currentSection)
			);
		}
		submenu.push(
			hasNewNavBar ? null : (
				<nav key="support-nav" className={this.isSection('support', currentSection) ? 'visible-force' : ''}>
					{this.getSubMenuItems('support').old}
				</nav>
			)
		);

		submenu.push(
			hasNewNavBar ? null : (
				<nav key="admin-nav" className={this.isSection('admin', currentSection) ? 'visible-force' : ''}>
					{this.getSubMenuItems('admin').old}
				</nav>
			)
		);

		const logo = (
			<Fragment>
				<BadgeLogo size="lg" />
			</Fragment>
		);
		var showOnboarding = false;
		const onboarding = metadata?.onboarding;

		if (onboarding && onboarding.length) {
			showOnboarding = true;
		}

		const notificationDropdown = <NotificationDropdown />;

		var profileDropdown = React.createElement(ReactTemplates.navbar.profileDropdown, {
			getStateHref: this.getStateHref,
			// self/accountSelf should not be passed here later on. profileDropdown should get it from redux
			self: self,
			accountSelf: accountSelf
		});

		var browserNotSupported = this.state.showBrowserWarning ? (
			<div className="browser-not-supported">
				<Text color="white">{this.lang.browserOutdated}</Text>
				<Button
					color="white"
					type="link"
					onClick={() => {
						this.setState({ showBrowserWarning: false });
					}}
				>
					<Icon name="times" />
				</Button>
			</div>
		) : null;

		var newVersion = this.state.showNewVersionWarning ? (
			<div className="new-version-available">
				<Text color="white">
					<Button
						color="white"
						type="link"
						onClick={() => {
							window.location.reload();
						}}
					>
						{this.lang.newVersionAvailable}
					</Button>
				</Text>
				<Button
					color="white"
					type="link"
					onClick={() => {
						this.setState({ showNewVersionWarning: false });
					}}
				>
					<Icon name="times" />
				</Button>
			</div>
		) : null;

		const calendarHref = Tools.$state.href('react-root-calendar', { selected: 'week' });
		const noSidebar = getSoftDeployAccessFromState(accountSelf, 'NO_SIDEBAR');
		const showNavBar =
			this.isSection('sale', currentSection) ||
			this.isSection('market', currentSection) ||
			this.isSection('portfolio', currentSection) ||
			this.isSection('followup', currentSection);

		return (
			<nav id="navbar" role="navigation" className={rootClass}>
				<Provider store={store}>
					{browserNotSupported}
					{newVersion}
					<ul className="nav-menu">
						<li id="logo">
							<a
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();

									goToStartPage();
								}}
							>
								{getSoftDeployAccessFromState(accountSelf, 'SANTA') ? (
									<img
										src="img/hat.png"
										style={{
											position: 'absolute',
											width: '42px',
											top: '-2px',
											left: '9px'
										}}
									/>
								) : null}
								{logo}
							</a>
						</li>

						{sections}

						<div id="search-curtain" />
						{supersearch}
						{noSidebar ? <NavbarAddMenu /> : null}
					</ul>

					<ul className="nav-menu menu-right">
						{noSidebar ? (
							<Fragment>
								<NavbarCustomLinks />
								<li className="menu-item">
									<a href={calendarHref} className="dropdown-toggle">
										<Icon name="calendar" />
									</a>
								</li>
							</Fragment>
						) : null}
						{showOnboarding && <OnboardingNotification />}
						{notificationDropdown}
						{profileDropdown}
					</ul>

					{/* Dont show brand while we have onboarding as we dont have space in the navbar for that */}
					{showBrands && !showOnboarding && <NavbarBrandDropdown />}
					{!showOnboarding && !isSupportOnly ? (
						<ul className="nav-menu menu-quotas">
							<QuotaCharts />
						</ul>
					) : null}

					{showNavBar ? (
						// This key results in a re-mount of the sub-menu every time the route changes. This is not optimal and did not really work with the new navbar.
						// It seems to work fine without it so i have removed it if you have the NEW_NAVBAR feature.
						<div id="sub-menu" key={hasNewNavBar ? undefined : Tools.$state.current.name}>
							{submenu}
						</div>
					) : null}
				</Provider>
			</nav>
		);
	}
}

export default connector(withRouter(Navbar));
