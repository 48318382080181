import { useSelector } from 'App/components/hooks';
import { hasSoliditetMatcher } from 'App/helpers/matcherHelper';
import { RootState } from 'Store/index';

export default function MatcherAllowed() {
	const { self, accountSelf } = useSelector(({ App }: RootState) => ({
		self: App.self,
		accountSelf: App.accountSelf
	}));

	return hasSoliditetMatcher(self, accountSelf) || false;
}
