import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';

const replaceColumn = (columns: string[], column: string, replaceWith: string) => {
	const index = columns.indexOf(column);
	if (index !== -1) {
		columns[index] = replaceWith;
	}
};

const removeColumn = (columns: string[], column: string) => {
	const index = columns.indexOf(column);
	if (index !== -1) {
		columns.splice(index, 1);
	}
};

export const getColumns = (
	columns: string[],
	replacementMap?: {
		[column: string]: string;
	},
	remove?: string
) => {
	if (replacementMap) {
		Object.entries(replacementMap).forEach(([column, replacementColumn]) => {
			replaceColumn(columns, column, replacementColumn);
		});
	}

	if (remove) {
		removeColumn(columns, remove);
	}

	return columns;
};

export const getFilterFn = (
	includeSubaccounts: boolean,
	showRelatedContacts = false,
	showRelatedOrders = false,
	prefix = 'client.'
) => {
	const getDataFilter = (rb: RequestBuilder, clientIds: number[]) => {
		const orBuilder = rb.orBuilder();
		orBuilder.next();
		orBuilder.addFilter({ field: prefix + 'id' }, ComparisonTypes.Equals, clientIds);

		if (showRelatedOrders) {
			orBuilder.next();
			orBuilder.addFilter({ field: 'clientConnection.id' }, ComparisonTypes.Equals, clientIds);
		}

		if (!includeSubaccounts) {
			if (showRelatedContacts) {
				orBuilder.next();
				orBuilder.addFilter({ field: 'connectedClients.relatedToClientId' }, ComparisonTypes.Equals, clientIds);
			}
		} else {
			orBuilder.next();
			orBuilder.addFilter({ field: prefix + 'operationalAccount.id' }, ComparisonTypes.Equals, clientIds);
		}

		orBuilder.done();
	};

	return getDataFilter;
};
