export default function AccessView({ list }) {
	if (!list || !list.length) {
		return null;
	}

	list = list.reduce(function (res, item) {
		let found;
		if (item.roleId) {
			// its a role!

			if (item.isRole) {
				found = Tools.AppService.getRoles().find(role => {
					return role.id === item.$id;
				});
			}

			found = Tools.AppService.getRoles().find(role => {
				return role.id === item.roleId;
			});
		}

		if (item.userId) {
			found = Tools.AppService.getUsers(null, false, 'all').find(user => {
				return user.id === item.userId;
			});
		}

		if (found) {
			res.push(found);
		}

		return res;
	}, []);

	if (list.length === 1 && list[0]) {
		return list[0].name;
	}

	if (list.length > 1) {
		return `${list[0].name} + ${list.length - 1} ${Tools.$translate('default.more2').toLowerCase()}`;
	}

	return null;
}
