import { Title, Text, Progressbar, ButtonSelect, Icon, Tooltip } from '@upsales/components';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import { hasCMWithRR, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import SalesGraph, { Groupings, WidgetType } from './SalesGraph';
import bemClass from '@upsales/components/Utils/bemClass';
import { Quota } from 'App/resources/Model/Client';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import React, { useState } from 'react';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import moment from 'moment';

import './ClientSales.scss';
import { Feature } from 'Store/actions/FeatureHelperActions';

type Props = {
	setGraphType?: (t: 'recurring' | 'cashflow') => void;
	onGroupingChange?: (type: Groupings) => void;
	contributionMarginTotal: number;
	contributionMarginRatio: number;
	arrChangeLast12Months: number;
	mrrChangeLast12Months: number;
	salesThisYear?: number;
	cmThisYear?: number;
	arrThisYear?: number;
	mrrThisYear?: number;
	currentARR: number;
	orderTotal: number;
	clientId: number;
	quotas: Quota[];
	startType?: 'recurring' | 'cashflow';
	startGrouping?: Groupings;
	hideTarget?: boolean;
	subAccountIds?: number[];
};

const ClientSales = ({
	contributionMarginTotal,
	contributionMarginRatio,
	arrChangeLast12Months,
	mrrChangeLast12Months,
	onGroupingChange = () => {},
	salesThisYear = 0,
	cmThisYear = 0,
	arrThisYear = 0,
	mrrThisYear = 0,
	currentARR,
	orderTotal,
	startType,
	startGrouping,
	clientId,
	setGraphType = () => {},
	quotas,
	hideTarget,
	subAccountIds = []
}: Props) => {
	const hasSubaccountsV1 = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
	const hasSubaccountsFeature = useFeatureAvailable(Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
	const hasSubAccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;

	const { metadata } = useSelector(({ App }: RootState) => App);
	const { SalesModelOption, SalesModelOption3, SalesModel } = metadata?.params || {};
	const currency = metadata?.defaultCurrency.iso;

	const hasRecurring = SalesModel === 'rr';
	const hasMRR = hasRecurring && SalesModelOption === 'mrr';
	const hasARR = hasRecurring && SalesModelOption === 'arr';
	const hasCM = SalesModel === 'cm';
	let currentQuotaType = 'sales';
	if (hasRecurring) {
		currentQuotaType = 'recurring';
	} else if (hasCM) {
		currentQuotaType = 'contributionMargin';
	}

	const usingRRWithCM = hasRRWithCM();

	quotas = quotas.filter(q => q.type === currentQuotaType);

	const currencyFormat = new CurrencyFormat(currency);
	const currencyQuota = quotas[0]?.currency ?? currency;
	const currencyFormatQuota = new CurrencyFormat(currencyQuota);

	const numberFormat = new NumberFormat();

	const [grouping, setGrouping] = useState<Groupings>(startGrouping || Groupings.YEAR);
	const [widgetType, setWidgetType] = useState<WidgetType>(
		startType
			? startType === 'recurring'
				? WidgetType.TOTAL_RECURRING
				: WidgetType.CASH_FLOW
			: hasRecurring
			? WidgetType.TOTAL_RECURRING
			: WidgetType.CASH_FLOW
	);

	let arrColor: 'success-5' | 'red' | undefined;
	if (arrChangeLast12Months > 0) {
		arrColor = 'success-5';
	} else if (arrChangeLast12Months < 0) {
		arrColor = 'red';
	}

	let mrrColor: 'success-5' | 'red' | undefined;
	if (mrrChangeLast12Months > 0) {
		mrrColor = 'success-5';
	} else if (mrrChangeLast12Months < 0) {
		mrrColor = 'red';
	}

	const widgetTypeHandler = (type: WidgetType) => {
		setWidgetType(type);
		setGrouping(Groupings.YEAR);
		onGroupingChange(Groupings.YEAR);
	};

	const groupingHandler = (grouping: Groupings) => {
		setGrouping(grouping);
		if (
			!(
				grouping === Groupings.USER &&
				(widgetType === WidgetType.RECURRING || widgetType === WidgetType.TOTAL_RECURRING)
			)
		) {
			onGroupingChange(grouping);
		}
	};

	const getRecurringOptions = () => {
		const options = [
			{
				value: WidgetType.TOTAL_RECURRING,
				title: T(hasMRR ? 'default.monthlyValue' : 'default.annualValue')
			},
			{
				value: WidgetType.RECURRING,
				title: T('client.target.growth')
			},
			{ value: WidgetType.CASH_FLOW, title: T('default.cashFlow') }
		];

		if (usingRRWithCM) {
			options.push({
				value: WidgetType.CONTRIBUTION_MARGIN,
				title: T('default.contributionMargin')
			});
		}

		return options;
	};

	const getCmOptions = () => {
		const options = [
			{ value: WidgetType.CASH_FLOW, title: T('default.cashFlow') },
			{
				value: WidgetType.CONTRIBUTION_MARGIN,
				title: T('default.contributionMargin')
			}
		];

		if (hasCMWithRR()) {
			const usingMRR = SalesModelOption3 === 'cmCombinedWithMRR';
			options.push(
				{
					value: WidgetType.TOTAL_RECURRING,
					title: T(usingMRR ? 'default.monthlyValue' : 'default.annualValue')
				},
				{
					value: WidgetType.RECURRING,
					title: T('client.target.growth')
				}
			);
		}

		return options;
	};

	const currencyRate = metadata?.customerCurrencies.find(c => c.iso === currencyQuota)?.rate ?? 1;
	const currentQuota =
		quotas?.reduce((res, current) => {
			if (moment().year() === moment(current.period).year()) {
				return res + current.quota * current.currencyRate;
			}
			return res;
		}, 0) ?? 0;

	let progress = salesThisYear * currencyRate;
	if (hasARR) {
		progress = arrThisYear * currencyRate;
	} else if (hasMRR) {
		progress = mrrThisYear * currencyRate;
	} else if (hasCM) {
		progress = cmThisYear * currencyRate;
	}

	progress /= currentQuota;

	const classes = new bemClass('ClientSales');
	return (
		<div className={classes.b()}>
			<div className={classes.elem('leftSide').b()}>
				<div className={classes.elem('leftSide').elem('info').b()}>
					<Title>{currencyFormat.short(hasRecurring ? currentARR / (hasMRR ? 12 : 1) : orderTotal)}</Title>
					<Text size="sm">
						{T(
							hasRecurring
								? hasMRR
									? 'account.sales.currentMRR'
									: 'account.sales.currentARR'
								: 'default.sales12Months'
						)}
					</Text>
				</div>
				<div className={classes.elem('leftSide').elem('info').b()}>
					{
						{
							cm: (
								<>
									<Title>{currencyFormat.short(contributionMarginTotal)}</Title>
									<Text size="sm">{T('default.contributionMargin12Months')}</Text>
								</>
							),
							rr: (
								<>
									{hasMRR ? (
										<Title color={mrrColor}>{`${
											mrrChangeLast12Months > 0 ? '+' : ''
										}${currencyFormat.short(mrrChangeLast12Months)}`}</Title>
									) : (
										<Title color={arrColor}>{`${
											arrChangeLast12Months > 0 ? '+' : ''
										}${currencyFormat.short(arrChangeLast12Months)}`}</Title>
									)}
									<Text size="sm">
										{T(
											hasMRR
												? 'client.target.MRRChange12Months'
												: 'client.target.ARRChange12Months'
										)}
									</Text>
								</>
							)
						}[SalesModel as 'cm' | 'rr']
					}
				</div>
				{hasCM || usingRRWithCM ? (
					<div className={classes.elem('leftSide').elem('info').b()}>
						<Title>{numberFormat.percent(contributionMarginRatio)}</Title>
						<Text size="sm">{T('default.contributionMarginRatio12Months')}</Text>
					</div>
				) : null}
				{/* TODO: hidden for now in new client card */}
				{!hideTarget ? (
					<Tooltip title={T('client.target.editCompanyTarget')}>
						<div
							className={classes.elem('leftSide').elem('info').mod('hasClick').b()}
							onClick={() => openModal('EditClientTarget', { quotas, clientId })}
						>
							<Title>{currencyFormatQuota.short(currentQuota)}</Title>
							<Progressbar color="green" size="sm" value={currentQuota === 0 ? 0 : progress * 100} />
							<div className={classes.elem('leftSide').elem('info').elem('settings').b()}>
								<Text size="sm">
									{T(
										hasARR
											? 'client.target.percentOfARRTarget'
											: hasMRR
											? 'client.target.percentOfMRRTarget'
											: 'client.target.percentOfTarget',
										{
											percent: numberFormat.percent(currentQuota === 0 ? 0 : progress)
										}
									)}
								</Text>
								<Icon name="cog" color="grey-8" />
							</div>
						</div>
					</Tooltip>
				) : null}
			</div>
			<div className={classes.elem('rightSide').b()}>
				<div className={classes.elem('rightSide').elem('buttonSelects').b()}>
					{hasRecurring ? (
						<ButtonSelect
							value={widgetType}
							onChange={v => {
								widgetTypeHandler(v);
								setGraphType(
									v === WidgetType.RECURRING || v === WidgetType.TOTAL_RECURRING
										? 'recurring'
										: 'cashflow'
								);
							}}
							options={getRecurringOptions()}
						/>
					) : null}

					{hasCM ? (
						<ButtonSelect
							value={widgetType}
							onChange={v => {
								widgetTypeHandler(v);
								setGraphType(
									v === WidgetType.RECURRING || v === WidgetType.TOTAL_RECURRING
										? 'recurring'
										: 'cashflow'
								);
							}}
							options={getCmOptions()}
						/>
					) : null}

					<ButtonSelect
						value={grouping}
						onChange={groupingHandler}
						options={
							[
								{ value: Groupings.YEAR, title: T('date.year') },
								{ value: Groupings.MONTH, title: T('date.month') },
								{ value: Groupings.USER, title: T('default.user') },
								widgetType === WidgetType.CASH_FLOW
									? { value: Groupings.STAGE, title: T('default.stage') }
									: undefined,
								hasSubAccounts && subAccountIds.length > 0
									? { value: Groupings.CLIENT, title: T('account.subaccounts') }
									: undefined
							].filter(Boolean) as { value: Groupings; title: string }[]
						}
					/>
				</div>
				<SalesGraph
					clientId={clientId}
					grouping={grouping}
					widgetType={widgetType}
					subAccountIds={subAccountIds}
				/>
			</div>
		</div>
	);
};

export default ClientSales;
