import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import React, { useEffect, useRef, useState } from 'react';
import CompanyList from '../CompanyList';
import RequestBuilder from 'Resources/RequestBuilder';
import { useTranslation } from 'Components/Helpers/translate';
import { openDrawer } from 'Services/Drawer';
import ListTab from 'Components/ListTab';
import Client from 'App/resources/Model/Client';
import OpportunityList from './OpportunityList';
import OrderResource from 'App/resources/Order';
import logError from 'Helpers/logError';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { getColumns, getFilterFn } from 'Components/ListTab/ListTabHelper';
import { useCompanyGroupSelector } from '../CompanyGroupCard/Context/CompanyGroupContext';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

const getOpportunityColumns = (isGroupBySubaccount: boolean, isDrawer?: boolean, isSubaccount = false) => {
	const columns = ['date', 'description', 'stage', 'value', 'user', ''];
	if (!isSubaccount) {
		columns.splice(1, 0, 'operationalAccount');
	}
	const replacementMap = isGroupBySubaccount
		? {
				operationalAccount: 'client'
		  }
		: undefined;

	const removeColumn = isDrawer && isGroupBySubaccount ? 'client' : isDrawer ? 'operationalAccount' : undefined;

	return getColumns(columns, replacementMap, removeColumn);
};

const getCompanyColumns = (isGroupBySubaccount: boolean) => {
	const columns = [
		'nameWithLocation',
		'numberOfOpportunitiesTotal',
		'subaccountPipelineValueTotal',
		'subaccountPipelineDistribution',
		'users'
	];
	const replacementMap = isGroupBySubaccount
		? {
				numberOfOpportunitiesTotal: 'numberOfOpportunities',
				subaccountPipelineDistribution: 'pipelineDistribution',
				subaccountPipelineValueTotal: 'pipelineValueTotal'
		  }
		: undefined;

	return getColumns(columns, replacementMap);
};

type Props = {
	isGroupBySubaccount?: boolean;
	clientIds: number[];
	client?: Pick<Client, 'operationalAccount' | 'numberOfSubaccounts'>;
};

export const Detached = ({ isGroupBySubaccount = false, clientIds, client }: Props) => {
	const { t } = useTranslation();
	const openReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof OrderResource.find>>>>(null);
	const closedReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof OrderResource.find>>>>(null);
	const isSubaccount = !!client?.operationalAccount || client?.numberOfSubaccounts === 0;

	const [openCount, setOpenCount] = useState<number>(0);
	const [lostCount, setLostCount] = useState<number>(0);

	const hasGroupBonanza = useSoftDeployAccess('GROUP_BONANZA');
	const hasCompanyGroupCardFeature = useFeatureAvailable(Feature.COMPANY_GROUP_CARD) && hasGroupBonanza;

	const hasSubaccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccounts;
	const hasSubaccountsFeature = useFeatureAvailable(Feature.SUB_ACCOUNTS) && hasSubaccountsV2;

	const companyGroupOrSubaccounts = hasCompanyGroupCardFeature || hasSubaccountsFeature;

	const filterFn = getFilterFn(!isGroupBySubaccount);

	useEffect(() => {
		if (!clientIds.length) {
			return;
		}

		const updateNumberOfOpportunities = () => {
			const getOpenOpportunities = () => {
				openReq.current?.cancel();

				const rb = new RequestBuilder();

				filterFn(rb, clientIds);

				rb.addFilter({ field: 'probability' }, ComparisonTypes.NotEquals, 100);
				rb.addFilter({ field: 'probability' }, ComparisonTypes.NotEquals, 0);
				rb.limit = 0;

				openReq.current = makeCancelable(OrderResource.find(rb.build()));
				openReq.current.promise
					.then(({ metadata }) => {
						setOpenCount(metadata.total);
					})
					.catch(e => logError(e, 'Failed to open opportunities'));
			};

			const getLostOpportunities = () => {
				closedReq.current?.cancel();

				const rb = new RequestBuilder();

				filterFn(rb, clientIds);
				rb.addFilter({ field: 'probability' }, ComparisonTypes.Equals, 0);
				rb.limit = 0;

				closedReq.current = makeCancelable(OrderResource.find(rb.build()));
				closedReq.current.promise
					.then(({ metadata }) => {
						setLostCount(metadata.total);
					})
					.catch(e => logError(e, 'Failed to open opportunities'));
			};

			getOpenOpportunities();
			getLostOpportunities();
		};

		updateNumberOfOpportunities();
		const listeners = [
			// f u elastic
			Tools.$rootScope.$on('order.updated', () => setTimeout(() => updateNumberOfOpportunities(), 1000)),
			Tools.$rootScope.$on('order.added', () => setTimeout(() => updateNumberOfOpportunities(), 1000)),
			Tools.$rootScope.$on('order.deleted', () => setTimeout(() => updateNumberOfOpportunities(), 1000)),
			Tools.$rootScope.$on('opportunity.updated', () => setTimeout(() => updateNumberOfOpportunities(), 1000)),
			Tools.$rootScope.$on('opportunity.added', () => setTimeout(() => updateNumberOfOpportunities(), 1000)),
			Tools.$rootScope.$on('opportunity.deleted', () => setTimeout(() => updateNumberOfOpportunities(), 1000))
		];

		return () => {
			openReq.current?.cancel();
			listeners.forEach(unsub => unsub());
		};
	}, [clientIds]);

	const lists = [
		{
			value: 'all',
			title: `${t('default.all')}`,
			component: (
				<OpportunityList
					columns={getOpportunityColumns(isGroupBySubaccount, isSubaccount)}
					getDataFilter={filterFn}
					clientIds={clientIds}
					filterValue="all"
					key={'all'}
				/>
			)
		},
		{
			value: 'open',
			title: companyGroupOrSubaccounts
				? `${openCount} ${openCount === 1 ? t('default.active') : t('default.activeMany')}`
				: `${openCount} ${t('default.open')}`,
			component: (
				<OpportunityList
					columns={getOpportunityColumns(isGroupBySubaccount, isSubaccount)}
					getDataFilter={filterFn}
					clientIds={clientIds}
					filterValue="open"
					key={'open'}
				/>
			)
		},
		{
			value: 'lost',
			title: companyGroupOrSubaccounts
				? `${lostCount} ${lostCount === 1 ? t('default.lost2') : t('default.lost')}`
				: `${lostCount} ${t('default.lost')}`,
			component: (
				<OpportunityList
					columns={getOpportunityColumns(isGroupBySubaccount, isSubaccount)}
					getDataFilter={filterFn}
					clientIds={clientIds}
					filterValue="lost"
					key={'lost'}
				/>
			)
		}
	];

	const onRowClick = (account: Client) => {
		const lists = [
			{
				value: 'all',
				title: `${t('default.all')}`,
				component: (
					<OpportunityList
						columns={getOpportunityColumns(isGroupBySubaccount, true)}
						getDataFilter={filterFn}
						key={'all'}
						clientIds={[account.id]}
						filterValue="all"
						hideHeader
					/>
				)
			}
		];

		openDrawer('ListDrawer', { account, lists });
	};

	const groupList =
		clientIds.length > 1 ? (
			<CompanyList
				columns={getCompanyColumns(isGroupBySubaccount)}
				clientIds={clientIds}
				onRowClick={onRowClick}
				getDataFilter={(rb: RequestBuilder) => {
					if (!isGroupBySubaccount) {
						rb.addFilter({ field: 'operationalAccount.id' }, ComparisonTypes.Equals, null);
						rb.addFilter({ field: 'growth.numberOfOpportunitiesTotal' }, ComparisonTypes.GreaterThan, 0);
					} else {
						rb.addFilter({ field: 'growth.numberOfOpportunities' }, ComparisonTypes.GreaterThan, 0);
					}
				}}
			/>
		) : undefined;

	return (
		<ListTab
			lists={lists}
			title={t('companyGroup.opportunities.showOpportunities')}
			isGroupBySubaccount={isGroupBySubaccount}
			groupList={groupList}
		/>
	);
};

const OpportunityTab = ({ ...props }: Omit<Props, 'clientIds'>) => {
	const { clientIds } = useCompanyGroupSelector(({ clientIds }) => ({
		clientIds
	}));

	return <Detached {...props} clientIds={clientIds} />;
};

export default OpportunityTab;
