import { insertAfter } from 'App/babel/store/helpers/array';
import getCategoryConfigs from 'App/babel/filters/CategoryFilters';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';
import { default as generateAppointmentFilter } from 'App/babel/filters/Appointment';
import { default as generateActivityFilter } from 'App/babel/filters/Activity';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import AddressFilter from 'App/upsales/common/attributes/filter/addressFilter';
import DatePreset from 'App/upsales/common/services/datePresetService';
import SocialEventFilter from 'App/babel/filters/SocialEvent';
import OrderFilter from 'App/babel/angular/attributes/filter/orderFilter';
import AgreementFilter from 'App/babel/angular/attributes/filter/agreementFilter';
import TaskFilter from 'App/babel/angular/attributes/filter/taskFilter';
import OpportunityFilter from 'App/babel/angular/attributes/filter/opportunityFilter';
import ActivityFilter from 'App/babel/angular/attributes/filter/activityFilter';
import FormSubmitFilter from 'App/babel/angular/attributes/filter/formSubmitFilter';
import MailCampaignFilter from 'App/babel/angular/attributes/filter/mailCampaignFilter';
import VisitorFilter from 'App/babel/angular/attributes/filter/visitorFilter';
import AccountManagerFilter from 'App/upsales/common/attributes/filter/accountManagerFilter';
import UserRoleFilter from 'App/upsales/common/attributes/filter/userRoleFilter';
import ComparisonTypes from 'Resources/ComparisonTypes';

angular.module('upAttributes').service('ContactAttributes', [
	'FilterType',
	'DisplayType',
	'FeatureHelper',
	'AppService',
	'$q',
	function (FilterType, DisplayType, FeatureHelper, AppService, $q) {
		return function () {
			var attributes = {
				id: {
					title: 'default.id',
					field: 'id',
					type: FilterType.Number,
					displayType: DisplayType.Number,
					groupable: false,
					selectableColumn: false
				},
				account: {
					title: 'default.account',
					field: 'client.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					filterName: 'clientId',
					link: 'accounts',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String,
							skipMap: true
						},
						users: {
							field: 'client.users.id',
							type: FilterType.Number,
							parent: 'client.users',
							skipMap: true
						}
					}
				},
				connectedClients: {
					title: 'default.connectedClients',
					field: 'connectedClients.relatedToClientId',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					comparisonTypes: ['Equals', 'NotEquals'],
					selectableColumn: false,
					attr: {
						relatedToClientId: {
							field: 'connectedClients.relatedToClientId',
							type: FilterType.Number
						}
					}
				},
				categories: {
					title: 'default.categories',
					field: 'category.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: 'categories',
					filterable: false,
					parent: 'categories',
					attr: {
						id: {
							field: 'category.id',
							type: FilterType.Number
						},
						name: {
							field: 'category.name',
							type: FilterType.String
						}
					}
				},
				campaigns: {
					title: 'default.campaigns',
					field: 'project.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'projects',
					attr: {
						id: {
							field: 'project.id',
							type: FilterType.Number
						},
						name: {
							field: 'project.name',
							type: FilterType.String
						}
					}
				},
				clientText: {
					title: 'default.account',
					field: 'client.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					filterName: 'clientId',
					link: 'accounts',
					comparisonTypes: ['Equals', 'NotEquals'],
					groupable: false,
					sortable: 'client.name',
					selectableColumn: false,
					parent: 'client',
					attr: {
						id: {
							field: 'client.id',
							type: FilterType.Number
						},
						name: {
							field: 'client.name',
							type: FilterType.String,
							skipMap: true
						},
						users: {
							field: 'client.users.id',
							type: FilterType.Number,
							parent: 'client.users',
							skipMap: true
						}
					}
				},
				contactText: {
					title: 'column.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'name'
					// inputType: 'text'
				},
				name: {
					title: 'column.name',
					field: 'name',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'name'
					// inputType: 'text'
				},
				firstName: {
					title: 'default.firstName',
					field: 'firstName',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'firstName'
				},
				lastName: {
					title: 'default.lastName',
					field: 'lastName',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: false,
					sortable: 'lastName'
				},
				title: {
					title: 'default.title',
					field: 'title',
					type: FilterType.String,
					displayType: DisplayType.String,
					selectableColumn: true,
					sortable: 'title'
				},
				titleCategory: {
					title: 'default.titlecategory',
					field: 'titleCategory',
					displayAttr: 'value',
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'titleCategory.tagId',
					selectableColumn: true,
					attr: {
						tagId: {
							field: 'titleCategory.tagId',
							type: FilterType.Number
						},
						value: {
							field: 'titleCategory.value',
							type: FilterType.String
						},
						language: {
							field: 'titleCategory.language',
							type: FilterType.String
						}
					},
					unreleasedFeature: 'NEW_FIELDS'
				},
				salutation: {
					title: 'default.salutation',
					field: 'salutation.value',
					displayAttr: 'value',
					displayType: DisplayType.String,
					groupable: false,
					sortable: 'salutation.tagId',
					selectableColumn: false,
					parent: 'salutation',
					attr: {
						tagId: {
							field: 'salutation.tagId',
							type: FilterType.Number
						},
						value: {
							field: 'salutation.value',
							type: FilterType.String
						},
						language: {
							field: 'salutation.language',
							type: FilterType.String
						}
					},
					unreleasedFeature: 'NEW_FIELDS'
				},
				phone: {
					title: 'default.phone',
					displayType: DisplayType.Number,
					field: 'phone',
					selectableColumn: true,
					type: FilterType.String
				},
				cellPhone: {
					title: 'default.cellPhone',
					displayType: DisplayType.Number,
					field: 'cellPhone',
					selectableColumn: true,
					type: FilterType.String
				},
				email: {
					field: 'email',
					type: FilterType.String,
					displayType: DisplayType.String,
					title: 'default.email',
					selectableColumn: true,
					sortable: 'email'
				},
				emailBounceReason: {
					field: 'emailBounceReason',
					type: FilterType.String,
					displayType: DisplayType.String,
					title: 'default.emailBounceReason',
					selectableColumn: false
				},
				notes: {
					field: 'notes',
					type: FilterType.String
				},
				score: {
					field: 'score',
					title: 'default.score',
					type: FilterType.Number,
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					filterable: true,
					sortable: 'score'
				},
				scoreUpdateDate: {
					title: 'default.scoreUpdate',
					field: 'scoreUpdateDate',
					type: FilterType.Date,
					displayType: DisplayType.Date,
					selectableColumn: true,
					filterable: true,
					sortable: 'scoreUpdateDate'
				},
				users: {
					title: 'default.user',
					field: 'user.id',
					type: FilterType.Number,
					displayType: DisplayType.String,
					inputType: 'select',
					displayAttr: 'name',
					parent: 'users',
					attr: {
						id: {
							field: 'user.id',
							type: FilterType.Number
						},
						name: {
							field: 'user.name',
							type: FilterType.String
						}
					}
				},
				active: {
					field: 'active',
					type: FilterType.Boolean
				},
				unsubscribed: {
					title: 'mail.unsubscribed',
					field: 'unsubscribed',
					type: FilterType.Date
				},
				segments: {
					title: 'segment.segments',
					field: 'segment.id',
					// inputType: 'select',
					displayAttr: 'name',
					displayType: DisplayType.String,
					groupable: false,
					selectableColumn: true,
					parent: 'segments',
					attr: {
						id: {
							field: 'segment.id',
							type: FilterType.Number
						},
						name: {
							field: 'segment.name',
							type: FilterType.String
						},
						date: {
							field: 'segment.date',
							type: FilterType.Date
						}
					}
				},
				custom: {},
				history: {
					title: 'column.contactHistory',
					field: 'history',
					type: FilterType.String,
					selectableColumn: true
				},
				markethistory: {
					title: 'column.marketHistory',
					field: 'markethistory',
					type: FilterType.String,
					selectableColumn: true
				},
				socialEvent: {
					field: 'socialEvent.id',
					type: FilterType.Number
				},
				socialEventStatus: {
					field: 'socialEvent.status',
					type: FilterType.String
				},
				journeyStep: {
					title: 'default.journeyStep',
					field: 'journeyStep',
					displayType: DisplayType.None,
					sortable: 'journeySort',
					selectableColumn: false,
					groupable: false
				}
			};
			attributes.client = angular.copy(attributes.account);
			attributes.client.selectableColumn = true;

			if (AppService.loaded) {
				if (!FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA)) {
					attributes.scoreUpdateDate.selectableColumn = false;
					attributes.score.selectableColumn = false;
				}
			}

			var dateFilterPresets = [
				'whenever',
				'last7days',
				'last14days',
				'last30days',
				'last45days',
				'last90days',
				'lastXdays',
				'currentMonth',
				'currentQuarter',
				'currentWeek',
				'currentYear',
				'lastMonth',
				'lastWeek',
				'lastYear',
				'custom'
			];

			var agreementDateFilterPresets = [
				'whenever',
				'lastDay',
				'lastWeek',
				'lastMonth',
				'lastQuarter',
				'lastYear',
				'untilToday',
				'currentDay',
				'fromToday',
				'currentWeek',
				'currentMonth',
				'currentQuarter',
				'currentYear',
				'nextDay',
				'nextWeek',
				'nextMonth',
				'nextQuarter',
				'nextYear',
				'nextXdays',
				'nextXweeks',
				'nextXmonths',
				'nextXyears',
				'last7days',
				'last14days',
				'last30days',
				'custom'
			];

			const { Category: StandardCategory, ...customContactCategoryFilters } = getCategoryConfigs('contact', '', {
				showOnSegment: true
			});
			const { 'account.Category': CategoryClient, ...customClientCategoryFilters } = getCategoryConfigs(
				'account',
				'account.',
				{ field: 'client.category.id', showOnSegment: true }
			);

			var standardFilters = {
				Name: {
					field: 'name',
					columnPath: 'name',
					inputType: 'text',
					type: 'text',
					title: 'default.name'
				},
				NameList: {
					field: 'name',
					inputType: 'list',
					type: 'list',
					displayType: 'text',
					comparisonType: 'Search',
					title: 'default.name',
					hide: true,
					showOnSegment: true
				},
				Title: {
					field: 'title',
					columnPath: 'title',
					inputType: 'text',
					type: 'text',
					title: 'default.title'
				},
				TitleCategory: {
					field: 'titleCategory.tagId',
					columnPath: 'titleCategory',
					key: 'tagId',
					resource: function (RequestBuilder) {
						return function (customerId, values) {
							var filter = new RequestBuilder();

							filter.addFilter({ field: 'type' }, filter.comparisonTypes.Equals, 'titlecategory');
							filter.addFilter({ field: 'tagId' }, filter.comparisonTypes.Equals, values);

							return FieldTranslation.find(filter.build());
						};
					},
					displayType: 'listLazy',
					type: 'list',
					search: true,
					idAttr: {
						field: 'tagId'
					},
					searchFn: function (RequestBuilder) {
						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addFilter({ field: 'type' }, filter.comparisonTypes.Equals, 'titlecategory');
							filter.addSort({ field: 'value' }, true);

							if (term) {
								filter.addFilter({ field: 'value' }, filter.comparisonTypes.Search, term);
							}

							return FieldTranslation.find(filter.build());
						};
					},
					showOnSegment: true,
					searchField: 'value',
					displayText: _.property('value'),
					title: 'default.titlecategory',
					multiComparitors: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				Salutation: {
					field: 'salutation.tagId',
					key: 'tagId',
					resource: function (RequestBuilder) {
						return function (customerId, values) {
							var filter = new RequestBuilder();

							filter.addFilter({ field: 'type' }, filter.comparisonTypes.Equals, 'salutation');
							filter.addFilter({ field: 'tagId' }, filter.comparisonTypes.Equals, values);

							return FieldTranslation.find(filter.build());
						};
					},
					displayType: 'listLazy',
					type: 'list',
					search: true,
					searchFn: function (RequestBuilder) {
						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addFilter({ field: 'type' }, filter.comparisonTypes.Equals, 'salutation');
							filter.addSort({ field: 'value' }, true);

							if (term) {
								filter.addFilter({ field: 'value' }, filter.comparisonTypes.Search, term);
							}

							return FieldTranslation.find(filter.build());
						};
					},
					searchField: 'value',
					displayText: _.property('value'),
					title: 'default.salutation',
					multiComparitors: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				TitleList: {
					field: 'title',
					columnPath: 'title',
					type: 'list',
					displayType: 'lookUp',
					comparisonType: 'MatchExact',
					title: 'default.title',
					hide: true,
					showOnSegment: true
				},
				Campaign: {
					field: 'project.id',
					columnPath: 'campaigns',
					resource: 'Campaign',
					inputType: 'select',
					type: 'list',
					search: true,
					includeFields: ['name'],
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}

							return Campaign.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.campaigns',
					multiComparitors: true,
					showOnSegment: true
				},
				/*
					I dont think this is used anymore, as hide = true and showOnSegment = undefined,
					however I dont dare to remove it if some customer happens to have it in their saved views.
					maby we can check that in the database now that we have migrated from saving saved views in redis.
				*/
				Category: {
					field: 'category.id',
					inputType: 'select',
					dataPromise: function (AppService, $q) {
						var categories = AppService.getCategories('contact');
						return $q.when({ data: categories });
					},
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.categories',
					multiComparitors: true,
					multiple: true,
					type: 'list',
					columnPath: 'categories',
					hide: true
				},
				StandardCategory,
				...customContactCategoryFilters,
				HasVisit: {
					title: 'default.siteVisits',
					parent: 'default.market',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.with', value: true },
						{ text: 'default.without', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val === null) {
							return;
						} else if (val === true) {
							rb.addFilter({ field: 'visit.id' }, ComparisonTypes.NotEquals, null);
						} else {
							rb.addFilter({ field: 'visit.id' }, ComparisonTypes.Equals, null);
						}
					}
				},
				HasMail: {
					title: 'default.mailing',
					parent: 'default.market',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.with', value: true },
						{ text: 'default.without', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val === null) {
							return;
						} else if (val === true) {
							var group = rb.groupBuilder();
							group.addFilter({ field: 'mail.mailCampaign.id' }, ComparisonTypes.GreatherThan, 0);
							group.done();
						} else {
							var notGroup = rb.groupBuilder();
							notGroup.addFilter({ field: 'mail.mailCampaign.id' }, ComparisonTypes.GreatherThan, 0);
							notGroup.isNotFilter();
							notGroup.done();
						}
					}
				},
				HasFormSubmit: {
					title: 'event.types.Form',
					parent: 'default.market',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.with', value: true },
						{ text: 'default.without', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb) {
						var val = filter.value;

						if (val === null) {
							return;
						} else if (val === true) {
							rb.addFilter({ field: 'formSubmit.id' }, ComparisonTypes.NotEquals, null);
						} else {
							rb.addFilter({ field: 'formSubmit.id' }, ComparisonTypes.Equals, null);
						}
					}
				},
				NameClient: {
					field: 'client.name',
					entity: 'account',
					columnPath: 'name',
					inputType: 'text',
					type: 'text',
					title: 'default.nameText'
				},
				NameClientList: {
					field: 'client.name',
					inputType: 'list',
					type: 'list',
					displayType: 'text',
					comparisonType: 'Search',
					title: 'default.name',
					showOnSegment: true,
					entity: 'account',
					hide: true
				},
				Account: {
					field: 'client.id',
					columnPath: 'account',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					displayType: 'listLazy',
					includeFields: ['id', 'name'],
					search: true,
					searchFn: function (Account, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Account.attr.name, true);

							if (term) {
								filter.addFilter(Account.attr.name, filter.comparisonTypes.Search, term);
							}

							return Account.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.name',
					multiComparitors: true,
					entity: 'account'
				},
				AccountManager: {
					field: 'client.users.id',
					multiple: true,
					inputType: 'selectGroup',
					noParent: 'default.otherUsers',
					groupParent: 'role.id',
					groupParentTitle: 'role.name',
					dataPromise: function (UserTreeFilterMeta) {
						return UserTreeFilterMeta(AppService.AccessType.CONTACT);
					},
					type: 'list',
					searchField: 'name',
					displayText: _.property('name'),
					title: 'default.accountManagers',
					multiComparitors: true,
					entity: 'account'
				},
				AccountManagerSegment: {
					title: 'segment.accountManager',
					type: 'raw',
					displayType: 'list',
					hide: true,
					showOnSegment: true,
					entity: 'account',
					multiComparitors: true,
					generate: UserRoleFilter.generate,
					isInactive: UserRoleFilter.isInactive,
					toUrl: UserRoleFilter.toUrl,
					fromUrl: UserRoleFilter.fromUrl,
					build: UserRoleFilter.build({ fieldPrefix: 'client' }),
					dataPromise: function ($q) {
						var options = {
							accessType: Tools.AppService.AccessType.CONTACT,
							roleIdPrefix: 'role-'
						};

						return $q.when({ data: window.UserRoleTree(options) });
					}
				},
				CategoryClient,
				...customClientCategoryFilters,
				CampaignClient: {
					field: 'client.project.id',
					entity: 'account',
					inputType: 'select',
					resource: 'Campaign',
					multiple: true,
					search: true,
					includeFields: ['name'],
					searchFn: function (Campaign, AppService, RequestBuilder) {
						var cId = AppService.getCustomerId();

						return function (term, fields, offset, limit) {
							var filter = new RequestBuilder();
							filter.fields = fields;
							filter.offset = offset;
							filter.limit = limit;

							filter.addSort(Campaign.attr.name, true);

							if (term) {
								filter.addFilter(Campaign.attr.name, filter.comparisonTypes.Search, term);
							}

							return Campaign.customer(cId).find(filter.build());
						};
					},
					searchField: 'name',
					displayText: _.property('name'),
					type: 'list',
					displayType: 'listLazy',
					multiComparitors: true,
					title: 'default.campaigns',
					columnPath: 'campaigns',
					showOnSegment: true
				},
				ScoreClient: {
					title: 'default.score',
					field: 'client.score',
					entity: 'account',
					columnPath: 'score',
					type: 'range',
					inputType: 'range',
					parent: 'default.market',
					showOnSegment: true
				},
				ScoreUpdateDateClient: {
					title: 'default.scoreUpdate',
					field: 'client.scoreUpdateDate',
					entity: 'account',
					columnPath: 'scoreUpdateDate',
					inputType: 'date',
					type: 'dateRange',
					parent: 'default.market',
					showOnSegment: true,
					presets: dateFilterPresets
				},
				PhoneClient: {
					title: 'filters.columns.phoneAccount',
					field: 'client.phone',
					entity: 'account',
					columnPath: 'phone',
					inputType: 'text',
					type: 'text'
				},
				ParentClient: {
					field: 'client.parent.id',
					entity: 'account',
					columnPath: 'parent',
					inputType: 'select',
					multiple: true,
					resource: 'Account',
					type: 'list',
					displayType: 'listLazy',
					searchFn: function () {
						return function (term) {
							var customerId = Tools.AppService.getCustomerId();

							var rb = new Tools.RequestBuilder();

							rb.addFilter(Tools.Account.attr.parent.attr.name, ComparisonTypes.Search, term);
							var idBuilder = rb.aggregationBuilder();
							idBuilder.aggregationSize(1000);
							idBuilder.addAggregation(rb.aggregationTypes.Terms, Tools.Account.attr.parent.attr.id);
							var nameBuilder = idBuilder.aggregationBuilder();
							nameBuilder.addAggregation(rb.aggregationTypes.Terms, Tools.Account.attr.parent.attr.name);
							nameBuilder.done();
							idBuilder.done();

							return Tools.Report.customer(customerId)
								.setType(Tools.Report.type.CLIENT)
								.find(rb.build())
								.then(function (res) {
									var data = _.map(res.data.group_by_parent_id.buckets, function (item) {
										return {
											id: item.key,
											name: _.get(item, 'group_by_parent_name.buckets[0].key') || ''
										};
									});
									return { data: _.sortBy(data, 'name') };
								});
						};
					},
					searchField: 'name',
					displayText: 'name',
					search: true,
					title: 'default.parentCompany',
					multiComparitors: true,
					showOnSegment: true
				},
				WebpageClient: {
					field: 'client.webpage',
					entity: 'account',
					inputType: 'text',
					type: 'text',
					title: 'default.webpage',
					parent: 'filters.columns.contactinformation'
				},
				IsAssignedClient: {
					title: 'scoreboard.assigned',
					entity: 'account',
					field: 'client.assigned.user.id',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'scoreboard.assigned', value: null, comparisonType: 'NotEquals' },
						{ text: 'scoreboard.unassigned', value: null, comparisonType: 'Equals' }
					],
					parent: 'filters.columns.accountother'
				},
				AddressFiltersClient: {
					title: 'default.addresses',
					entity: 'account',
					type: 'raw',
					inputType: 'address',
					displayType: 'address',
					controller: 'AddressFilter',
					availableAddresses: ['Visit', 'Mail', 'Delivery', 'Invoice', 'Other'],
					view: 'upsales/common/components/listFilters/address.tpl.html',
					getText: AddressFilter.getText,
					generate: AddressFilter.generate,
					isInactive: AddressFilter.isInactive,
					toUrl: AddressFilter.toUrl,
					fromUrl: AddressFilter.fromUrl,
					build: AddressFilter.build({ fieldPrefix: 'client' }),
					parent: 'filters.columns.contactinformation'
				},
				Address: {
					field: 'client.address.address',
					inputType: 'list',
					type: 'list',
					displayType: 'text',
					comparisonType: 'Search',
					title: 'address.address',
					hide: true,
					showOnSegment: true,
					entity: 'account',
					parent: 'filters.columns.contactinformation'
				},
				ZipCode: {
					field: 'client.address.zipcode',
					type: 'raw',
					displayType: 'range',

					title: 'address.zip',
					hide: true,
					showOnSegment: true,
					parent: 'filters.columns.contactinformation',
					entity: 'account',
					generate: function () {
						return {
							inactive: true,
							value: { start: null, end: null }
						};
					},
					toUrl: function (filter) {
						var f = { v: {} };

						f.i = filter.inactive;
						f.s = filter.value.start;
						f.e = filter.value.end;

						return f;
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.inactive = rawFilter.i;
						filter.value = {
							start: rawFilter.s,
							end: rawFilter.e
						};
						return filter;
					},
					build: function (filter, rb) {
						if (filter.inactive) {
							return;
						}

						if (filter.value.start || filter.value.end) {
							var group = rb.groupBuilder();

							if (filter.value.start) {
								group.addFilter(
									{ field: 'client.address.zipcode' },
									ComparisonTypes.GreaterThanEquals,
									filter.value.start
								);
							}
							if (filter.value.end) {
								group.addFilter(
									{ field: 'client.address.zipcode' },
									ComparisonTypes.LessThanEquals,
									filter.value.end
								);
							}

							group.done();
						}
					}
				},
				Country: {
					field: 'client.address.country',
					type: 'list',
					dataPromise: function (CountryCodes, $translate) {
						var customerId = AppService.getCustomerId();
						var language = AppService.getSelf().language;

						return CountryCodes.customer(customerId)
							.find({})
							.then(function (res) {
								var mapped = _.cloneDeep(res.data).map(function (cc) {
									cc.name = $translate.instant(cc.lang);
									return cc;
								});

								if (language) {
									mapped.sort(sortAlphabetically('name', false, language));
								}

								return { data: mapped, metadata: { total: mapped.length } };
							});
					},
					title: 'address.country',
					hide: true,
					showOnSegment: true,
					parent: 'filters.columns.contactinformation',
					entity: 'account'
				},
				State: {
					field: 'client.address.state',
					searchField: 'addresses.attr.state',
					type: 'list',
					displayType: 'lookUp',
					comparisonType: 'MatchExact',
					title: 'address.state',
					hide: true,
					showOnSegment: true,
					parent: 'filters.columns.contactinformation',
					entity: 'account'
				},
				City: {
					field: 'client.address.city',
					searchField: 'addresses.attr.city',
					type: 'list',
					displayType: 'lookUp',
					comparisonType: 'MatchExact',
					title: 'address.city',
					hide: true,
					showOnSegment: true,
					parent: 'filters.columns.contactinformation',
					entity: 'account'
				},
				Municipality: {
					field: 'client.address.municipality',
					searchField: 'addresses.attr.municipality',
					type: 'list',
					displayType: 'lookUp',
					comparisonType: 'Match',
					title: 'address.municipality',
					hide: true,
					showOnSegment: FeatureHelper.hasSoftDeployAccess('ADDRESS_MUNICIPALITY'),
					parent: 'filters.columns.contactinformation',
					entity: 'account'
				},
				Score: {
					field: 'score',
					columnPath: 'score',
					title: 'default.score',
					type: 'range',
					inputType: 'range',
					parent: 'default.market',
					showOnSegment: true
				},
				ScoreUpdateDate: {
					field: 'scoreUpdateDate',
					columnPath: 'scoreUpdateDate',
					title: 'default.scoreUpdate',
					inputType: 'date',
					displayType: 'dateRange',
					type: 'raw',
					parent: 'default.market',
					showOnSegment: true,
					presets: dateFilterPresets,
					generate: function () {
						return { value: { start: null, end: null, preset: 'whenever' }, comparisonType: null };
					},
					build: function (filter, rb) {
						var val = filter.value;
						val = DatePreset.getDatesFromValue(val, true);

						if (val.start) {
							rb.addFilter({ field: 'scoreUpdateDate' }, ComparisonTypes.GreaterThanEquals, val.start);
						}
						if (val.end) {
							rb.addFilter({ field: 'scoreUpdateDate' }, ComparisonTypes.LessThanEquals, val.end);
						}
						rb.addFilter({ field: 'score' }, ComparisonTypes.GreaterThan, 0);
					}
				},
				MailBounced: {
					title: 'event.bounce',
					type: 'raw',
					displayType: 'listShortSingle',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'mailbounce.filter.didnt', value: false },
						{ text: 'mailbounce.filter.all', value: true },
						{ text: 'mailbounce.filter.hard', value: 'hard' },
						{ text: 'mailbounce.filter.soft', value: 'soft' }
					],
					generate: function () {
						return {
							inactive: true,
							value: false
						};
					},
					toUrl: function (filter) {
						var f = { v: {} };

						f.i = filter.inactive;
						f.v = filter.value;

						return f;
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.inactive = rawFilter.i;
						filter.value = rawFilter.v;

						return filter;
					},
					build: function (filter, rb, getField) {
						if (filter.inactive) {
							return;
						}

						let value = ['hard_bounce', 'soft_bounce'];
						let ct = ComparisonTypes.NotTerm;
						const field = getField('mailBounces');

						if (filter.value === true) {
							ct = ComparisonTypes.Term;
						} else if (filter.value === 'soft') {
							value = ['soft_bounce'];
							ct = ComparisonTypes.Term;
							rb.addFilter(field, ComparisonTypes.NotTerm, ['hard_bounce']);
						} else if (filter.value === 'hard') {
							value = ['hard_bounce'];
							ct = ComparisonTypes.Term;
						} // else = false = no bounce

						rb.addFilter(field, ct, value);
					},
					parent: 'default.market',
					showOnSegment: true
				},
				Phone: {
					field: 'phone',
					columnPath: 'phone',
					inputType: 'text',
					type: 'text',
					title: 'default.phone',
					parent: 'filters.columns.contact'
				},
				CellPhone: {
					field: 'cellPhone',
					columnPath: 'cellPhone',
					inputType: 'text',
					type: 'text',
					title: 'default.cellPhone',
					parent: 'filters.columns.contact'
				},
				Email: {
					parent: 'filters.columns.contact',
					field: 'email',
					type: 'raw',
					displayType: 'radioSearch',
					title: 'default.email',

					options: [
						{ text: 'default.any', value: null, inactive: true },
						{ text: 'default.has', value: true },
						{ text: 'default.hasNot', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.value = rawFilter.v;
						return filter;
					},
					build: function (filter, rb) {
						if (typeof filter.value === 'string') {
							rb.addFilter({ field: 'email' }, ComparisonTypes.Search, filter.value);
						} else {
							if (filter.value === true) {
								rb.addFilter({ field: 'email' }, ComparisonTypes.NotEquals, null);
							} else if (filter.value === false) {
								const or = rb.orBuilder();
								or.next();
								or.addFilter({ field: 'email' }, ComparisonTypes.Equals, null);
								or.next();
								or.addFilter({ field: 'email' }, ComparisonTypes.Equals, '');
								or.done();
							}
						}
					}
				},
				EmailList: {
					field: 'email',
					inputType: 'list',
					type: 'list',
					displayType: 'text',
					comparisonType: 'Search',
					title: 'default.email',
					showOnSegment: true,
					hide: true
				},
				Active: {
					field: 'active',
					listTitle: 'default.activeInactive',
					title: 'filters.contactStatus',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.activeContacts', value: true, comparisonType: 'Equals' },
						{ text: 'default.inactiveContacts', value: false, comparisonType: 'Equals' }
					],
					parent: 'filters.columns.contact',
					showOnSegment: true
				},
				Notes: {
					field: 'notes',
					inputType: 'text',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						if (filter.value) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter(getField('comment.description'), ComparisonTypes.Search, filter.value);

							orBuilder.next();
							orBuilder.addFilter(getField('notes'), ComparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					},
					title: 'default.notes',
					search: true,
					displayType: 'text'
				},
				FromImport: {
					field: 'importId',
					multiple: true,
					inputType: 'select',
					type: 'list',
					dataPromise: function (Import) {
						return Import.find({ status: Import.status.FINISHED, limit: 500, f: ['id', 'file'] });
					},
					searchField: 'file.filename',
					displayText: function (imprt) {
						return imprt.file ? imprt.file.filename : '';
					},
					title: 'import.import',
					multiComparitors: true,
					parent: 'filters.columns.contact'
				},
				RegDate: {
					field: 'regDate',
					title: 'default.created',
					inputType: 'date',
					type: 'dateRange',
					parent: 'filters.columns.contact',
					presets: [
						'whenever',
						'currentDay',
						'lastDay',
						'currentWeek',
						'lastWeek',
						'currentMonth',
						'lastMonth',
						'currentQuarter',
						'lastQuarter',
						'currentYear',
						'lastYear',
						'last7days',
						'last14days',
						'last30days',
						'custom'
					]
				},
				CreatedAt: {
					field: 'regDate',
					title: 'default.created',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					hide: true,
					showOnSegment: true,
					generate: function () {
						return { value: { start: null, end: null, preset: 'whenever' }, comparisonType: null };
					},
					build: function (filter, rb) {
						var val = filter.value;
						val = DatePreset.getDatesFromValue(val, true);

						if (val.start) {
							rb.addFilter({ field: 'regDate' }, ComparisonTypes.GreaterThanEquals, val.start);
						}
						if (val.end) {
							rb.addFilter({ field: 'regDate' }, ComparisonTypes.LessThanEquals, val.end);
						}
					}
				},
				CreatedAtClient: {
					title: 'default.created',
					field: 'client.regDate',
					entity: 'account',
					columnPath: 'regDate',
					showOnSegment: true,
					hide: true,
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					generate: function () {
						return { value: { start: null, end: null, preset: 'whenever' }, comparisonType: null };
					},
					build: function (filter, rb) {
						var val = filter.value;
						val = DatePreset.getDatesFromValue(val, true);

						if (val.start) {
							rb.addFilter({ field: 'regDate' }, ComparisonTypes.GreaterThanEquals, val.start);
						}
						if (val.end) {
							rb.addFilter({ field: 'regDate' }, ComparisonTypes.LessThanEquals, val.end);
						}
					}
				},
				OptIn: {
					field: 'optIn.id',
					multiple: true,
					inputType: 'select',
					type: 'list',
					dataPromise: function (OptIn) {
						return OptIn.find({});
					},
					searchField: 'title',
					displayText: function (optIn) {
						return optIn.title;
					},
					title: 'default.optIns',
					multiComparitors: true,
					parent: 'default.market',
					showOnSegment: true
				},
				Unsubscribed: {
					field: 'unsubscribed',
					columnPath: 'unsubscribed',
					title: 'mail.unsubscribedGroupMail',
					type: 'radio',
					inputType: 'radio',
					options: [
						{ text: 'default.all', inactive: true },
						{ text: 'default.yes', value: null, comparisonType: 'NotEquals' },
						{ text: 'default.no', value: null, comparisonType: 'Equals' }
					],
					parent: 'default.market',
					showOnSegment: true
				},
				EmailMissingAt: {
					title: 'default.email',
					type: 'raw',
					inputType: 'text',
					hide: true,
					generate: function () {
						return {
							value: {
								Email: { value: '' },
								MissingAt: false
							}
						};
					},
					build: function (filter, rb, getField) {
						var val = filter.value;

						if (val.MissingAt) {
							// var group = rb.groupBuilder();
							rb.addFilter(getField('email'), ComparisonTypes.NotWildcard, '@');
							// group.isNotFilter();
							// group.done();
						} else if (val.Email.value && val.Email.value.length) {
							rb.addFilter(getField('email'), ComparisonTypes.Wildcard, val.Email.value);
						}
					}
				},
				MailCampaignFilters: {
					title: 'default.mailing',
					type: 'raw',
					hide: true,
					displayType: 'mailCampaign',
					generate: MailCampaignFilter.generate,
					isInactive: MailCampaignFilter.isInactive,
					toUrl: MailCampaignFilter.toUrl,
					fromUrl: MailCampaignFilter.fromUrl,
					build: MailCampaignFilter.build(),
					datePresets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'lastXdays',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month'
					],
					controller: '',
					view: '',
					showOnSegment: true,
					parent: 'default.market'
				},
				VisitorFilters: {
					title: 'default.siteVisits',
					type: 'raw',
					hide: true,
					displayType: 'visit',
					generate: VisitorFilter.generate,
					isInactive: VisitorFilter.isInactive,
					toUrl: VisitorFilter.toUrl,
					fromUrl: VisitorFilter.fromUrl,
					build: VisitorFilter.build(),
					datePresets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'lastXdays',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month'
					],
					controller: '',
					view: '',
					showOnSegment: true,
					parent: 'default.market'
				},
				FormSubmitFilters: {
					title: 'default.formFormSubmit',
					type: 'raw',
					hide: true,
					displayType: 'formSubmit',
					generate: FormSubmitFilter.generate,
					isInactive: FormSubmitFilter.isInactive,
					toUrl: FormSubmitFilter.toUrl,
					fromUrl: FormSubmitFilter.fromUrl,
					build: FormSubmitFilter.build(),
					datePresets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'lastXdays',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month'
					],
					controller: '',
					view: '',
					showOnSegment: true,
					parent: 'default.market'
				},
				AccountManagerFilters: {
					title: 'filter.accountManagerFilter',
					type: 'raw',
					hide: true,
					controller: '',
					view: '',
					generate: AccountManagerFilter.generate,
					isInactive: AccountManagerFilter.isInactive,
					toUrl: AccountManagerFilter.toUrl,
					fromUrl: AccountManagerFilter.fromUrl,
					build: AccountManagerFilter.build()
				},
				ListSearch: {
					filterName: 'ListSearch',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						return {
							value: rawFilter.v
						};
					},
					build: function (filter, rb) {
						if (filter.value && filter.value.length) {
							var orBuilder = rb.orBuilder();

							orBuilder.next();
							orBuilder.addFilter({ field: 'client.name' }, ComparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'name' }, ComparisonTypes.Wildcard, filter.value);

							orBuilder.next();
							orBuilder.addFilter({ field: 'email' }, ComparisonTypes.Wildcard, filter.value);

							orBuilder.done();
						}
					}
				},
				// What is the difference between all of these? Id, ExcludeId, IncludeId
				Id: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'number',
					type: 'idList',
					hide: true
				},
				ExcludeId: {
					title: 'default.excludedContacts',
					field: 'id',
					columnPath: 'id',
					inputType: 'number',
					type: 'idList',
					isExcludeFilter: true,
					hide: true,
					noExpand: true
				},
				IncludeId: {
					title: 'default.id',
					field: 'id',
					columnPath: 'id',
					inputType: 'number',
					type: 'idList',
					isExcludeFilter: true,
					excludeType: 'Equals',
					hide: true
				},
				HasEmail: {
					title: 'filters.hasEmail',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.yes', value: true },
						{ text: 'default.no', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.value = rawFilter.v;
						return filter;
					},
					build: function (filter, rb) {
						if (filter.value === true) {
							rb.addFilter({ field: 'email' }, ComparisonTypes.NotEquals, null);
						} else if (filter.value === false) {
							const or = rb.orBuilder();
							or.next();
							or.addFilter({ field: 'email' }, ComparisonTypes.Equals, null);
							or.next();
							or.addFilter({ field: 'email' }, ComparisonTypes.Equals, '');
							or.done();
						}
					},
					hide: true,
					showOnSegment: true
				},
				MissingEmail: {
					title: 'mail.missingEmail',
					filterName: 'MissingEmail',
					type: 'raw',
					inputType: 'radio',
					displayType: 'radio',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'default.yes', value: true },
						{ text: 'default.no', value: false }
					],
					generate: function () {
						return {
							value: null
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.value = rawFilter.v;
						return filter;
					},
					build: function (filter, rb) {
						if (filter.value === true) {
							rb.addFilter({ field: 'email' }, ComparisonTypes.Equals, null);
						} else if (filter.value === false) {
							rb.addFilter({ field: 'email' }, ComparisonTypes.NotEquals, null);
						}
					},
					hide: true
				},
				Segment: {
					field: 'segment.id',
					columnPath: 'segments',
					resource: 'Segment',
					displayType: 'list',
					type: 'raw',
					search: true,
					searchFn: function (Segment, RequestBuilder) {
						return function (term) {
							var filter = new RequestBuilder();
							filter.addFilter(Segment.attr.name, filter.comparisonTypes.Search, term);
							return Segment.find(filter.build());
						};
					},
					searchField: 'name',
					generate: function () {
						return {
							value: [],
							comparisonType: 'Equals'
						};
					},
					isInactive: function (filter) {
						return !filter || filter.inactive || !filter.value || !filter.value.length;
					},
					toUrl: function (filter) {
						return { v: filter.value, c: filter.comparisonType };
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.value = rawFilter.v;

						if (rawFilter.c) {
							filter.comparisonType = rawFilter.c;
						}

						return filter;
					},
					build: function (filter, rb, getFieldWithPrefix) {
						if (!filter.inactive && filter.value && filter.value.length) {
							rb.addFilter(
								getFieldWithPrefix('segment.id'),
								ComparisonTypes[filter.comparisonType],
								filter.value
							);
							// We want to be able to remove this. And I think it is safe, but put it behind a feature flag so we can test it.
							if (!Tools.FeatureHelper.hasSoftDeployAccess('SEGMENT_FILTER_NO_EXTRA_PARAMS')) {
								rb.addExtraParam('customerId', AppService.getCustomerId());
								rb.addExtraParam('userId', AppService.getSelf().id);
							}
						}
					},
					displayText: _.property('name'),
					title: 'segment.segments',
					multiComparitors: true,
					multiple: true,
					showOnSegment: true
				},
				SocialEvent: new SocialEventFilter({
					parent: 'default.market'
				}),
				EmailMarketStatus: {
					title: 'contact.emailMarketStatus',
					filterName: 'EmailMarketStatus',
					type: 'raw',
					hide: true,
					displayType: 'listShort',
					options: [
						{ text: 'default.all', value: null, inactive: true },
						{ text: 'contact.optIn', value: 'optIn' },
						{ text: 'contact.nonOptIn', value: 'nonOptIn' },
						{ text: 'event.bounce', value: 'hardbounce' },
						{ text: 'groupMail.unSubscribed', value: 'unsub' }
					],
					generate: function () {
						return {
							value: []
						};
					},
					toUrl: function (filter) {
						return { v: filter.value };
					},
					fromUrl: function (rawFilter) {
						var filter = this.generate();
						filter.value = rawFilter.v;
						return filter;
					},
					build: function (filter, rb) {
						if (!filter.value.length) {
							return;
						}
						var orBuilder = rb.orBuilder();

						if (filter.value.indexOf('optIn') !== -1) {
							orBuilder.next();
							orBuilder.addFilter({ field: 'optIn' }, ComparisonTypes.NotEquals, null);
						}
						if (filter.value.indexOf('nonOptIn') !== -1) {
							orBuilder.next();
							orBuilder.addFilter({ field: 'optIn' }, ComparisonTypes.Equals, null);
						}
						if (filter.value.indexOf('unsub') !== -1) {
							orBuilder.next();
							orBuilder.addFilter({ field: 'unsubscribed' }, ComparisonTypes.NotEquals, null);
						}
						if (filter.value.indexOf('hardbounce') !== -1) {
							orBuilder.next();
							orBuilder.addFilter({ field: 'mail.errorCause' }, ComparisonTypes.Term, 'hard_bounce');
						}
						orBuilder.done();
					},
					parent: 'default.market'
				},
				ClientHistoryFilter: {
					title: 'default.history',
					hide: true,
					entity: 'account',
					displayType: 'history',
					combinationFilter: {
						ClientOrderFilters: {
							icon: 'fa fa-usd'
						},
						ClientActivityFilters: {
							icon: 'up-icon-activity'
						},
						OpportunityFilters: {
							icon: 'up-icon-pipeline'
						}
					}
				},
				ContactHistoryFilter: {
					title: 'default.history',
					entity: 'contact',
					displayType: 'history',
					hide: true,
					combinationFilter: {
						ContactOrderFilters: {
							icon: 'fa fa-usd'
						},
						ContactOnlyActivityFilters: {
							icon: 'up-icon-activity'
						},
						ContactOpportunityFilters: {
							icon: 'up-icon-pipeline'
						}
					}
				},
				ContactOrderFilters: {
					title: 'default.sales',
					type: 'raw',
					displayType: 'order',
					hide: true,
					entity: 'contact',
					generate: OrderFilter.generate,
					isInactive: OrderFilter.isInactive,
					toUrl: OrderFilter.toUrl,
					fromUrl: OrderFilter.fromUrl,
					build: OrderFilter.build({ mixedProductsAndCategories: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'lastXdays',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month',
						'custom'
					],
					showOnSegment: false,
					parent: 'advancedSearch.contactHistory'
				},
				ClientOrderFilters: {
					title: 'default.sales',
					type: 'raw',
					displayType: 'HistoryFilter',
					parent: 'filter.saleHistory',
					entity: 'account',
					getText: OrderFilter.getText,
					generate: OrderFilter.generate,
					isInactive: OrderFilter.isInactive,
					toUrl: OrderFilter.toUrl,
					fromUrl: OrderFilter.fromUrl,
					build: OrderFilter.build({ fieldPrefix: 'client', mixedProductsAndCategories: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'lastXdays',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month',
						'custom'
					],
					showOnSegment: true
				},
				ContactAgreementFilters: {
					title: 'default.agreement',
					type: 'raw',
					displayType: 'agreement',
					hide: true,
					entity: 'contact',
					generate: AgreementFilter.generate,
					isInactive: AgreementFilter.isInactive,
					toUrl: AgreementFilter.toUrl,
					fromUrl: AgreementFilter.fromUrl,
					build: AgreementFilter.build({ mixedProductsAndCategories: true }),
					presets: agreementDateFilterPresets,
					showOnSegment: false,
					parent: 'advancedSearch.contactHistory'
				},
				ClientAgreementFilters: {
					title: 'default.agreement',
					type: 'raw',
					displayType: 'agreement',
					hide: true,
					entity: 'account',
					generate: AgreementFilter.generate,
					isInactive: AgreementFilter.isInactive,
					toUrl: AgreementFilter.toUrl,
					fromUrl: AgreementFilter.fromUrl,
					build: AgreementFilter.build({ fieldPrefix: 'client', mixedProductsAndCategories: true }),
					presets: agreementDateFilterPresets,
					showOnSegment: true,
					parent: 'filter.saleHistory'
				},
				HasTodo: {
					title: 'default.todo',
					parent: 'filter.contactHistory',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'contact',
					getText: TaskFilter.getText,
					generate: TaskFilter.generate,
					isInactive: TaskFilter.isInactive,
					toUrl: TaskFilter.toUrl,
					fromUrl: TaskFilter.fromUrl,
					build: TaskFilter.build({ phonecall: false }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				HasPhonecall: {
					title: 'default.phonecall',
					parent: 'filter.contactHistory',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'contact',
					getText: TaskFilter.getText,
					generate: TaskFilter.generate,
					isInactive: TaskFilter.isInactive,
					toUrl: TaskFilter.toUrl,
					fromUrl: TaskFilter.fromUrl,
					build: TaskFilter.build({ phonecall: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				ClientTodoFilters: {
					title: 'default.todo',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: TaskFilter.getText,
					generate: TaskFilter.generate,
					isInactive: TaskFilter.isInactive,
					toUrl: TaskFilter.toUrl,
					fromUrl: TaskFilter.fromUrl,
					build: TaskFilter.build({ phonecall: false, fieldPrefix: 'client' }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				ClientPhonecallFilters: {
					title: 'default.phonecall',
					parent: 'filter.sale',
					type: 'raw',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: TaskFilter.getText,
					generate: TaskFilter.generate,
					isInactive: TaskFilter.isInactive,
					toUrl: TaskFilter.toUrl,
					fromUrl: TaskFilter.fromUrl,
					build: TaskFilter.build({ phonecall: true, fieldPrefix: 'client' }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					]
				},
				OpportunityFilters: {
					title: 'default.opportunity',
					type: 'raw',
					displayType: 'HistoryFilter',
					parent: 'filter.saleHistory',
					entity: 'account',
					getText: OpportunityFilter.getText,
					generate: OpportunityFilter.generate,
					isInactive: OpportunityFilter.isInactive,
					toUrl: OpportunityFilter.toUrl,
					fromUrl: OpportunityFilter.fromUrl,
					build: OpportunityFilter.build({ fieldPrefix: 'client', mixedProductsAndCategories: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'lastXdays',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month',
						'custom'
					],
					showOnSegment: true
				},
				ContactOpportunityFilters: {
					title: 'default.opportunity',
					type: 'raw',
					displayType: 'opportunity',
					hide: true,
					entity: 'contact',
					generate: OpportunityFilter.generate,
					isInactive: OpportunityFilter.isInactive,
					toUrl: OpportunityFilter.toUrl,
					fromUrl: OpportunityFilter.fromUrl,
					build: OpportunityFilter.build({ mixedProductsAndCategories: true }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last45days',
						'last90days',
						'lastXdays',
						'prev6Month',
						'prev12Month',
						'prev18Month',
						'prev24Month',
						'custom'
					],
					showOnSegment: false,
					parent: 'advancedSearch.contactHistory'
				},
				Appointment: generateAppointmentFilter({}),
				ClientAppointment: generateAppointmentFilter(
					{ fieldPrefix: 'client' },
					{ filterName: 'ClientAppointment', entity: 'account', parent: 'filter.saleHistory' }
				),
				ClientActivityFilters: {
					title: 'default.activitiesAndAppointments.short',
					type: 'raw',
					parent: 'filter.saleHistory',
					displayType: 'HistoryFilter',
					entity: 'account',
					getText: ActivityFilter.getText,
					generate: ActivityFilter.generate,
					isInactive: ActivityFilter.isInactive,
					toUrl: ActivityFilter.toUrl,
					fromUrl: ActivityFilter.fromUrl,
					build: ActivityFilter.build({ fieldPrefix: 'client' }),
					presets: [
						'whenever',
						'last7days',
						'last14days',
						'last30days',
						'last90days',
						'lastXdays',
						'next7days',
						'next30days',
						'next45days',
						'next90days',
						'custom'
					],
					showOnSegment: true
				},
				ContactOnlyActivityFilters: generateActivityFilter({}, { filterName: 'ContactOnlyActivityFilters' }),
				OrgNo: {
					field: 'client.orgNo',
					inputType: 'list',
					type: 'list',
					displayType: 'text',
					comparisonType: 'Search',
					title: 'default.orgNumber',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				Status: {
					title: 'default.status',
					field: 'client.status',
					type: 'list',
					inputType: 'select',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('status').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'text',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				CompanyForm: {
					title: 'soliditet.corporateForm',
					field: 'client.companyForm',
					type: 'list',
					inputType: 'select',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('companyForm').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'text',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				CreditRating: {
					title: 'default.creditRating',
					field: 'client.creditRating',
					type: 'list',
					inputType: 'select',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('creditRating').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'text',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				Turnover: {
					title: 'default.turnover',
					field: 'client.turnover',
					type: 'range',
					inputType: 'range',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				Profit: {
					title: 'default.profit',
					field: 'client.profit',
					type: 'range',
					inputType: 'range',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				NoEmployees: {
					title: 'default.numOfEmployees',
					field: 'client.noEmployees',
					type: 'range',
					inputType: 'range',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				RegistrationDate: {
					title: 'default.founded',
					field: 'client.registrationDate',
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					entity: 'account',
					showOnSegment: true,
					hide: true,
					unreleasedFeature: 'NEW_FIELDS'
				},
				SniCode: {
					field: 'client.sniCode',
					type: 'list',
					title: 'default.companyBranch.sni',
					displayType: 'listLazy',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('sniCode').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'name',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				SicCode: {
					field: 'client.sicCode',
					type: 'list',
					title: 'default.companyBranch.sic',
					displayType: 'listLazy',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('sicCode').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'name',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				NaicsCode: {
					field: 'client.naicsCode',
					type: 'list',
					title: 'default.companyBranch.naics',
					displayType: 'listLazy',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('naicsCode').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'name',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				UKSicCode: {
					field: 'client.ukSicCode',
					type: 'list',
					title: 'default.companyBranch.uksic',
					displayType: 'listLazy',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('ukSicCode').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'name',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				NaceCode: {
					field: 'client.naceCode',
					type: 'list',
					title: 'default.companyBranch.nace',
					displayType: 'listLazy',
					dataPromise: function () {
						return Tools.AppService.getStaticValuesPromise('naceCode').then(function (options) {
							return { data: options };
						});
					},
					searchField: 'name',
					entity: 'account',
					showOnSegment: true,
					hide: true,
					comparisonType: 'MatchExact',
					unreleasedFeature: 'NEW_FIELDS'
				},
				UnsubscribedList: {
					field: 'optOut.id',
					columnPath: 'optOut.id',
					title: 'mail.unsubscribedFromList',
					multiple: true,
					multiComparitors: true,
					inputType: 'select',
					type: 'list',
					dataPromise: function () {
						return Tools.GroupMailCategory.find({});
					},
					searchField: 'title',
					displayText: 'title',
					parent: 'default.market',
					showOnSegment: true
				},
				MailCampaignStatus: {
					// SPECIAL
					hide: true,
					title: 'default.mailing',
					parent: 'default.market',
					type: 'raw',
					generate: function () {
						return {
							value: null
						};
					},
					build: function (filter, rb, getField) {
						var val = filter.value;
						var group = rb.groupBuilder();

						group.addFilter(getField('mail.mailCampaign.id'), ComparisonTypes.Equals, val.mailCampaignId);

						if (val.deliveredGroup) {
							if (val.open) {
								group.addFilter(getField('mail.lastReadDate'), ComparisonTypes.NotEquals, null);
							}
							if (val.click) {
								group.addFilter(getField('mail.lastClickDate'), ComparisonTypes.NotEquals, null);
							}
							if (val.submit) {
								group.addFilter(
									getField('contact.formSubmit.leadSource.numvalue'),
									ComparisonTypes.Equals,
									val.mailCampaignId
								);
							}
							if (val.unsub) {
								group.addFilter(getField('mail.event.type'), ComparisonTypes.Equals, 'unsub');
							}

							group.addFilter(getField('mail.errorCause'), ComparisonTypes.Equals, null);
						} else {
							var errors = [];
							if (val.bounce) {
								errors.push('hard_bounce');
								errors.push('soft_bounce');
							}
							if (val.prevbounce) {
								errors.push('previous_bounce');
							}

							if (val.prevunsub) {
								errors.push('previous_unsubscribed');
							}
							if (val.duplicates) {
								errors.push('duplicate_email');
							}
							if (val.invalidemail) {
								errors.push('invalid_email');
							}

							if (errors.length) {
								group.addFilter(getField('mail.errorCause'), ComparisonTypes.MatchExact, errors);
							} else {
								group.addFilter(getField('mail.errorCause'), ComparisonTypes.NotEquals, null);
							}
						}

						if (val.mailId && val.mailId.length) {
							group.addFilter(getField('mail.id'), ComparisonTypes.Equals, val.mailId);
						}

						group.done();
					}
				},
				JourneyStep: {
					type: 'list',
					filterName: 'JourneyStep',
					field: 'journeyStep',
					title: 'default.journeyStep',
					hide: true,
					dataPromise: function () {
						return $q.when({
							data: _.map(Tools.AppService.getJourneySteps(), function (j) {
								j.id = j.value;
								return j;
							})
						});
					},
					displayText: function (item) {
						return Tools.$translate(item.name);
					},
					displayComponent: function (item) {
						return renderJourneyStepFilterRow(item);
					},
					showOnSegment: true
				},
				ClientJourneyStep: {
					type: 'list',
					filterName: 'JourneyStep',
					field: 'client.journeyStep',
					title: 'default.journeyStep',
					entity: 'account',
					dataPromise: function () {
						return $q.when({
							data: _.map(Tools.AppService.getJourneySteps(), function (j) {
								j.id = j.value;
								return j;
							})
						});
					},
					displayText: function (item) {
						return Tools.$translate(item.name);
					},
					displayComponent: function (item) {
						return renderJourneyStepFilterRow(item);
					},
					showOnSegment: true
				},
				JourneyStepChangedDate: {
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					filterName: 'JourneyStepdChangeDate',
					field: 'journeyStepChangedDate',
					title: 'default.journeyStepChangedDate',
					entity: 'contact',
					showOnSegment: true,
					hide: true
				},
				ClientJourneyStepChangedDate: {
					inputType: 'date',
					type: 'dateRange',
					presets: dateFilterPresets,
					filterName: 'ClientJourneyStepdChangeDate',
					field: 'client.journeyStepChangedDate',
					title: 'default.journeyStepChangedDate',
					entity: 'account',
					showOnSegment: true,
					hide: true
				}
			};

			if (AppService.loaded) {
				if (!FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
					standardFilters.HasPhonecall.hide = true;
					standardFilters.HasTodo.hide = true;
					standardFilters.ClientPhonecallFilters.hide = true;
					standardFilters.ClientTodoFilters.hide = true;
				}

				if (FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
					standardFilters.ClientActivityFilters.hide = true;
				}

				var hasGroupMail = FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL);
				var hasOptIn = FeatureHelper.isAvailable(FeatureHelper.Feature.OPT_IN);
				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.LEADS)) {
					standardFilters.ScoreUpdateDate.hide = true;
					standardFilters.Score.hide = true;
				}

				// If missing both optin and group mail = hide filter
				if (!(hasOptIn && hasGroupMail)) {
					standardFilters.EmailMarketStatus.hide = true;
				}

				if (!hasGroupMail) {
					standardFilters.Unsubscribed.hide = true;
					standardFilters.MailBounced.hide = true;
					standardFilters.HasMail.hide = true;
				}

				if (!hasOptIn) {
					standardFilters.OptIn.hide = true;
				}
				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.SOCIAL_EVENTS)) {
					standardFilters.SocialEvent.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.VISITS)) {
					standardFilters.HasVisit.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.FORMS)) {
					standardFilters.HasFormSubmit.hide = true;
				}

				if (!FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL) && !hasGroupMail) {
					standardFilters.Unsubscribed.hide = true;
					standardFilters.UnsubscribedList.hide = true;
				}

				if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
					standardFilters.JourneyStep.hide = false;
					standardFilters.ClientJourneyStep.hide = false;
					attributes.journeyStep.selectableColumn = true;
				}

				if (FeatureHelper.hasSoftDeployAccess('BOUNCE_REASONS')) {
					attributes.emailBounceReason.selectableColumn = true;
				}

				if (FeatureHelper.hasSoftDeployAccess('RETURN_RESULT_FROM_ES6')) {
					standardFilters.ContactHistoryFilter.hide = false;
					standardFilters.ContactOpportunityFilters.showOnSegment = true;
					standardFilters.ContactOrderFilters.showOnSegment = true;
					standardFilters.ContactAgreementFilters.showOnSegment = true;
					standardFilters.ContactOnlyActivityFilters.showOnSegment = true;
				}

				if (FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
					standardFilters.ContactToDo = generateActivityFilter(
						{ fixedActivityType: Tools.AppService.getTodoTypes().TODO?.id },
						{ filterName: 'ContactToDo', title: 'default.todos' }
					);
					standardFilters.ContactPhoneCall = generateActivityFilter(
						{ fixedActivityType: Tools.AppService.getTodoTypes().PHONE_CALL?.id },
						{ filterName: 'ContactPhoneCall', title: 'default.phonecall' }
					);
					standardFilters.ClientToDo = generateActivityFilter(
						{ fieldPrefix: 'client', fixedActivityType: Tools.AppService.getTodoTypes().TODO?.id },
						{
							entity: 'account',
							filterName: 'ClientToDo',
							title: 'default.todos',
							parent: 'filter.saleHistory'
						}
					);
					standardFilters.ClientPhoneCall = generateActivityFilter(
						{ fieldPrefix: 'client', fixedActivityType: Tools.AppService.getTodoTypes().PHONE_CALL?.id },
						{
							entity: 'account',
							filterName: 'ClientPhoneCall',
							title: 'default.phonecall',
							parent: 'filter.saleHistory'
						}
					);
				} else {
					standardFilters.ClientActivity = generateActivityFilter(
						{ fieldPrefix: 'client' },
						{ parent: 'filter.saleHistory', entity: 'account', filterName: 'ClientActivity' }
					);
				}

				if (
					AppService.getMetadata().params.brokenFiscalYearEnabled &&
					FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR)
				) {
					insertAfter(dateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(dateFilterPresets, 'currentYear', 'currentFiscalYear');

					insertAfter(agreementDateFilterPresets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(agreementDateFilterPresets, 'lastYear', 'lastFiscalYear');
					insertAfter(agreementDateFilterPresets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(agreementDateFilterPresets, 'currentYear', 'currentFiscalYear');
					insertAfter(agreementDateFilterPresets, 'nextQuarter', 'nextFiscalQuarter');
					insertAfter(agreementDateFilterPresets, 'nextYear', 'nextFiscalYear');

					insertAfter(standardFilters.RegDate.presets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(standardFilters.RegDate.presets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(standardFilters.RegDate.presets, 'currentYear', 'currentFiscalYear');
					insertAfter(standardFilters.RegDate.presets, 'lastYear', 'lastFiscalYear');
				}

				if (FeatureHelper.hasSoftDeployAccess('CONTACT_USER_SEARCH_ATTR')) {
					attributes.account.attr.users.field = 'users.id';
					attributes.clientText.attr.users.field = 'users.id';
					standardFilters.AccountManager.field = 'users.id';
				}
			}

			var requiredFields = ['custom'];
			return {
				getAll: function () {
					return attributes;
				},
				attr: attributes,
				standardFilters: standardFilters,
				requiredFields: requiredFields,
				relatedEntities: ['account'],
				keys: {
					ID: 'id',
					ACCOUNT: 'account',
					NAME: 'name',
					CAMPAIGNS: 'campaigns',
					TITLE: 'title',
					PHONE: 'phone',
					CELLPHONE: 'cellPhone',
					EMAIL: 'email',
					NOTES: 'notes',
					ACTIVE: 'active',
					CUSTOM: 'custom'
				}
			};
		};
	}
]);
