import { Card, CardHeader, Label, Select } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import React, { useState } from 'react';

export type SelectOption = {
	id: number;
	title: string;
};

const getInitialOption = (): SelectOption => {
	const sessionTimeValue = Tools.AppService.getMetadata().params.sessionTime;
	const hours = sessionTimeValue / 60;
	return {
		id: hours,
		title: `${hours} ${hours === 1 ? T('hour') : T('hours')}`
	};
};

const AccountSettingSessionTimeoutCard = () => {
	const [selectedOption, setSelectedOption] = useState<SelectOption>(getInitialOption());
	const classes = new Bem('SessionTimeout');
	const selectOptions = Array.from({ length: 10 }, (_, i) => ({
		id: i + 1,
		title: `${i + 1} ${i + 1 === 1 ? T('hour') : T('hours')}`
	}));

	const handleSelectChange = async (newOption: SelectOption) => {
		const storingValue = newOption.id * 60;

		try {
			await Tools.ClientParam.save(18, storingValue);
			const meta = Tools.AppService.getMetadata();
			meta.params['sessionTime'] = storingValue;
			Tools.AppService.setMetadata(meta);
			setSelectedOption(newOption);
		} catch (e) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'error',
				title: 'default.error',
				body: 'admin.sessionTimeout.errors.failedToUpdate'
			});
			logError(e, 'Failed to update session timeout settings');
		}
	};

	return (
		<div className={`admin-table ${classes.b()}`}>
			<Card>
				<CardHeader title={T('admin.sessionTimeout.title')} />
				<div className="admin-info-row">
					<div className="info-row-info">{T('admin.sessionTimeout.info')}</div>
					<div className="info-row-content">
						<Label>{T('admin.sessionTimeout.label')}</Label>
						<Select
							anchor={document.querySelector('#admin-content')}
							placeholder={T('admin.sessionTimeout.placeholder')}
							options={selectOptions}
							value={selectedOption}
							onChange={handleSelectChange}
						/>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default AccountSettingSessionTimeoutCard;
