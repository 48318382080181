import { useSoftDeployAccess } from 'App/components/hooks/featureHelper';
import { useFeatureAvailable } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

export default function IsAllowed() {
	const hasFeature = useSoftDeployAccess('GROUP_BONANZA');
	const hasAccess = useFeatureAvailable(Feature.COMPANY_GROUP_CARD);

	return hasFeature && hasAccess;
}
