import _ from 'lodash';
import { ListView, ReportView } from '../AllIWant';
import UserDefinedObjectType from '../Model/UserDefinedObjectType';
import { CategoryType } from '../Model/Category';
import CustomField from '../Model/CustomField';
import T from 'Components/Helpers/translate';

export default function (listView: ListView) {
	if (listView.columns) {
		listView.columns = listView.columns.reduce<string[]>((res, name) => {
			if (name === 'addresses') {
				res.push('Address_Visit_city');
				return res;
			}

			if (listView.type === 'account' && name === 'eventTypes') {
				const self = Tools.AppService.getAccountSelf();
				if (!self?.products?.ma) {
					return res;
				}
			}

			res.push(name);
			return res;
		}, []);
	}

	if (listView.type === 'activity' && listView.filters?.length) {
		// Fix old DateStatus filter
		const [brokenDateFilter] = _.remove(listView.filters, { filterName: 'DateStatus' });
		if (
			brokenDateFilter &&
			brokenDateFilter.value &&
			typeof brokenDateFilter.value === 'object' &&
			!Array.isArray(brokenDateFilter.value)
		) {
			if (brokenDateFilter.value.status) {
				listView.filters.push({
					filterName: 'Status',
					value: brokenDateFilter.value.status
				});
			}
			var dateFilter = {
				filterName: 'Date',
				value: brokenDateFilter.value
			};
			dateFilter.value.status = undefined;
			listView.filters.push(dateFilter);
		}
	}

	return listView;
}

export const parseUdoListView = (
	view: ListView,
	customFields: CustomField[],
	categoryTypes: CategoryType[],
	udoObj?: UserDefinedObjectType
) => {
	if (!view.columns?.length) {
		customFields.forEach(field => {
			if (field.visible && field.datatype !== 'Text') {
				view.columns.push('Custom_' + field.id);
			}
		});
		categoryTypes.forEach(categoryType => {
			view.columns.push('Category_' + categoryType.id);
		});
	}
	if (udoObj) {
		if (!view.private) {
			view.title = udoObj?.name?.length ? udoObj.name : '<<' + T('default.noName') + '>>';
		}
	}
	view.columns = view.columns.slice(0, 6);

	return view;
};

export const parseReportView = function (view: ReportView) {
	if (view.type === 'activity' && view.filters?.length) {
		// Fix old DateStatus filter
		let [brokenDateFilter] = _.remove(view.filters, { filterName: 'DateStatus' });

		// Fix old Date filter (changed to CloseDate)
		// Remove when allowing both filters in activityReports
		if (!brokenDateFilter) {
			[brokenDateFilter] = _.remove(view.filters, { filterName: 'Date' });
		}

		if (
			brokenDateFilter &&
			brokenDateFilter.value &&
			typeof brokenDateFilter.value === 'object' &&
			!Array.isArray(brokenDateFilter.value)
		) {
			var dateFilter = {
				filterName: 'CloseDate',
				value: brokenDateFilter.value
			};
			dateFilter.value.status = undefined;
			view.filters.push(dateFilter);
		}
	}

	return view;
};
