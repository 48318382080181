import React, { Fragment, useMemo, useState, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Icon, Flex, Block } from '@upsales/components';
import './SubMenuItem.scss';
import NavbarSubMenuDropdown from '../NavbarSubMenuDropdown';
import { useTranslation } from 'Components/Helpers/translate';
import DropdownItem from 'App/components/DropdownItem';
import { globalTracker } from 'App/babel/helpers/Tracker';
import { useSelector, useSoftDeployAccess } from 'App/components/hooks';
import { topPortfolioAllowed } from 'App/pages/CustomerPortfolio/allowed';

type SubItem = {
	id: string;
	title: string;
	hidden?: boolean;
	state: string;
	stateParams?: object;
};

type Section = { type?: 'header'; title: string; items: SubItem[]; state: string; stateParams?: object };

type Item = {
	id: string;
	title: string;
	dropdown?: Section[];
	state: string;
	stateParams?: object;
};

type Props = {
	item: Item;
	className?: string;
	goToState: (state: string, params?: object) => void;
	alignRight?: boolean;
};

const onClick = (item: SubItem, section: Section, customerId: number) => {
	globalTracker.track(`navbar.dropdown.${item.state || section.state}`);

	let link;
	if (item.state) {
		link = Tools.$state.href(item.state, {
			customerId
		});
	} else if (item.stateParams) {
		link = Tools.$state.href(section.state, {
			...(item.stateParams || {}),
			customerId
		});
	} else {
		link =
			Tools.$state.href(section.state, {
				...(section.stateParams || {}),
				customerId
			}) +
			'?id=' +
			item.id;
	}
	window.location.href = link;
};

type RouteMapping = typeof ROUTE_MAPPINGS;

const ROUTE_MAPPINGS = {
	'react-root-contacts': 'react-root-companies',
	'react-root-projects': 'react-root-projectBoard',
	appointments: 'activities',
	'react-root-landingpages': 'react-root-forms',
	'react-root-flows': 'react-root-segments',
	'react-root-marketingboard': 'react-root-leads',
	'react-root-calllists': 'react-root-campaigns',
	'react-root-opportunities': 'react-root-salesboard',
	periodizations: 'react-root-salesboard'
};

const SubMenuItem = ({ item, className, goToState, alignRight = false }: Props) => {
	const { title, dropdown, state, stateParams } = item;
	const classes = new BemClass('SubMenuItem', className);
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownArrowRef = useRef<HTMLDivElement>(null);
	const hasTopCustomerPortfolio = topPortfolioAllowed();
	const hasTopContacts = !useSoftDeployAccess('CONTACTS_AND_CLIENTS_NOT_IN_PORTFOLIO') && hasTopCustomerPortfolio;

	const { t } = useTranslation();
	const currentState = Tools.$state.current.name as keyof RouteMapping;
	if (hasTopContacts) {
		ROUTE_MAPPINGS['react-root-contacts'] = 'react-root-contacts';
	}
	const active = currentState === state || ROUTE_MAPPINGS[currentState] === state;
	const { customerId } = useSelector(({ App }) => App);
	const dropdownContent = useMemo(
		() =>
			dropdown?.map((section, index) => {
				if (section.type === 'header') {
					return (
						<Text key={section.title + index} size="xl" bold space="ptm pbm plm prm">
							{section.title}
						</Text>
					);
				}
				return section.items?.length ? (
					<Fragment key={section.state + index}>
						<Block space="ptm pbm plm prm">
							<Text bold>{section.title}</Text>
						</Block>

						{section.items.map((item, index) =>
							!item.hidden ? (
								<DropdownItem
									key={item.title + index}
									title={t(item.title)}
									onClick={e => {
										e.stopPropagation(); // Prevent click event from bubbling up to the parent and triggering the item click
										onClick(item, section, customerId);
										setShowDropdown(false);
									}}
								/>
							) : null
						)}
					</Fragment>
				) : null;
			}),
		[dropdown]
	);

	return (
		<Flex
			className={classes.mod({ active, 'has-dropdown': !!dropdown?.length }).b()}
			onClick={() => goToState(state, stateParams)}
			alignItems="center"
			gap="u1"
		>
			<Text className={classes.elem('title').b()} bold>
				{title}
			</Text>
			{dropdown?.length ? (
				<>
					<Flex
						className={classes.elem('drop-trigger').mod({ active: showDropdown }).b()}
						alignItems="center"
						justifyContent="center"
						onClick={e => {
							e.stopPropagation();
							setShowDropdown(!showDropdown);
						}}
						ref={dropdownArrowRef}
					>
						<Icon name="chevron-down" />
					</Flex>
					<NavbarSubMenuDropdown
						isOpen={showDropdown}
						onOpenChange={open => setShowDropdown(open)}
						align={alignRight ? 'right' : 'left'}
						insideRefs={[dropdownArrowRef.current]}
					>
						{dropdownContent}
					</NavbarSubMenuDropdown>
				</>
			) : null}
		</Flex>
	);
};

export default SubMenuItem;
