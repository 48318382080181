'use strict';
import { Button, Icon, Toggle, ButtonGroup, Input, Text, Title } from '@upsales/components';
import moment from 'moment';
import React from 'react';
import _ from 'lodash';

import SendBeam from 'App/babel/resources/SendBeam';
import PhoneInput from 'App/babel/components/Inputs/PhoneInput';
import ValidationService from 'Services/ValidationService';
import globalTracker from 'App/babel/helpers/Tracker/Global';
import logError from 'App/babel/helpers/logError';
import { makeCancelable } from 'App/babel/helpers/promise';
import SettingsToggle from 'App/components/SettingsToggle';
import ProfilePicture from 'App/pages/Admin/ProfileSettings/ProfilePicture';
import t from 'Components/Helpers/translate';
import TimezoneSelect from 'App/components/Inputs/Selects/TimezoneSelect';
import LanguageSelect from 'App/components/Inputs/Selects/LanguageSelect';
import CountrySelect from 'App/components/Inputs/Selects/CountrySelect';
import LocaleSelect from 'App/components/Inputs/Selects/LocaleSelect';
import HideTabs from '../components/HideTabs';
import { topPortfolioAllowed } from 'App/pages/CustomerPortfolio/allowed';

ReactTemplates.admin.profile = window.ReactCreateClass({
	// hovered: null,
	getInitialState: function () {
		this.self = Tools.AppService.getSelf();

		return {
			passwordVisible: false,
			passwordStrength: null,
			passwordFail: null,
			comparePasswords: null,
			password: null,
			oldPassword: null,
			user: this.props.rootData.pageData.user,
			passwordTouched: false,
			callFromWeb: this.self.userParams.callFromWeb,
			shouldSendNotification: false,
			sendingNotification: false,
			notificationSuccess: false,
			soliditetIsActive: this.self.userParams.soliditetIsActive,
			emailTracking: this.self.userParams.emailTracking,
			phoneClickDrawer: this.self.userParams.phoneClickDrawer,
			easyBookingLinkText: this.self.userParams.easyBookingLinkText
		};
	},
	UNSAFE_componentWillMount: function () {
		this.lang = {
			profile: t('default.admin'),
			email: t('default.email'),
			name: t('default.name'),
			passwordTitle: t('default.password'),
			title: t('default.title'),
			language: t('default.language'),
			phone: t('default.phone'),
			timeZone: t('default.timeZone'),
			cellPhone: t('default.cellPhone'),
			address: t('address.address'),
			zip: t('address.zip'),
			city: t('address.city2'),
			streetAddress: t('address.streetAddress'),
			password: {
				change: t('admin.changePassword'),
				current: t('admin.currentPassword'),
				new: t('admin.newPassword'),
				repeat: t('admin.repeatPassword'),
				confirm: t('default.confirm') + ' ' + t('default.password')
			},
			abort: t('default.abort'),
			profileImage: t('admin.profileImage'),
			contactinformation: t('filters.columns.contactinformation'),
			foundNoGravatar: t('admin.foundNoGravatar'),
			userOtherFieldInfo: t('admin.userOtherFieldInfo'),
			region: t('admin.region'),
			langAndRegion: t('admin.langAndRegion2'),
			langAndRegionInfo: t('admin.langAndRegion2.description'),
			mainMarket: t('admin.mainMarket'),
			changePasswordInfo: t('admin.changePasswordInfo'),
			passwordFailDigitWarning: t('admin.passwordFailDigitWarning'),
			passwordFailUpperCaseWarning: t('admin.passwordFailUpperCaseWarning'),
			passwordFailLengthWarning: t('admin.passwordLengthWarning'),
			userOtherFields: t('admin.profile.other.fields'),
			activities: t('default.activities'),
			appointments: t('default.appointments'),
			opportunities: t('default.opportunities'),
			sales: t('default.sales'),
			sendCallToSmartphone: t('admin.sendCallToSmartphone'),
			callNotificationDesc: t('admin.callNotificationDesc'),
			routeCallsToApp: t('admin.routeCallsToApp'),
			sendTestNotification: t('admin.sendTestNotification'),
			sendTestNotificationDesc: t('admin.sendTestNotificationDesc'),
			sendNotificationButton: t('admin.sendNotificationButton'),
			notificationSent: t('admin.notificationSent'),
			addYouMobileNumber: t('admin.addYouMobileNumber'),
			save: t('default.save'),
			learnMoreAndTrouble: t('admin.learnMoreAndTrouble'),
			testNotificationSubj: t('admin.clickToCall.testNotificationSubject'),
			testNotificationBody: t('admin.clickToCall.testNotificationBody'),
			disableSoftPhone: t('admin.disableSoftPhone'),
			startPage: t('start_screen'),
			startPageDescription: t('admin.profile.startPageDescription'),
			emailTrackingTitle: t('admin.emailTracking.title'),
			emailTrackingBody: t('admin.emailTracking.body'),
			trackEmails: t('admin.trackEmails'),
			phoneClickDrawerToggleText: t('admin.phoneClickDrawer.toggleText'),
			titles: {
				todo: t('default.todos'),
				activity: t('default.activityResult'),
				client: t('default.accounts'),
				contact: t('default.contacts'),
				order: t('feature.orders'),
				salesboard: t('default.salesboard'),
				mail: t('default.mailSent'),
				esign: t('feature.esign'),
				recurringorders: t('feature.recurringOrder'),
				leads: t('feature.leads'),
				campaign: t('default.campaigns'),
				mailTemplates: t('mail.mailAndTemplates'),
				formSubmits: t('default.formSubmits'),
				forms: t('feature.forms'),
				landingPages: t('default.landingpages'),
				advertising: t('default.advertising'),
				webvisits: t('default.siteVisits'),
				reports: t('default.reportcenter'),
				analytics: t('feature.analytics'),
				looker: t('admin.looker'),
				groupMail: t('mail.mailings'),
				socialEvents: t('default.socialEvents'),
				support: t('default.support')
			},
			productTitles: {
				crm: t('navbar.sale'),
				ma: t('navbar.market'),
				followup: t('navbar.followup'),
				support: t('default.support')
			},
			easyBookingLinkTextTitle: t('admin.easyBookingLinkText.title'),
			easyBookingLinkTextDescription: t('admin.easyBookingLinkText.description')
		};

		this.numberFormat = Tools.$filter('numberFormat');
		this.currencyFormat = Tools.$filter('currencyFormat');

		this.startPageStates = {
			todo: 'todo',
			activity: 'activities',
			client: Tools.FeatureHelper.hasSoftDeployAccess('LIST_COMPANIES_REACT')
				? 'react-root-companies'
				: 'accounts',
			contact: (Tools.FeatureHelper.hasSoftDeployAccess('LIST_CONTACTS_REACT') ? 'react-root-' : '') + 'contacts',
			order: 'react-root-orders',
			salesboard: 'react-root-salesboard',
			mail: 'react-root-mail',
			esign: 'esign',
			recurringorders:
				(Tools.FeatureHelper.hasSoftDeployAccess('NEW_AGREEMENT_LIST') ? 'react-root-' : '') + 'agreements',
			leads: 'react-root-leads',
			campaign: 'react-root-campaigns',
			mailTemplates: Tools.FeatureHelper.hasSoftDeployAccess('MAIL_TEMPLATES_V2')
				? 'react-root-mailtemplates'
				: 'listMailTemplates',
			formSubmits: 'react-root-formSubmits',
			forms: 'react-root-forms',
			landingPages: 'react-root-landingpages',
			advertising: 'listAds',
			webvisits: 'react-root-visitors',
			reports: 'react-root-reportcenter',
			analytics: 'analytics',
			looker: 'looker',
			groupMail: 'react-root-mail-campaigns',
			socialEvents: 'react-root-events',
			support: 'react-root-support',
			portfolio: 'react-root-portfolio'
		};

		const accountSelf = Tools.AppService.getAccountSelf();
		const products = accountSelf.products;
		if (
			!products.ma &&
			(accountSelf.boughtAddons?.MARKETING_AUTOMATION ||
				accountSelf.boughtAddons?.MARKETING_AUTOMATION_ENTERPRISE)
		) {
			products.ma = true;
		}
		this.products = products;

		const isAdmin = this.self.administrator;
		const isSupport = this.self.support;
		const marketAdmin = this.self.userParams.mailAdmin;
		const mailActivated = Tools.AppService.getMetadata().map.mailActivated;

		const FeatureHelper = Tools.FeatureHelper;
		this.features = {
			portfolio: [
				{
					name: 'portfolio',
					available: function () {
						return topPortfolioAllowed();
					}
				}
			],
			crm: [
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
					? { name: 'todo', available: 'ACTIVITIES_AND_APPOINTMENTS' }
					: { name: 'activity', available: 'ACTIVITIES_AND_APPOINTMENTS' },
				{ name: 'client', available: 'COMPANIES_AND_CONTACTS' },
				{ name: 'contact', available: 'COMPANIES_AND_CONTACTS' },
				{ name: 'order', available: 'ORDERS' },
				{ name: 'salesboard', available: 'PIPELINE' },
				{
					name: 'mail',
					available: function () {
						return !products.ma && (FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL) || isAdmin);
					},
					onlyShowWhenAvailable: true
				},
				{ name: 'esign', available: 'ESIGN' },
				{ name: 'recurring-orders', available: 'RECURRING_ORDER' },
				{
					name: 'campaign',
					available: function () {
						return (
							FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECTS) &&
							(!products.ma || (!isAdmin && !marketAdmin))
						);
					},
					onlyShowWhenAvailable: true
				}
			],
			ma: [
				{
					name: 'client',
					available: function () {
						return (
							!products.crm &&
							(FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS) || isAdmin)
						);
					},
					onlyShowWhenAvailable: true
				},
				{
					name: 'contact',
					available: function () {
						return (
							!products.crm &&
							(FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS) || isAdmin)
						);
					},
					onlyShowWhenAvailable: true
				},
				{ name: 'leads', available: 'LEADS' },
				{ name: 'webvisits', available: 'VISITS' },
				{ name: 'formSubmits', available: 'FORMS' },
				{ name: 'forms', available: 'FORMS' },
				{ name: 'landingPages', available: 'FORMS' },
				{
					name: 'campaign',
					available: function () {
						return FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECTS) && products.ma;
					},
					onlyShowWhenAvailable: true
				},
				{ name: 'groupMail', available: 'GROUP_MAIL' },
				{
					name: 'mail',
					available: function () {
						return FeatureHelper.isAvailable(FeatureHelper.Feature.EMAIL) && (mailActivated || isAdmin);
					},
					onlyShowWhenAvailable: true
				},
				{
					name: 'advertising',
					available: function () {
						return !FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING') && FeatureHelper.Feature.ADS;
					},
					onlyShowWhenAvailable: true
				},
				{
					name: 'socialEvents',
					available: function () {
						return FeatureHelper.isAvailable(FeatureHelper.Feature.SOCIAL_EVENTS) || isAdmin;
					},
					onlyShowWhenAvailable: true
				}
			],
			followup: [
				{ name: 'reports', available: 'REPORTS' },
				{
					name: 'looker',
					available: function () {
						return FeatureHelper.isAvailable(FeatureHelper.Feature.LOOKER);
					}
				}
			],
			support: [
				{
					name: 'support',
					available: function () {
						return (
							FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT) && (isSupport || isAdmin)
						);
					}
				}
			]
		};
	},

	validateStartPage: function (startPage) {
		if (!startPage) return false;

		return Object.keys(this.features).some(feature =>
			this.features[feature].find(i => this.startPageStates[i.name.replace('-', '')] === startPage)
		);
	},

	componentDidMount: function () {
		const paramStartPage = this.self.userParams.startPage;
		const value =
			paramStartPage === 'activities' && Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
				? 'todo'
				: paramStartPage;
		jQuery(this._setStartPage)
			.select2({})
			.select2('val', value || 'react-root-salesboard')
			.on('change', e => {
				const startPage = e.val;

				if (this.validateStartPage(startPage)) {
					const user = this.state.user;

					user.userParams.startPage = startPage;
					this.props.rootData.onChange(user);
					this.setState({ user });
				} else {
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.ERROR,
						icon: 'times',
						title: 'default.error',
						body: `Invalid start page ${startPage}`
					});
				}
			});
	},
	componentWillUnmount: function () {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	},
	loadProfileImage: function (id) {
		if (this.profileImage) {
			var element = this.profileImage;
			var pageData = this.props.rootData.pageData;
			element.src =
				Tools.URL +
				Tools.API +
				pageData.customerId +
				'/resources/download/internal/' +
				id +
				'?inline=true&thumbnail=true&nocache=' +
				Date.now();
		}
		this.props.rootData.imgChanged(id);
	},
	getProfileUrl: function () {
		var pageData = this.props.rootData.pageData;
		if (!pageData.profileImageId) {
			return null;
		}
		return (
			Tools.URL +
			Tools.API +
			pageData.customerId +
			'/resources/download/internal/' +
			pageData.profileImageId +
			'?inline=true&thumbnail=true'
		);
	},
	fileUpload: function (e) {
		var self = this;
		var file = e.target.files[0];

		if (file?.size > 1_000_000) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: t('default.fileTooLargeMaxSizeIs', { size: '1 MB' })
			});
			return;
		}
		var pageData = self.props.rootData.pageData;
		var url =
			Tools.URL + Tools.API + pageData.customerId + '/resources/upload/internal/profileImage/' + pageData.user.id;
		var xhr = new XMLHttpRequest();
		var fd = new FormData();
		xhr.open('POST', url, true);
		xhr.setRequestHeader('Accept', 'application/json');
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				var result = JSON.parse(xhr.responseText);
				self.loadProfileImage(result.data.id);
			}
		};
		xhr.withCredentials = true;
		fd.append('file', file);
		xhr.send(fd);
	},

	setRef: function (name, ref) {
		this[name] = ref;
	},

	getProfilePictureUrl: function () {
		var url = this.getProfileUrl();

		var avatar = Tools.avatarService.getGravatar(this.props.rootData.pageData.user, { notFoundError: true });

		if (!url && avatar && avatar.gravatar && this.state.hasGravatar) {
			url = avatar.url;
		}

		if (url && url.length && url !== '#') {
			return url;
		}
		return null;
	},

	changePasswordDrip: function () {
		delete this.props.rootData.pageData.user.password;
		delete this.props.rootData.pageData.user.oldPassword;
		this.props.rootData.onChange(this.props.rootData.pageData.user);

		this.setState({
			passwordVisible: !this.state.passwordVisible,
			password: '',
			oldPassword: '',
			passwordStrength: null,
			passwordFail: null,
			comparePasswords: null
		});
	},

	passwordScore: function (pw) {
		var score = 0;
		if (!pw) return score;

		var chars = new Object();
		for (var i = 0; i < pw.length; i++) {
			chars[pw[i]] = (chars[pw[i]] || 0) + 1;
			score += 5.0 / chars[pw[i]];
		}

		var variations = {
			digits: /\d/.test(pw),
			lower: /[a-z]/.test(pw),
			upper: /[A-Z]/.test(pw),
			nonWords: /\W/.test(pw)
		};

		var variationCount = 0;
		for (var check in variations) {
			variationCount += variations[check] === true ? 1 : 0;
		}

		score += (variationCount - 1) * 10;
		return parseInt(score);
	},

	passwordStrength: function (e) {
		var password = e.target.value;
		var hasLength = password && password.length >= 8;
		var hasDigit = /\d/.test(password);
		var hasUpperCase = /[A-Z]/.test(password);

		if (hasLength && hasDigit && hasUpperCase) {
			return this.setState({ passwordFail: null, passwordStrength: 'strong', password: password });
		} else if (!hasLength) {
			return this.setState({ passwordFail: 'length', passwordStrength: 'weak', password: password });
		} else if (!hasDigit) {
			return this.setState({ passwordFail: 'digit', passwordStrength: 'weak', password: password });
		} else {
			return this.setState({ passwordFail: 'upperCase', passwordStrength: 'weak', password: password });
		}
	},

	comparePasswords: function (e) {
		const isSamePassword = e.target.value === this.state.password;
		this.setState({ passwordTouched: true, comparePasswords: isSamePassword });
		if (this.state.oldPassword) {
			if (isSamePassword) {
				this.props.rootData.pageData.user.oldPassword = this.state.oldPassword;
				this.props.rootData.pageData.user.password = e.target.value;
				this.props.rootData.onChange(this.props.rootData.pageData.user);
			} else {
				delete this.props.rootData.pageData.user.password;
				delete this.props.rootData.pageData.user.oldPassword;
				this.props.rootData.onChange(this.props.rootData.pageData.user);
			}
		}
	},

	oldPassword: function (e) {
		this.setState({ oldPassword: e.target.value });
		if (this.state.comparePasswords) {
			if (e.target.value) {
				this.props.rootData.pageData.user.oldPassword = e.target.value;
				this.props.rootData.pageData.user.password = this.state.password;
				this.props.rootData.onChange(this.props.rootData.pageData.user);
			} else {
				delete this.props.rootData.pageData.user.oldPassword;
				delete this.props.rootData.pageData.user.password;
				this.props.rootData.onChange(this.props.rootData.pageData.user);
			}
		}
	},

	changeMainMarket: function (lang) {
		var user = this.state.user;

		user.userParams.dnbCountryCodes = lang.country;
		this.props.rootData.onChange(user);
		this.setState({ user: user });
	},

	changeLanguage: function (lang) {
		var user = { ...this.state.user };
		user.language = lang.iso;
		this.props.rootData.onChange(user);
		this.setState({ user: user });
	},

	changeRegion: function (locale) {
		var user = this.state.user;
		user.userParams.locale = locale.id;
		this.props.rootData.onChange(user);
		this.setState({ user: user });
	},

	changeTimezone: function (timezone) {
		const user = { ...this.state.user, userParams: { ...this.state.user.userParams, timeZone: timezone.id } };
		this.props.rootData.onChange(user);
		this.setState({ user });
	},

	setHiddenTabs: function (hiddenTabs) {
		const user = { ...this.state.user, userParams: { ...this.state.user.userParams, hiddenTabs } };
		this.props.rootData.onChange(user);
		this.setState({ user });
	},

	renderPassword: function () {
		if (!this.props.rootData.pageData.canChangePassword) {
			return null;
		}
		var pwPasswordStrengthIcon = 'Icon';
		var pwPasswordFailLengthText = this.lang.passwordFailLengthWarning;
		var pwPasswordFailDigitText = this.lang.passwordFailDigitWarning;
		var pwPasswordFailUpperCaseText = this.lang.passwordFailUpperCaseWarning;

		var pwFormClass = 'half-column password-form';
		var pwButtonClass = null;
		if (this.state.passwordVisible) {
			pwFormClass += ' is-open';
			pwButtonClass += 'hide';
		}

		if (this.state.passwordStrength) {
			switch (this.state.passwordStrength) {
				case 'weak':
					pwPasswordStrengthIcon += ' Icon-times';
					break;

				case 'strong':
					pwPasswordStrengthIcon += ' Icon-check';
					break;
			}
		}

		var compPassword = {};
		if (this.state.password) {
			if (this.state.comparePasswords) {
				compPassword.text = 'OK';
				compPassword.class = 'ok';
			} else if (this.state.passwordTouched) {
				compPassword.text = 'Lösenorden överrensstämmer inte';
				compPassword.class = 'no';
			}
		}

		return (
			<div className="admin-card has-info">
				<div className="admin-card-top">
					<div className="admin-card-title">{this.lang.passwordTitle}</div>
				</div>
				<div className="admin-info-row">
					<div className="info-row-info">{this.lang.changePasswordInfo}</div>
					<div className="info-row-content half-row">
						{this.state.passwordVisible ? (
							<div className={pwFormClass}>
								<div className="marginBottom">
									<label>{this.lang.password.current}</label>
									<input
										className="form-control"
										type="password"
										autoComplete="nope"
										onChange={this.oldPassword}
									/>
								</div>

								<div
									className={
										this.state.passwordStrength
											? 'pw-input ' + this.state.passwordStrength
											: 'pw-input'
									}
								>
									<label>{this.lang.password.new}</label>
									<input
										className="form-control"
										type="password"
										autoComplete="new-password"
										onChange={this.passwordStrength}
									/>
									<i className={pwPasswordStrengthIcon} />
									{this.state.passwordFail && this.state.passwordFail === 'digit' ? (
										<span className="password-strength-text">{pwPasswordFailDigitText}</span>
									) : (
										''
									)}
									{this.state.passwordFail && this.state.passwordFail === 'length' ? (
										<span className="password-strength-text">{pwPasswordFailLengthText}</span>
									) : (
										''
									)}
									{this.state.passwordFail && this.state.passwordFail === 'upperCase' ? (
										<span className="password-strength-text">{pwPasswordFailUpperCaseText}</span>
									) : (
										''
									)}
								</div>
								<div
									className={
										compPassword && compPassword.class
											? 'pw-input ' + compPassword.class
											: 'pw-input'
									}
								>
									<label>{this.lang.password.repeat}</label>
									<input
										className="form-control"
										type="password"
										autoComplete="new-password"
										onChange={this.comparePasswords}
									/>
									<i className={pwPasswordStrengthIcon} />
									{compPassword && compPassword.title ? compPassword.title : ''}
								</div>
								<Button
									type="lined"
									shadow="none"
									className={pwButtonClass}
									onClick={this.changePasswordDrip}
								>
									{this.lang.abort}
								</Button>
							</div>
						) : (
							<div className="half-column">
								<Button
									type="lined"
									shadow="none"
									className={pwButtonClass}
									onClick={this.changePasswordDrip}
								>
									{this.lang.password.change}
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	},

	changeCustomValue: function (fieldId, value) {
		var user = Object.assign({}, this.state.user);

		var cf = _.find(user.custom, function (obj) {
			return parseInt(obj.fieldId) === parseInt(fieldId);
		});
		if (cf) {
			cf.value = value;
		} else {
			user.custom.push({ fieldId: fieldId, value: value });
		}

		this.props.rootData.onChange(user);
		this.setState({ user: user });
	},

	rootPropChange: function (key, e) {
		var user = this.state.user;

		user[key] = e.target.value;
		this.props.rootData.onChange(user);
		this.setState({ user: user });
	},

	timezoneFormat: function (timeZone, container, escape) {
		return '<span>' + escape(timeZone.description) + '</span>';
	},

	toggleCheck: async function () {
		const self = Tools.AppService.getSelf();
		const isSelected = this.state.callFromWeb;

		this.setState({
			callFromWeb: !isSelected,
			shouldSendNotification: !isSelected
		});

		try {
			await Tools.UserParam.save(64, !isSelected, { skipNotification: true, skipErrorNotification: false });
			Tools.AppService.setSelf({
				...self,
				userParams: {
					...self.userParams,
					callFromWeb: !isSelected
				}
			});

			globalTracker.track(`Send call to app: ${isSelected ? 'ON' : 'OFF'}`);
		} catch (err) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'default.somethingWrong'
			});
			this.setState({
				callFromWeb: isSelected
			});
		}
	},

	toggleEmailTracking: async function (value) {
		const self = Tools.AppService.getSelf();
		this.setState({ emailTracking: value });

		try {
			await Tools.UserParam.save(77, value, {
				updateSuccessBody: value ? 'admin.emailTracking.enabled' : 'admin.emailTracking.disabled'
			});
			Tools.AppService.setSelf({
				...self,
				userParams: {
					...self.userParams,
					emailTracking: value
				}
			});
		} catch (err) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'default.somethingWrong'
			});
		}
	},

	updateEasyBookingLinkText: async function (value) {
		const self = Tools.AppService.getSelf();
		if (!value || self.userParams.easyBookingLinkText === value) {
			return;
		}
		try {
			await Tools.UserParam.save(80, value, {
				updateSuccessBody: 'admin.easyBookingLinkText.updated'
			});
			Tools.AppService.setSelf({
				...self,
				userParams: {
					...self.userParams,
					easyBookingLinkText: value
				}
			});
		} catch (err) {
			logError('Failed to update easy booking link text', err);
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'default.somethingWrong'
			});
		}
	},

	togglePhoneClickDrawer: async function (value) {
		const self = Tools.AppService.getSelf();
		this.setState({ phoneClickDrawer: value });

		try {
			await Tools.UserParam.save(78, value, {
				updateSuccessBody: value ? 'admin.phoneClickDrawer.enabled' : 'admin.phoneClickDrawer.disabled'
			});
			Tools.AppService.setSelf({
				...self,
				userParams: {
					...self.userParams,
					phoneClickDrawer: value
				}
			});
		} catch (err) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'default.somethingWrong'
			});
		}
	},

	sendTestNotification: async function () {
		const { successNotification } = this.state;

		if (successNotification) return;

		this.setState({ sendingNotification: true });

		try {
			await SendBeam.sendNotification({
				'loc-key': 'OTHER',
				'loc-args': [this.lang.testNotificationSubj, this.lang.testNotificationBody],
				sound: 'assign_ding',
				category: 'OTHER'
			});
			this.setState({ sendingNotification: false, successNotification: true });
			setTimeout(() => {
				this.setState({
					successNotification: false,
					shouldSendNotification: false
				});
			}, 2000);
		} catch (e) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'default.somethingWrong'
			});
			this.setState({ sendingNotification: false });
		}
	},

	handlePhoneChange: function ({ target }) {
		const isValid = ValidationService.validatePhone(target.value);

		if (isValid) {
			this.setUserPhone(target.value);
		}
	},

	setUserPhone: function (userPhone) {
		const { user } = this.state;

		this.setState({
			user: {
				...user,
				userPhone
			}
		});
	},

	updateProfile: function () {
		this.props.rootData.updateUserPhone(this.state.user.userPhone);
	},

	renderLangRegion: function () {
		var user = this.state.user;

		const selectAnchor = document.getElementById('admin-content');

		var multiMarkets = null;
		if (this.props.rootData.pageData.features.soliditetMultiMarkets) {
			multiMarkets = (
				<div className="half-column">
					<label>{this.lang.mainMarket}</label>
					<CountrySelect
						value={user.userParams.dnbCountryCodes.trim()}
						anchor={selectAnchor}
						onChange={this.changeMainMarket}
						availableCountries={['SE', 'NO', 'FI', 'DK']}
						required
					/>
					<span className="TurnOffBisnode">
						<Toggle
							color="medium-green"
							space="mrs"
							size="xxs"
							checked={this.state.soliditetIsActive === false}
							onChange={value => {
								var v = value === true ? 0 : 1;
								this.cancelablePromise = makeCancelable(
									Tools.UserParam.save(7, v, {
										updateSuccessBody: v
											? 'admin.bisnode.nordic.active'
											: 'admin.bisnode.nordic.inactive'
									})
								);
								this.cancelablePromise.promise
									.then(() => {
										Tools.$rootScope.$broadcast('userParam.updated', 'soliditetIsActive', !!v);
										this.setState({ soliditetIsActive: !!v });
									})
									.catch(e => {
										logError(e, 'Failed saving UserParam');
									});
							}}
						/>
						{Tools.$translate('admin.profile.turnOffBisnode')}
					</span>
				</div>
			);
		} else {
			multiMarkets = (
				<div className="half-column" style={{ padding: '0 0 0 1px' }}>
					<label>{Tools.$translate('admin.profile.turnOffBisnode')}</label>
					<Toggle
						color="medium-green"
						checked={this.state.soliditetIsActive === false}
						onChange={value => {
							const v = value === true ? 0 : 1;
							this.cancelablePromise = makeCancelable(
								Tools.UserParam.save(7, v, {
									updateSuccessBody: v
										? 'admin.bisnode.nordic.active'
										: 'admin.bisnode.nordic.inactive'
								})
							);
							this.cancelablePromise.promise
								.then(() => {
									Tools.$rootScope.$broadcast('userParam.updated', 'soliditetIsActive', !!v);
									this.setState({ soliditetIsActive: !!v });
								})
								.catch(e => {
									logError(e, 'Failed saving UserParam');
								});
						}}
					/>
				</div>
			);
		}

		var locale = Tools.CountryCodes.getLocale(user.userParams.locale);
		locale.translated = Tools.$translate(locale.lang);

		var exampleDateTimeFormat = moment().locale(locale.locale);
		exampleDateTimeFormat = exampleDateTimeFormat.format('L LT');
		var currencyOptions = {
			style: 'currency',
			currency: 'SEK',
			currencyDisplay: 'code'
		};
		var exampleNumberFormat = new Intl.NumberFormat(locale.locale, currencyOptions).format(12345.99);
		const userLanguage = user.language ? user.language.split('-')[0] : null;
		const shouldShowDisableTranslation =
			Tools.FeatureHelper.hasSoftDeployAccess('DISABLE_LANGTAG_TRANSLATION') &&
			localStorage['DISABLE_LANGTAG_TRANSLATION'] === 'true';

		return (
			<div className="admin-card has-info">
				<div className="admin-card-top">
					<div className="admin-card-title">{this.lang.langAndRegion}</div>
				</div>

				<div className="admin-info-row">
					<div className="info-row-info">{this.lang.langAndRegionInfo}</div>
					<div className="info-row-content half-row">
						<div className="half-column">
							<label>{this.lang.language}</label>
							<LanguageSelect
								value={userLanguage}
								onChange={this.changeLanguage}
								anchor={selectAnchor}
								extraOptions={
									shouldShowDisableTranslation
										? [{ country: 'noLangTran', id: 'noLangTran', name: 'Disable Translation' }]
										: undefined
								}
								required
							/>
						</div>
						<div className="half-column">
							<label>{this.lang.timeZone}</label>
							<TimezoneSelect
								anchor={selectAnchor}
								onChange={this.changeTimezone}
								value={user.userParams.timeZone}
								required
							/>
						</div>
						{multiMarkets}
						<div className="half-column">
							<label>{this.lang.region}</label>
							<LocaleSelect
								value={user.userParams.locale}
								anchor={selectAnchor}
								onChange={this.changeRegion}
								required
							/>
							<div className="Text Text--italic Text--grey-10 Text--sm" style={{ marginTop: '4px' }}>
								{exampleDateTimeFormat + ', and ' + exampleNumberFormat}
							</div>
						</div>
						<div className="half-column" />
					</div>
				</div>
			</div>
		);
	},

	renderRootField: function (key, label, disabled, maxLength) {
		return (
			<div className="half-column" key={key}>
				<label>{label}</label>
				<Input
					disabled={disabled}
					maxLength={maxLength}
					value={this.props.rootData.pageData.user[key] || ''}
					onChange={this.rootPropChange.bind(this, key)}
				/>
			</div>
		);
	},

	renderCustomFields: function () {
		var self = this;
		var fields = Tools.AppService.getCustomFields('user');
		var user = self.state.user;
		if (!fields || !fields.length || !user) {
			return null;
		}

		var fieldElements = _.chain(fields)
			.sortBy('sortId')
			.reduce(function (out, field) {
				if (field.$hasAccess) {
					var valObj = _.find(self.state.user.custom, function (obj) {
						return parseInt(obj.fieldId) === parseInt(field.id);
					});
					out.push(
						<div className="half-column" key={'field-' + field.id}>
							<label>{field.name}</label>
							<ReactTemplates.customFieldInput
								field={Object.assign({}, field, { value: valObj ? valObj.value : null })}
								entity={'user'}
								valueChange={self.changeCustomValue.bind(null, field.id)}
							/>
						</div>
					);
				}
				return out;
			}, [])
			.value();

		return (
			<div className="admin-card has-info">
				<div className="admin-card-top">
					<div className="admin-card-title">{this.lang.userOtherFields}</div>
				</div>

				<div className="admin-info-row">
					<div className="info-row-info">{this.lang.userOtherFieldInfo}</div>
					<div className="info-row-content half-row">{fieldElements}</div>
				</div>
			</div>
		);
	},

	renderStats: function (title, value) {
		return (
			<div className="user-stat">
				{value}
				<div className="stat-title">{title}</div>
			</div>
		);
	},

	gravatarLoad: function () {
		this.setState({ hasGravatar: true });
	},
	gravatarFail: function () {
		this.setState({ hasGravatar: false });
	},

	renderExperimentalFeatures: function () {
		if (!this.props.rootData.pageData.experimentalFeatures.length) {
			return null;
		}
		var self = this;
		return (
			<div className="admin-card has-info">
				<div className="admin-card-top">
					<div className="admin-card-title">{'Experimental features'}</div>
				</div>

				<div className="admin-info-row">
					<div className="info-row-info">
						{
							'Try out experimental features before everyone else. Some features might require you to log out and in again.'
						}
					</div>
					<div className="info-row-content half-row">
						{this.props.rootData.pageData.experimentalFeatures.map(function (feature) {
							return (
								<div className="half-column" key={feature.name}>
									<label>{feature.name.toLowerCase()}</label>
									<ReactTemplates.upFilters.components.toggle
										value={feature.active}
										className="toggle-bright-blue toggle-sm"
										onChange={function (val) {
											self.props.rootData.toggleExperimentalFeature(feature.name, val);
										}}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	},

	renderSendCallSettings: function () {
		const { user } = this.state;
		const isSupportOnly = user.support && !user.crm && !user.administrator;
		if (isSupportOnly) {
			return null;
		}
		const hasPhoneIntegration = Tools.AppService.getPhoneIntegration();

		const rightColumn = (
			<div className="card--right-col">
				{this.state.shouldSendNotification ? (
					this.props.rootData.pageData.user.userPhone ? (
						<div className="notification-form">
							<div className="admin-card-top">
								<div className="admin-card-title">{this.lang.sendTestNotification}</div>
								<div className="Text Text--red no-line-height">
									{this.lang.sendTestNotificationDesc}
								</div>
								<Button
									loading={this.state.sendingNotification}
									onClick={this.sendTestNotification}
									color={this.state.successNotification ? 'bright-green' : 'bright-blue'}
								>
									{this.state.successNotification ? (
										<Icon name="check" space="margin-right-large" />
									) : null}
									{this.state.successNotification
										? this.lang.notificationSent
										: this.lang.sendNotificationButton}
								</Button>
							</div>
						</div>
					) : (
						<div className="notification-form">
							<div className="admin-card-top">
								<div className="admin-card-title">{this.lang.addYouMobileNumber}</div>
								<ButtonGroup block>
									<PhoneInput onChange={this.setUserPhone} name="phone" iconClass="phone" />
									<Button
										loading={this.props.rootData.updatingPhone}
										className="ButtonGroup__no-flex"
										disabled={!user.userPhone}
										onClick={this.updateProfile}
									>
										{this.lang.save}
									</Button>
								</ButtonGroup>
							</div>
						</div>
					)
				) : (
					<img src="img/call-notification.png" alt="callnotification" />
				)}
			</div>
		);
		return (
			<div className="admin-card">
				<SettingsToggle
					title={this.lang.sendCallToSmartphone}
					descriptionText={this.lang.callNotificationDesc}
					toggleText={this.lang.routeCallsToApp}
					onToggleChange={this.toggleCheck}
					toggleChecked={this.state.callFromWeb}
					rightColumn={rightColumn}
					articleId={1052}
					articleText={this.lang.learnMoreAndTrouble}
					toggleDisabled={hasPhoneIntegration}
					extraToggle={
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
							? {
									toggleChecked: this.state.phoneClickDrawer,
									onToggleChange: this.togglePhoneClickDrawer,
									toggleText: this.lang.phoneClickDrawerToggleText
							  }
							: undefined
					}
				/>
				{hasPhoneIntegration ? (
					<div className="soft-app-warining">
						<Text size="xl">{this.lang.disableSoftPhone}</Text>
					</div>
				) : null}
			</div>
		);
	},

	renderEmailTracking: function () {
		const { user } = this.state;
		const isSupportOnly = user.support && !user.crm && !user.administrator;
		if (isSupportOnly) {
			return null;
		}
		return (
			<div className="admin-card">
				<div className="email-tracking">
					<Title className="admin-card-title">{this.lang.emailTrackingTitle}</Title>
					<div className="description">
						<Text color="grey-10">{this.lang.emailTrackingBody}</Text>
					</div>
					<div className="toggle-label-container">
						<Toggle
							checked={this.state.emailTracking}
							onChange={this.toggleEmailTracking}
							size="lg"
							color="medium-green"
							space="mrl"
						/>
						<Text>{this.lang.trackEmails}</Text>
					</div>
				</div>
			</div>
		);
	},

	renderEasyBookingNewMailLinkText: function () {
		return (
			<div className="admin-card">
				<div className="easy-booking-new-mail-link-text">
					<Title className="admin-card-title">{this.lang.easyBookingLinkTextTitle}</Title>
					<div className="description">
						<Text color="grey-10">{this.lang.easyBookingLinkTextDescription}</Text>
					</div>
					<Input
						maxLength={120}
						type="text"
						state={
							this.state.easyBookingLinkText !== null &&
							this.state.easyBookingLinkText !== undefined &&
							this.state.easyBookingLinkText.trim() === ''
								? 'error'
								: undefined
						}
						defaultValue={this.state.easyBookingLinkText || t('editor.easyBookingTile')}
						onChange={e => {
							this.setState({ easyBookingLinkText: e.target.value });
						}}
						onBlur={e => this.updateEasyBookingLinkText(e.target.value)}
					/>
				</div>
			</div>
		);
	},

	getAvailableProducts: function () {
		const availableProducts = [];

		const isAdmin = this.self.administrator;
		const isSupport = this.self.support;
		const marketAdmin = this.self.userParams.mailAdmin;
		const products = this.products;

		if (isAdmin || products.crm) {
			availableProducts.push({ name: 'crm', active: products.crm });
		}

		if (isAdmin || ((marketAdmin || isAdmin) && products.ma)) {
			availableProducts.push({ name: 'ma', active: products.ma });
		}

		const FeatureHelper = Tools.FeatureHelper;
		const anyReportFeatureActive =
			FeatureHelper.isAvailable(FeatureHelper.Feature.REPORTS) ||
			FeatureHelper.isAvailable(FeatureHelper.Feature.LOOKER);

		if (isAdmin || anyReportFeatureActive) {
			availableProducts.push({ name: 'followup', active: anyReportFeatureActive });
		}

		const hasSupport = FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT);
		if (hasSupport && (isAdmin || isSupport)) {
			availableProducts.push({ name: 'support', active: hasSupport });
		}

		return availableProducts;
	},

	getStartPageOptions: function () {
		const startPageOptions = [];

		const products = this.getAvailableProducts();
		const FeatureHelper = Tools.FeatureHelper;

		for (const product of products) {
			const availableOptions = [];

			for (const feature of this.features[product.name]) {
				let available = false;

				if (typeof feature.available === 'function') {
					available = feature.available();
				} else if (typeof feature.available === 'string') {
					available = FeatureHelper.isAvailable(FeatureHelper.Feature[feature.available]);
				} else {
					available = feature.available;
				}

				if (available) {
					const featureName = feature.name.replace('-', '');
					availableOptions.push(
						<option key={feature.name} value={this.startPageStates[featureName]}>
							{this.lang.titles[featureName]}
						</option>
					);
				}
			}

			startPageOptions.push(
				<optgroup
					key={`option-group-${product.name}`}
					label={this.lang.productTitles[product.name.replace('-', '')]}
				>
					{availableOptions}
				</optgroup>
			);
		}

		return startPageOptions;
	},

	renderStartPageSettings: function () {
		return (
			<div className="admin-card" id="admin-profile-starting-screen">
				<div className="admin-card-top">
					<div className="admin-card-title">{this.lang.startPage}</div>
				</div>
				<div className="admin-info-row">
					<div className="info-row-content half-row no-info">
						<div className="half-column">
							<label>{this.lang.startPageDescription}</label>
							<select className="form-control" ref={this.setRef.bind(this, '_setStartPage')}>
								{this.getStartPageOptions()}
							</select>
						</div>
					</div>
				</div>
				{this.products.crm && Tools.FeatureHelper.hasSoftDeployAccess('HIDE_TABS') ? (
					<HideTabs
						hiddenTabs={this.state.user.userParams.hiddenTabs || undefined}
						onChange={this.setHiddenTabs}
					/>
				) : null}
			</div>
		);
	},

	render: function () {
		var user = this.props.rootData.pageData.user;
		var imgUrl = this.getProfilePictureUrl();
		var stats = this.props.rootData.pageData.stats;
		// We do this so we can find out if the user has a gravatar or nah
		var gravatarUrl = Tools.avatarService.getGravatar(this.props.rootData.pageData.user, { notFoundError: true });

		return (
			<div id="admin-page-profile">
				<div id="user-profile-header" className={'admin-page-header' + (imgUrl ? ' has-profile-img' : '')}>
					<img
						src={gravatarUrl.url}
						onLoad={this.gravatarLoad}
						onError={this.gravatarFail}
						style={{ visibility: 'hidden' }}
					/>

					<ProfilePicture
						user={this.props.rootData.pageData.user}
						profileImageId={this.props.rootData.pageData.profileImageId}
						hasGravatar={this.state.hasGravatar}
						removeImage={this.props.rootData.removeImage}
						fileUpload={this.fileUpload}
					/>

					<div className="name-wrap">
						{user.name}
						<div className="user-title">{user.userTitle}</div>
					</div>
					<div className={'stats-wrap' + (!this.props.rootData.pageData.statsLoading ? ' loaded' : '')}>
						<h3 />
						{this.renderStats(this.lang.activities, this.numberFormat(stats.activities))}
						{this.renderStats(this.lang.appointments, this.numberFormat(stats.appointments))}
						{this.renderStats(this.lang.opportunities, this.numberFormat(stats.opportunities))}
						{this.renderStats(this.lang.sales, stats.sales)}
					</div>
				</div>

				<div id="admin-content">
					<div className="admin-card">
						<div className="admin-card-top">
							<div className="admin-card-title">{this.lang.contactinformation}</div>
						</div>
						<div className="admin-info-row">
							<div className="info-row-content half-row no-info">
								{this.renderRootField('name', this.lang.name, false, 50)}
								{this.renderRootField('userTitle', this.lang.title, false, 50)}
								{this.renderRootField('email', this.lang.email, true, 75)}
								<div className="half-column" />
								{this.renderRootField('userPhone', this.lang.phone, false, 30)}
								{this.renderRootField('userCellPhone', this.lang.cellPhone, false, 30)}
							</div>
						</div>
						<div className="admin-card-top">
							<div className="admin-card-title">{this.lang.address}</div>
						</div>
						<div className="admin-info-row">
							<div className="info-row-content half-row no-info">
								{this.renderRootField('userAddress', this.lang.streetAddress, false, 255)}
								<div className="half-column">
									<div className="w40">
										<label>{this.lang.zip}</label>
										<Input
											maxLength={10}
											value={this.props.rootData.pageData.user.userZipCode || ''}
											onChange={this.rootPropChange.bind(this, 'userZipCode')}
										/>
									</div>
									<div className="w65">
										<label>{this.lang.city}</label>
										<Input
											value={this.props.rootData.pageData.user.userState || ''}
											onChange={this.rootPropChange.bind(this, 'userState')}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					{this.renderCustomFields()}

					{this.renderLangRegion()}

					{this.renderExperimentalFeatures()}

					{this.renderPassword()}

					{this.renderSendCallSettings()}

					{this.renderEmailTracking()}

					{Tools.AppService.getMetadata().activatedFeatures.easyBooking.active &&
					Tools.FeatureHelper.hasSoftDeployAccess('MAIL_EASY_BOOKING_LINK_TEXT')
						? this.renderEasyBookingNewMailLinkText()
						: null}

					{this.renderStartPageSettings()}
				</div>
			</div>
		);
	}
});
