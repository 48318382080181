import React from 'react';
import { EllipsisTooltip, Flag, Flex, Text } from '@upsales/components';
import { TFunction } from 'i18next';
import { useTranslation } from 'Components/Helpers/translate';

export type CountryChipType = { countryCode: string; flag: string; title: string };
export const CountryChipMapper = (countryCode: string, t: TFunction): CountryChipType => {
	const country = Tools.CountryCodes.getCountry(countryCode.trim());
	const name = t(country.lang);
	return { countryCode, flag: countryCode.toLowerCase(), title: name };
};

interface CountryFlagProps {
	countryCode: string;
}

export const CountryFlag = ({ countryCode }: CountryFlagProps) => {
	const { t } = useTranslation();

	const country = Tools.CountryCodes.getCountry(countryCode);

	return (
		<Flex alignItems="center" gap="u1">
			<Flag code={country.code.toLowerCase()} />
			<EllipsisTooltip title={t(country.lang)}>
				<Text>{t(country.lang)}</Text>
			</EllipsisTooltip>
		</Flex>
	);
};
