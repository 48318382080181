import { getSalesData, getDateHeader, getColumns, onRowClick, sumValuesByDate, getAccountHref } from './helpers';
import useComponentDidUnmount from 'App/components/hooks/useComponentDidUnmount';
import { WidgetType } from 'App/components/ClientSales/SalesGraph/SalesGraph';
import ARRChangesAttributes from 'App/babel/attributes/ARRChangesAttributes';
import { useMetadata, useOrderRelationActive, useStages } from 'App/components/hooks/appHooks';
import InlineChangeHistory from 'App/components/InlineChangeHistory';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import SimpleListView from 'App/pages/ClientCard/SimpleListView';
import ARRChangeResource from 'App/babel/resources/ARRChanges';
import { currencyFormat } from 'Components/Filters/Currencies';
import { getFilterFn } from 'Components/ListTab/ListTabHelper';
import { useTranslation } from 'Components/Helpers/translate';
import { ARRChange } from 'App/resources/Model/ARRChange';
import BemClass from '@upsales/components/Utils/bemClass';
import OrderAttributes from 'App/babel/attributes/Order';
import { Title, Link, Icon, Tooltip, Flex } from '@upsales/components';
import openModal from 'App/services/Modal/Modal';
import React, { useRef, useState } from 'react';
import OrderResource from 'App/resources/Order';
import Order from 'App/resources/Model/Order';
import User from 'App/resources/Model/User';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import logError from 'Helpers/logError';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';

type Props = {
	clientIds: number[];
	grouping: string;
	widgetType: string;
	isGroupBySubAccount: boolean;
	isSubaccount: boolean;
};

const ClientColumn = ({
	item,
	isGroupBySubAccount,
	clientIds
}: {
	item: Order | ARRChange;
	isGroupBySubAccount: boolean;
	clientIds?: number[];
}) => {
	const { t } = useTranslation();

	const operationalAccountName = item.client.operationalAccount?.name;

	const orderRelationActive = useOrderRelationActive();
	const operationalAccountId = item.client?.operationalAccount?.id;
	const isSubaccount = !!operationalAccountId;

	const isRelated = !isSubaccount && !clientIds?.includes(item.client.id!) && orderRelationActive;

	return item.client?.id ? (
		<Flex alignItems="center" gap="u1">
			{isRelated ? (
				<Tooltip title={t('default.clientConnection')}>
					<Icon name="sitemap" color="grey-11" />
				</Tooltip>
			) : null}
			<Flex direction="column">
				<Link key={item.client?.id} onClick={e => e.stopPropagation()} href={getAccountHref(item.client?.id)}>
					{!isGroupBySubAccount && operationalAccountName ? operationalAccountName : item.client.name}
				</Link>
				{isGroupBySubAccount && item.client?.operationalAccount ? (
					<SubAccountLabel isCompanyColumn operationalAccount={item.client.operationalAccount} />
				) : null}
			</Flex>
		</Flex>
	) : null;
};

const SalesList = ({ clientIds, grouping, widgetType, isGroupBySubAccount, isSubaccount }: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('SalesListCompanyGroup');
	const {
		params: { SalesModelOption: salesModelOption, GroupARRChangesByUser: groupARRChangesByUser },
		customerCurrencies
	} = useMetadata() ?? { params: { SalesModelOption: 'arr' }, customerCurrencies: [] };

	const shouldShowAgreements = widgetType === WidgetType.RECURRING || widgetType === WidgetType.TOTAL_RECURRING;
	const shouldShowOrders = widgetType === WidgetType.CONTRIBUTION_MARGIN || widgetType === WidgetType.CASH_FLOW;
	// Need this to force re render, couldn't find a better way since it's not working with braodcasting
	const [count, setCount] = useState(0);
	const filterFn = getFilterFn(isGroupBySubAccount);
	const { self } = useSelector(({ App }: RootState) => ({
		self: App.self
	}));

	const isAdmin = self?.administrator;

	const [RRChanges, setRRChanges] = useState<ARRChange[]>([]);
	const masterCurrency = customerCurrencies.find(currency => currency.masterCurrency);

	const arrChangeRequest = useRef<CancelablePromise<Awaited<ReturnType<typeof ARRChangeResource.get>>> | null>(null);

	useComponentDidUnmount(() => {
		arrChangeRequest.current?.cancel?.();
	});

	const excludedStageIds = useStages()
		.filter(s => s.exclude)
		.map(stage => stage.id);

	const generateSubHeader = (item: Order, sorting: string) => {
		const operationalAccountName = item.client.operationalAccount?.name;

		switch (sorting) {
			case 'date':
				return getDateHeader(item, grouping);
			case 'user.name':
				return item.user.name;
			case 'stage.id':
				return item.stage.name;
			case 'client.name':
				return !isGroupBySubAccount && operationalAccountName ? operationalAccountName : item.client.name;
		}
		return '';
	};

	const generateSubHeaderARRChanges = (item: ARRChange, sorting: string) => {
		const dateType = grouping === 'year' || grouping === 'user' ? 'year' : 'month';
		const valueType = salesModelOption === 'arr' ? 'annual' : 'monthly';
		const sumMap = sumValuesByDate(RRChanges, dateType, valueType);
		const dateKey =
			dateType === 'year'
				? new Date(item.date).getFullYear().toString()
				: `${new Date(item.date).getFullYear()}-${new Date(item.date).getMonth() + 1}`;
		const sumValue = sumMap.get(dateKey) ?? 0;

		switch (sorting) {
			case 'date':
				return `${getDateHeader(item, grouping)} ${
					sumValue > 0
						? `(+${currencyFormat(sumValue, masterCurrency?.iso!)})`
						: `(${currencyFormat(sumValue, masterCurrency?.iso!)})`
				}`;

			case 'type':
				return t(`arrchange.type.${item.type}`);
			case 'client.name':
				return item.client?.name ?? '';
		}
		return '';
	};

	const customColumnRenders: GetProp<typeof SimpleListView<ARRChange>, 'customColumnRenders'> = {
		client: item => {
			return (
				<ClientColumn
					key={'clientColumnAgreement' + item.id}
					item={item}
					isGroupBySubAccount={isGroupBySubAccount}
					clientIds={clientIds}
				/>
			);
		},
		'': item => {
			return isAdmin && !groupARRChangesByUser && item.client.id ? (
				<InlineChangeHistory
					key={'inlineChangeHistory' + item.id}
					change={{
						...item,
						client: {
							id: item.client.id
						},
						date: new Date(item.date),
						users: item.users as User[]
					}}
					onChange={() => {
						Tools.$rootScope.$broadcast('account.updated', {
							id: item.client.id
						});
						Tools.$rootScope.$broadcast('arrChange.updated');
						setCount(count + 1);
					}}
				/>
			) : null;
		}
	};

	const customColumnRendersSales: GetProp<typeof SimpleListView<Order>, 'customColumnRenders'> = {
		client: item => {
			return (
				<ClientColumn
					key={'clientColumnOrder' + item.id}
					item={item}
					isGroupBySubAccount={isGroupBySubAccount}
					clientIds={clientIds}
				/>
			);
		}
	};

	return (
		<>
			{shouldShowAgreements ? (
				<SimpleListView<ARRChange>
					classes={classes}
					getData={rb => {
						filterFn(rb, clientIds);
						arrChangeRequest.current = makeCancelable(ARRChangeResource.find(rb.build()));
						arrChangeRequest.current.promise
							.then(res => {
								if (res.data) {
									setRRChanges(res.data);
								}
							})
							.catch(error => {
								logError(error, 'Could not get arr changes');
							});
						return arrChangeRequest.current.promise;
					}}
					columns={getColumns(salesModelOption, widgetType, isSubaccount)}
					key={count}
					subHeaderFn={generateSubHeaderARRChanges}
					attributes={ARRChangesAttributes}
					customColumnRenders={customColumnRenders}
					renderHeader={() => (
						<Title bold space="pll pbl" color="black">
							{t(salesModelOption === 'arr' ? 'default.arr' : 'default.mrr')}{' '}
							{t('default.changes').toLowerCase()}
						</Title>
					)}
					onRowClick={change => () => {
						if (change.client?.id) {
							openModal('ARRChangeDrawerV2', {
								clientId: change.client.id,
								date: change.date,
								type: 'arrchange.type.' + change.type
							});
						}
					}}
					initialSorting={[{ attribute: 'date', ascending: false }]}
					tableLimitOptions={[25]}
					isFullscreen
				/>
			) : null}
			{shouldShowOrders ? (
				<SimpleListView<Order>
					classes={classes}
					getData={rb => getSalesData(rb, clientIds, excludedStageIds, isGroupBySubAccount)}
					columns={getColumns(salesModelOption, widgetType, isSubaccount)}
					subHeaderFn={generateSubHeader}
					customColumnRenders={customColumnRendersSales}
					attributes={{
						...OrderAttributes,
						description: {
							...OrderAttributes.description,
							sortable: false
						},
						contact: {
							...OrderAttributes.contact,
							placeholder: 'default.noContact'
						}
					}}
					rowStyles={{
						inactive: item => item.probability === 0
					}}
					deleteFn={order => {
						return OrderResource.delete(order.id);
					}}
					isDeletable={order => {
						return !!order.userRemovable;
					}}
					entityName="default.order"
					onRowClick={onRowClick}
					renderHeader={() => (
						<Title bold space="pll pbl" color="black">
							{t('default.orders')}
						</Title>
					)}
					broadcastType="order"
					initialSorting={[{ attribute: 'date', ascending: false }]}
					tableLimitOptions={[25]}
				/>
			) : null}
		</>
	);
};

export default SalesList;
