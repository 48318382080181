import { ClickableItem, DropDownMenu, Flex, Tooltip, Icon, Button } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import ContactResource from 'App/resources/Contact';
import OrderResource from 'App/resources/Order';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import logError from 'Helpers/logError';
import React from 'react';
import ActivityResource from 'App/babel/resources/Activity';
import { useSelector } from 'App/components/hooks';
import { RootState } from 'Store/index';
import IconName from '@upsales/components/Icon/IconName';

import './ActionColumn.scss';

export type Entity = 'contact' | 'order' | 'activity';

export enum Action {
	Edit,
	Delete,
	CreateDocument
}

type Props = {
	entity: Entity;
	object: { id: number; userEditable: boolean; userRemovable: boolean; isAppointment?: boolean };
	actions: Action[];
};

export const ActionColumn = ({ entity, object, actions }: Props) => {
	const { documentTemplates } = useSelector(({ App }: RootState) => App);
	const orderTemplates = documentTemplates.order;

	const { t } = useTranslation();
	const lang = {
		edit: t('default.edit'),
		delete: t('default.delete'),
		entity: t(`default.${entity}`),
		createDocument: t('default.createDocument'),
		showOptions: t('default.showOptions')
	};

	const runAction = async (action: Action) => {
		switch (action) {
			case Action.Edit: {
				switch (entity) {
					case 'activity':
						Tools.$upModal.open(object.isAppointment ? 'editAppointment' : 'editActivity', {
							id: object.id
						});
						break;
					case 'contact':
						Tools.$upModal.open('editContact', { id: object.id });
						break;
					case 'order':
						Tools.$upModal.open('editOrder', { id: object.id });
						break;
					default:
						throw new Error('Invalid entity for edit');
				}
				break;
			}
			case Action.Delete: {
				let resource;
				switch (entity) {
					case 'activity':
						resource = ActivityResource;
						break;
					case 'contact':
						resource = ContactResource;
						break;
					case 'order':
						resource = OrderResource;
						break;
					default:
						throw new Error('Invalid entity for delete');
				}

				if (resource) {
					try {
						await resource.delete(object.id);
					} catch (err) {
						logError(err, `[ActionColumn] failed to remove the ${entity} with id ${object.id}`);
					}
				}

				break;
			}
			case Action.CreateDocument:
				Tools.$upModal.open('createDocument', {
					type: 'agreement',
					id: object.id,
					templates: entity === 'order' ? orderTemplates : []
				});
				break;
			default:
				throw new Error('Invalid action');
		}
	};

	const hasEdit = actions.indexOf(Action.Edit) >= 0 && object.userEditable;
	const hasDelete = actions.indexOf(Action.Delete) >= 0 && object.userRemovable;
	const hasCreateDocument = actions.indexOf(Action.CreateDocument) >= 0;

	type ActionProps = {
		action: Action;
		icon: IconName;
		title: string;
		confirm?: boolean;
	};

	const actionsConfig = [
		{ action: Action.Edit, icon: 'edit', title: lang.edit, visible: hasEdit },
		{ action: Action.Delete, icon: 'trash', title: lang.delete, confirm: true, visible: hasDelete },
		{ action: Action.CreateDocument, icon: 'file', title: lang.createDocument, visible: hasCreateDocument }
	].filter(action => action.visible) as ActionProps[];

	const renderQuickActions = (close: () => void) => (
		<Flex direction="column">
			{actionsConfig.map(({ action, icon, title, confirm }) =>
				confirm ? (
					<InlineConfirm
						keepTabPosition
						onConfirm={() => runAction(action)}
						show
						entity={lang.entity}
						key={action}
					>
						<ClickableItem size="sm" icon={icon} borderRadius title={title} />
					</InlineConfirm>
				) : (
					<ClickableItem
						key={action}
						size="sm"
						icon={icon}
						onClick={e => {
							e.stopPropagation();
							runAction(action);
							close();
						}}
						borderRadius
						title={title}
					/>
				)
			)}
		</Flex>
	);

	return (
		<Flex justifyContent="flex-end" alignItems="center">
			{actionsConfig.length > 0 ? (
				<DropDownMenu
					align="right"
					renderTrigger={(expanded, setExpanded) => (
						<Tooltip title={lang.showOptions}>
							<Button onClick={setExpanded} type="link">
								<Icon color="grey-8" name="ellipsis-h" />
							</Button>
						</Tooltip>
					)}
				>
					{close => <div>{renderQuickActions(close)}</div>}
				</DropDownMenu>
			) : null}
		</Flex>
	);
};

export default ActionColumn;
