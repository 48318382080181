import { ButtonSelect, Icon } from '@upsales/components';
import T, { useTranslation } from 'Components/Helpers/translate';
import Highcharts, { ColumnChartSeriesOptions } from 'highcharts';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import type { MarketingMeta, MarketingGraphData } from './Marketing';
import IconName from '@upsales/components/Icon/IconName';
import Client from 'App/resources/Model/Client';
import logError from 'Helpers/logError';

type MarketingGraphProps = {
	client: Client;
	nrOfMonths: number;
	setNrOfMonths: (nrOfMonths: number) => void;
	marketGrouping: string;
	setMarketGrouping: (marketGrouping: string) => void;
	subAccountIds?: number[];
};

const newMarketingMeta = (): MarketingMeta => ({
	totalCount: 0,
	totalMailCount: 0,
	totalFormCount: 0,
	totalLeadScore: 0,

	totalVisit: 0,
	totalMail: 0,
	totalForm: 0,
	totalMarketingCustom: 0,
	impressionCount: 0
});

var buildChart = function ({
	data,
	marketGrouping,
	marketingMeta,
	nrOfMonths
}: Pick<MarketingGraphProps, 'nrOfMonths' | 'marketGrouping'> & {
	marketingMeta: MarketingMeta;
	data: MarketingGraphData;
}): Highcharts.Options {
	var makeLegendItem = function (name: string, kpi: number, noP: boolean, icon: IconName) {
		return renderToStaticMarkup(
			<>
				<span style={{ fontSize: '18px' }}>{`${kpi}${marketGrouping === 'score' && !noP ? 'p' : ''}`}</span>
				<span>
					<br />
					<Icon name={icon} /> {T(name)}
				</span>
			</>
		);
	};

	var categories: string[];
	var serieForm = new Array(12).fill(0);
	var serieMail = new Array(12).fill(0);
	var serieVisit = new Array(12).fill(0);
	var serieMarketingCustom = new Array(12).fill(0);
	var serieImpressionCount = new Array(12).fill(0);

	const hasAdverterising = !Tools.FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING');

	if (nrOfMonths === 12) {
		var month = new Date().getMonth();
		var year = new Date().getFullYear();
		categories = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (m) {
			var mon = month - m;
			return (
				(mon < 0 ? year - 1 : year) +
				'-' +
				moment()
					.month(mon % 12)
					.format('MM')
			);
		});
	} else {
		// first day three months ago..
		categories = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(function (m) {
			var dateStart = moment().subtract(m, 'w');
			return dateStart.format('YYYY') + '-' + dateStart.format('WW');
		});
	}

	var countOrScore: 'Count' | 'Score' = marketGrouping === 'event' ? 'Count' : 'Score';

	Object.keys(data).forEach(function (key) {
		var index;
		index = categories.indexOf(key);
		if (index !== -1) {
			serieForm[index] = data[key][`form${countOrScore}`];
			serieMail[index] = data[key][`mail${countOrScore}`];
			serieVisit[index] = data[key][`visit${countOrScore}`];
			serieMarketingCustom[index] = data[key][`marketingcustom${countOrScore}`];
			if (marketingMeta.impressionCount > 0) {
				serieImpressionCount[index] = data[key].impressionCount;
			}
		}
	});

	var series: ColumnChartSeriesOptions[] = [
		{
			name: makeLegendItem('leads.fromForms', marketingMeta.totalForm, false, 'file-text'),
			type: 'column',
			color: '#4E0065',
			data: serieForm,
			visible: !marketingMeta.totalLeadScore || !!marketingMeta.totalForm
		},
		{
			name: makeLegendItem('mail.clickedMails', marketingMeta.totalMail, false, 'envelope'),
			type: 'column',
			color: '#721A94',
			data: serieMail,
			visible: !marketingMeta.totalLeadScore || !!marketingMeta.totalMail
		},
		{
			name: makeLegendItem('default.siteVisits', marketingMeta.totalVisit, false, 'globe'),
			type: 'column',
			color: '#B254E0',
			data: serieVisit,
			visible: !marketingMeta.totalLeadScore || !!marketingMeta.totalVisit
		},
		{
			name: makeLegendItem('leads.fromMarketingCustom', marketingMeta.totalMarketingCustom, false, 'bullseye'),
			type: 'column',
			color: '#9933C0',
			data: serieMarketingCustom,
			visible: !marketingMeta.totalLeadScore || !!marketingMeta.totalMarketingCustom
		}
	];

	if (
		serieImpressionCount &&
		(serieImpressionCount.length || marketingMeta.impressionCount) > 0 &&
		hasAdverterising
	) {
		series.push({
			name: makeLegendItem('default.adImpressions', marketingMeta.impressionCount, true, 'bullhorn'),
			type: 'line',
			color: '#9933C0',
			yAxis: 1,
			data: serieImpressionCount
		});
	}

	return {
		chart: {
			type: 'column',
			marginTop: 60,
			height: 200,
			style: { fontFamily: '"Roboto"' },
			borderRadius: 4,
			backgroundColor: 'transparent'
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			categories: categories,
			lineColor: 'transparent',
			tickLength: 0,
			tickWidth: 0,
			labels: {
				formatter: function (this: any) {
					if (nrOfMonths === 3) {
						var date = moment().week(this.value.substring(5, 7));
						if (this.chart.chartWidth < 1250) {
							return T('calendar.weekPrefix') + '.' + date.isoWeek();
						}
						return date.startOf('w').format('DD MMM') + ' - ' + date.endOf('w').format('DD MMM');
					} else {
						if (this.chart.chartWidth < 750) {
							return moment(this.value).format("MMM 'YY");
						}
						return moment(this.value).format('MMM YYYY');
					}
				},
				overflow: false,
				rotation: 0
			}
		},
		yAxis: [
			{
				min: 0,
				maxPadding: 0.01,
				title: { text: '' },
				labels: { style: { color: '#6B7C93' } }
			},
			{
				title: {
					text: '',
					style: { color: Highcharts.getOptions().colors?.[2] }
				},
				opposite: true,
				labels: { style: { color: '#6B7C93' } }
			}
		],
		legend: {
			align: 'center',
			x: 0,
			verticalAlign: 'top',
			y: -10,
			floating: false,
			backgroundColor: 'transparent',
			borderWidth: 0,
			symbolWidth: 0,
			symbolHeight: 0,
			symbolRadius: 0,
			shadow: false,
			useHTML: true,
			itemStyle: {
				fontWeight: 'normal',
				fontSize: '11px',
				fontFamily: 'Roboto'
			}
		},
		tooltip: { enabled: false },
		plotOptions: {
			column: {
				stacking: 'normal',
				dataLabels: { enabled: false },
				borderWidth: 0,
				marker: { radius: 2 }
			},
			series: { marker: { radius: 2 } }
		},
		series: series
	};
};

async function getGraphData({
	clientId,
	nrOfMonths,
	marketGrouping,
	subAccountIds
}: {
	clientId: number;
	nrOfMonths: number;
	marketGrouping: string;
	subAccountIds?: number[];
}): Promise<{ data: MarketingGraphData; metadata: MarketingMeta } | void> {
	const options: {
		clientId: number | number[];
		interval: string;
		startDate: string;
		endDate: string;
		subAccountIds?: number[];
	} = {
		clientId: clientId,
		interval: nrOfMonths === 3 ? 'week' : 'month',
		startDate: moment().subtract(nrOfMonths, 'month').format('YYYY-MM-DD'),
		endDate: moment().add(1, 'day').format('YYYY-MM-DD')
	};

	const hasSubaccountsV1 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
	const hasSubaccountsFeature =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
	const hasSubaccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;

	if (hasSubaccounts && subAccountIds?.length) {
		options.clientId = [clientId, ...subAccountIds];
	}

	return Tools.Account.customer(Tools.AppService.getCustomerId())
		.getAccountMarket(options)
		.then(results => {
			const meta = newMarketingMeta();

			if (results && results.data) {
				meta.totalVisit =
					marketGrouping === 'event' ? results.metadata.visitCount : results.metadata.visitScore;
				meta.totalMail = marketGrouping === 'event' ? results.metadata.mailCount : results.metadata.mailScore;
				meta.totalForm = marketGrouping === 'event' ? results.metadata.formCount : results.metadata.formScore;
				meta.totalMarketingCustom =
					marketGrouping === 'event'
						? results.metadata.marketingcustomCount
						: results.metadata.marketingcustomScore;
				meta.totalLeadScore = results.metadata.totalScore;
				meta.totalCount =
					(results.metadata.visitCount || 0) +
					(results.metadata.mailCount || 0) +
					(results.metadata.formCount || 0) +
					(results.metadata.marketingcustomCount || 0) +
					(results.metadata.manualCount || 0);
				meta.impressionCount = results.metadata.impressionCount;
			}

			return { data: results?.data ?? {}, metadata: meta };
		})
		.catch(error => logError(error, `controllers/accountMarket - getData`));
}

const MarketingChart = ({
	client,
	nrOfMonths,
	setNrOfMonths,
	marketGrouping,
	setMarketGrouping,
	subAccountIds
}: MarketingGraphProps) => {
	const { t } = useTranslation();

	const [graphData, setGraphData] = useState<MarketingGraphData>({});
	const [marketingMeta, setMarketingMeta] = useState(newMarketingMeta);

	useEffect(() => {
		getGraphData({ clientId: client.id, nrOfMonths, marketGrouping, subAccountIds })
			.then(res => {
				if (res) {
					setMarketingMeta(res.metadata);
					setGraphData(res.data);
				}
			})
			.catch(() => {});
	}, [nrOfMonths, marketGrouping]);

	const chartData = useMemo(() => {
		return buildChart({ data: graphData, marketGrouping, marketingMeta, nrOfMonths });
	}, [marketingMeta, graphData]);

	useEffect(() => {
		Highcharts.chart('clientCardMarketingGraph', chartData);
	}, [chartData]);

	return (
		<div id="market-chart">
			<div className="toggle-buttons">
				<ButtonSelect
					options={[
						{
							value: 3,
							title: `${3} ${t('default.months')}`
						},
						{
							value: 12,
							title: `${12} ${t('default.months')}`
						}
					]}
					onChange={setNrOfMonths}
					value={nrOfMonths}
				/>
				<ButtonSelect
					options={[
						{
							value: 'event',
							title: `${marketingMeta.totalCount} ${t('default.marketEvents').toLowerCase()}`
						},
						{
							value: 'score',
							title: `${marketingMeta.totalLeadScore} ${t('default.score').toLowerCase()}`
						}
					]}
					onChange={setMarketGrouping}
					value={marketGrouping}
				/>
			</div>
			<div id="clientCardMarketingGraph"></div>
		</div>
	);
};

export default MarketingChart;
