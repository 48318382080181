import { DateInput, NumberInput, Checkbox, Input, Textarea, TimeInput } from '@upsales/components';
import PhoneInput from 'Components/Inputs/PhoneInput';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import _ from 'lodash';

function createDebounce(fn, time) {
	let timeoutID = null;

	function debounce(...args) {
		clearTimeout(timeoutID);
		timeoutID = setTimeout(() => fn(...args), time);

		debounce.flush = function () {
			if (timeoutID) {
				clearTimeout(timeoutID);
				fn(...args);
			}
		};
	}

	return debounce;
}

ReactTemplates.customFieldInput = window.ReactCreateClass({
	// Stores the datepickerObject for.. yes.. datepickers
	datepicker: null,

	// Some value formatters
	formatters: {
		date: function (val) {
			if (val && moment(val).isValid()) {
				var v = moment(val).format('L');
				return v;
			}
			return val;
		},
		integer: function (val) {
			var parsed = parseInt(val);
			if (!isNaN(parsed)) {
				return parsed;
			}
			return val || '';
		},
		float: function (val) {
			var parsed = parseFloat(val);
			if (!isNaN(parsed)) {
				return parsed;
			}
			return val || '';
		}
	},

	validFloatInputSequenze(val) {
		// fudge complicated regexes, i will do it this way
		return val.replace(/\D/g, (match, offset, string) => {
			// minus sign is only allowed as first character
			if (match === '-' && offset === 0) {
				return match;
			}
			// only one dot sign is allowed and it most follow at least one digit
			else if (match === '.' && /^[-]?[\d]+$/.test(string.slice(0, offset))) {
				return match;
			}
			// replace all other non digits
			else {
				return '';
			}
		});
	},

	ngModel: function (onChange) {
		var ngModel = {};

		ngModel.$setViewValue = function (newValue) {
			onChange({ target: { value: newValue } });
		};
		ngModel.$render = function () {};

		return ngModel;
	},

	// Set initial value (before render)
	getInitialState: function () {
		this.hasUpdateCustomFieldsOnBlur = Tools.FeatureHelper.hasSoftDeployAccess('UPDATE_CUSTOM_FIELDS_ON_BLUR');
		this.debounceableTypes = ['currency', 'discount', 'integer', 'percent', 'string', 'email', 'text', 'link'];

		const field = this.props.field;
		const type = field.datatype && field.datatype.toLowerCase();
		this.isDebounceableType = this.debounceableTypes.includes(type);

		return { value: this.props.field.value, focused: false, data: null, dropdownDefaultWasSet: null };
	},

	// The onChange function for the field. Triggers valueChange so the scope gets updated in the directive
	handleChange: function (event, dropdownDefaultWasSet) {
		var val = event.target.value;
		var data = event.target.data;

		if (this.props.field.datatype === 'Currency') {
			val = this.validFloatInputSequenze(val);
		} else if (this.props.field.datatype === 'Boolean' && !this.props.isNew) {
			if (val === 'true' || val === true || val === 1) {
				val = false;
			} else {
				val = true;
			}
		}

		const shouldUpdateOnBlur =
			this.hasUpdateCustomFieldsOnBlur && this.props.updateOnBlur && this.isDebounceableType;
		// Do formatting here
		this.setState({ value: val, data, dropdownDefaultWasSet });
		if (!shouldUpdateOnBlur) {
			this.valueChange(val, data, dropdownDefaultWasSet);
		}
	},

	// The reason for this wrapping props valueChange, is so that this component can debounce it
	valueChange: function (...args) {
		this.props.valueChange(...args);
	},

	onBlur: function (e) {
		if (e?.target?.type === 'number') {
			e.target.removeEventListener('wheel', this.onWheel, { passive: false });
		}

		if (this.hasUpdateCustomFieldsOnBlur && this.props.updateOnBlur && this.isDebounceableType) {
			this.valueChange(this.state.value, this.state.data, this.state.dropdownDefaultWasSet);
		}
		this.setState({ focused: false });
	},

	onFocus: function (e) {
		if (e?.target?.type === 'number') {
			e.target.addEventListener('wheel', this.onWheel, { passive: false });
		}
		this.setState({ focused: true });
	},

	setSelect2Value(value) {
		const component = jQuery(ReactDOM.findDOMNode(this));

		if (component.select2) {
			if (this.props.multiple) {
				if (value === undefined || value === null) {
					component.select2('data', []);
				} else {
					const val = Array.isArray(value) ? value : [value];
					component.select2('data', val);
				}
			} else {
				if (typeof value === 'object') {
					component.select2('data', value);
				} else {
					component.select2('val', value);
				}
			}
		}
	},

	// Fired when react is done rendering the component after an update
	componentDidUpdate: function (prevProps) {
		const { field, placeholder } = this.props;
		// Update datepicker to initialize it
		if (field.datatype === 'Date' && this.datepicker) {
			this.datepicker.update(field.value);
		} else if (
			(field.datatype === 'Select' || (field.datatype === 'User' && !this.props.multiple)) &&
			!_.isEqual(prevProps.field.value, field.value)
		) {
			this.setSelect2Value(field.value);
		} else if (['Users', 'User'].includes(field.datatype) && !_.isEqual(prevProps.field.value, field.value)) {
			const mappedUsers = Tools.AppService.getActiveUsers().reduce((memo, user) => {
				memo[user.id] = { id: user.id, text: user.name };
				return memo;
			}, {});

			let nextValue = [];
			if (!Array.isArray(field.value) && field.value.length) {
				nextValue = field.value.split(',').map(a => mappedUsers[a]);
			} else if (Array.isArray(field.value)) {
				nextValue = field.value.map(a => mappedUsers[a]);
			}

			// Remove null/undefineds
			this.setSelect2Value(nextValue.filter(Boolean));
		}

		if (field.datatype === 'Select') {
			const optionsChanged = !_.isEqual(prevProps.field.default, field.default);
			const placeholderChanged = !_.isEqual(prevProps.placeholder, placeholder);
			if (optionsChanged || placeholderChanged) {
				this.initSelect(field, field.default);
			}
		}
		if (['Users', 'User'].includes(field.datatype) && !_.isEqual(prevProps.placeholder, placeholder)) {
			this.initUserSelect();
		}
		const numberFields = ['discount', 'percent', 'integer', 'currency'];
		const isNumberField = numberFields.includes(field.datatype?.toLowerCase());
		const valueChanged = field.value !== prevProps.field.value;
		const valueDiffersFromState = field.value !== this.state.value;
		const isEmpty = field.value === '';

		if (
			(isNumberField ? isEmpty || !Number.isNaN(Number.parseInt(field.value)) : true) &&
			valueChanged &&
			valueDiffersFromState
		) {
			this.setState({ value: field.value });
		}
	},
	onWheel: function (e) {
		e.preventDefault();
	},

	initSelect: function (field, data, isQuery) {
		const self = this;
		jQuery(ReactDOM.findDOMNode(this))
			.select2({
				allowClear: !field.obligatoryField,
				data: data,
				minimumInputLength: -1,
				query: isQuery,
				multiple: this.props.multiple,
				placeholder: this.props.placeholder || field.name,
				separator: '|',
				formatSelection: function (object, container, escape) {
					return escape(object);
				},
				formatResult: function (object, container, query, escape) {
					return escape(object);
				},
				id: function (object) {
					return object;
				},
				matcher: function (term, undef, item) {
					if (item.text && typeof item.text === 'string') {
						return item.text.toLowerCase().indexOf(term.toLowerCase()) !== -1;
					}
					return item.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1;
				}
			})
			.on('change', function (e) {
				if (self.props.multiple) {
					if (jQuery(ReactDOM.findDOMNode(self)).val() === '') {
						self.handleChange({ target: { value: [] } });
						return;
					}
					var val = jQuery(ReactDOM.findDOMNode(self)).val().split('|');
					self.handleChange({ target: { value: val, data: data } });
				} else {
					self.handleChange({ target: { value: e.val } });
				}
			});

		if (this.props.field.value || this.props.field.value === 0) {
			this.setSelect2Value(field.value);
		} else if (this.props.field.dropdownDefault) {
			const defaultValue = Array.isArray(this.props.field.dropdownDefault)
				? this.props.field.dropdownDefault[0]
				: this.props.field.dropdownDefault;
			this.setSelect2Value(defaultValue);

			setTimeout(() => {
				self.handleChange({ target: { value: defaultValue } }, true);
			}, 0);
		}
	},

	initUserSelect: function () {
		const self = this;
		const field = this.props.field;

		var activeUsers = Tools.AppService.getActiveUsers().map(function (user) {
			return { id: user.id, text: user.name };
		});
		const data = self.props.lookupType ? this.props.filterValues : activeUsers;

		let isQuery;

		if (self.props.lookupType && data === undefined) {
			isQuery = function (query) {
				var results = Tools.AppService.getActiveUsers().map(function (user) {
					return { id: user.id, text: user.name };
				});

				if (query.term) {
					results = results.filter(function (result) {
						return result.text.toLowerCase().includes(query.term.toLowerCase());
					});
				}

				query.callback({
					results: results
				});
			};
		}

		jQuery(ReactDOM.findDOMNode(this))
			.select2({
				allowClear: !field.obligatoryField,
				data: data,
				minimumInputLength: -1,
				query: isQuery,
				multiple: this.props.field.datatype === 'Users' || this.props.multiple,
				placeholder: this.props.placeholder || field.name,
				separator: ',',
				formatSelection: function (object, container, escape) {
					return escape(object.text);
				},
				formatResult: function (object, container, query, escape) {
					return escape(object.text);
				},
				id: function (object) {
					return object.id;
				},
				matcher: function (term, undef, item) {
					return item.text.toLowerCase().indexOf(term.toLowerCase()) !== -1;
				}
			})
			.on('change', function (e) {
				if (self.props.field.datatype === 'Users' || self.props.multiple) {
					if (jQuery(ReactDOM.findDOMNode(self)).val() === '') {
						self.handleChange({ target: { value: [] } });
						return;
					}
					var val = jQuery(ReactDOM.findDOMNode(self)).val();
					if (self.props.multiple) {
						val = val.split(',');
					}
					self.handleChange({ target: { value: val, data: data } });
				} else {
					self.handleChange({ target: { value: e.val } });
				}
			});
	},

	// Triggered when react is done creating the element
	// This is where we setup jQuery plugins on the input element
	componentDidMount: function () {
		var self = this;
		// Setup some jquery stuffs
		var field,
			data,
			isQuery = undefined;

		// Select 2 for users
		if (this.props.field.datatype === 'User' || this.props.field.datatype === 'Users') {
			this.initUserSelect();
		} else if (this.props.field.datatype === 'Select') {
			// Select 2 for selects
			field = this.props.field;
			/*
				I think filterValues is allways undefined here. It comes from ui/app/upsales/common/directives/upCustomField.js
				and in the first render it is undefined, it is only set after handleChange has been called (witch should be after this right)
				What the purpose of that prop was from the begining I have no ide about. But the only consequence of that is that data = []
				for as soon as props have a lookupType.
			*/
			data = self.props.lookupType ? this.props.filterValues : field.default;
			if (!Array.isArray(data)) {
				data = [];
			}

			// Make sure selected value is in data-array
			if (!this.props.multiple && field.value && field.value.length) {
				if (data.indexOf(field.value) === -1) {
					data.push(field.value);
				}
			}
			if (self.props.lookupType) {
				isQuery = function (query) {
					Tools.Lookup.customer(Tools.AppService.getCustomerId())
						.setType(self.props.lookupType)
						.findCustomValues(field.id, query.term, 10000)
						.then(function (res) {
							res.data = _.map(res.data, function (field) {
								field.value = field.value.trim();
								return field;
							});
							var searchResult = _.map(res.data, function (agg) {
								var found = _.find(field.default, function (defaultValue) {
									return defaultValue.toLowerCase().trim() === agg.value.toLowerCase().trim();
								});
								return found ? found : agg.value;
							});
							query.callback({ results: _.compact(searchResult) });
						})
						.catch(function (error) {
							console.error('customFieldInput.jsx componentDidMount', error);
							query.callback({ results: [] });
						});
				};
			}

			this.initSelect(field, data, isQuery);
		}

		// Date input gets
		if (self.props.field.datatype === 'Date' && !self.props.usenewdate) {
			var model = this.props.ngModel || new this.ngModel(self.handleChange);
			self.datepicker = Tools.$datepicker(jQuery(ReactDOM.findDOMNode(self)), model, {
				controller: model,
				autoclose: true,
				container: 'body',
				placement: 'auto',
				scope: {
					$digest: () => {},
					$new: () => null
				}
			});
			if (self.state.value) {
				self.datepicker.update(self.state.value);
			}
			self.datepicker.update();
		}

		// Time
		if (this.props.field.datatype === 'Time' && !this.props.useNewTime) {
			//jQuery timepicker config
			var pickerConfig = {};
			pickerConfig.step = 15;
			pickerConfig.appendTo = 'body';
			pickerConfig.timeFormat = 'H:i';
			pickerConfig.lang = { decimal: '.', mins: 'min', hr: 'h', hrs: 'h' };
			pickerConfig.forceRoundTime = false;
			pickerConfig.maxTime = '24:00';
			pickerConfig.scrollDefaultNow = true;

			jQuery(ReactDOM.findDOMNode(this))
				.timepicker(pickerConfig)
				.on('change', function (e) {
					self.handleChange(e);
				});
		}

		const useDebounce = this.props.useDebounce ?? true;
		if (
			useDebounce &&
			this.debounceableTypes.includes(this.props.field?.datatype?.toLowerCase()) &&
			(!this.hasUpdateCustomFieldsOnBlur || !this.props.updateOnBlur)
		) {
			this.valueChange = createDebounce(this.valueChange, this.props.inputDebounceSetting || 500);
		}
	},

	keyDown: function (event) {
		if (!event) {
			return;
		}
		this.flushOnkeyDown(event);

		if (this.props.field.datatype === 'Integer') {
			if (event.keyCode === 69) {
				event.preventDefault();
				return;
			}
		}
		if (this.hasUpdateCustomFieldsOnBlur && this.props.updateOnBlur && this.isDebounceableType) {
			if (event.keyCode === 13) {
				//Enter
				this.valueChange(this.state.value, this.state.date, this.state.dropdownDefaultWasSet);
				return;
			}
		}
	},

	flushOnkeyDown: function (event) {
		if (this.valueChange?.flush && ['Enter', 'Tab'].includes(event.code)) {
			this.valueChange.flush();
		}
	},

	// This builds the input element and runs when component is created/updated
	render: function () {
		var self = this;
		var field = self.props.field;
		var formatNumber = window.Tools.$filter('numberFormat');
		var isInput = true;
		var name = self.props.name || 'upCustomField_' + self.props.field.id;
		var value = self.state.value;
		var type = field.datatype && field.datatype.toLowerCase();
		var inputType = 'text';
		var min = null;
		var max = null;
		var step = null;
		var checked = null;
		var disabled = self.props.useExternalDisabled
			? self.props.disabled
			: !field.editable || field.locked || self.props.disabled
			? true
			: null;
		var required = field.obligatoryField ? true : null;
		var classNames = this.props.isNew ? '' : 'form-control up-input';
		var entity = self.props.entity;

		// Set max length depending on entity
		var maxLength = 65536;
		switch (entity) {
			case 'user':
			case 'appointment':
			case 'activity':
				maxLength = 255; // varchar(255)
				break;
			case 'orderrow':
			case 'contact':
			case 'account':
			case 'client':
				maxLength = 1024; // varchar(1024)
				break;
			case 'order':
			case 'agreement':
			case 'product':
			case 'udo':
			case 'ticket':
				maxLength = 65536; // text
				break;
		}

		if (field.maxLength > 0 && field.maxLength < maxLength) {
			maxLength = field.maxLength;
		}

		if (self.props.filter) {
			disabled = false;
		}

		switch (type) {
			case 'link':
				inputType = 'url';
				break;
			case 'email':
				inputType = 'email';
				break;
			case 'integer':
				inputType = 'number';
				value = self.formatters.integer(value);

				if (self.props.useNumberInput) {
					return (
						<NumberInput
							name={name}
							value={value}
							onBlur={this.hasUpdateCustomFieldsOnBlur ? this.onBlur : undefined}
							disabled={disabled}
							onChange={value => this.handleChange({ target: { value: value ?? '' } })}
							allowEmpty
							onKeyDown={this.hasUpdateCustomFieldsOnBlur ? this.keyDown : this.flushOnkeyDown}
							tabIndex={this.props.tabindex}
						/>
					);
				}

				break;
			case 'currency':
				inputType = 'text';

				if (this.state.focused) {
					value = value ?? '';
				} else {
					var parsed = parseFloat(value);
					if (value && !isNaN(parsed)) {
						value = Tools.$filter('number')(parsed);
					}
				}

				break;
			case 'discount':
				inputType = 'number';
				value = self.formatters.float(value);

				if (self.props.useNumberInput) {
					return (
						<NumberInput
							name={name}
							value={value}
							decimals={10}
							disabled={disabled}
							onBlur={this.hasUpdateCustomFieldsOnBlur ? this.onBlur : undefined}
							onChange={value => this.handleChange({ target: { value: value ?? '' } })}
							allowEmpty
							onKeyDown={this.hasUpdateCustomFieldsOnBlur ? this.keyDown : this.flushOnkeyDown}
							tabIndex={this.props.tabindex}
						/>
					);
				}

				break;
			case 'percent':
				inputType = 'number';
				min = 0;
				step = 'any';
				value = self.formatters.float(value);

				if (self.props.useNumberInput) {
					return (
						<NumberInput
							name={name}
							value={value}
							min={0}
							decimals={10}
							disabled={disabled}
							onBlur={this.hasUpdateCustomFieldsOnBlur ? this.onBlur : undefined}
							onChange={value => this.handleChange({ target: { value: value ?? '' } })}
							allowEmpty
							onKeyDown={this.hasUpdateCustomFieldsOnBlur ? this.keyDown : this.flushOnkeyDown}
							tabIndex={this.props.tabindex}
						/>
					);
				}

				break;
			case 'date':
				inputType = 'text';
				if (self.props.usenewdate) {
					value = typeof value === 'string' ? (value.length ? new Date(value) : undefined) : value;
					return (
						<DateInput
							name={name}
							closeOnSelect
							state={this.props.state || undefined}
							value={value}
							onChange={date => {
								this.handleChange({ target: { value: date.target.value?.toString() } });
							}}
							placeholder={this.props.placeholder}
							disabled={disabled}
							tabIndex={this.props.tabindex}
						/>
					);
				}
				value = self.formatters.date(value);

				break;
			case 'calculation':
				isInput = false;
				break;
			case 'time':
				inputType = 'text';
				max = 8;

				if (value && moment(value).isValid()) {
					var mmt = moment(value);
					var mmtMidnight = mmt.clone().startOf('day');
					var diffMinutes = mmt.diff(mmtMidnight, 'seconds');

					if (!diffMinutes) {
						var hours = Math.floor(diffMinutes / 3600);
						var minutes = Math.floor(diffMinutes / 60) - hours * 60;

						value = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);

						value = value || '00:00';
					}
				}
				if (self.props.useNewTime) {
					const usesTwelveHourFormat = moment('13:00', 'LT').format('LT').length > 5;
					return (
						<TimeInput
							name={name}
							min={`${min}`}
							max={`${max}`}
							state={this.props.state || undefined}
							step={15}
							value={typeof value !== 'number' ? value || '' : value}
							onChange={this.handleChange}
							placeholder={this.props.placeholder}
							disabled={disabled}
							tabIndex={this.props.tabindex}
							timeFormat={usesTwelveHourFormat ? 'military' : undefined}
							anchor={this.props.anchor}
							portalPlacement="bottom"
						/>
					);
				}
				break;
			case 'string':
				value = value ?? '';
				break;
			case 'boolean':
				if (this.props.isNew) {
					return (
						<Checkbox
							size="sm"
							name={name}
							checked={!!value}
							disabled={disabled}
							onChange={value => this.handleChange({ target: { value } })}
							tabIndex={this.props.tabindex}
						/>
					);
				}
				inputType = 'checkbox';
				checked = !!value;
				classNames = 'up-input';
				break;
			case 'text':
				if (this.props.isNew) {
					return (
						<Textarea
							state={this.props.state}
							name={name}
							className={classNames}
							onChange={this.handleChange}
							placeholder={this.props.placeholder}
							disabled={disabled}
							onBlur={this.hasUpdateCustomFieldsOnBlur ? this.onBlur : undefined}
							required={required}
							tabIndex={this.props.tabindex}
							value={value || ''}
							maxLength={maxLength}
						/>
					);
				}
				return (
					<textarea
						name={name}
						className={classNames}
						onChange={this.handleChange}
						placeholder={this.props.placeholder}
						disabled={disabled}
						required={required}
						tabIndex={this.props.tabindex}
						onBlur={this.hasUpdateCustomFieldsOnBlur ? this.onBlur : undefined}
						value={value || ''}
						maxLength={maxLength}
					/>
				);
			case 'select':
				inputType = 'hidden';

				if (self.props.multiple) {
					if (value && !Array.isArray(value)) {
						value = [value];
					}

					value = (value || []).join('|');
				}
				break;
			case 'phone': {
				if (Tools.FeatureHelper.hasSoftDeployAccess('CLICKABLE_PHONE_LINK')) {
					return (
						<PhoneInput
							name={name}
							state={this.props.state}
							phone={value}
							required={required}
							iconClass="phone"
							onChange={phoneNumber => {
								this.handleChange({ target: { value: phoneNumber } });
							}}
						/>
					);
				}
				break;
			}
			case 'user':
			case 'users':
				inputType = 'hidden';
		}

		const hasFormulaVisible = Tools.FeatureHelper.hasSoftDeployAccess('FORMULA_VISIBLE');

		if (isInput) {
			if (this.props.isNew && inputType !== 'hidden') {
				return (
					<Input
						state={this.props.state}
						data-autocomplete="nope"
						name={name}
						min={min}
						max={max}
						step={step}
						onKeyDown={this.keyDown}
						className={classNames}
						type={inputType}
						value={typeof value !== 'number' ? value || '' : value}
						onChange={this.handleChange}
						placeholder={this.props.placeholder}
						checked={checked}
						disabled={disabled}
						required={required}
						tabIndex={this.props.tabindex}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						maxLength={maxLength}
					/>
				);
			}
			return (
				<Fragment>
					<input
						data-autocomplete="nope"
						name={this.props.isNew ? undefined : name}
						min={min}
						max={max}
						step={step}
						onKeyDown={this.keyDown}
						className={
							(this.props.isNew ? 'form-control' : classNames) +
							(this.props.isNew && this.props.state === 'error' ? ' has-error' : '')
						}
						type={inputType}
						value={typeof value !== 'number' ? value || '' : value}
						onChange={this.handleChange}
						placeholder={this.props.placeholder}
						checked={checked}
						disabled={disabled}
						required={required}
						tabIndex={this.props.tabindex}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						maxLength={maxLength}
					/>
					{/* To be able to scroll to elment by name */}
					{this.props.isNew && inputType === 'hidden' ? <span name={name} /> : null}
				</Fragment>
			);
		} else {
			return (
				<span
					className={`calculation-field-value ${
						!hasFormulaVisible || this.props.field.formulaVisible ? '' : 'hide-icon'
					}`}
				>
					<span>{formatNumber(value, true, 2, false, 0)}</span>
				</span>
			);
		}
	}
});
