import SalesGraph, { Groupings, WidgetType } from 'App/components/ClientSales/SalesGraph/SalesGraph';
import {
	fetcherOrders,
	fetcherAgreements,
	getSummedOrders,
	getSummedAgreements,
	fetcherARRChanges,
	getSummedChanges,
	fetcherClients
} from './helpers';
import { OldOrNewAgreement } from 'App/components/SubscriptionCards/SubscriptionCards';
import { useCompanyGroupSelector } from '../Context/CompanyGroupContext';
import { useGetData, useSoftDeployAccess } from 'App/components/hooks';
import React, { useMemo, useState } from 'react';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import SubscriptionCards from 'App/components/SubscriptionCards';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import { useMetadata } from 'App/components/hooks/appHooks';
import { NumberFormat } from 'App/babel/utils/numberFormat';
import GroupSubscriptionList from './GroupSubscriptionList';
import BemClass from '@upsales/components/Utils/bemClass';
import { Colors } from '@upsales/components/Utils/colors';
import { Toggle, Flex, Text } from '@upsales/components';
import GroupCompanyToggle from './GroupCompanyToggle';
import Agreement from 'App/resources/Model/Agreement';
import { ARRChange } from 'App/resources/Model/ARRChange';
import SalesTarget from './SalesTarget/SalesTarget';
import ButtonSelectGroup from './ButtonSelectGroup';
import Client from 'App/resources/Model/Client';
import Order from 'App/resources/Model/Order';
import GrowthGrid from './GrowthGrid';
import SalesList from './SalesList';
import BigNumber from './BigNumber';

import './SalesTabGroup.scss';

type Props = {
	clientIds: number[];
	isGroupBySubAccount?: boolean;
	client?: Client;
};

export type BigNumberProps = {
	value: string;
	label: string;
	loading?: boolean;
	color?: Colors;
};

export type FetcherProps = {
	clientIds: number[];
	isGroupBySubAccount: boolean;
	hasRecurring?: boolean;
};

const LIMIT = 1000;

export const Detached = ({ clientIds: initialClientIds, client, isGroupBySubAccount = false }: Props) => {
	const metadata = useMetadata();
	const currency = metadata?.defaultCurrency || { iso: 'SEK', rate: 1 };
	const { SalesModelOption, SalesModel: salesModel } = metadata?.params || {};
	const { t } = useTranslation();
	const hasRecurring = salesModel === 'rr';
	const hasMRR = hasRecurring && SalesModelOption === 'mrr';
	const hasSales = salesModel === 'sales';
	const numberFormat = new NumberFormat();
	const [grouping, setGrouping] = useState<Groupings>(Groupings.YEAR);
	const [widgetType, setWidgetType] = useState<WidgetType>(
		hasRecurring ? WidgetType.TOTAL_RECURRING : WidgetType.CASH_FLOW
	);
	const [agreementGroups, setAgreementGroups] = useState<OldOrNewAgreement[]>([]);
	const [groupByCompany, setGroupByCompany] = useState(false);
	const [clientIds, setClientIds] = useState<number[]>([]);

	const groupingLabel = isGroupBySubAccount ? t('account.subaccount') : t('company');
	const hasClientTarget = useSoftDeployAccess('CLIENT_TARGET');
	const operationalAccountId = client?.operationalAccount?.id;
	const numberOfSubaccounts = client?.numberOfSubaccounts;

	useGetData<FetcherProps, Client>({
		fetcher: fetcherClients,
		fetcherProps: {
			clientIds: initialClientIds,
			isGroupBySubAccount
		},
		onFetched: ({ data: clients }) => {
			const ids: number[] = [];
			clients.forEach(client => {
				ids.push(client.id);
			});
			setClientIds(ids);
		},
		limit: LIMIT
	});

	const { data: orders, loading: loadingOrders } = useGetData<FetcherProps, Order>({
		fetcher: fetcherOrders,
		fetcherProps: {
			clientIds: initialClientIds,
			isGroupBySubAccount,
			hasRecurring
		},
		limit: LIMIT,
		broadcastTypes: ['order.added', 'order.updated', 'order.deleted', 'opportunity.deleted']
	});

	const { data: agreements, loading: loadingAgreements } = useGetData<FetcherProps, Agreement>({
		fetcher: fetcherAgreements,
		fetcherProps: {
			clientIds: initialClientIds,
			isGroupBySubAccount,
			hasRecurring
		},
		limit: LIMIT,
		broadcastTypes: [
			'agreementGroup.added',
			'agreementGroup.updated',
			'agreementGroup.deleted',
			'agreement.deleted',
			'agreement.added',
			'agreement.updated'
		]
	});

	const { data: agreementChanges, loading: loadingChanges } = useGetData<FetcherProps, ARRChange>({
		fetcher: fetcherARRChanges,
		fetcherProps: {
			clientIds: initialClientIds,
			isGroupBySubAccount,
			hasRecurring
		},
		limit: LIMIT,
		broadcastTypes: [
			'agreementGroup.added',
			'agreementGroup.updated',
			'agreementGroup.deleted',
			'agreement.deleted',
			'agreement.added',
			'agreement.updated'
		]
	});

	const { sumOrder, sumCM } = orders.length ? getSummedOrders(orders, currency) : { sumOrder: 0, sumCM: 0 };
	const sumARR = agreements.length ? getSummedAgreements(agreements, currency) : 0;
	const { sumChangesARR, sumChangesMRR } = agreementChanges.length
		? getSummedChanges(agreementChanges, currency)
		: { sumChangesARR: 0, sumChangesMRR: 0 };

	const salesValues = {
		arr: sumARR,
		sales: sumOrder,
		cm: sumCM,
		mrr: sumARR / 12,
		arrChange: sumChangesARR,
		mrrChange: sumChangesMRR
	};

	let realGrouping = grouping;
	if (widgetType === WidgetType.PRODUCT) {
		realGrouping = Groupings.PRODUCT;
	}

	let realWidgetType = widgetType;
	if (widgetType === WidgetType.PRODUCT && salesModel !== 'rr') {
		realWidgetType = WidgetType.CASH_FLOW;
	}

	const showClientName = !operationalAccountId && numberOfSubaccounts !== 0;

	const content = useMemo(() => {
		switch (widgetType) {
			case WidgetType.RECURRING:
			case WidgetType.TOTAL_RECURRING:
				return groupByCompany && clientIds.length ? (
					<GroupSubscriptionList
						clientIds={clientIds}
						isGroupBySubAccount={isGroupBySubAccount}
						groupCompanyToggle={
							<Flex space="pll prl">
								<Toggle
									checked={groupByCompany}
									onChange={() => setGroupByCompany(!groupByCompany)}
									space="mrm"
								/>
								<Text>{`${t('default.groupBy')} ${groupingLabel.toLowerCase()}`}</Text>
							</Flex>
						}
						client={client}
						agreementGroups={agreementGroups}
					/>
				) : !client && clientIds.length ? (
					<SubscriptionCards
						showClientName={showClientName}
						hasMRR={hasMRR}
						clientId={clientIds[0]}
						subAccountIds={[...clientIds].splice(1)}
						clientIdsInCompanyGroup={clientIds}
						isGroupBySubAccount={isGroupBySubAccount}
						setItems={setAgreementGroups}
						groupCompanyToggle={
							showClientName ? (
								<GroupCompanyToggle
									groupByCompany={groupByCompany}
									onChange={() => setGroupByCompany(!groupByCompany)}
									groupingLabel={groupingLabel}
								/>
							) : null
						}
					/>
				) : client && clientIds.length ? (
					<SubscriptionCards
						showClientName={showClientName}
						hasMRR={hasMRR}
						client={client as Client}
						subAccountIds={clientIds.filter(id => id !== client.id)}
						isGroupBySubAccount={isGroupBySubAccount}
						setItems={setAgreementGroups}
						groupCompanyToggle={
							showClientName ? (
								<GroupCompanyToggle
									groupByCompany={groupByCompany}
									onChange={() => setGroupByCompany(!groupByCompany)}
									groupingLabel={groupingLabel}
								/>
							) : null
						}
					/>
				) : null;
			case WidgetType.PRODUCT:
				return (
					<GrowthGrid
						clientIds={clientIds}
						setGrouping={setGrouping}
						grouping={grouping}
						isGroupBySubAccount={isGroupBySubAccount}
					/>
				);
		}
	}, [widgetType, grouping, groupByCompany, clientIds]);

	const listContent = useMemo(() => {
		switch (widgetType) {
			case WidgetType.PRODUCT:
				return null;
			default:
				return clientIds.length ? (
					<SalesList
						clientIds={clientIds}
						widgetType={realWidgetType}
						grouping={realGrouping}
						isGroupBySubAccount={isGroupBySubAccount}
						isSubaccount={!!operationalAccountId}
					/>
				) : null;
		}
	}, [widgetType, realGrouping, clientIds]);

	const classes = new BemClass('SalesTabGroup');

	let bigNumbers: BigNumberProps[] = [
		{
			loading: loadingAgreements,
			value: currencyFormat((hasMRR ? salesValues.mrr : salesValues.arr ?? 0) * currency.rate, currency.iso),
			label: hasMRR ? t('default.currentMRR') : t('default.currentARR')
		},
		{
			loading: loadingChanges,
			value: `${(hasMRR ? salesValues.mrrChange : salesValues.arrChange ?? 0) >= 0 ? '+' : ''}${currencyFormat(
				(hasMRR ? salesValues.mrrChange : salesValues.arrChange ?? 0) * currency.rate,
				currency.iso
			)}`,
			label: hasMRR ? t('account.recurring.mrr12months') : t('account.recurring.arr12months'),
			color: (hasMRR ? salesValues.mrrChange : salesValues.arrChange ?? 0) >= 0 ? 'success-5' : 'red'
		}
	];

	if (salesModel === 'sales') {
		bigNumbers = [
			{
				loading: loadingOrders,
				value: currencyFormat((salesValues.sales ?? 0) * currency.rate, currency.iso),
				label: t('default.sales12Months')
			}
		];
	} else if (salesModel === 'cm') {
		bigNumbers = [
			{
				loading: loadingOrders,
				value: currencyFormat((salesValues.sales ?? 0) * currency.rate, currency.iso),
				label: t('default.sales12Months')
			},
			{
				loading: loadingOrders,
				value: currencyFormat((salesValues.cm ?? 0) * currency.rate, currency.iso),
				label: t('default.contributionMargin12Months')
			},
			{
				loading: loadingOrders,
				value: numberFormat.percent((salesValues.cm ?? 0) / (salesValues.sales || 1)),
				label: t('default.contributionMarginRatio12Months')
			}
		];
	}

	return (
		<Flex direction="column" className={classes.b()}>
			<Flex direction="column" className={classes.elem('topSection').b()} space="ptl pbxl">
				<Flex>
					<Flex
						direction="column"
						gap="u8"
						space="plxl prxl"
						className={classes.elem('topSection').elem('bigNumbers').b()}
					>
						{bigNumbers.map(({ value, label, loading, color }, index) => (
							<BigNumber key={index} value={value} label={label} loading={loading} color={color} />
						))}
						{isGroupBySubAccount && client && hasClientTarget ? (
							<SalesTarget
								quotas={client.clientQuotas}
								clientId={client.id}
								salesThisYear={salesValues.sales}
								cmThisYear={salesValues.cm}
								arrThisYear={salesValues.arr}
								mrrThisYear={salesValues.mrr}
							/>
						) : null}
					</Flex>
					{initialClientIds.length ? (
						<Flex
							direction="column"
							className={classes.elem('topSection').elem('widget').b()}
							space="ptl mrxl"
						>
							<ButtonSelectGroup
								grouping={grouping}
								widgetType={widgetType}
								onGroupingChange={v => {
									if (hasSales && (v === Groupings.PRODUCT || v === Groupings.PRODUCT_CATEGORY)) {
										setWidgetType(WidgetType.PRODUCT);
									} else if (hasSales) {
										setWidgetType(WidgetType.CASH_FLOW);
									}
									setGrouping(v);
								}}
								onWidgetChange={v => {
									setWidgetType(v);
									if (v === WidgetType.PRODUCT) {
										setGrouping(Groupings.PRODUCT);
									} else {
										setGrouping(Groupings.YEAR);
									}
								}}
							/>
							<SalesGraph
								hideGoal
								height={2}
								grouping={grouping}
								widgetType={realWidgetType}
								clientId={initialClientIds[0]}
								subAccountIds={[...initialClientIds].splice(1)}
								isGroupBySubAccount={isGroupBySubAccount}
							/>
						</Flex>
					) : null}
				</Flex>
			</Flex>
			{initialClientIds.length ? (
				<Flex direction="column">
					{content}
					{listContent}
				</Flex>
			) : (
				<Flex justifyContent="center">
					<DefaultNoData subtitle="" formatNoData={() => t('default.noResults')} />
				</Flex>
			)}
		</Flex>
	);
};

const SalesTab = ({ ...props }: Omit<Props, 'clientIds'>) => {
	const { clientIds } = useCompanyGroupSelector(({ clientIds }) => ({
		clientIds
	}));

	return <Detached {...props} clientIds={clientIds} />;
};

export default SalesTab;
