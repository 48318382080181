import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { init, updateRelations, resetGroupSize, addAllAccounts } from 'Store/reducers/AccountReducer';
import Bem from '@upsales/components/Utils/bemClass';
import T from '../../Helpers/translate';
import GenericRelationTable from './GenericRelationTable';
import GroupSize from './GroupSize';
import './AccountRelations.scss';
import CompanyGroupAccountRelations from './CompanyGroupAccountRelations';

const mapStateToProps = state => ({
	loading: state.Account.loading,
	relations: state.Account.relations,
	customRelations: state.Account.customRelations,
	siblings: state.Account.siblings,
	subsidiaries: state.Account.subsidiaries,
	hasLoaded: state.Account.hasLoaded,
	showGroupSize: state.Account.showGroupSize,
	tree: state.Account.tree
});

const mapDispatchToProps = {
	init,
	resetGroupSize,
	updateRelations,
	addAllAccounts
};

class AccountRelations extends React.Component {
	constructor(props) {
		super(props);

		const { scope, init, updateRelations } = this.props;

		this.state = { view: 'groupTree' };

		this.listeners = [
			Tools.$rootScope.$on('account.merged', () => {
				init(scope.AccountCtrl.account);
			}),

			Tools.$rootScope.$on('accountRelation.added', (_e, added) => {
				updateRelations();
				if (Tools.$state.$current.self.name === 'react-root-clientCard') {
					scope.AccountCtrl.account.connectedClients.push(added);
				}
			}),

			Tools.$rootScope.$on('accountRelation.updated', (_e, updated) => {
				updateRelations();
				if (Tools.$state.$current.self.name === 'react-root-clientCard') {
					if (
						updated.clientId === scope.AccountCtrl.account.id ||
						updated.relatedToClientId === scope.AccountCtrl.account.id
					) {
						const index = _.findIndex(scope.AccountCtrl.account.connectedClients, { id: updated.id });

						if (index !== -1) {
							scope.AccountCtrl.account.connectedClients[index] = updated;
						} else {
							scope.AccountCtrl.account.connectedClients.push(updated);
						}
					}
				}
			}),

			Tools.$rootScope.$on('accountRelation.deleted', (_e, deleted) => {
				updateRelations();
				if (Tools.$state.$current.self.name === 'react-root-clientCard') {
					const index = _.findIndex(scope.AccountCtrl.account.connectedClients, { id: deleted.id });

					if (index !== -1) {
						scope.AccountCtrl.account.connectedClients.splice(index, 1);
					}
				}
			})
		];
	}

	componentDidMount() {
		const { init, scope } = this.props;
		init(scope.AccountCtrl.account);
	}

	componentWillUnmount() {
		this.listeners.forEach(listener => listener());
		this.props.resetGroupSize();
	}

	componentDidUpdate(prevProps) {
		const { init, scope } = this.props;

		if (scope.AccountCtrl.account.id !== prevProps.scope.AccountCtrl.account.id) {
			init(scope.AccountCtrl.account);
		}
	}

	render() {
		const MainClass = new Bem('AccountRelations');
		const { subsidiaries, showGroupSize, scope, customRelations, hasLoaded, tree, addAllAccounts } = this.props;
		const hasSplitButtonClientCard = Tools.FeatureHelper.hasSoftDeployAccess('SPLIT_BUTTON_CLIENT_CARD');
		const hasGroupBonanza = Tools.FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA');
		const hasCompanyGroupCardFeature =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.COMPANY_GROUP_CARD) && hasGroupBonanza;

		const subsidiaryRelationsWithDesc = subsidiaries.map(subsidiary => ({
			...subsidiary,
			descriptionChildParent: T('client.subsidiary')
		}));
		const combinedRelations = [...customRelations, ...subsidiaryRelationsWithDesc];

		if (hasLoaded === false) {
			return null;
		}

		const TableList =
			hasCompanyGroupCardFeature && showGroupSize
				? []
				: [
						<GenericRelationTable
							key="connected-clients"
							account={scope.AccountCtrl}
							data={customRelations}
							hideButton={hasSplitButtonClientCard}
							title={T('client.connectedClients')}
							buttonText={`${T('add')} ${T('default.relation').toLowerCase()}`}
							openAccount={id => Tools.$state.go('account.dashboard', { id: id })}
							showGroupSize={showGroupSize}
							columns={[
								{ title: T('default.name') },
								{ title: T('default.relation') },
								{ title: T('default.history') }
							]}
						/>
				  ];

		if (
			(subsidiaries.length && !hasCompanyGroupCardFeature) ||
			(subsidiaries.length && hasCompanyGroupCardFeature && !showGroupSize)
		) {
			TableList.unshift(
				<GenericRelationTable
					key="subsidiaries"
					account={scope.AccountCtrl}
					data={subsidiaries}
					title={T('client.subsidiaries')}
					hideButton
					isSubsidiary
					columns={[{ title: T('default.name') }, { title: T('default.history') }]}
					openAccount={id => Tools.$state.go('account.dashboard', { id: id })}
				/>
			);
		}

		if (hasCompanyGroupCardFeature && showGroupSize) {
			if (scope.AccountCtrl.account.prospectingId) {
				TableList.push(
					<CompanyGroupAccountRelations
						key="group-bonanza"
						prospectingId={scope.AccountCtrl.account.prospectingId}
						tree={tree}
						setView={view => this.setState({ view })}
						view={this.state.view}
						addAllAccounts={addAllAccounts}
						manualRelationsCounter={combinedRelations.length}
					/>
				);

				if (this.state.view === 'groupTree') {
					TableList.push(<GroupSize isRelationsTab key={'group-tree'} />);
				} else if (this.state.view === 'manualRelations') {
					TableList.push(
						<GenericRelationTable
							key="connected-clients"
							account={scope.AccountCtrl}
							data={combinedRelations}
							hideButton={hasSplitButtonClientCard}
							title={T('client.connectedClients')}
							buttonText={`${T('add')} ${T('default.relation').toLowerCase()}`}
							openAccount={id => Tools.$state.go('account.dashboard', { id: id })}
							columns={[
								{ title: T('default.name') },
								{ title: T('default.relation') },
								{ title: T('default.history') }
							]}
						/>
					);
				}
			} else {
				TableList.push(
					<GenericRelationTable
						key="connected-clients"
						account={scope.AccountCtrl}
						data={combinedRelations}
						noCompanyGroup
						hideButton={hasSplitButtonClientCard}
						title={T('client.connectedClients')}
						buttonText={`${T('add')} ${T('default.relation').toLowerCase()}`}
						openAccount={id => Tools.$state.go('account.dashboard', { id: id })}
						columns={[
							{ title: T('default.name') },
							{ title: T('default.relation') },
							{ title: T('default.history') }
						]}
					/>
				);
			}
		}
		if (!hasCompanyGroupCardFeature && showGroupSize) {
			TableList.push(<GroupSize key={'group-tree'} />);
		}

		return <div className={MainClass.mod({ isRelationsTab: hasCompanyGroupCardFeature })}>{TableList}</div>;
	}
}

AccountRelations.propTypes = {
	scope: PropType.object,
	init: PropType.func,
	subsidiaries: PropType.array,
	customRelations: PropType.array,
	hasLoaded: PropType.bool,
	updateRelations: PropType.func,
	resetGroupSize: PropType.func,
	showGroupSize: PropType.bool,
	tree: PropType.object,
	addAllAccounts: PropType.func
};

export const detached = AccountRelations;
const Component = connect(mapStateToProps, mapDispatchToProps)(AccountRelations);
window.AccountRelations = Component;
export default Component;
