import SubaccountsColumn from 'Components/Account/AccountListContacts/Columns/SubaccountsColumn';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import { EllipsisTooltip, Flex, Row, TableColumn, TableRow, Text, ButtonSelect } from '@upsales/components';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import { BasicUser, BasicUserWithPermissions } from 'App/resources/Model/User';
import DefaultNoData from 'App/components/ListView/DefaultNoData';
import Client, { ClientIdName } from 'App/resources/Model/Client';
import SimpleListView from 'App/pages/ClientCard/SimpleListView';
import { useSupportUsers } from 'App/components/hooks/appHooks';
import GenericHref from 'Components/Helpers/Hrefs/GenericHref';
import BemClass from '@upsales/components/Utils/bemClass';
import { HashRouter as Router } from 'react-router-dom';
import { dateCalendar } from 'App/helpers/DateHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import type Ticket from 'App/resources/Model/Ticket';
import { Equals } from 'Resources/ComparisonTypes';
import { ClientColumn } from 'App/pages/CompanyGroupCard/Helpers/SubaccountV2Helpers';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import TicketResource from 'App/resources/Ticket';
import Contact from 'App/resources/Model/Contact';
import TicketAttributes from 'Attributes/Ticket';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';
import logError from 'Helpers/logError';
import Avatar from 'Components/Avatar';
import React, { useState, useEffect, useRef } from 'react';

import './ClientContactCardTicketList.scss';

const COLUMNS_OPEN_CONTACT = ['title', 'regDate', 'user'];
const COLUMNS_CLOSED_CONTACT = ['title', 'resolveDate', 'user'];
const className = new BemClass('ClientCardContent__Support');

type SupportProps = {
	client: Client | Contact;
	isClient: boolean;
	subaccountIds?: number[];
};

type GetDataProps = SupportProps & {
	rb: RequestBuilder;
	getOpenTickets?: boolean;
	getArchivedTickets?: boolean;
	subaccountIds?: number[];
	limit?: number;
};

const getData = ({
	limit,
	client,
	isClient,
	rb,
	getOpenTickets,
	getArchivedTickets,
	subaccountIds
}: GetDataProps): Promise<{ data: Ticket[]; metadata: { total: number } }> => {
	if (getOpenTickets !== undefined) {
		rb.addSort({ field: getOpenTickets ? 'regDate' : 'resolveDate' }, false);
		rb.addFilter({ field: 'status.closed' }, Equals, getOpenTickets ? false : true);
	} else {
		rb.addSort({ field: 'regDate' }, false);
	}

	if (getArchivedTickets) {
		rb.addFilter({ field: 'isArchived' }, Equals, true);
	} else {
		rb.addFilter({ field: 'isArchived' }, Equals, false);
	}

	if (isClient) {
		rb.addFilter({ field: 'client.id' }, Equals, [client.id, ...(subaccountIds || [])]);
	} else {
		rb.addFilter({ field: 'contact.id' }, Equals, client.id);
	}

	if (limit) {
		rb.limit = limit;
	}

	return TicketResource.find(rb.build()).catch(error => {
		logError(error);
		return { data: [], metadata: { total: 0 } };
	});
};

export const renderTableRow = (
	ticket: Ticket,
	{ columns, attributes, categories }: ListViewTableProvided<Ticket>,
	users: BasicUserWithPermissions[],
	subaccountIds: number[],
	isOperationalAccount: boolean,
	hasSubaccountsV2?: boolean
) => {
	const foundUser = users.filter(u => !u.ghost).find(u => u.id === ticket.user?.id);
	const userIsInactive: boolean = !foundUser && !!ticket.user;
	const columnElements = columns.map(column => {
		let content = null;

		switch (column) {
			case 'title':
				return (
					<TableColumn className={className.elem('subject').b()} key={column + ticket.id}>
						<EllipsisTooltip title={ticket.title}>
							<Text size="sm">{ticket.title}</Text>
						</EllipsisTooltip>
					</TableColumn>
				);
			case 'subaccount':
				return isOperationalAccount && !hasSubaccountsV2 ? (
					<TableColumn>
						{ticket.client && subaccountIds.includes(ticket.client?.id) ? (
							<SubaccountsColumn client={ticket.client as ClientIdName} />
						) : null}
					</TableColumn>
				) : (
					<ClientColumn item={ticket} />
				);
			case 'contact':
				content = ticket.contact ? (
					<GenericHref type="contact" text={ticket.contact?.name} id={ticket.contact?.id} />
				) : (
					<Text size="sm" italic color="grey-10">
						{T('default.noContact')}
					</Text>
				);
				break;
			case 'user':
				content = (
					<EllipsisTooltip
						title={
							ticket.user
								? userIsInactive
									? `${ticket.user.name} (${T('default.inactive')})`
									: ticket.user.name
								: T('support.unassigned')
						}
						className={className.elem('user-name').b()}
					>
						{ticket.user ? (
							<>
								<Avatar user={ticket.user as BasicUser} size={20} />
								<Text size="sm">
									{userIsInactive
										? `${ticket.user.name} (${T('default.inactive')})`
										: ticket.user.name}
								</Text>
							</>
						) : (
							<Text size="sm" italic color="grey-10">
								{T('support.unassigned')}
							</Text>
						)}
					</EllipsisTooltip>
				);
				break;
			case 'regDate':
				content = <Text size="sm">{dateCalendar(new Date(ticket.regDate), true) as string}</Text>;
				break;
			case 'resolveDate':
				content = (
					<Text size="sm">
						{
							dateCalendar(
								new Date(ticket.resolveDate ? ticket.resolveDate : ticket.modDate),
								true
							) as string
						}
					</Text>
				);
				break;
			default:
				return (
					<ListViewDefaultColumn<Ticket>
						key={column}
						item={ticket}
						attributes={attributes}
						column={column}
						categories={categories}
					/>
				);
		}

		return <TableColumn key={column + ticket.id}>{content}</TableColumn>;
	});

	return (
		<TableRow key={ticket.id} onClick={() => openModal('EditTicket', { ticketId: ticket.id })}>
			{columnElements}
		</TableRow>
	);
};

const renderOpenTableRow =
	(
		users: BasicUserWithPermissions[],
		subaccountIds: number[],
		isOperationalAccount: boolean,
		hasSubaccountsV2: boolean
	) =>
	(ticket: Ticket, { columns, attributes, categories }: ListViewTableProvided<Ticket>) => {
		return renderTableRow(
			ticket,
			{ columns, attributes, categories } as ListViewTableProvided<Ticket>,
			users,
			subaccountIds,
			isOperationalAccount,
			hasSubaccountsV2
		);
	};

const renderClosedTableRow =
	(
		users: BasicUserWithPermissions[],
		subaccountIds: number[],
		isOperationalAccount: boolean,
		hasSubaccountsV2: boolean
	) =>
	(ticket: Ticket, { columns, attributes, categories }: ListViewTableProvided<Ticket>) => {
		return renderTableRow(
			ticket,
			{ columns, attributes, categories } as ListViewTableProvided<Ticket>,
			users,
			subaccountIds,
			isOperationalAccount,
			hasSubaccountsV2
		);
	};

const renderArchivedTableRow =
	(
		users: BasicUserWithPermissions[],
		subaccountIds: number[],
		isOperationalAccount: boolean,
		hasSubaccountsV2: boolean
	) =>
	(ticket: Ticket, { columns, attributes, categories }: ListViewTableProvided<Ticket>) => {
		return renderTableRow(
			ticket,
			{ columns, attributes, categories } as ListViewTableProvided<Ticket>,
			users,
			subaccountIds,
			isOperationalAccount,
			hasSubaccountsV2
		);
	};

const TicketList = (props: SupportProps) => {
	const users = useSupportUsers('active');
	const columnsOpenClient = ['title', 'contact', 'regDate', 'user'];
	const columnsClosedClient = ['title', 'contact', 'resolveDate', 'user'];
	const subaccountIds = props.subaccountIds ?? [];
	const fetchTicketCountsReq = useRef<CancelablePromise | null>(null);
	const [view, setView] = useState('all');
	const [ticketCounts, setTicketCounts] = useState({ open: 0, closed: 0, archived: 0 });


	const hasSubaccountsV1 = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
	const hasSubaccountsFeature = useFeatureAvailable(Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
	const hasSubaccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;

	const isOperationalAccount = hasSubaccounts && subaccountIds.length > 0;

	if (isOperationalAccount) {
		columnsOpenClient.splice(1, 0, 'subaccount');
		columnsClosedClient.splice(1, 0, 'subaccount');
	}

	if (hasSubaccountsFeature) {
		TicketAttributes.subaccount.title = T('account');
	}

	useEffect(() => {
		const fetchCounts = () => {
			fetchTicketCountsReq.current?.cancel();

			fetchTicketCountsReq.current = makeCancelable(
				Promise.all([
					//open
					getData({
						limit: 0,
						client: props.client,
						isClient: props.isClient,
						rb: new RequestBuilder(),
						getOpenTickets: true,
						subaccountIds
					}),
					//closed
					getData({
						limit: 0,
						client: props.client,
						isClient: props.isClient,
						rb: new RequestBuilder(),
						getOpenTickets: false,
						subaccountIds
					}),
					//archived
					getData({
						limit: 0,
						client: props.client,
						isClient: props.isClient,
						rb: new RequestBuilder(),
						getArchivedTickets: true,
						subaccountIds
					})
				])
			);

			fetchTicketCountsReq.current.promise
				.then(([openData, closedData, archivedData]) => {
					setTicketCounts({
						open: openData.metadata.total,
						closed: closedData.metadata.total,
						archived: archivedData.metadata.total
					});
				})
				.catch(error => {
					logError(error);
				});
		};

		fetchCounts();

		const listeners = [
			Tools.$rootScope.$on('ticket.updated', () => setTimeout(() => fetchCounts(), 1000)),
			Tools.$rootScope.$on('ticket.deleted', () => setTimeout(() => fetchCounts(), 1000)),
			Tools.$rootScope.$on('ticket.added', () => setTimeout(() => fetchCounts(), 1000))
		];

		return () => {
			fetchTicketCountsReq.current?.cancel();
			listeners.forEach(unsub => unsub());
		};
	}, [props.client, props.isClient, subaccountIds]);

	const TICKET_VIEWS = [
		{
			value: 'open ',
			key: 'support.openMany',
			getOpenTickets: true,
			title: 'support.openTickets',
			renderRow: renderOpenTableRow,
			columns: props.isClient ? columnsOpenClient : COLUMNS_OPEN_CONTACT,
			noData: 'support.noOpenTickets',
			count: ticketCounts.open
		},
		{
			value: 'closed',
			key: 'support.closedMany',
			getOpenTickets: false,
			title: 'support.closedTickets',
			renderRow: renderClosedTableRow,
			columns: props.isClient ? columnsClosedClient : COLUMNS_CLOSED_CONTACT,
			noData: 'support.noClosedTickets',
			count: ticketCounts.closed
		},
		{
			value: 'archived',
			key: 'support.archived',
			getArchivedTickets: true,
			title: 'support.archivedTickets',
			renderRow: renderArchivedTableRow,
			columns: props.isClient ? columnsOpenClient : COLUMNS_OPEN_CONTACT,
			noData: 'support.noArchivedTickets',
			count: ticketCounts.archived
		}
	];

	return (
		<Router>
			{hasSubaccountsFeature ? (
				<Flex space="mtxl mbl mll" alignItems="center" gap="u3">
					<Text>{T('support.showTickets')}</Text>
					<ButtonSelect
						size="sm"
						value={view}
						options={[
							{ value: 'all', title: T('support.all') },
							...TICKET_VIEWS.map(({ value, key, count }) => ({
								value,
								title: count + ' ' + T(key)
							}))
						]}
						onChange={setView}
					/>
				</Flex>
			) : null}

			{TICKET_VIEWS.filter(({ value }) => view === 'all' || view === value).map(
				({ key, getOpenTickets, getArchivedTickets, title, renderRow, columns, noData }) => (
					<SimpleListView<Ticket>
						key={key}
						classes={className}
						renderTableRow={renderRow(users, subaccountIds, isOperationalAccount, hasSubaccountsV2)}
						getData={(rb: RequestBuilder) =>
							getData({
								client: props.client,
								isClient: props.isClient,
								rb,
								getOpenTickets,
								getArchivedTickets,
								subaccountIds
							})
						}
						attributes={TicketAttributes}
						columns={columns}
						broadcastType="ticket"
						renderNoData={() => <DefaultNoData formatNoData={() => T(noData)} subtitle="" />}
						renderHeader={() => (
							<Row className={className.elem('header').b()}>
								<Text size="xl">{T(title)}</Text>
							</Row>
						)}
					/>
				)
			)}
		</Router>
	);
};

export default TicketList;
