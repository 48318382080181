'use strict';

import RangeInput from 'App/components/Inputs/RangeInput';

angular.module('domain.advancedSearch').directive('accountInfoSearchSection', [
	'FeatureHelper',
	'$safeApply',
	function (FeatureHelper, $safeApply) {
		return {
			restrict: 'A',
			templateUrl: require('App/upsales/domain/advanced-search/directives/accountInfoSearchSection.tpl.html?file'),
			scope: {
				Filters: '=filters',
				onChange: '&',
				onKeyup: '&'
			},
			controller: [
				'$scope',
				'AppService',
				function ($scope, AppService) {
					$scope.inited = false;
					$scope.ngChange = function () {
						var accountManagerFilterIsActive =
							$scope.Filters.AccountManagerFilters.value.User.value.length ||
							$scope.Filters.AccountManagerFilters.value.missingAccountManager;

						if (accountManagerFilterIsActive) {
							$scope.Filters.AccountManagerFilters.inactive = false;
						}

						if ($scope.onChange) {
							$scope.onChange();
						}
					};

					$scope.ngKeyup = function (e) {
						if ($scope.onKeyup) {
							$scope.onKeyup({ $event: e });
						}
					};

					$scope.RangeInput = RangeInput;
					$scope.sniCodeRangeActive = false;

					$scope.toggleSniCodeRange = () => {
						// Clear all filters when the filter type is changed
						$scope.Filters.SniCodeRange.value.start = null;
						$scope.Filters.SniCodeRange.value.end = null;
						$scope.Filters.SniCode.value = [];
						$scope.sniCodeRangeActive = !$scope.sniCodeRangeActive;
						$safeApply($scope);
					};

					$scope.onSniCodeRangeMinChange = value => {
						$scope.Filters.SniCodeRange.value.start = value;
						$scope.ngChange();
						$safeApply($scope);
					};
					$scope.onSniCodeRangeMaxChange = value => {
						$scope.Filters.SniCodeRange.value.end = value;
						$scope.ngChange();
						$safeApply($scope);
					};

					var init = function () {
						if ($scope.Filters.RegDate.value.preset !== 'custom') {
							$scope.Filters.RegDate.value = {
								field: 'date',
								start: null,
								end: null,
								preset: 'custom'
							};
						}

						$scope.Filters.Name.comparisonType = 'Search';
						$scope.Filters.Notes.comparisonType = 'Search';
						$scope.Filters.JourneyStep.comparisonType =
							$scope.Filters.JourneyStep.comparisonType || 'Equals';

						$scope.inited = true;

						$scope.show = FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);

						$scope.categoryTypes = AppService.getCategoryTypes('account');

						if (Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS)) {
							$scope.standardFields = _.reduce(
								Tools.AppService.getMetadata().standardFields.Client,
								function (res, config) {
									if (
										config.canHide === true &&
										config.active === true &&
										['Facebook', 'Twitter', 'LinkedIn', 'About'].indexOf(config.name) === -1
									) {
										if (config.name === 'CreditRating') {
											res.push(_.assign({}, config, { nameTag: 'default.creditRating' }));
										} else if (config.name === 'OrgNo') {
											res.push(
												_.assign({}, config, {
													nameTag: 'default.orgNumberShort',
													name: 'OrgNoLookup',
													hideCount: true,
													type: 'LookUp'
												})
											);
										} else if (
											['SniCode', 'SicCode', 'NaicsCode', 'UKSicCode', 'NaceCode'].indexOf(
												config.name
											) > -1
										) {
											res.push(
												_.assign({}, config, {
													type: 'LookUp',
													staticValue: config.field
												})
											);
										} else if (config.name === 'NoEmployees') {
											res.push(_.assign({}, config, { nameTag: 'default.numOfEmployees.short' }));
										} else {
											res.push(config);
										}

										if (config.type === 'Date') {
											$scope.Filters[config.name].value.preset = 'custom';
										}
									}
									return res;
								},
								[]
							);
						} else {
							$scope.standardFields = [];
						}

						if (FeatureHelper.isAvailable(FeatureHelper.Feature.JOURNEY_STATUS)) {
							$scope.journeySteps = AppService.getJourneySteps();
						}

						$scope.showActiveFilter = FeatureHelper.isAvailable(FeatureHelper.Feature.INACTIVE_COMPANIES);

						const hasSubaccountsV1 = FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
						const hasSubaccountsV2 =
							FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccountsV1;
						const hasSubaccountsFeature =
							FeatureHelper.isAvailable(FeatureHelper.Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
						$scope.hasSubAccounts = (hasSubaccountsV1 && !hasSubaccountsV2) || hasSubaccountsFeature;
					};

					// eslint-disable-next-line promise/catch-or-return
					AppService.loadedPromise.then(function () {
						var unWatchInit = $scope.$watch('Filters', function (filters) {
							if (filters !== undefined) {
								unWatchInit();
								init();
							}
						});
					});
				}
			]
		};
	}
]);
