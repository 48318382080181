import { TableColumn, TableRow } from '@upsales/components';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import useComponentDidUnmount from 'App/components/hooks/useComponentDidUnmount';
import ActionColumn, { Action } from 'App/pages/CompanyGroupCard/ActionColumn';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import OrderAttributes from 'App/babel/attributes/Order';
import orderFilters from 'App/babel/filterConfigs/Order';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import OrderResource from 'App/resources/Order';
import ListView from 'App/components/ListView';
import Order from 'App/resources/Model/Order';
import { DisplayType, Type } from 'App/babel/attributes/Attribute';

import './OpportunityList.scss';

const renderTableRow = (order: Order, { columns, attributes }: ListViewTableProvided<Order>) => {
	const openOrder = (order: Order) => {
		Tools.$upModal.open('editOrder', { id: order.id });
	};

	return (
		<TableRow key={order.id} onClick={() => openOrder(order)}>
			{columns.map(column =>
				column === '' ? (
					<TableColumn key={column + order.id}>
						<ActionColumn
							entity={'order'}
							object={order}
							actions={[Action.CreateDocument, Action.Delete]}
						/>
					</TableColumn>
				) : (
					<ListViewDefaultColumn<Order> key={column} item={order} attributes={attributes} column={column} />
				)
			)}
		</TableRow>
	);
};

type Props = {
	clientIds: number[];
	filterValue: 'all' | 'open' | 'lost';
	hideHeader?: boolean;
	columns?: string[];
	getDataFilter: (rb: RequestBuilder, clientIds: number[]) => void;
};

const OpportunityList = ({
	clientIds,
	hideHeader,
	filterValue,
	getDataFilter,
	columns = ['date', 'client', 'description', 'stage', 'value', 'user', '']
}: Props) => {
	const { t } = useTranslation();

	const listReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof OrderResource.find>>>>(null);
	const OrderFilters = useMemo(() => orderFilters(), []);
	const [initialSorting, setInitialSorting] = useState([{ attribute: 'date', ascending: false }]);

	useComponentDidUnmount(() => {
		listReq.current?.cancel?.();
	});

	const classes = new BemClass('OpportunityList');

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			if (clientIds.length === 0) {
				return {
					data: [],
					metadata: { total: 0, limit: 50, offset: 0 }
				};
			}

			getDataFilter(rb, clientIds);
			rb.addFilter({ field: 'probability' }, ComparisonTypes.NotEquals, 100);

			if (filterValue === 'open') {
				rb.addFilter({ field: 'probability' }, ComparisonTypes.NotEquals, 0);
			}
			if (filterValue === 'lost') {
				rb.addFilter({ field: 'probability' }, ComparisonTypes.Equals, 0);
			}

			listReq.current = makeCancelable(OrderResource.find(rb.build()));

			return listReq.current.promise;
		},
		[filterValue]
	);

	const modifiedOrderAttributes = { ...OrderAttributes };
	modifiedOrderAttributes.stage.sortable = 'probability';
	modifiedOrderAttributes.stage.type = Type.Number;
	modifiedOrderAttributes.description.displayType = DisplayType.EllipsisText;

	return (
		<ListView<Order>
			key={initialSorting.map(sort => `${sort.ascending}`).join('-')}
			className={classes.elem('listview').b()}
			attributes={modifiedOrderAttributes}
			hideFilters
			isNewListView
			subtitle=""
			renderHeader={hideHeader ? () => <div /> : undefined}
			initialSorting={initialSorting}
			renderTableRow={renderTableRow}
			quickSearchFilter="ListSearch"
			quickSearchPlaceholder={t('companyGroup.opportunities.searchOpportunities')}
			broadcastType="opportunity"
			filterConfigs={OrderFilters}
			onSortChange={({ field }) => {
				if (field === 'client.operationalAccount.name') {
					setInitialSorting(prev =>
						prev.map(sort => ({
							...sort,
							ascending: !sort.ascending,
							missing: sort.ascending ? 'first' : 'last'
						}))
					);
				} else if (field === 'probability') {
					setInitialSorting(prev =>
						prev.map(sort => ({
							...sort,
							ascending: !sort.ascending
						}))
					);
				}
			}}
			getData={getData}
			initialFilters={{}}
			columns={columns}
		/>
	);
};

export default OpportunityList;
